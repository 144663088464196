import {Component, Input} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeText} from 'app/shared/model/frage/input/InputTypeText';
import {InputTypeTextAntwort} from 'app/shared/model/antwort/input/InputTypeTextAntwort';

const debug = require('debug')('InputTypeTextViewComponent');

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: 'app-input-type-text-view',
	templateUrl: './InputTypeTextViewComponent.html',
	styleUrls: ['./InputTypeTextViewComponent.less']
})
export class InputTypeTextViewComponent implements IInputTypeViewComponent<InputTypeText> {

	context = {};

	variant: string;

	@Input()
	antwort: InputTypeTextAntwort;

	@Input()
	type: InputTypeText;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	variantClass(): string {
		return this.variant;
	}
}
