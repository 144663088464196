<div class="list-unstyled fragebogen-liste">
	<li *ngFor="let fragebogen of frageboegen" class="abschnitt">
		<div class="text">
			<div class="title">
				<a (click)="onNavigationClicked()" routerLink="/fragebogen/{{fragebogen.id}}">{{ fragebogen.name }}</a>
			</div>
			<div class="description" style="color: #333333; font-weight: normal">
				<span class="subtitle">
					<span>
						{{getRechteLabel(fragebogen)}}
					</span>
				</span>
			</div>
			<div class="description">
				{{ fragebogen.beschreibungKurz }}
			</div>
		</div>
		<div class="buttons-right" ngPreserveWhitespaces>
			<app-ausfueller-verwaltung-dialog-button
				*ngIf="darfFragebogenausfuellerVerwalten()"
				[fragebogen]="fragebogen"
				[type]="'Fragebogenausfueller'"
				class="fragebogenausfueller">
				<span class="controls"><span class="btn btn-default btn-xs disabled" style="width: 45px; cursor: wait;">…</span></span>
			</app-ausfueller-verwaltung-dialog-button>
			<a (click)="goToFragebogen(fragebogen)" appPreventDefault class="btn btn-primary" type="button">Zum Fragebogen</a>
		</div>
	</li>
</div>
