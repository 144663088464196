import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AusfuellerVerwaltungDialogComponent} from 'app/form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
/* eslint-disable-next-line max-len */
import {AbschnittsausfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AbschnittReadonlyService} from 'app/form-viewer/service/AbschnittReadonlyService';
import {AbschnittSperreService} from 'app/form-viewer/service/AbschnittSperreService';
import {AbschnittStorageService} from 'app/form-viewer/service/AbschnittStorageService';
import {FragebogenAbschnittsdaten} from 'app/form-viewer/service/AbschnittStorageService/FragebogenAbschnittsdaten';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';
import {FragebogenService} from 'app/shared/service/FragebogenService';
import {NavigationService} from 'app/shared/service/NavigationService';
import {DateFormatterUtil} from 'app/shared/util/DateFormatterUtil';
import {CapitalizeFirst} from 'app/util/CapitalizeFirst';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {NewTabComponent} from "../../shared/components/new-tab.component";
import {FragebogenDruckenComponent} from "../fragebogen-drucken/FragebogenDruckenComponent";

@Component({
	selector: 'app-viewer-abschnitt-liste',
	templateUrl: './ViewerAbschnittListeComponent.html',
	styleUrls: ['./ViewerAbschnittListeComponent.less'],
})
export class ViewerAbschnittListeComponent implements OnInit {

	private static readonly ANZAHL_ABSCHNITTE_AB_DENEN_ZWEITER_ZURUECK_BUTTON_EINGEBLENDET_WIRD = 9;


	@ViewChild('vorschau')
	vorschau: NewTabComponent;

	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	fragebogen_abgeschlossen: boolean;

	@Input()
	showControls: boolean;

	@Output()
	selectAbschnitt = new EventEmitter<Abschnitt>();

	@ViewChild('dialog', {static: true})
	dialog: AusfuellerVerwaltungDialogComponent;

	@ViewChild('modal')
	modal: ModalDirective;

	showBackLinks: boolean;
	hatFragebogenAntworten = false;
	showVorschau = false;


	private fragebogenAbschnittsdaten: FragebogenAbschnittsdaten;

	constructor(
		private abschnittStorageService: AbschnittStorageService,
		private abschnittSperreService: AbschnittSperreService,
		private capitalizeFirst: CapitalizeFirst,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private previewService: PreviewService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private navigationService: NavigationService,
		private abschnittReadonlyService: AbschnittReadonlyService,
		private fragebogenService: FragebogenService) {
	}

	get institutionId(): string {
		return this.aktuelleBerechtigungService.getInstitutionsId();
	}

	get abschnitte(): Abschnitt[] {
		return this.fragebogenAbschnittsdaten.abschnitte;
	}

	isAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istAdministrator();
	}

	isCoAdministrator(): boolean {
		return this.ausfuellerBerechtigungsService.istCoAdministrator();
	}

	isFragebbogenAusfuellerFuer(abschnitt: Abschnitt): boolean {
		return this.ausfuellerBerechtigungsService.istFragebbogenAusfuellerFuer(abschnitt.fragebogen_id);
	}

	isAbschnittsAusfuellerFuer(abschnitt: Abschnitt): boolean {
		return this.ausfuellerBerechtigungsService.istAbschnittsAusfuellerFuer(abschnitt.id);
	}

	getRechteLabel(abschnitt: Abschnitt) {
		if (this.isAdministrator()) {
			return "Administrator";
		} else if (this.isCoAdministrator()) {
			return "CoAdministrator"
		} else if (this.isFragebbogenAusfuellerFuer(abschnitt)) {
			return "Fragebogenausfüller";
		} else if (this.isAbschnittsAusfuellerFuer(abschnitt)) {
			return "Abschnittsausfüller";
		} else {
			return "Mit den aktuellen Berechtigungen haben Sie nur Leserechte";
		}
	}

	ngOnInit(): void {
		this.showBackLinks = this.previewService.isNotInPreview();
		this.fragebogenAbschnittsdaten = this.abschnittStorageService.get(this.fragebogen);

		if (this.fragebogen.hatVorjahresfragebogen() && this.previewService.isNotInPreview()) {
			this.fragebogenService.hatAbgeschlosseneFragebogenVersion(this.fragebogen.vorjahresfragebogen.id, this.institutionId).then(value => this.hatFragebogenAntworten = value);
		}
	}

	onSelectAbschnitt(abschnitt: Abschnitt): void {
		this.onNavigationClicked();
		this.selectAbschnitt.emit(abschnitt);
	}

	isAbschnittReadonly(abschnitt: Abschnitt): boolean {
		return this.abschnittReadonlyService.sindAbschnittsinhalteReadonly(this.fragebogenAbschnittsdaten, this.fragebogen_abgeschlossen, abschnitt);
	}

	iconClassForAbschnitt(abschnitt: Abschnitt): string {
		if (this.isAbschnittReadonly(abschnitt)) {
			return 'icon-eye';
		} else {
			return 'icon-edit';
		}
	}

	istAbschnittVonAnderemUserGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittVonAnderemUserGesperrt(this.fragebogenAbschnittsdaten.abschnittSperren, abschnitt);
	}

	istVonAktuellemUserInAndererInstanzGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittVonAktuellemUserInAndererInstanzGesperrt(this.fragebogenAbschnittsdaten.abschnittSperren, abschnitt);
	}

	istAbschnittsausfuellerVerwaltenSichtbar(): boolean {
		return this.previewService.isNotInPreview() &&
			!this.fragebogen_abgeschlossen &&
			this.ausfuellerBerechtigungsService.darfAbschnittsausfuellerFuerFragebogenVerwalten(this.fragebogen);
	}

	sindAlleFragenGeprueft(abschnitt: Abschnitt): boolean {
		return this.fragebogenAbschnittsdaten.sindAlleFragenGeprueft(abschnitt);
	}

	getAnzahlGepruefterFragen(abschnitt: Abschnitt): number {
		return this.fragebogenAbschnittsdaten.getAnzahlGepruefterFragen(abschnitt);
	}

	showLowerBackLink(): boolean {
		return this.showBackLinks &&
			this.fragebogenAbschnittsdaten.hatAbschnitte() &&
			this.fragebogenAbschnittsdaten.length >= ViewerAbschnittListeComponent.ANZAHL_ABSCHNITTE_AB_DENEN_ZWEITER_ZURUECK_BUTTON_EINGEBLENDET_WIRD;
	}

	zeigeLetztenBearbeiterAn(abschnitt: Abschnitt): string {
		const status = this.fragebogenAbschnittsdaten.getStatus(abschnitt);
		if (status) {
			return DateFormatterUtil.transformToDateOnly(status.modified) + ' von ' + status.modifiedBy.getNameMitDativAnrede();
		} else {
			return '';
		}
	}

	zeigeAktuellenBearbeiterAn(abschnitt: Abschnitt): string {
		const sperre = this.fragebogenAbschnittsdaten.getSperren(abschnitt);
		if (sperre) {
			return sperre.bearbeiter.getNameMitDativAnrede();
		} else {
			return '';
		}
	}

	isInBearbeitung(abschnitt: Abschnitt): boolean {
		return this.fragebogenAbschnittsdaten.hatSperren(abschnitt);
	}

	abschnittNochNichtBearbeitet(abschnitt: Abschnitt): boolean {
		const hatStatus = this.fragebogenAbschnittsdaten.hatStatus(abschnitt);
		const hatSperre = this.fragebogenAbschnittsdaten.hatSperren(abschnitt);
		return !(hatSperre || hatStatus);
	}

	zeigeFragebogenAufschliessen(): boolean {
		return this.previewService.isNotInPreview() &&
			this.fragebogen_abgeschlossen &&
			this.fragebogen.kannRkNochAusgefuelltWerden() &&
			this.fragebogen.kannRkWiedereroeffnetWerden() &&
			this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen();
	}

	zeigeDeaktiviertenAufschliessenButton(): boolean {
		return this.previewService.isNotInPreview() &&
			this.fragebogen_abgeschlossen &&
			(!this.fragebogen.kannRkNochAusgefuelltWerden() || !this.fragebogen.kannRkWiedereroeffnetWerden()) &&
			this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen();
	}

	zeigeDeaktiviertenAbschliessenButton(): boolean {
		return this.previewService.isNotInPreview() &&
			!this.fragebogen_abgeschlossen &&
			!this.fragebogen.kannRkNochAusgefuelltWerden() &&
			this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen();
	}

	zeigeFragebogenAbschliessen(): boolean {
		return this.previewService.isNotInPreview() &&
			!this.fragebogen_abgeschlossen &&
			this.fragebogen.kannRkNochAusgefuelltWerden() &&
			this.ausfuellerBerechtigungsService.darfFrageboegenAufUndAbschliessen();
	}

	abschnittsausfuellerVerwaltenDialogOeffnen(abschnitt: Abschnitt): void {
		this.dialog.show(
			new AbschnittsausfuellerVerwaltungFormConfiguration(abschnitt, this.fragebogen)
		);
	}

	getAnzahlFragen(abschnitt: Abschnitt): number {
		return abschnitt ? abschnitt.fragen.length : 0;
	}

	onZurueckZurUebersichtClicked(): void {
		this.navigationService.goToFragebogenuebersicht();
	}

	uebernehmeAntworten(fragebogenId, institutionId): void {
		this.fragebogenService.uebernehmeAntworten(fragebogenId, institutionId);
		this.closeDialog();
	}

	darfAntwortenUebernehmen() {
		return this.previewService.isNotInPreview() && this.ausfuellerBerechtigungsService.darfAntwortenUebernehmen();
	}

	openDialog(event) {
		event.preventDefault();
		this.modal.show();
	}

	closeDialog() {
		this.modal.hide();
	}

	onNavigationClicked() {
		try {
			this.blockUI.start('Daten werden verarbeitet...');
		} finally {
			this.blockUI.stop();
		}
	}

	print() {
		this.showVorschau = true;
		this.vorschau.openContent(FragebogenDruckenComponent, 'Druckvorschau');
	}
}
