import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {EditLockService} from 'app/shared/service/EditLockService';

@Component({
	selector: 'app-editor-abschnitt-view',
	templateUrl: './AbschnittViewComponent.html',
	styleUrls: ['./AbschnittViewComponent.less']
})
export class AbschnittViewComponent implements OnInit {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	edit = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	constructor(private lock: EditLockService) {
	}

	ngOnInit() {
	}

	beginEdit() {
		this.edit.emit();
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}
}
