import {Injectable} from '@angular/core';

const debug = require('debug')('service:NotificationSeenService');

@Injectable()
export class NotificationSeenService {

	setHasSeen(type: Object, id: string): void {
		this.setCookie(this.getCookieName(type, id));
	}

	hasSeen(type: Object, id: string): boolean {
		return this.hasCookie(this.getCookieName(type, id));
	}

	private getDateOfTomorrow(): string {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		tomorrow.setHours(0);
		tomorrow.setMinutes(0);
		tomorrow.setSeconds(0);
		tomorrow.setMilliseconds(0);
		return tomorrow.toUTCString();
	}

	private getCookieName(type: object, id: string): string {
		const name = encodeURI(this.constructor.name + '_' + type.constructor.name + '_' + id);
		return name.replace(/[^a-zA-Z0-9_\-]/g, '');
	}

	private setCookie(cname: string): void {
		document.cookie = cname + '=1; Expires=' + this.getDateOfTomorrow() + ';path=/';
	}

	private hasCookie(cname: string): boolean {
		const value = ';' + document.cookie + ';';
		const regexp = new RegExp('; *' + cname + '=1 *;');
		if (value.search(regexp) > -1) {
			return true;
		}
		return false;
	}
}
