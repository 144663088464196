<div class="form-group textfeld">
	<ng-container [ngSwitch]="showTextarea()" *ngIf="!printView">
		<textarea
			*ngSwitchCase="true"
			class="form-control"
			[(ngModel)]="antwort.textantwort"
			[readonly]="readonly"
			name="antwort"
			placeholder="{{ frage.platzhalterText }}"
			appPreventSubmitOnEnter
			appScrollToFocusedElement
		></textarea>
		<input
			*ngSwitchCase="false"
			class="form-control"
			[(ngModel)]="antwort.textantwort"
			[readonly]="readonly"
			name="antwort"
			placeholder="{{ frage.platzhalterText }}"
			appScrollToFocusedElement
		/>
	</ng-container>
	<div *ngIf="printView" class="textfeld readonly">
		{{ antwort.textantwort ? antwort.textantwort : "-" }}
	</div>
</div>
