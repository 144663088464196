import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FragebogenLoeschenService} from 'app/form-editor/service/FragebogenLoeschenService';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {Recherchekomplex} from 'app/shared/model/Recherchekomplex';
import {NavigationService} from 'app/shared/service/NavigationService';
import {SelectItem} from 'primeng/api';

@Component({
	selector: 'app-editor-fragebogen-list',
	templateUrl: './EditorFragebogenListComponent.html',
	styleUrls: ['./EditorFragebogenListComponent.less'],
	providers: []
})
export class EditorFragebogenListComponent {

	@Input()
	ueberschrift: '';

	@Input()
	frageboegenByRecherchekomplex: { [key: string]: Fragebogen[] } = {};

	@Input()
	recherchekomplexe: Recherchekomplex[] = [];

	@Output()
	duplicateCreated = new EventEmitter<Fragebogen>();

	@Output()
	fragebogenVeroeffentlicht = new EventEmitter<Fragebogen>();

	items: SelectItem[] = [{label: 'Reports', value: ''}];
	selectedReport: any;

	constructor(
		private navigationService: NavigationService,
		private fragebogenLoeschenService: FragebogenLoeschenService,
		private alertService: AlertService,
		private http: HttpClient
	) {
	}

	onClick(fragebogen: Fragebogen) {
		if (fragebogen.veroeffentlicht) {
			this.navigationService.goToEditorFragebogenAntworten(fragebogen);
		} else {
			this.navigationService.goToEditorFragebogen(fragebogen);
		}
	}

	loesche(fragebogen: Fragebogen) {
		this.fragebogenLoeschenService.loeschen(fragebogen).then(() => {
			this.alertService.showSuccess('Fragebogen ' + fragebogen.name + ' erfolgreich gelöscht.');
			const index = this.frageboegenByRecherchekomplex[fragebogen.recherchekomplex.id].indexOf(fragebogen);
			this.frageboegenByRecherchekomplex[fragebogen.recherchekomplex.id].splice(index, 1);
		});
	}

	existierenFrageboegenFuerRecherchekomplex(recherchekomplex: Recherchekomplex): boolean {
		return (recherchekomplex.id in this.frageboegenByRecherchekomplex) &&
			(this.frageboegenByRecherchekomplex[recherchekomplex.id]) &&
			(this.frageboegenByRecherchekomplex[recherchekomplex.id].length > 0);
	}

	getFrageboegenFuerRecherchekomplex(recherchekomplex: Recherchekomplex): Fragebogen[] {
		return this.frageboegenByRecherchekomplex[recherchekomplex.id] || [];
	}

	downloadReport(recherchekomplex: Recherchekomplex) {
		return this.http.get(`/api/editor/erneut-eingereichte-frageboegen?recherchekomplex=${recherchekomplex.id}`, {responseType: 'text'})
				   .toPromise()
				   .then(response => new Blob([response], {type: 'text/csv'}))
				   .then(blob => {
					   const url = window.URL.createObjectURL(blob);
					   const a = document.createElement('a');
					   a.download = `Erneut eingereichte Fragebögen Report - ${recherchekomplex.name}_${new Date().toISOString()}.csv`;
					   a.href = url;
					   a.click();
				   });

	}

	onDuplicateCreated($event: Fragebogen) {
		this.duplicateCreated.emit($event);
	}

	onFragebogenVeroeffentlicht($event: Fragebogen) {
		this.fragebogenVeroeffentlicht.emit($event);
	}
}
