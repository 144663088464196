import {Fragebogen} from 'app/shared/model/Fragebogen';
import {SimplePluralPipe} from 'app/util/SimplePluralPipe';

export class SichtbarerFragebogenDto {
	fragebogen: Fragebogen;
	anzahlDerAbschnitte: number;
	istAbgeschlossen: boolean;

	constructor(dto?: SichtbarerFragebogenDto) {
		if (dto) {
			this.fragebogen = new Fragebogen(dto.fragebogen);
			this.anzahlDerAbschnitte = dto.anzahlDerAbschnitte;
			this.istAbgeschlossen = dto.istAbgeschlossen;
		}
	}

	get zustandsbeschreibung(): string {
		let abgeschlossenStatus = '';
		if (this.istAbgeschlossen) {
			abgeschlossenStatus = 'Ist übermittelt, ';
		}

		return abgeschlossenStatus + SimplePluralPipe.transform(this.anzahlDerAbschnitte, 'ein Abschnitt', 'Abschnitte');
	}
}
