<p-orderList
	class="list-group"
	[value]="abschnitte"
	[class.readonly]="disabled"
	[dragdrop]="true">
	<ng-template let-abschnitt pTemplate="item">
		<li class="list-group-item" >
			{{ abschnitt.ueberschrift }}
		</li>
	</ng-template>
</p-orderList>
