import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FragebogenVeroeffentlichenService} from 'app/form-editor/service/FragebogenVeroeffentlichenService';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {NavigationService} from 'app/shared/service/NavigationService';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
			   selector: 'app-editor-fragebogen-list-veroeffentlichen-modal',
			   templateUrl: './EditorFragebogenListeVeroeffentlichenModalComponent.html',
			   styleUrls: ['./EditorFragebogenListeVeroeffentlichenModalComponent.less'],
			   providers: []
		   })
export class EditorFragebogenListeVeroeffentlichenModalComponent {
	@Input()
	fragebogen: Fragebogen;

	@Output()
	fragebogenVeroeffentlicht = new EventEmitter<Fragebogen>();

	@ViewChild('modal', { static: true })
	modal: ModalDirective;

	constructor(private fragebogenVeroeffentlichenService: FragebogenVeroeffentlichenService, private alertService: AlertService, private navigationService: NavigationService) {
	}

	openModalClicked(event: any) {
		this.modal.show();
		event.stopPropagation();
	}

	commitVeroeffentlichen() {
		this.closeDialog();
		this.fragebogenVeroeffentlichenService.veroeffentlichen(this.fragebogen)
			.then(response => new Fragebogen(response))
			.then(fragebogen => {
				this.zeigeErfolgsmeldung();
				this.fragebogenVeroeffentlicht.emit(fragebogen);
			});
	}

	closeDialog() {
		this.modal.hide();
	}

	private zeigeErfolgsmeldung() {
		this.alertService.showSuccess('Fragebogen ' + this.fragebogen.name + ' erfolgreich veröffentlicht.');
	}
}
