import {Component, Input} from '@angular/core';
import {AusfuellerVerwaltungInnerFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
import {ControlContainer, NgForm} from '@angular/forms';
/* eslint-disable-next-line max-len */
import {AbschnittsausfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AbschnittsausfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/AbschnittsausfuellerAnlegenDto';

@Component({
	selector: 'app-abschnittsausfueller-verwaltung-form',
	templateUrl: './AbschnittsausfuellerVerwaltungFormComponent.html',
	styleUrls: ['./AbschnittsausfuellerVerwaltungFormComponent.less'],
	viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class AbschnittsausfuellerVerwaltungFormComponent implements AusfuellerVerwaltungInnerFormComponent {
	@Input()
	dto: AbschnittsausfuellerAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: AbschnittsausfuellerVerwaltungFormConfiguration;
}
