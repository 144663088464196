import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Institution} from 'app/shared/model/Institution';
import {InstitutionFragebogenStatus} from 'app/shared/model/InstitutionFragebogenStatus';
import {InstitutionAntwortService} from 'app/shared/service/InstitutionAntwortService';
import {UUID} from 'app/util/export-types';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-institutions-auswahl',
	templateUrl: './InstitutionsAuswahlComponent.html',
	styleUrls: ['./InstitutionsAuswahlComponent.less'],
})
export class InstitutionsAuswahlComponent implements OnInit {
	@Input() private institutionId: UUID;
	@Input() private fragebogenId: UUID;
	@Input() fragebogenName: string;
	institutionenUndStatus: InstitutionFragebogenStatus[];

	@Output() institutionSelected = new EventEmitter<Institution>();

	canClose: boolean;

	@ViewChild('institutionModal', { static: true })
	institutionModal: ModalDirective;

	constructor(private institutionAntwortService: InstitutionAntwortService) {
	}

	ngOnInit(): void {
		this.canClose = this.hasInstitution();

		this.institutionModal.config = {
			backdrop: this.canClose ? true : 'static'
		};

		if (!this.hasInstitution()) {
			this.institutionModal.show();
		}

		this.fetchInstitutionen();
	}

	hasInstitution() {
		return !!this.institutionId;
	}

	isLoaded() {
		return !!this.institutionenUndStatus;
	}

	private fetchInstitutionen() {
		this.institutionAntwortService.getInstitutionenUndStatusFuerFragebogen(this.fragebogenId)
			.then(institutionenUndStatus => this.institutionenUndStatus = institutionenUndStatus);
	}

	onSelect(institution: Institution) {
		this.institutionSelected.emit(institution)
		this.institutionModal.hide()
		// window.location.href = '/editor/fragebogen/' + this.fragebogenId + '/antworten/' + institution.id;
	}
}
