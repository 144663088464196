<br>
<p-table #table
		 [globalFilterFields]="['ausfuellerAnrede', 'ausfuellerName', 'ausfuellerEmail', 'institutionName', 'ausfuellerLastLogin']"
		 [paginator]="true"
		 [rowTrackBy]="trackeVeraenderungenAmBaum"
		 [rows]="rows"
		 [showCurrentPageReport]="true"
		 [value]="verwaltbareAusfuellerMitInstitutionUndRecherchekomplexList"
		 [(selection)]="selectedValue"
		 sortField="ausfuellerName"
		 sortOrder="1"
		 selectionMode="single"
		 styleClass="p-datatable-striped">

	<ng-template pTemplate="caption">
		<div class="flex flex-row justify-content-between">
			<div>{{getAnzahlRegistrierteNutzer()}} registrierte Benutzer gefunden</div>
			<div class="flex">
				<div class="p-input-icon-left">
					<i class="pi pi-filter"></i>
					<input (input)="table.filterGlobal($any($event.target).value, 'contains')"
						   class="searchField"
						   pInputText
						   placeholder="Filter über alle Spalten"
						   style="margin-right: 9px"
						   type="text">
				</div>

				<button class="table-button wideIconButton table-text-button p-button-raised"
						label="Einladen"
						pButton
						style="margin-left: 5px; background-color: #079716; border: 1px solid #079716;"
						(click)="erstelleAusfueller()"
						data-bs-placement="bottom" data-bs-toggle="tooltip"
						title="Neue Benutzer einladen"
						type="button">
					<fa-icon [icon]="faUserPlus"></fa-icon>
				</button>

<!--				<button class="table-button wideIconButton table-text-button p-button-raised "-->
<!--						label="PWDs für alle Benutzer neu generien und ggf. im KC anlegen"-->
<!--						pButton-->
<!--						style="margin-left: 5px; background-color: #e10101; border: 1px solid #e10101;"-->
<!--						(click)="erstellePwdsNeu()"-->
<!--						data-bs-placement="bottom" data-bs-toggle="tooltip"-->
<!--						title="Neue Benutzer einladen"-->
<!--						type="button">-->
<!--					<fa-icon [icon]="faUserPlus"></fa-icon>-->
<!--				</button>-->

			</div>
		</div>
	</ng-template>

	<ng-template pTemplate="header">
		<tr>
			<th pSortableColumn="ausfuellerAnrede" style="width: 13rem">Anrede
				<p-sortIcon field="ausfuellerAnrede"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerName">Name
				<p-sortIcon field="ausfuellerName"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerEmail">E-Mail
				<p-sortIcon field="ausfuellerEmail"></p-sortIcon>
			</th>
			<th pSortableColumn="ausfuellerLastLogin" style="width: 25rem">Letzter Login
				<p-sortIcon field="ausfuellerLastLogin"></p-sortIcon>
			</th>
			<th style="width: 5rem"></th>
			<th style="width: 5rem"></th>
		</tr>
		<tr>
			<th>
				<p-columnFilter [showMenu]="false" field="ausfuellerAnrede" matchMode="contains">
					<ng-template let-filter="filterCallback" let-value pTemplate="filter">
						<span class="p-input-icon-left p-column-filter-row anredeFilter">
							<i class="pi pi-filter" style="z-index: 2;"></i>
							<p-dropdown (onChange)="filter($event.value)"
										[ngModel]="value"
										[options]="anreden"
										[showClear]="true"
										placeholder="&nbsp;">
								<ng-template let-option pTemplate="item">
									<span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
								</ng-template>
							</p-dropdown>
						</span>
					</ng-template>
				</p-columnFilter>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
					<i class="pi pi-filter"></i>
					<input (input)="table.filter($any($event.target).value, 'ausfuellerName', 'contains')"
						   class="p-column-filter-row"
						   pInputText
						   type="text">
				</span>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
					<i class="pi pi-filter"></i>
					<input (input)="table.filter($any($event.target).value, 'ausfuellerEmail','contains')"
						   class="p-column-filter-row"
						   pInputText
						   type="text">
				</span>
			</th>
			<th>
				<span class="p-input-icon-left p-column-filter-row">
					<i class="pi pi-filter"></i>
					<input (input)="table.filter($any($event.target).value, 'ausfuellerLastLoginString','contains')"
						   class="p-column-filter-row"
						   pInputText
						   type="text">
				</span>
			</th>
			<th></th>
			<th></th>
		</tr>
	</ng-template>

	<ng-template let-verwaltbarerAusfueller pTemplate="body">
		<tr (dblclick)="bearbeiteAusfueller(verwaltbarerAusfueller)" class="selectableRow">
			<td>{{verwaltbarerAusfueller.ausfuellerAnrede}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerName}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerEmail}}</td>
			<td>{{verwaltbarerAusfueller.ausfuellerLastLoginString}}</td>
			<td>
				<button (click)="bearbeiteAusfueller(verwaltbarerAusfueller)"
						class="p-button-outlined p-button-rounded table-button p-button-raised"
						pButton
						data-bs-toggle="tooltip" data-bs-placement="top"
						title="Benutzer bearbeiten">
					<fa-icon style="margin-right: 1px" [icon]="faPen"></fa-icon>
				</button>
			</td>
			<td>
				<button (click)="entferneAusfuellerAusUnternehmen(verwaltbarerAusfueller);"
						class="p-button-outlined p-button-rounded table-button p-button-raised table-trash-button"
						data-bs-placement="bottom"
						data-bs-toggle="tooltip" pButton
						title="Benutzer aus Unternehmen entfernen">
					<fa-icon style="margin-right: 1px" [icon]="faTrashCan"></fa-icon>
				</button>
			</td>
		</tr>
	</ng-template>

</p-table>
<p-confirmDialog [baseZIndex]="10000"
				 [style]="{width: '50vw'}"
				 rejectButtonStyleClass="p-button-text"></p-confirmDialog>
