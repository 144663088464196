export class DateUtil {

	// yyyy-MM-dd – 2017-12-02
	public static stringToDate(dateString: string): Date {
		const parts: any[] = dateString.split('-');
		return new Date(parts[0], parts[1] - 1, parts[2]);
	}

	/**
	 * Liest den Datumswert aus einer Array Angabe oder String Format aus.
	 *
	 * Ohne Zeit! Bitte dafür eine neue Funktion anlegen.
	 *
	 * Date selbst wird geklont.
	 *
	 * Ansonsten wird der angegebene Wert zurück gegeben
	 */
	static parseDate(obj: any): Date {
		if (!obj) {
			return obj;
		}

		if (this.isDateObject(obj)) {
			return new Date(obj.getTime());
		} else if (Array.isArray(obj)) {
			return DateUtil.arrayToDate(obj);
		} else if (typeof obj === 'string' && obj.match(/\d\d\d\d-\d\d-\d\d/)) {
			return DateUtil.stringToDate(obj);
		}

		return obj;
	}

	private static isDateObject(obj: any): boolean {
		return typeof obj.getMonth === 'function';
	}

	// [yyyy, MM, dd] – [2017, 12, 2]
	private static arrayToDate(dateArray: Array<number>): Date {
		// Timezone des Kunden ist idR. auch unsere Timezone...
		return new Date(
			dateArray[0],
			dateArray[1] - 1,
			dateArray[2],
			0, 0, 0, 0);
	}
}
