<p-orderList class="list-group" [value]="abschnitt.fragen" [attr.data-abschnitt]="abschnitt.id" [dragdrop]="true" (onReorder)="onReorder()">
	<!-- [class.can-drag]="canDrag() -->
	<ng-template let-frage pTemplate="item">
		<li class="list-group-item frage">
			<app-editor-abschnitt-frage *ngIf="frage != null"
										[index]="frage.sortIndex"
										[abschnitt]="abschnitt"
										[frage]="frage"
										(remove)="removeFrage(frage)"
										(update)="updateFrage(frage, $event)"
										(duplicate)="duplicateFrage(frage)"
			></app-editor-abschnitt-frage>
		</li>
	</ng-template>
</p-orderList>

<button class="btn btn-default add-frage" (click)="addFrage()" *ngIf="!isNew()" [disabled]="isReadOnly()"><i class="icon-plus"></i>Frage hinzufügen</button>
