<div class="marker clearfix" *ngIf="!istVersteckt()">
	<button
		type="button"
		(click)="toggleGeprueft()"
		class="btn btn-toggle pull-left"
		[class.btn-checked]="istAlsGeprueftMarkiert()"
		[title]="istAlsGeprueftMarkiert() && !readonly ? 'Erneut klicken um als Ungeprüft zu markieren.' : ''"
		[disabled]="readonly"
	>
		<i class="icon-lock-success"></i>
		{{ istAlsGeprueftMarkiert() ? 'Geprüft' : 'Als geprüft markieren' }}
	</button>

	<div *ngIf="istAlsGeprueftMarkiert()" class="infotext pull-left">
		Als geprüft markiert am {{ frageGeprueftMarker.created | date:'dd.MM.yy HH:mm' }} Uhr
	</div>
</div>
