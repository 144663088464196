<table class="table table-bordered">
	<thead>
	<tr>
		<th></th>
		<th *ngFor="let spalte of getSpalten()">
			{{ spalte.ueberschrift }}

			<app-view-info-text [beschreibung]="spalte.beschreibung"></app-view-info-text>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let zeile of getZeilen()">
		<th>{{ zeile.titel }}</th>
		<td class="input {{zelle.spalte.type.type}}" *ngFor="let zelle of zeile.zellenSpaltenTupel">
			<app-input-view [context]="{ hint: zelle.spalte.ueberschrift }"
							[type]="zelle.spalte.type"
							[antwort]="zelle.antwort"
							[readonly]="readonly"
							[printView]="printView"
							variant="table-cell"
			></app-input-view>
		</td>
	</tr>
	</tbody>
</table>
