import {RolleTitleUtil} from "../../util/RolleTitleUtil";

export class VerwaltbarerAusfuellerAbschnittDto {
	id: string;
	ueberschrift: string;
	label: string;
	hatBerechtigungFuerDiesesObjekt: boolean;
	title: String;

	constructor(verwaltbarerAusfuellerAbschnittDto?: VerwaltbarerAusfuellerAbschnittDto) {
		if (verwaltbarerAusfuellerAbschnittDto) {
			this.id = verwaltbarerAusfuellerAbschnittDto.id;
			this.ueberschrift = verwaltbarerAusfuellerAbschnittDto.ueberschrift;
			this.label = verwaltbarerAusfuellerAbschnittDto.label;
			this.title = RolleTitleUtil.dekliniere(this.label);

			this.hatBerechtigungFuerDiesesObjekt = verwaltbarerAusfuellerAbschnittDto.hatBerechtigungFuerDiesesObjekt;
		}
	}
}
