import {Component, Input} from '@angular/core';
import {EntscheidungsFrage} from 'app/shared/model/frage/EntscheidungsFrage';
import {IFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {EntscheidungsfragenAntwort} from 'app/shared/model/antwort/EntscheidungsfragenAntwort';

const debug = require('debug')('EntscheidungsFrageViewComponent');

@Component({
	selector: 'app-viewer-abschnitt-frage-view-entscheidung',
	templateUrl: './EntscheidungsFrageViewComponent.html',
	styleUrls: ['./EntscheidungsFrageViewComponent.less']
})
export class EntscheidungsFrageViewComponent implements IFrageViewComponent {

	@Input()
	frage: EntscheidungsFrage;

	@Input()
	antwort: EntscheidungsfragenAntwort;

	@Input()
	readonly: boolean;

	printView = false;

	isReadonly() {
		return this.readonly;
	}

	private isEntscheidungGetroffen(): boolean {
		return this.antwort.isEntscheidungGetroffen();
	}

	begruendungsTextfeldAktiviert(): boolean {
		return this.isEntscheidungGetroffen() && this.frage.istBegruendungAktivBei(this.antwort.auswahl);
	}

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}
}
