import {Abschnitt} from 'app/shared/model/Abschnitt';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {FrageGeprueftMarker} from 'app/form-viewer/model/FrageGeprueftMarker';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

const debug = require('debug')('FrageGeprueftMarkerService');

@Injectable()
export class FrageGeprueftMarkerService {

	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	getGeprueftMarkerFuerAbschnitt(abschnitt: Abschnitt): Promise<FrageGeprueftMarker[]> {
		debug('Lade Fertig-Marker für Abschnitt', abschnitt);
		return this.http.get<FrageGeprueftMarker[]>('/view/frage/geprueft-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => FrageGeprueftMarker.buildList(response))
			.catch(this.alertService.handleHttpError);
	}

	alsGeprueftMarkieren(frage: Frage): Promise<void> {
		return this.http.post('/view/frage/geprueft-marker', null, {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}

	alsUngeprueftMarkieren(frage: Frage): Promise<void> {
		return this.http.delete('/view/frage/geprueft-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}
}
