import {VerwaltbarerAusfuellerFragebogenDto} from "./VerwaltbarerAusfuellerFragebogenDto";
import {VerwaltbarerAusfuellerDto} from "./VerwaltbarerAusfuellerDto";
import {VerwaltbarerAusfuellerAbschnittDto} from "./VerwaltbarerAusfuellerAbschnittDto";
import {Recherchekomplex} from "../../shared/model/Recherchekomplex";
import {VerwaltbarerAusfuellerInstitutionDto} from "./VerwaltbarerAusfuellerInstitutionDto";
import {Institution} from "../../shared/model/Institution";

export class VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto {

	recherchekomplexName: string;
	rechekomplexId: string;
	recherchekomplexTitel: String;
	recherchekomplexFrageboegenGesamt: number;
	recherchekomplexFrageboegenBerechtigt: number;
	institutionName: string;
	institutionId: string;
	ausfuellerName: string;
	ausfuellerId: string;
	ausfuellerAnrede: string;
	ausfuellerBenutzername: string;
	ausfuellerEmail: string;
	ausfuellerEnabled: boolean;
	ausfuellerRegistriert: Date;
	ausfuellerEingeladen: Date;
	ausfuellerLastLogin: Date;
	ausfuellerArchiviertAm: Date;
	ausfuellerRegistriertString: String;
	ausfuellerEingeladenString: String;
	ausfuellerLastLoginString: String;
	ausfuellerArchiviertAmString: String;
	ausfuellerArchiviert: boolean;
	ausfuellerEinRecherchekomplexAdministrator: boolean;
	fragebogenName: string;
	abschnittUeberschrift: string;
	frageboegen: VerwaltbarerAusfuellerFragebogenDto[] = [];
	abschnitte: VerwaltbarerAusfuellerAbschnittDto[] = [];
	darfFragebogenAbschliessen: boolean= false;


	constructor(orgObj?: VerwaltbarerAusfuellerDto) {
		if (orgObj) {
			this.ausfuellerId = orgObj.id;
			this.ausfuellerAnrede = orgObj.anrede;
			this.ausfuellerName = orgObj.anzeigename;
			this.ausfuellerEmail = orgObj.email;
			this.ausfuellerLastLogin = orgObj.lastLogin;
			this.ausfuellerEingeladen = orgObj.eingeladen;
			this.ausfuellerArchiviertAm = orgObj.archiviertAm;
			this.ausfuellerRegistriert = orgObj.registriert;

			const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};

			if (this.ausfuellerEingeladen)
				this.ausfuellerEingeladenString = this.ausfuellerEingeladen.toLocaleDateString('de-DE', options);
			if (this.ausfuellerLastLogin)
				this.ausfuellerLastLoginString = this.ausfuellerLastLogin.toLocaleDateString('de-DE', options);
			if (this.ausfuellerArchiviertAm)
				this.ausfuellerArchiviertAmString = this.ausfuellerArchiviertAm.toLocaleDateString('de-DE', options);
			if (this.ausfuellerRegistriert)
				this.ausfuellerRegistriertString = this.ausfuellerRegistriert.toLocaleDateString('de-DE', options);

			if (orgObj.institutionen && orgObj.institutionen.length > 0) {
				this.institutionName = orgObj.institutionen[0].anzeigename
				this.institutionId = orgObj.institutionen[0].id
			}

			orgObj.institutionen.forEach(inst => {
				inst.recherchekomplexe.forEach(komplex => {
					komplex.frageboegen.forEach(frage => {
							this.frageboegen.push(frage);
						}
					)
				})
			})

			this.ausfuellerEinRecherchekomplexAdministrator = this.istAusfuellerEinRecherchekomplexAdministrator(orgObj);
		}
	}

	private _recherchekomplexLabel: string;

	get recherchekomplexLabel(): string {
		if (this._recherchekomplexLabel == "Fragebogen-Bearbeiter" || this._recherchekomplexLabel == "Abschnitt-Bearbeiter") {
			return "Bearbeiter";
		}
		return this._recherchekomplexLabel
	}


	set recherchekomplexLabel(value: string) {
		this._recherchekomplexLabel = value;
	}

	getAusfuellerRegistriertString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.ausfuellerRegistriert.toLocaleDateString('de-DE', options);
	}

	getAusfuellerEingeladenString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.ausfuellerEingeladen.toLocaleDateString('de-DE', options);
	}

	getAusfuellerLastLoginString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.ausfuellerLastLogin.toLocaleDateString('de-DE', options);
	}

	getAusfuellerArchiviertAmString() {
		const options: Intl.DateTimeFormatOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.ausfuellerArchiviertAm.toLocaleDateString('de-DE', options);
	}

	istAusfuellerEinRecherchekomplexAdministrator(orgObj?: VerwaltbarerAusfuellerDto) {
		if (orgObj) {
			for (const institution of orgObj.institutionen) {
				for (const recherchekomplex of institution.recherchekomplexe) {
					if (recherchekomplex.istRecherchekomplexAdministrator) {
						return true;
					}
				}
			}
		}
		return false;
	}

	isJuveAdminRight():boolean {
		return this._recherchekomplexLabel == "JUVE Recherche-Ansprechpartner"
	}

	isCoAdminRight():boolean {
		return this._recherchekomplexLabel == "Recherche-Koordinator"
	}

	setKomplexAndInstituteFields(recherchekomplex: Recherchekomplex, institution: VerwaltbarerAusfuellerInstitutionDto) {
		this.institutionName = institution.anzeigename;
		this.institutionId = institution.id;
		this.recherchekomplexName = recherchekomplex.name;
		this.rechekomplexId = recherchekomplex.id;
	}

	setKomplexAndInstituteFieldsByInstitution(recherchekomplex: Recherchekomplex, institution: Institution) {
		this.institutionName = institution.anzeigename;
		this.institutionId = institution.id;
		this.recherchekomplexName = recherchekomplex.name;
		this.rechekomplexId = recherchekomplex.id;
	}

	istAusfuellerRecherchekomplexAdministratorForKomplexId(rechekomplexId: string, ausfueller: VerwaltbarerAusfuellerDto) {
		if (ausfueller) {
			for (const institution of ausfueller.institutionen) {
				for (const recherchekomplex of institution.recherchekomplexe) {
					if (rechekomplexId === recherchekomplex.id) {

						if (recherchekomplex.istRecherchekomplexAdministrator) {
							return true;
						}
					}
				}
			}
		}
		return false;
	}

}
