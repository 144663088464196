import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeAuswahl} from 'app/shared/model/frage/input/InputTypeAuswahl';
import {InputTypeCheckbox} from 'app/shared/model/frage/input/InputTypeCheckbox';
import {InputTypeGanzzahl} from 'app/shared/model/frage/input/InputTypeGanzzahl';
import {InputTypeMehrfachauswahl} from 'app/shared/model/frage/input/InputTypeMehrfachauswahl';
import {InputTypeText} from 'app/shared/model/frage/input/InputTypeText';
import {InputTypeTextarea} from 'app/shared/model/frage/input/InputTypeTextarea';
import {InputTypeEmail} from "./frage/input/InputTypeEmail";
import {InputTypeKommazahl} from "./frage/input/InputTypeKommazahl";
import {InputTypeTelefon} from "./frage/input/InputTypeTelefon";

const debug = require('debug')('InputTypes');

export class InputTypes {
	static readonly TYPES: typeof InputType[] = [
		InputTypeText,
		InputTypeTextarea,
		InputTypeCheckbox,
		InputTypeAuswahl,
		InputTypeMehrfachauswahl,
		InputTypeGanzzahl,
		InputTypeKommazahl,
		InputTypeEmail,
		InputTypeTelefon,
	];

	static typeForID(id: string): typeof InputType {
		const typeClass = InputTypes.TYPES.find(type => type.ID === id);
		if (!typeClass) {
			debug('InputType nicht definiert: ', id);
		}

		return typeClass;
	}
}
