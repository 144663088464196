import {Component} from '@angular/core';
import {TabellenZeileView} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenZeileView';
import {TabelleViewComponentBase} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleViewComponentBase';

@Component({
	selector: 'app-tabelle-erweiterbar-view',
	templateUrl: './TabelleErweiterbarViewComponent.html',
	styleUrls: ['./TabelleErweiterbarViewComponent.less', '../tabelle-view.shared.less']
})
export class TabelleErweiterbarViewComponent extends TabelleViewComponentBase {

	addZeile(): void {
		this.tabellenView.addZeile();
	}

	removeZeile(zeile: TabellenZeileView): void {
		if (!this.istZeileLoeschbar()) {
			return;
		}

		this.tabellenView.removeZeile(zeile);
		if (this.tabellenView.isEmpty()) {
			this.addZeile();
		}
	}

	spaltenAnzahlMitControls(): number {
		return this.tabellenFrage.anzahlSpalten() + 1;
	}

	istZeileLoeschbar() {
		if (this.tabellenView.getAnzahlZeilen() === 1) {
			const einzigeZeile = this.tabellenView.getZeilenViews()[0];
			if (einzigeZeile.isEmpty()) {
				return false;
			}
		}

		return true;
	}

	protected afterTabellenviewInitialized() {
		if (this.tabellenView.isEmpty()) {
			this.addZeile();
		}
	}
}
