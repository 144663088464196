import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {VerwaltbarerAusfuellerDto} from "../../../../model/VerwaltbarerAusfuellerDto";
import {VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto} from "../../../../model/VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {RecherchekomplexMitSichtbarenAbschnittenDto} from "../../../../model/RecherchekomplexMitSichtbarenAbschnittenDto";
import {AusfuellerService} from "../../../../services/AusfuellerService";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {ConfirmationService, ConfirmEventType, MessageService} from "primeng/api";
import {FormGroup, NgForm} from "@angular/forms";
import {AlertService} from "../../../../../shared/alert/service/AlertService";
import {faExchange, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../../../../form-viewer/service/UserService";
import {EventService} from "../../../../services/EventService";
import {Institution} from "../../../../../shared/model/Institution";

@Component({
	selector: 'app-recherchekomplex-bearbeiten',
	templateUrl: './rechte-verwalten.component.html',
	styleUrls: ['./rechte-verwalten.component.less'],
	providers: [ConfirmationService]
})
export class RechteVerwaltenComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	public verwaltbarerAusfueller: VerwaltbarerAusfuellerDto;
	public institutionUndRecherchekomplex: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public recherchekomplexMitAllenAbschnittenListe: RecherchekomplexMitSichtbarenAbschnittenDto[];
	public verwaltbarerAusfuellerMitInstitutionUndRecherchekomplex: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;

	// public fragebogenMitAbschnitten: FragebogenMitSichtbarenAbschnittenDto[];

	// abschnittFreigabe: boolean;
	// fragebogenFreigabe: boolean;
	loading: boolean;
	faExchange = faExchange;
	faExclamationTriangle = faExclamationTriangle;

	@ViewChild('myForm') myForm: NgForm;
	@Output() submitBenutzer = new EventEmitter<VerwaltbarerAusfuellerDto>();
	public myGroup: FormGroup;
	public message: string;
	recherchekomplexMitSichtbarenAbschnitten: RecherchekomplexMitSichtbarenAbschnittenDto[];
	public currentInstitute: Institution;
	public currentInstituteString: string;
	private admin: boolean;
	private coadmin: boolean;

	constructor(public ref: DynamicDialogRef,
				public config: DynamicDialogConfig,
				public ausfuellerService: AusfuellerService,
				private messageService: MessageService,
				private alertService: AlertService,
				private userService: UserService,
				private eventService: EventService,
				private confirmationService: ConfirmationService) {
	}

	ngOnInit(): void {
		this.blockUI.start('Daten werden verarbeitet...');
		this.loading = true;
		this.verwaltbarerAusfueller = this.config.data.verwaltbarerAusfueller;
		// console.log(this.verwaltbarerAusfueller)

		this.institutionUndRecherchekomplex = this.config.data.institutionUndRecherchekomplex;

		this.currentInstitute = this.userService.getAktuelleInstitution()
		this.currentInstituteString = this.userService.getAktuelleInstitution().anzeigename

		let neueRechte = this.config.data.institutionUndRecherchekomplex;

		// console.log(neueRechte)

		if (neueRechte)
			this.ausfuellerService.getRecherchekomplexMitSichtbarenAbschnittenOhneRechte(this.verwaltbarerAusfueller,
				this.institutionUndRecherchekomplex.rechekomplexId,
				this.institutionUndRecherchekomplex.institutionId)
				.then(alleAbschnitte => this.setRecherchekomplexMitAllenAbschnittenListeAndLog(alleAbschnitte))
				.finally(this.blockUI.stop);

		else
			this.ausfuellerService.getRecherchekomplexMitSichtbarenAbschnitten(this.verwaltbarerAusfueller,
				this.institutionUndRecherchekomplex.rechekomplexId,
				this.institutionUndRecherchekomplex.institutionId)
				.then(alleAbschnitte => this.setRecherchekomplexMitAllenAbschnittenListeAndLog(alleAbschnitte))
				.finally(this.blockUI.stop);


		setTimeout(() => {
			this.blockUI.stop()
		}, 3000);
	}


	isAdminOrCoAdmin() {
		return this.admin || this.coadmin
	}

	zugeordnetenRecherchekomplexBearbeiten() {
	}

	closeDialog() {
		this.ref.close();
	}

	closeDialogAfterComplete(newUser): void {
		this.messageService.clear()

		if (newUser) {
			this.messageService.add({
				severity: 'info',
				life: 8000,
				summary: 'Der Benutzer "' + this.verwaltbarerAusfueller.anrede + ' ' + this.verwaltbarerAusfueller.anzeigename + '" wurde angelegt und die Berechtigungen zugewiesen und gespeichert.'
			})
		} else {
			this.messageService.add({
				severity: 'info',
				life: 5000,
				summary: 'Die Berechtigungen für "' + this.verwaltbarerAusfueller.anrede + ' ' + this.verwaltbarerAusfueller.anzeigename + '" wurden zugewiesen und gespeichert.'
			})
		}

		this.eventService.emitUserRightsEditEventBenutzer("benutzer-bearbeiten");
		this.eventService.emitRecherchekomplexEingeladeneUserEvent("benutzer-bearbeiten")
		this.eventService.emitUserOffeneEinladungenEvent("offene-einladung-bearbeiten")
		this.eventService.emitUserActiveToggledEventInactive("benutzer-bearbeiten")
		this.blockUI.stop()
		this.ref.close();
	}

	handleChange(event: any) {
		var isChecked = event.checked;
	}

	showSaveError() {
		this.alertService.showWarning("Fehler beim Speichern der Berechtigungen für" + this.verwaltbarerAusfueller.anzeigename + ".");
	}

	showSavingMsg() {
		this.alertService.showInfo("Die Berechtigungen für " + this.verwaltbarerAusfueller.anzeigename + " werden gespeichert.");
	}

	updateRechte(): void {

		console.log(this.recherchekomplexMitAllenAbschnittenListe)

		let rightFound = false
		for (const recherchekomplexMitSichtbarenAbschnittenDto of this.recherchekomplexMitAllenAbschnittenListe) {
			for (const fragebogenFreigaben of recherchekomplexMitSichtbarenAbschnittenDto.frageboegen) {
				if (fragebogenFreigaben.hatTeilweiseRechte() || fragebogenFreigaben.hatAlleRechte()) {
					rightFound = true
					break
				}
			}
			if (rightFound)
				break
		}

		if (!rightFound) {
			this.messageService.clear()
			this.messageService.add({
				severity: 'error',
				life: 8000,
				summary: 'Sie können einem Benutzer keinen Rechnerekomplex ohne Berechtigunen auf mindestens einen Fragebogen zuweisen!'
			})

			return
		}

		this.messageService.clear()

		let message;
		if (!this.verwaltbarerAusfueller.id) {
			message = '\nWollen Sie den Benutzer \"' + this.verwaltbarerAusfueller.anzeigename + "\" mit den angegebenen Berechtigungen anlegen?\n"
		} else {
			message = '\nWollen Sie die Berechtigungen für den Benutzer \"' + this.verwaltbarerAusfueller.anzeigename + "\" wirklich ändern?\n"
		}

		console.log("updateAusfueller");
		// console.log(this.recherchekomplexMitAllenAbschnittenListe);
		this.confirmationService.confirm({
			message: message,
			acceptLabel: "Speichern",
			rejectLabel: "Abbrechen",
			closeOnEscape: false,
			header: 'Bitte Speichern bestätigen',
			icon: 'pi pi-info-circle',
			accept: () => {
				this.blockUI.start('Daten werden verarbeitet...');
				this.messageService.clear();
				if (!this.verwaltbarerAusfueller.id) {
					this.ausfuellerService.createUserAndAddRights(this.verwaltbarerAusfueller, this.recherchekomplexMitAllenAbschnittenListe[0], false).subscribe(() => this.showSavingMsg(), () => this.showSaveError(), () => this.closeDialogAfterComplete(true));
				} else
					this.ausfuellerService.updateAlleRechte(this.verwaltbarerAusfueller, this.recherchekomplexMitAllenAbschnittenListe[0]).subscribe(() => this.showSavingMsg(), () => this.showSaveError(), () => this.closeDialogAfterComplete(false));

				console.log('Submitted!', this.myForm);
			},
			reject: (type) => {
				switch (type) {
					case ConfirmEventType.REJECT:
						this.messageService.clear();
						this.messageService.add({
							severity: 'info',
							summary: 'Die Benutzerdatenänderung wird abgebrochen.'
						});
						// 					this.verwaltbarerAusfueller = null;
						break;
					case ConfirmEventType.CANCEL:
						this.messageService.clear();
						this.messageService.add({
							severity: 'info',
							summary: 'Die Benutzerdatenänderung wird abgebrochen.'
						});
						// 					this.verwaltbarerAusfueller = null;
						break;
				}
			}
		});
		// if (this.verwaltbarerAusfueller) {
		// 	this.ausfuellerService.updateAlleRechte(this.verwaltbarerAusfueller).subscribe();
		// 	console.log('Submitted');
		// }
	}

	private setAdminAndCoAdminFlag(adminRightsParam, coAdminRightsParam) {
		this.admin = adminRightsParam
		this.coadmin = coAdminRightsParam

		if (this.admin) {
			this.message = 'Für diesen Benutzer können keine individuellen Rechte erteilt werden, weil er Recherchekomplex-Admin ist'
		} else if (this.coadmin) {
			this.message = 'Für diesen Benutzer können keine individuellen Rechte erteilt werden, weil er Recherchekomplex-CoAdmin ist'
		}
	}

	private setRecherchekomplexMitAllenAbschnittenListeAndLog(alleAbschnitte: RecherchekomplexMitSichtbarenAbschnittenDto[]) {
		this.recherchekomplexMitAllenAbschnittenListe = alleAbschnitte;
		this.loading = true;
		this.blockUI.start('Daten werden verarbeitet...');


		if (alleAbschnitte[0].institution.id === this.userService.getAktuelleInstitution().id) {
			console.log("RECHTE SIND FÜR AKTUELLES UNTERNEHMEN")
		} else {
			console.log("************* RECHTE SIND NICHT FÜR AKTUELLES UNTERNEHMEN")
		}

		this.setAdminAndCoAdminFlag(alleAbschnitte[0].adminRights, alleAbschnitte[0].coAdminRights);

		this.loading = false;


		setTimeout(() => {
			this.blockUI.stop()
		}, 2000);
		return this.recherchekomplexMitAllenAbschnittenListe;
	}
}
