import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Recherchekomplex} from 'app/shared/model/Recherchekomplex';

@Injectable()

export class RecherchekomplexService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getRecherchekomplexe(): Promise<Recherchekomplex[]> {
		return this.http.get<Recherchekomplex[]>('/api/editor/recherchekomplex')
			.toPromise()
			.then(response => {
				return response.map(recherchekomplex => new Recherchekomplex(recherchekomplex));
			})
			.catch(this.alerts.handleHttpError);
	}
}
