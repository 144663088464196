import {Component, Input, OnInit} from '@angular/core';
import {IFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {KartenfragenAntwort} from 'app/shared/model/antwort/KartenfragenAntwort';
import {ViewKarte} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/ViewKarte';
import {KartenAntwort} from 'app/shared/model/antwort/KartenAntwort';

@Component({
	selector: 'app-karten-frage-view',
	templateUrl: './KartenFrageViewComponent.html',
	styleUrls: ['./KartenFrageViewComponent.less']
})
export class KartenFrageViewComponent implements IFrageViewComponent, OnInit {

	@Input()
	frage: KartenFrage;

	_antwort: KartenfragenAntwort;

	viewKarten: ViewKarte[] = [];

	@Input()
	readonly: boolean;

	printView = false;

	@Input()
	set antwort(antwort: KartenfragenAntwort) {
		this._antwort = antwort;
		this.viewKarten = this._antwort.karten.map((karte, index) => new ViewKarte(karte, this.isKarteOpen(index)));
	}

	get antwort(): KartenfragenAntwort {
		return this._antwort;
	}

	isReadonly() {
		// console.log("readonly")
		return this.readonly;
	}

	setPrintView(printView: boolean): void {
		console.log("setPrintView")

		this.printView = printView;

		if (this.printView) {
			this.openAll();

			if (!this.hasKarten()) {
				this.addKarte();
			}
		}
	}

	hasKarten() {
		console.log("hasKarten()")
		return this._antwort.karten.length > 0;
	}

	ngOnInit() {
		// console.log("ngOnInit")
	}

	addKarte() {
		const kartenAntwort = KartenAntwort.forKartenFrage(this.frage);
		this.antwort.karten.splice(0, 0, kartenAntwort);
		// isOpen erst mal false, da das eigentliche Öffnen in toggleOpen erfolgt
		const neueViewKarte = new ViewKarte(kartenAntwort, false);
		this.viewKarten.splice(0, 0, neueViewKarte);
		this.toggleOpen(neueViewKarte);
	}

	onRemoveKarte(viewKarte: ViewKarte) {
		const indexOfKarte = this.viewKarten.indexOf(viewKarte);
		if (indexOfKarte > -1) {
			this._antwort.karten.splice(indexOfKarte, 1);
			this.viewKarten.splice(indexOfKarte, 1);
		}
	}

	toggleOpen(toggleKarte: ViewKarte) {
		this.viewKarten.forEach(viewKarte => {
			if (viewKarte !== toggleKarte) {
				viewKarte.close();
			} else {
				viewKarte.toggleOpen();
			}
		});
	}

	hasCardsOpen() {
		return this.viewKarten.some(viewKarte => viewKarte.isOpen);
	}

	isCardDisabled(viewKarte: ViewKarte) {
		// console.log("isCardDisabled")

		return this.hasCardsOpen() && !viewKarte.isOpen;
	}

	openAll() {
		this.viewKarten.forEach((kartenView) => kartenView.open());
	}

	showKartenHeader(): boolean {
		// console.log("showKartenHeader")
		return !this.printView;
	}

	showEmptyMessage(): boolean {
		return this.readonly && !this.hasKarten();
	}

	private isKarteOpen(index: number) {
		if (this.viewKarten[index]) {
			return this.viewKarten[index].isOpen;
		} else {
			return false;
		}
	}
}
