import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';

@Injectable()
export class FragebogenVeroeffentlichenService {

	constructor(private http: HttpClient, private alertService: AlertService) {
	}

	veroeffentlichen(fragebogen: Fragebogen): Promise<any> {
		return this.http
			.post('/api/editor/fragebogen/' + fragebogen.id + '/veroeffentlichen', {})
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
