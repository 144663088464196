<div class="frage-text" id="frage-{{frage.id}}">
<!--TODO: neu entwickeln oder ganz rausnehmen
	 trackScroll
	 (trackScrollEnter)="enter()"
	 [trackScrollConfig]="{
		position: 'top',
		offset: 0,
		offsetPosition: 'top'
	}"-->
	<div class="headline">
		<h3>
			<i
				*ngIf="fertig && !geprueft && !printView"
				class="icon-check marker"
				[title]="'Diese Frage ist als fertig markiert.'"
			></i>
			<i
				*ngIf="geprueft && !printView"
				class="icon-lock-success marker"
				[title]="'Diese Frage wurde bereits durch einen JUVE Recherche-Ansprechpartner oder Recherche-Koordinator geprüft und kann nur durch diese wieder editierbar gesetzt werden.'"
				container="body"
			></i>
			{{ frage.ueberschrift }}
		</h3>
		<div *ngIf="!printView">
			<app-viewer-export-trigger-view
				[abschnitt]="abschnitt"
				[frage]="frage"
				[antwort]="antwort"
			></app-viewer-export-trigger-view>
			<app-viewer-import-trigger-view
				(imported)="onImported($event)"
				[frage]="frage"
				[antwort]="antwort"
				[isReadonly]="isReadonly()"
				(fileuploadWorking)="onFileuploadWorking($event)"
			></app-viewer-import-trigger-view>
		</div>
	</div>
	<p>{{ frage.beschreibung }}</p>
</div>
<div class="frage-content">
	<ng-container #frageViewer></ng-container>
</div>
