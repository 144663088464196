import {AusfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import {UUID} from 'app/util/export-types';
import {AusfuellerType} from 'app/form-viewer/model/AusfuellerType';

export class AbschnittsausfuellerAnlegenDto extends AusfuellerAnlegenDto {
	type: AusfuellerType = AusfuellerType.Abschnittsausfueller;

	fuerAbschnitt: UUID;
	abgabefrist = '';
}
