import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {TabellenSpalte} from 'app/shared/model/frage/TabellenSpalte';

export class TabellenZellenSpaltenTupel {
	spalte: TabellenSpalte;
	antwort: InputTypeAntwort<any>;

	constructor(spalte: TabellenSpalte, antwort: InputTypeAntwort<any>) {
		this.spalte = spalte;
		this.antwort = antwort;
	}

}
