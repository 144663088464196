import {TextfeldFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/textfeld/TextfeldFrageEditComponent';
import {TextfeldFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/textfeld/TextfeldFrageViewComponent';
import {Frage} from 'app/shared/model/Frage';
import {TextfeldfragenAntwort} from '../antwort/TextfeldfragenAntwort';

export class TextfeldFrage extends Frage {
	static readonly TYPE_DISPLAY_NAME = 'Text';
	static readonly TYPE_ICON_NAME = 'text-t';
	static readonly ID = 'TextfeldFrage';

	static readonly EDITOR = TextfeldFrageEditComponent;
	static readonly VIEWER = TextfeldFrageViewComponent;

	platzhalterText = '';
	grossesTextfeldAktiviert = false;

	constructor(frage?: TextfeldFrage) {
		super(frage);

		if (frage) {
			this.constructFromTextfeldFrage(frage);
		}
	}

	private constructFromTextfeldFrage(frage: TextfeldFrage) {
		this.platzhalterText = frage.platzhalterText;
		this.grossesTextfeldAktiviert = frage.grossesTextfeldAktiviert;

		this.aeltesteAntwort = frage.aeltesteAntwort ? new TextfeldfragenAntwort(frage.aeltesteAntwort as TextfeldfragenAntwort) : null;
		this.neuesteAntwort = frage.neuesteAntwort ? new TextfeldfragenAntwort(frage.neuesteAntwort as TextfeldfragenAntwort) : null;
	}

	typeIconName() {
		return TextfeldFrage.TYPE_ICON_NAME;
	}

	typeDisplayName() {
		return TextfeldFrage.TYPE_DISPLAY_NAME;
	}

	isExportableAsCsv() {
		return false;
	}
}
