<p-toast></p-toast>

<app-alert></app-alert>

<span *ngIf="!loaded">
	<app-spinner></app-spinner>
</span>
<span *ngIf="loaded">
	<app-portal-institution-selector
		*ngIf="aktuelleInstitution"
		[institution]="sichtbareInstitution"
		[(aktuelleInstitution)]="aktuelleInstitution"
	></app-portal-institution-selector>

	<app-portal-recherchekomplex-selector
		*ngIf="aktuelleInstitution"
		[institution]="aktuelleInstitution.institution"
		[recherchekomplexDtos]="aktuelleInstitution.recherchekomplexe"
	></app-portal-recherchekomplex-selector>

	<div *ngIf="! aktuelleInstitution">Keine Freigaben vorhanden.
	</div>
</span>
