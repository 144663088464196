<p-toast></p-toast>

<a
	*ngIf="isExportEnabled()"
	href="{{ generateHref() }}"
	(click)="onClick($event)"
	disabled="{{isUserEmpty()}}"
	[title]="getExportTooltip()"
	target="_blank"
	appScrollToFocusedElement
	ngPreserveWhitespaces
	tooltip="Exportieren Sie Ihre Antworten im CSV-Format. Die Anleitung zum Import finden Sie in den FAQs unter 'Kann ich Informationen aus unseren Datensystemen in die JUVE Recherche übertragen?'"
>
	<i class="icon icon-download"></i>&nbsp;<span>CSV-Export</span>
</a>

<a style="width: auto;"
	href="https://support.microsoft.com/de-de/office/importieren-oder-exportieren-von-textdateien-txt-oder-csv-5250ac4c-663c-47ce-937b-339e391393ba#:~:text=Klicken%20Sie%20auf%20der%20Registerkarte,klicken%20Sie%20dann%20auf%20Importieren."
   target="_blank">
	<i class="icon icon-help-with-circle"></i>&nbsp;<span style="width: auto;">CSV in Excel bearbeiten</span>
</a>
