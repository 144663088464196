<div class="frage-container">
	<div class="frage" *ngFor="let frage of abschnitt.fragen;">
		<div class="frage-content">
			<app-viewer-abschnitt-frage
				[abschnitt]="abschnitt"
				[frage]="frage"
				[readonly]="varIsReadonly"
				[fertig]="varIsFertig[frage.id]"
				[geprueft]="varIsGeprueft[frage.id]"
				[printView]="varIsPrintView"
				[antwort]="antwortForFrage(frage)"
				(entered)="onFrageEntered(frage)"
				(imported)="onImported()"
				(fileuploadWorking)="onFileuploadWorking($event)"
			></app-viewer-abschnitt-frage>

			<div class="marker-container" *ngIf="!printView">
				<app-viewer-antwort-als-fertig-markiert-liste
					*ngIf="varDarfFrageFertigMarkerSehen"
					[frageFertigMarker]="varFilterFertigMarkerForFrage[frage.id]"
					[unfertigeAusfueller]="varFilterUnfertigeAusfuellerForFrage[frage.id]"
				></app-viewer-antwort-als-fertig-markiert-liste>

				<app-viewer-antwort-als-geprueft-markieren
					*ngIf="varDarfAlsGeprueftMarkieren"
					[readonly]="varIsReadonly"
					[frageGeprueftMarker]="varFilterGeprueftMarkerForFrage[frage.id]"
					(alsGeprueftMarkiert)="onAlsGeprueftMarkiert(frage)"
					(alsUngeprueftMarkiert)="onAlsUngeprueftMarkiert(frage)"
				></app-viewer-antwort-als-geprueft-markieren>

				<app-viewer-antwort-als-fertig-markieren
					*ngIf="varDarfFrageAlsFertigMarkieren && !varIsGeprueft[frage.id]"
					[readonly]="varIsReadonly"
					[frageFertigMarker]="varMeinFertigMarkerForFrage[frage.id]"
					(alsFertigMarkiert)="onAlsFertigMarkiert(frage)"
					(alsUnfertigMarkiert)="onAlsUnfertigMarkiert(frage)"
				></app-viewer-antwort-als-fertig-markieren>
			</div>
		</div>
		<div class="container">
			<div class="frage-divider"></div>
		</div>
	</div>
</div>
