import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {AntwortAnsichtDto} from '../antworten/AntwortAnsichtDto';

const debug = require('debug')('service:ajax:AbschnittEditorService');

@Injectable()
export class AntwortAnsichtService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getAntwortAnsicht(fragebogenId: string): Promise<AntwortAnsichtDto> {
		return this.http.get<AntwortAnsichtDto>(`/api/editor/fragebogen/${fragebogenId}/antworten`)
			.toPromise()
			.then(response => new AntwortAnsichtDto(response))
			.catch(this.alerts.handleHttpError);
	}

	getAntwortAnsichtInstitution(fragebogenId: string, institutionId: string): Promise<AntwortAnsichtDto> {
		return this.http.get<AntwortAnsichtDto>(`/api/editor/fragebogen/${fragebogenId}/antworten/${institutionId}`)
				   .toPromise()
				   .then(response => new AntwortAnsichtDto(response))
				   .catch(this.alerts.handleHttpError);
	}

}
