import {Action, createReducer, on} from '@ngrx/store';
import {Institution} from "../../shared/model/Institution";
import {setInstitution} from "../action/institution.actions";

export const institutionFeatureKey = 'institution';

export interface InstitutionState {
	institution: Institution
}

export const initialState: InstitutionState = {
	institution: null
};


export const institutionReducer = createReducer(
	initialState,
	on(setInstitution, (state: InstitutionState, {institution}) => ({...state, institution: institution}))
);

export function reducer(state: InstitutionState | undefined, action: Action): any {
	return institutionReducer(state, action)
}

