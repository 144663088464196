import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OAuthModule, AuthConfig } from 'angular-oauth2-oidc';
import { authConfig, AuthService } from './auth.service';


export function init_app(authConfigService: AuthService) {
  return () => authConfigService.initAuth();
}

@NgModule({
  imports: [ HttpClientModule, OAuthModule.forRoot()
  ],
  providers: [
    AuthService,
    { provide: AuthConfig, useValue: authConfig },
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [ AuthService ],multi: true }
  ]
})
export class AuthModule { }
