<app-alert></app-alert>

<app-viewer-abschnitt-navbar
	*ngIf="selectedAbschnitt"
	[fragebogen]="fragebogen"
	[abgeschlossen]="fragebogen_abgeschlossen"
	[abschnitt]="selectedAbschnitt"
	(back)="onCommitSaveThenClose()"
></app-viewer-abschnitt-navbar>

<app-viewer-fragebogen-navbar
	*ngIf="loaded && !selectedAbschnitt"
	[fragebogen]="fragebogen"
	[abgeschlossen]="fragebogen_abgeschlossen"
	[anzahlAbschnitte]="anzahlAbschnitte">
</app-viewer-fragebogen-navbar>

<app-viewer-hinweis *ngIf="istAbgeschlossenHinweisSichtbar()">
	<h3><i class="icon-lock"></i> Bereits an JUVE übermittelt</h3>
	<p>
		Dieser Fragebogen <span class="highlight">wurde bereits an JUVE übermittelt</span> und kann daher nicht mehr bearbeitet werden.

		<ng-container *ngIf="kontaktZumAufschliessen.length > 0">
			Wenn Sie Ihre Informationen dennoch ergänzen möchten, wenden Sie sich bitte an:
		</ng-container>
	</p>

	<ul class="list-unstyled" *ngIf="kontaktZumAufschliessen.length > 0">
		<li *ngFor="let kontakt of kontaktZumAufschliessen">
			<a href="mailto:{{ kontakt.email }}">{{ kontakt.anrede }} {{ kontakt.anzeigename }}</a>
		</li>
	</ul>
</app-viewer-hinweis>

<app-viewer-hinweis *ngIf="istAbgabefristHinweisSichtbar()">
	<h3><i class="icon-info-with-circle"></i> Abgabefrist</h3>
	<p>
		Bitte füllen Sie den {{ getAbgabefristEinheit() }} <span class="highlight">bis {{ getAusfuellerAbgabefrist() }}</span> aus.
	</p>
</app-viewer-hinweis>

<app-frageboegen-nicht-wiederaufschliessbar-modal
	[recherchekomplexid]="fragebogen.recherchekomplex.id"
	*ngIf="zeigeFrageboegenNichtWiederaufschliessbarModal()"
></app-frageboegen-nicht-wiederaufschliessbar-modal>

<app-recherchekomplex-beantworten-nicht-mehr-moeglich-modal
	[recherchekomplex]="fragebogen.recherchekomplex"
	*ngIf="zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal()"
></app-recherchekomplex-beantworten-nicht-mehr-moeglich-modal>

<div class="content">
	<!--	<div *ngIf="!loaded">-->
	<!--		<app-spinner></app-spinner>-->
	<!--	</div>-->

	<ng-container *ngIf="loaded">
		<app-viewer-abschnitt
			#abschnittView
			*ngIf="selectedAbschnitt"
			[fragebogen]="fragebogen"
			[fragebogen_abgeschlossen]="fragebogen_abgeschlossen"
			[abschnitt]="selectedAbschnitt"
			(close)="onDeselectAbschnitt()"
		></app-viewer-abschnitt>

		<app-viewer-abschnitt-liste
			*ngIf="!selectedAbschnitt"
			[fragebogen]="fragebogen"
			[fragebogen_abgeschlossen]="fragebogen_abgeschlossen"
			[showControls]="showControls()"
			(selectAbschnitt)="onSelectAbschnitt($event)"
		></app-viewer-abschnitt-liste>
	</ng-container>
</div>
