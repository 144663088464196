import {UUID} from 'app/util/export-types';

export class AnzahlGepruefterFragenProAbschnittDto {
	abschnitt_id: UUID;
	anzahl_gepruefter_antworten: number;

	constructor(anzahlGepruefterFragenProAbschnitt?: AnzahlGepruefterFragenProAbschnittDto) {
		if (anzahlGepruefterFragenProAbschnitt) {
			this.abschnitt_id = anzahlGepruefterFragenProAbschnitt.abschnitt_id;
			this.anzahl_gepruefter_antworten = anzahlGepruefterFragenProAbschnitt.anzahl_gepruefter_antworten;
		}
	}

	getAnzahlGepruefterAntworten(): number {
		return this.anzahl_gepruefter_antworten;
	}
}
