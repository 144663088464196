<span *ngIf="isImportEnabled()" ngPreserveWhitespaces>
	<input
		type="file"
		accept=".csv, text/csv"
		name="{{generateFileUploadName()}}"
		id="{{generateFileUploadName()}}"
		class="icon icon-export fileupload"
		(change)="onChange($event)"
		title="Importieren Sie Ihre Antworten im CSV-Format"
		appScrollToFocusedElement
	>
	<label
		tooltip="Importieren Sie Ihre Antworten im CSV-Format. Die Anleitung zum Import finden Sie in den FAQs unter 'Kann ich Informationen aus unseren Datensystemen in die JUVE Recherche übertragen?'"
		   for="{{generateFileUploadName()}}">
		<i class="icon icon-upload"></i>&nbsp;<span>CSV-Import</span>
	</label>
</span>
