import {Component, Input, OnInit} from '@angular/core';
import {IFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import {TabellenFrage} from 'app/shared/model/frage/TabellenFrage';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeTextarea} from 'app/shared/model/frage/input/InputTypeTextarea';
import {InputTypes} from 'app/shared/model/InputTypes';
import {ArrayUtil} from 'app/shared/util/ArrayUtil';

@Component({
	selector: 'app-editor-abschnitt-frage-edit-tabelle',
	templateUrl: './TabelleFrageEditComponent.html',
	styleUrls: ['./TabelleFrageEditComponent.less']
})
export class TabelleFrageEditComponent implements IFrageEditComponent<TabellenFrage>, OnInit {
	@Input()
	frage: TabellenFrage;

	isErweiterbar: boolean;

	availableTypes: typeof InputType[];

	constructor() {
		const inputTypesNotInTable: typeof InputType[] = [
			InputTypeTextarea
		];
		this.availableTypes = InputTypes.TYPES.filter(type => !ArrayUtil.contains(inputTypesNotInTable, type));
	}

	ngOnInit(): void {
		this.isErweiterbar = this.frage.isErweiterbar();
	}

	erweiterbarkeitChanged() {
		if (this.isErweiterbar) {
			this.frage.clearFixeZeilen();
		} else {
			if (!this.frage.hasFixeZeilen()) {
				this.frage.addFixeZeile();
			}
		}
	}
}
