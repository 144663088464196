<div class="row">
	<div class="col-xs-12 col-md-6 spalte">
		<app-karten-view-title-field
			[readonly]="readonly"
			[printView]="printView"
			[frage]="frage"
			[antwort]="kartenAntwort"
		></app-karten-view-title-field>

		<app-karten-view-spalte
			[readonly]="readonly"
			[printView]="printView"
			[feldAntworten]="feldAntwortTupelLinks"
		></app-karten-view-spalte>
	</div>
	<div class="col-xs-12 col-md-6 spalte">
		<app-karten-view-spalte
			[readonly]="readonly"
			[printView]="printView"
			[feldAntworten]="feldAntwortTupelRechts"
		></app-karten-view-spalte>
	</div>

	<div class="col-xs-12" *ngIf="!isReadonly()">
		<div class="toolbar" role="toolbar">
			<a (click)="commitRemove()" href appPreventDefault><i class="icon-trash"></i>Eintrag löschen</a>
		</div>
	</div>
</div>
