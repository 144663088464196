export class RecherchekomplexBenutzerDto {

	anrede: string;
	name: string;

	static fromList(list: RecherchekomplexBenutzerDto[]) {
		return list.map(recherchekomplexBenutzerDto => new RecherchekomplexBenutzerDto(recherchekomplexBenutzerDto));
	}

	constructor(recherchekomplexBenutzerDto?: RecherchekomplexBenutzerDto){

	}


}
