<div *ngFor="let feldAntwort of feldAntworten" class="antwort form-group {{ feldAntwort.feld.type.type }}">
	<label for="{{ 'feldAntwort' | lastID }}">
		{{ feldAntwort.feld.ueberschrift }}
		<app-view-info-text [beschreibung]="feldAntwort.feld.beschreibung"></app-view-info-text>
	</label>

	<app-input-view [type]="feldAntwort.feld.type"
					[antwort]="feldAntwort.antwort.antwort"
					variant="karten-spalte"
					[readonly]="readonly"
					[printView]="printView"
					id="{{ 'feldAntwort' | newID }}"
	></app-input-view>
</div>
