import {Component, Input} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';

@Component({
	selector: 'app-abschnitt-sortieren',
	templateUrl: './AbschnittSortierenComponent.html',
	styleUrls: ['./AbschnittSortierenComponent.less'],
})
export class AbschnittSortierenComponent {
	@Input()
	abschnitte: Abschnitt[] = [];

	@Input()
	disabled = false;
}
