import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {NotificationSeenService} from 'app/form-viewer/service/NotificationSeenService';
import {Recherchekomplex} from 'app/shared/model/Recherchekomplex';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-recherchekomplex-beantworten-nicht-mehr-moeglich-modal',
	templateUrl: './RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent.html',
	styleUrls: ['../common/ModalCommonComponent.less'],
})
/* VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto nicht umbenennen da das Modal ansonsten noch einmal angezeigt wird */
export class RecherchekomplexBeantwortenNichtMehrMoeglichModalComponent implements AfterViewInit {

	@ViewChild('modalDirective', { static: true })
	modalDirective: ModalDirective;

	@Input()
	recherchekomplex: Recherchekomplex;

	constructor(
		private notificationSeenService: NotificationSeenService) {
	}

	ngAfterViewInit(): void {
		if (!this.notificationSeenService.hasSeen(this, this.recherchekomplex.id)) {
			this.openDialog();
		}
	}

	openDialog() {
		this.modalDirective.config.backdrop = 'static';
		this.modalDirective.show();
	}

	closeDialog() {
		this.notificationSeenService.setHasSeen(this, this.recherchekomplex.id);
		this.modalDirective.hide();
	}
}
