import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypes} from 'app/shared/model/InputTypes';
const debug = require('debug')('InputAntwortBuilderUtil');

export class InputAntwortBuilderUtil {
	static build(antwort: InputTypeAntwort<any>): InputTypeAntwort<any> {
		const typeKlass = InputTypes.typeForID(antwort.type);
		const clazz = typeKlass.ANTWORT;

		return new clazz(antwort);
	}

	static buildForType(type: InputType) {
		const clazz = type.getClass().ANTWORT;
		const instance: InputTypeAntwort<any> = new clazz();
		instance.input_id = type.id;
		return instance;
	}
}
