<div
	#editDialogModal="bs-modal"
	appPreventDefault
	bsModal
	class="modal fade"
	role="dialog"
	tabindex="-1"
>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left">Überschrift und Beschreibungstexte</h4>

				<button (click)="hide()" aria-label="Close" class="close pull-right" type="button">
					<span>&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-editor-fragebogen-metadata-form
					(abbrechen)="hide()"
					(commitSave)="commitSave()"
					*ngIf="editFragebogen"
					[(beschreibungKurz)]="editFragebogen.beschreibungKurz"
					[(beschreibungLang)]="editFragebogen.beschreibungLang"
					[(name)]="editFragebogen.name"
					[disabled]="disabled"
					class="clearfix"
					[submitButtonNames]="['Speichern']"
				></app-editor-fragebogen-metadata-form>
			</div>
		</div>
	</div>
</div>
