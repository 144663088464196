import {Component, Input, ViewChild} from '@angular/core';
/* eslint-disable-next-line max-len */
import {AusfuellerVerwaltungDialogComponent} from 'app/form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
/* eslint-disable-next-line max-len */
import {FragebogenausfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormConfiguration';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {UserService} from 'app/form-viewer/service/UserService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
/* eslint-disable-next-line max-len */
import {NavigationService} from 'app/shared/service/NavigationService';
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: 'app-viewer-fragebogen-navbar',
	templateUrl: './ViewerFragebogenNavbarComponent.html',
	styleUrls: ['./ViewerFragebogenNavbarComponent.less'],
	providers: [],
})
export class ViewerFragebogenNavbarComponent {


	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abgeschlossen: boolean;

	@Input()
	anzahlAbschnitte: number;

	@ViewChild('dialog', {static: true})
	dialog: AusfuellerVerwaltungDialogComponent;

	constructor(private userService: UserService,
				private previewService: PreviewService,
				private navigationService: NavigationService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService) {
		ausfuellerBerechtigungsService.clearCache();
	}

	zeigeZurueckButton(): boolean {
		return this.previewService.isNotInPreview();
	}

	istFragebogenausfuellerVerwaltenAktiv() {
		return this.previewService.isNotInPreview() && !this.abgeschlossen && this.ausfuellerBerechtigungsService.darfFragebogenausfuellerVerwalten();
	}

	fragebogenausfuellerVerwaltenDialogOeffnen() {
		this.dialog.show(
			new FragebogenausfuellerVerwaltungFormConfiguration(this.fragebogen)
		);
	}

	onZurueckZurUebersichtClicked() {
		this.blockUI.start('Daten werden verarbeitet...');
	}

}
