import {Institution} from 'app/shared/model/Institution';
import {SichtbarerRecherchekomplexDto} from 'app/portal-selector/model/SichtbarerRecherchekomplexDto';

export class SichtbareInstitutionDto {
	institution: Institution;
	recherchekomplexe: SichtbarerRecherchekomplexDto[];

	static buildList(dtos: any[]): SichtbareInstitutionDto[] {
		return dtos.map(dto => new SichtbareInstitutionDto(dto));
	}

	constructor(dto?: SichtbareInstitutionDto) {
		if (dto) {
			this.institution = new Institution(dto.institution);
			this.recherchekomplexe = dto.recherchekomplexe.map(rk => new SichtbarerRecherchekomplexDto(rk));
		}
	}
}
