import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {EditLockService} from 'app/shared/service/EditLockService';
import {NewTabComponent} from "../../shared/components/new-tab.component";
import {Recherchekomplex} from '../../shared/model/Recherchekomplex';
import {RecherchekomplexService} from '../../shared/service/RecherchekomplexService';
import {FormEditorVorschauComponent} from "../vorschau/FormEditorVorschauComponent";
import {UserService} from "../../form-viewer/service/UserService";

@Component({
	selector: 'app-form-editor-header',
	templateUrl: './FormEditorHeaderComponent.html',
	styleUrls: ['./FormEditorHeaderComponent.less'],
})
export class FormEditorHeaderComponent {
	@Input()
	fragebogen: Fragebogen;

	@Input()
	enableMove: boolean;

	@Output()
	beginMove = new EventEmitter<void>();

	@Output()
	beginEditMetadata = new EventEmitter<void>();

	@ViewChild('vorschau')
	vorschau: NewTabComponent;

	showVorschau = false;
	recherchekomplexeVisible: boolean;
	recherchekomplexe: Recherchekomplex[];

	constructor(private lockService: EditLockService, private recherchekomplexService: RecherchekomplexService, private router: Router,
				public userService: UserService) {
	}

	onBeginMoveClicked() {
		if (!this.moveButtonEnabled()) {
			return;
		}

		this.beginMove.emit();
	}

	onBeginEditMetadataClicked() {
		if (!this.metadataButtonEnabled()) {
			return;
		}

		this.beginEditMetadata.emit();
	}

	metadataButtonEnabled() {
		return !this.lockService.isLocked();
	}

	moveButtonEnabled() {
		return this.enableMove && !this.lockService.isLocked();
	}

	showRecherchekomplexModal() {
		this.recherchekomplexService.getRecherchekomplexe().then(r => this.recherchekomplexe = r);
		this.recherchekomplexeVisible = true;
	}

	goToEditor() {
		const index = this.fragebogen.veroeffentlicht ? '1' : '0';
		this.router.navigate([`/editor/fragebogen`], {fragment: index});
	}

	openVorschau() {
		this.showVorschau = true;
		this.vorschau.openContent(FormEditorVorschauComponent, 'Vorschau');
	}

}
