<nav class="navbar{{ style ? '-' + style : '' }}">
	<div class="container">
		<div class="navbar-header">
			<a class="navbar-brand" routerLink="/">
				<img src="assets/portal/logo{{ style ? '-' + style : '' }}.png" width="160">
			</a>
		</div>

		<ul class="nav navbar-nav navbar{{ style ? '-' + style : '' }} navbar-right">
			<li>
				<a routerLink="/portal-allgemein">Übersicht</a>
			</li>
			<li>
				<a routerLink="/archiv">Archiv</a>
			</li>
			<li>
				<a routerLink="/sicherheit">Sicherheit</a>
			</li>
			<li>
				<a routerLink="/faq">Fragen&nbsp;&&nbsp;Antworten</a>
			</li>
			<li *ngIf="istRecherchekomplexAdmin || istRecherchekomplexCoAdmin">
				<a routerLink="/benutzerverwaltung">Benutzerverwaltung</a>
			</li>
			<li *ngIf="showTeam()">
				<a routerLink="/team">Team</a>
			</li>

			<li><a (click)="userService.logout()">Abmelden</a></li>
		</ul>
	</div>
</nav>
