import {AbschnittSperre} from 'app/shared/model/AbschnittSperre';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {IFormViewerAbschnittSperrenHelper} from 'app/sperren-helper/IFormViewerAbschnittSperrenHelper';
import {AbschnittSperreService} from 'app/form-viewer/service/AbschnittSperreService';
import {Injectable} from '@angular/core';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';

@Injectable()
export class FormViewerAbschnittSperrenHelper implements IFormViewerAbschnittSperrenHelper {

	stoppRegelmaessigeVerlaengerungCallback: () => void = null;

	constructor(private abschnittSperreService: AbschnittSperreService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService) {

	}

	sperreAbschnitt(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			return this.abschnittSperreService.sperren(abschnitt);
		} else {
			return Promise.resolve(null);
		}
	}

	istAbschnittFuerAktuellenUserGesperrt(abschnitt: Abschnitt): boolean {
		return this.abschnittSperreService.istAbschnittFuerAktuellenUserGesperrt(abschnitt);
	}

	// Promise.catch muss vom Aufrufer bedient werden
	entsperrenAbschnitt(abschnitt: Abschnitt): Promise<void> {

		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			return this.abschnittSperreService.entsperren(abschnitt);
		} else {
			return Promise.resolve();
		}
	}

	starteRegelmaessigeVerlaengerung(abschnitt: Abschnitt) {
		if (this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt)) {
			this.stoppRegelmaessigeVerlaengerungCallback = this.abschnittSperreService.starteRegelmaessigeVerlaengerung(abschnitt);
		}
	}

	stoppeRegelmaessigeVerlaengerung() {
		if (this.stoppRegelmaessigeVerlaengerungCallback) {
			this.stoppRegelmaessigeVerlaengerungCallback();
			this.stoppRegelmaessigeVerlaengerungCallback = null;
		}
	}
}
