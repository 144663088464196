import {Component, Input} from '@angular/core';
import {FrageFertigMarker} from 'app/form-viewer/model/FrageFertigMarker';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';

@Component({
	selector: 'app-viewer-antwort-als-fertig-markiert-liste',
	templateUrl: './ViewerAntwortAlsFertigMarkiertListeComponent.html',
	styleUrls: ['./ViewerAntwortAlsFertigMarkiertListeComponent.less']
})
export class ViewerAntwortAlsFertigMarkiertListeComponent {
	@Input()
	frageFertigMarker: FrageFertigMarker[];

	@Input()
	unfertigeAusfueller: Ausfueller[];

	isEmpty() {
		return this.frageFertigMarker.length === 0 &&
			this.unfertigeAusfueller.length === 0;
	}
}
