<div class="row">
	<div class="col-md-12">
		<div class="checkbox">
			<input
				[(ngModel)]="isErweiterbar"
				id="{{ 'erweiterbarAktiviert' | newID }}"
				(ngModelChange)="erweiterbarkeitChanged()"
				name="erweiterbarAktiviert"
				type="checkbox"
			/>
			<label for="{{ 'erweiterbarAktiviert' | lastID }}">
				Erweiterbar
			</label>
		</div>
	</div>
</div>

<div class="scroll">

	<app-editor-abschnitt-frage-edit-tabelle-fix
		[frage]="frage"
		[availableTypes]="availableTypes"
		*ngIf="!isErweiterbar"
	></app-editor-abschnitt-frage-edit-tabelle-fix>
	<app-editor-abschnitt-frage-edit-tabelle-erweiterbar
		[frage]="frage"
		[availableTypes]="availableTypes"
		*ngIf="isErweiterbar"
	></app-editor-abschnitt-frage-edit-tabelle-erweiterbar>

</div>
