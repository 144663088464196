
<div *ngIf="initDone">

<div class="hero noKeyvisualBackground">
	<div class="keyvisual">
		<app-header></app-header>
		<div class="container">
			<div class="row">
				<div class="col-md-6 keyvisual-text">
					<h2 class="serif">Guten Tag, {{ ausfueller?.anrede }} {{ ausfueller?.anzeigename }}.</h2>
					<p>Herzlich Willkommen zur JUVE Recherche!</p>
					<p>
						Der JUVE Verlag steht seit über zwei Jahrzehnten für kompetente journalistische Berichterstattung über den
						Rechts- und Steuermarkt. Für Entscheider sind unsere Rankings und Analysen ein wichtiger Wegweiser.
					</p>

					<p>Mit der JUVE Recherche bieten wir Ihnen eine komfortable Möglichkeit, unsere Recherchen zu unterstützen.
					   Wählen Sie einfach die freigeschalteten Fragebögen aus, die Sie bearbeiten möchten, und legen Sie los.
					</p>
					<p>
					   Weitere Informationen finden Sie im Menü unter <a routerLink="/faq">„Fragen & Antworten“</a>
					   und in den einzelnen Recherchekomplexen.
					</p>
					<p><strong>Vielen Dank!</strong></p>
				</div>

				<div class="col-md-6">
					<div class="panel form-panel panel-default">
						<div class="panel-body">
							<h2 class="serif">Ihre Fragebögen</h2>
							<app-portal-selector [bereich]="'portal-allgemein'">
								<app-spinner></app-spinner>
							</app-portal-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container">
	<div class="row contacts">
		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Steuermarkt?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'mecke'" [bluePanel]="true"></app-contact>
			</div>
		</div>

		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Wirtschaftskanzleien?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'scherer'" [bluePanel]="true"></app-contact>
			</div>
		</div>

		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Top-Arbeitgeber für Juristen?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'ossen'" [bluePanel]="true"></app-contact>
			</div>
		</div>
	</div>
</div>

<div class="container about-juve centered">
	<div class="logo">
		<img src="assets/portal/juve-logomono.png" width="118" alt="">
	</div>
	<div class="about-text">
		Umfangreiche Recherchen bei Kanzleien, Unternehmensverantwortlichen, Behördenvertretern und Mitarbeitern aus Justiz und
		Wissenschaft schaffen die Basis für die Publikationen des JUVE-Verlags. Unsere umfassenden Rankings werden von einem
		rund 30-köpfigen Team von Fachjournalisten recherchiert und erstellt.
	</div>
</div>

<app-footer></app-footer>
</div>
