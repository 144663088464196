import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputAntwortBuilderUtil} from 'app/shared/util/InputAntwortBuilderUtil';
import {UUID} from 'app/util/export-types';

export class AntwortZelle {
	id: UUID;
	antwort: InputTypeAntwort<any>;

	static erzeugeLeereAntwortZelle(type: InputType): AntwortZelle {
		const leereZelle = new AntwortZelle();

		leereZelle.antwort = InputAntwortBuilderUtil.buildForType(type);

		return leereZelle;
	}

	constructor(zelle?: AntwortZelle) {
		if (zelle) {
			this.constructFromZelle(zelle);
		}
	}

	private constructFromZelle(zelle: AntwortZelle) {
		this.id = zelle.id;

		this.antwort = InputAntwortBuilderUtil.build(zelle.antwort);
	}

	applyIds(zelle: AntwortZelle) {
		this.id = zelle.id;
		this.antwort.applyIds(zelle.antwort);
	}

	istInhaltlichGleich(o: AntwortZelle) {
		return this.antwort.type === o.antwort.type && this.antwort.istInhaltlichGleich(o.antwort);
	}

	isValid() {
		return this.antwort.isValid();
	}
}
