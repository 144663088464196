<button (click)="openDialog()" class="btn btn-primary"
		title="Sollten Sie wichtige Ergänzungen zu Ihrem Fragebogen haben, können Sie den Fragebogen über diese Schaltfläche in den Bereich
	'Ihre Fragebögen' verschieben und von dort aus wieder bearbeiten. Bitte beachten Sie, dass Sie den Fragebogen anschließend erneut an
	JUVE übermitteln müssen, damit die Redaktion die neuen Informationen sehen kann." containerClass="bigger-tooltip"
>Fragebogen erneut bearbeiten
</button>

<div class="modal fade" bsModal #aufschliessenModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Fragebogen an JUVE übermitteln</h4>

				<button type="button" class="close pull-right" aria-label="Close" (click)="closeDialog()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="height: 400px">
				Bitte beachten Sie, dass der Fragebogen erneut an JUVE übermittelt werden muss, da JUVE sonst mit der zuletzt
				übermittelten Version weiter arbeitet.

				<div class="button-bar" ngPreserveWhitespaces>
					<button class="btn btn-primary" (click)="commitAufschliessen()">Fragebogen erneut bearbeiten</button>
					<button class="btn btn-default" (click)="closeDialog()">Fragebogen geschlossen lassen</button>
				</div>
			</div>
		</div>
	</div>
</div>
