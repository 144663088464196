import {Component, Input} from '@angular/core';
import {SichtbarerRecherchekomplexDto} from 'app/portal-selector/model/SichtbarerRecherchekomplexDto';
import {Institution} from 'app/shared/model/Institution';

@Component({
	selector: 'app-portal-recherchekomplex-selector',
	templateUrl: './PortalRecherchekomplexSelectorComponent.html',
	styleUrls: ['./PortalRecherchekomplexSelectorComponent.less']
})
export class PortalRecherchekomplexSelectorComponent {

	@Input()
	institution: Institution;

	@Input()
	recherchekomplexDtos: Array<SichtbarerRecherchekomplexDto> = [];
}
