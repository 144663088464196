export class CacheUtil {
	private cache = {};

	exists(key: string): any {
		return key in this.cache;
	}

	get(key: string): any {
		return this.cache[key];
	}

	set(key: string, value: any): any {
		this.cache[key] = value;
		return value;
	}

	clear() {
		// console.log("Clear Cache")
		// this.cache = {};
	}

	getOrCalculateAndSave(key: string, calculator: () => any): any {
		if (this.exists(key)) {
			// console.log(this.get(key))
			return this.get(key);
		} else {
			console.log(key + " NOT Found")
		}

		return this.set(key, calculator());
	}
}
