import {Component, Input, OnInit} from '@angular/core';
import {InputTypeText} from 'app/shared/model/frage/input/InputTypeText';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';

@Component({
	selector: 'app-input-type-text-edit',
	templateUrl: './InputTypeTextEditComponent.html',
	styleUrls: ['./InputTypeTextEditComponent.less']
})
export class InputTypeTextEditComponent implements OnInit, IInputTypeEditComponent<InputTypeText> {
	@Input()
	inputType: InputTypeText;

	platzhalterText = '';

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
