import {AusfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
/* eslint-disable-next-line max-len */
import {FragebogenausfuellerVerwaltungFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormComponent';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {FragebogenausfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/FragebogenausfuellerAnlegenDto';

export class FragebogenausfuellerVerwaltungFormConfiguration implements AusfuellerVerwaltungFormConfiguration {
	readonly FORM_COMPONENT = FragebogenausfuellerVerwaltungFormComponent;
	readonly DTO_TYPE = FragebogenausfuellerAnlegenDto;

	constructor(private fuerFragebogen: Fragebogen) {
	}

	getWebserviceEndpoint() {
		return 'fragebogenausfueller';
	}

	getFormTitleHtml() {
		return 'Neuen Fragebogen-Bearbeiter für <strong>' + this.fuerFragebogen.name + '</strong> einladen';
	}

	getEinladenButtonToolTipHtml() {
		return 'Hier können Sie weitere Bearbeiter für den Fragebogen <strong>' + this.fuerFragebogen.name + '</strong> einladen.';
	}

	getErklaerungsHtml() {
		return `<p>Hier können Sie weitere <a
				href="faq?recherchekomplex=${this.fuerFragebogen.recherchekomplex.id}#benutzerrolle_bearbeiter"
				target="_blank">Fragebogen-Bearbeiter</a> für den Fragebogen <b>${this.fuerFragebogen.name}</b> einladen. Dieser Bearbeiter darf
				alle Fragebögen einsehen, jedoch nur Fragen innerhalb dieses Fragebogens beantworten. Sie können ein Datum angeben, bis zu
				welchem dieser seine Antworten einzureichen hat. Dieses wird ihm beim Ausfüllen angezeigt.</p>

				<p>Möchten Sie stattdessen Bearbeiter mit geringeren Befugnissen einladen, verwenden Sie die Einladen-Buttons am jeweiligen
				 Abschnitt.</p>`;
	}

	applyToAnlegenDto(anlegenDto: FragebogenausfuellerAnlegenDto): FragebogenausfuellerAnlegenDto {
		anlegenDto.fuerFragebogen = this.fuerFragebogen.id;
		return anlegenDto;
	}
}
