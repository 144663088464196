<div class="col-md-12">
	<div class="header">
		<nav class="main navbar navbar-default">
			<div class="container-fluid">
				<div class="navbar-header">
					<a class="navbar-brand" style=" margin-left: 10px; margin-top: 10px;">
						<img class="logo" src="/assets/common/juve_logo.svg" alt="JUVE Recherche"/>
					</a>
				</div>
				<div style="padding: 25px;">
					<ul class="nav navbar-nav navbar-right">
						<li><a href="" (click)="showRecherchekomplexModal()" appPreventDefault>Recherchekomplexe</a>
						</li>
						<li><a (click)="userService.logoutEditor()">Ausloggen</a></li>
					</ul>
				</div>
			</div>
		</nav>
		<nav *ngIf="fragebogen" class="sub navbar navbar-default">
			<div class="container-fluid">
				<ul class="nav navbar-nav navbar-left">
					<li><a (click)="goToEditor()" href appPreventDefault><i class="icon-chevron-thin-left"></i></a></li>
					<!-- Verwende anchor Tag hier, obwohl es kein Link ist, da der Bootstrap Style nur hierfür greift -->
					<li><a class="object-name">{{ fragebogen ? fragebogen.name : '' }}</a></li>
				</ul>

				<ul class="nav navbar-nav navbar-right">
					<ng-content></ng-content>
					<li *ngIf="enableMove"><a [class.disabled]="!metadataButtonEnabled()" href
											  (click)="onBeginEditMetadataClicked()" appPreventDefault>
						<i class="glyphicon glyphicon-text-size"></i>
					</a></li>
					<li *ngIf="enableMove"><a [class.disabled]="!moveButtonEnabled()" href
											  (click)="onBeginMoveClicked()" appPreventDefault>
						<i class="glyphicon glyphicon-sort"></i>
					</a></li>
					<li><a href="" appPreventDefault (click)="openVorschau()">
						<i class="icon-eye"></i>
					</a></li>
				</ul>
			</div>
		</nav>
	</div>
</div>

<p-dialog header="Recherchekomplexe" [modal]="true" [(visible)]="recherchekomplexeVisible">
	<ul>
		<li *ngFor="let r of recherchekomplexe">
			<b>{{ r.name }}</b>
		</li>
	</ul>
</p-dialog>

<app-new-tab-container #vorschau *ngIf="fragebogen"></app-new-tab-container>
