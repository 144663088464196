<table class="table table-bordered">
	<thead>
	<tr>
		<th *ngFor="let spalte of getSpalten()">
			{{ spalte.ueberschrift }}

			<app-view-info-text [beschreibung]="spalte.beschreibung"></app-view-info-text>
		</th>
		<th class="controls-cell" *ngIf="!readonly"></th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let zeile of getZeilen()">
		<td class="input {{zelle.spalte.type.type}}" *ngFor="let zelle of zeile.zellenSpaltenTupel">
			<app-input-view [context]="{ hint: zelle.spalte.ueberschrift }"
							variant="table-cell"
							[type]="zelle.spalte.type"
							[readonly]="readonly"
							[printView]="printView"
							[antwort]="zelle.antwort"
			></app-input-view>
		</td>
		<td class="remove-zeile controls-cell" *ngIf="!readonly">
			<div class="remover" (click)="removeZeile(zeile)">
				<img src="/assets/button/delete-row.svg">
			</div>
		</td>
	</tr>
	</tbody>
	<tfoot *ngIf="!readonly">
	<tr>
		<th class="add-zeile" [attr.colspan]="spaltenAnzahlMitControls()">
			<button class="btn btn-primary" (click)="addZeile()"><i class="icon-plus"></i>Zeile hinzufügen</button>
		</th>
	</tr>
	</tfoot>
</table>
