<app-header></app-header>
<div class="container"
	 style="background-color: white; padding: 30px 0 20px 0 ; margin-bottom: 15px; border-radius: 5px;">
	<div class="row">
		<div class="col-md-8 col-md-offset-2">
			<h1>Fragen & Antworten</h1>
			<span *ngIf="!loaded">
				<app-spinner></app-spinner>
			</span>
			<span *ngIf="loaded">
<!--				<ul>-->
<!--					<li [innerHTML]="navigation"></li>-->
<!--				</ul>-->

				<span [innerHTML]="content"></span>
			</span>
		</div>
	</div>
</div>
<script>
	document.addEventListener("", () => {
		if (window.location.hash) {
			document.getElementById(window.location.hash).scrollIntoView()
		}
	})
</script>
<app-footer></app-footer>
