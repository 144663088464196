import {InputTypeEmailViewComponent} from "../../../../form-viewer/abschnitt/frage-liste/frage/view/input/input-type-email-view/InputTypeEmailViewComponent";
import {InputTypeEmailEditComponent} from "../../../components/input-type/email/InputTypeEmailEditComponent";
import {InputTypeEmailAntwort} from "../../antwort/input/InputTypeEmailAntwort";
import {InputType} from './InputType';

export class InputTypeEmail extends InputType {
	static readonly ID = 'InputTypeEmail';
	static readonly TYPE_DISPLAY_NAME = 'E-Mail';
	static readonly ANTWORT = InputTypeEmailAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static readonly VIEWER = InputTypeEmailViewComponent;
	static readonly EDITOR = InputTypeEmailEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeEmail) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}

}
