import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {TabellenFrage} from 'app/shared/model/frage/TabellenFrage';
import {Frage} from 'app/shared/model/Frage';
import {TextfeldFrage} from 'app/shared/model/frage/TextfeldFrage';
import {EntscheidungsFrage} from 'app/shared/model/frage/EntscheidungsFrage';
import {Antwort} from 'app/shared/model/Antwort';
import {TextfeldfragenAntwort} from 'app/shared/model/antwort/TextfeldfragenAntwort';
import {EntscheidungsfragenAntwort} from 'app/shared/model/antwort/EntscheidungsfragenAntwort';
import {TabellenfragenAntwort} from 'app/shared/model/antwort/TabellenfragenAntwort';
import {KartenfragenAntwort} from 'app/shared/model/antwort/KartenfragenAntwort';
import {MehrfachauswahlFrage} from 'app/shared/model/frage/MehrfachauswahlFrage';
import {MehrfachauswahlAntwort} from 'app/shared/model/antwort/MehrfachauswahlAntwort';

const debug = require('debug')('model:FrageTypes');

export class FrageTypes {
	static readonly TYPES: typeof Frage[] = [
		TextfeldFrage,
		EntscheidungsFrage,
		TabellenFrage,
		KartenFrage,
		MehrfachauswahlFrage,
	];

	static readonly ANTWORT_TYPES: (new(antwort?: Antwort) => Antwort)[] = [
		TextfeldfragenAntwort,
		EntscheidungsfragenAntwort,
		TabellenfragenAntwort,
		KartenfragenAntwort,
		MehrfachauswahlAntwort
	];

	static typeForID(id: string): typeof Frage {
		const typeClass = FrageTypes.TYPES.find(type => type.ID === id);
		if (!typeClass) {
			debug('Fragentyp nicht definiert: ', id);
		}

		return typeClass;
	}

	static antwortTypeForID(id: string): new(antwort?: Antwort) => Antwort {
		const typeClass = FrageTypes.ANTWORT_TYPES.find(type => type['FRAGEN_TYPE_ID'] === id);
		if (!typeClass) {
			debug('Antworttyp nicht definiert: ', id);
		}

		return typeClass;
	}
}
