import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-karten-add-button',
	templateUrl: './KartenAddButtonComponent.html',
	styleUrls: ['./KartenAddButtonComponent.less']
})
export class KartenAddButtonComponent {

	@Output()
	click = new EventEmitter();

	@Input()
	dimmed = false;

	onClick(e) {
		e.stopPropagation();
		this.click.emit();
	}
}
