import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {VerwaltbarerAusfuellerDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerDto';
import {VerwaltbarerAusfuellerFragebogenDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerFragebogenDto';
import {VerwaltbarerAusfuellerInstitutionDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerInstitutionDto';
import {VerwaltbarerAusfuellerRecherchekomplexDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerRecherchekomplexDto';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {UserService} from "../../form-viewer/service/UserService";
// Import BlockUI decorator & optional NgBlockUI type
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Observable, of} from "rxjs";
import {RecherchekomplexMitSichtbarenAbschnittenDto} from "../model/RecherchekomplexMitSichtbarenAbschnittenDto";
import {catchError} from "rxjs/operators";
import {MessageService} from "primeng/api";

@Injectable({
	providedIn: 'root'
})
export class AusfuellerService {
	@BlockUI() blockUI: NgBlockUI;


	httpOptions = {
		headers: new HttpHeaders({'Content-Type': 'application/json'})
	};
	private returnValue: Promise<boolean>;
	private boolVal: string;

	constructor(private httpClient: HttpClient, private alertService: AlertService, private userService: UserService,
				private messageService: MessageService) {
	}

	holeAusfueller(): Promise<VerwaltbarerAusfuellerDto[]> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get<VerwaltbarerAusfuellerDto[]>('/view/ausfueller')
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => VerwaltbarerAusfuellerDto.fromList(json))
			.finally(this.blockUI.stop);
	}

	holeAusfuellerForCurrentInst(block: boolean): Promise<VerwaltbarerAusfuellerDto[]> {
		if (block)
			this.blockUI.start('Daten werden verarbeitet...');

		let currentInst = this.userService.getAktuelleInstitution()

		return this.httpClient.get<VerwaltbarerAusfuellerDto[]>('/view/ausfueller/ausfuellerByInst/' + this.userService.getAktuelleInstitution().id)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => VerwaltbarerAusfuellerDto.fromList(json))
			.finally(
				this.blockUI.stop
			)
	}

	holeAnfragenderAusfuellerMitVerwaltbarenRechten(): Promise<VerwaltbarerAusfuellerDto[]> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get<VerwaltbarerAusfuellerDto[]>('/view/ausfueller/anfragenderAusfuellerMitVerwaltbarenRechten')
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => VerwaltbarerAusfuellerDto.fromList(json))
			.finally(this.blockUI.stop);
	}

	getAusfuellerById(ausfueller: VerwaltbarerAusfuellerDto): Promise<VerwaltbarerAusfuellerDto> {
		console.log("getAusfuellerById")
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get<VerwaltbarerAusfuellerDto>('/view/ausfueller/ausfuellerById/' + ausfueller.id)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	getAusfuellerByMail(ausfueller: VerwaltbarerAusfuellerDto): Promise<VerwaltbarerAusfuellerDto> {
		console.log("getAusfuellerByMail")

		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get<VerwaltbarerAusfuellerDto>('/view/ausfueller/ausfuellerByMail/' + ausfueller.email)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	getAusfuellerByIdParam(ausfuellerId: String): Promise<VerwaltbarerAusfuellerDto> {
		console.log("getAusfuellerByIdParam")

		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get<VerwaltbarerAusfuellerDto>('/view/ausfueller/ausfuellerById/' + ausfuellerId)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	//Test für Anzeige von Abschnitten
	getRecherchekomplexMitSichtbarenAbschnitten(ausfueller: VerwaltbarerAusfuellerDto,
												recherchekomplexId: string,
												institutionId: string): Promise<RecherchekomplexMitSichtbarenAbschnittenDto[]> {
		return this.httpClient.get<RecherchekomplexMitSichtbarenAbschnittenDto[]>(
			'/view/ausfueller/fragebogenMitBerechtigung/' + ausfueller.id + '/' + recherchekomplexId + '/' + institutionId)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => RecherchekomplexMitSichtbarenAbschnittenDto.fromList(json)).finally(this.blockUI.stop);
	}

	getRecherchekomplexMitSichtbarenAbschnittenOhneRechte(ausfueller: VerwaltbarerAusfuellerDto,
														  recherchekomplexId: string,
														  institutionId: string): Promise<RecherchekomplexMitSichtbarenAbschnittenDto[]> {
		return this.httpClient.get<RecherchekomplexMitSichtbarenAbschnittenDto[]>(
			'/view/ausfueller/recherchekomplexMitSichtbarenAbschnittenOhneRechte/' + ausfueller.id + '/' + recherchekomplexId + '/' + institutionId)
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => RecherchekomplexMitSichtbarenAbschnittenDto.fromList(json));
	}

	//Test
	hallo() {
		return this.httpClient.get('/view/ausfueller/hallo')
			.toPromise()
			.catch(this.alertService.handleHttpError)
			.then(json => console.log(json))
	}

	updateAlleRechte(ausfueller: VerwaltbarerAusfuellerDto, recherchekomplexMitAllenAbschnitten: RecherchekomplexMitSichtbarenAbschnittenDto): Observable<VerwaltbarerAusfuellerDto> {
		// console.log(ausfueller);
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/rechte_aendern/' + ausfueller.id, recherchekomplexMitAllenAbschnitten, this.httpOptions).pipe();
	}

	//nach Tour of Heroes / Angular Website
	updateAusfueller(ausfueller: VerwaltbarerAusfuellerDto): Observable<VerwaltbarerAusfuellerDto> {
		if (!ausfueller.benutzername ||
			ausfueller.benutzername === "") {
			ausfueller.benutzername = ausfueller.email
		}
		// console.log(ausfueller);
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/daten_aendern/' + ausfueller.id, ausfueller, this.httpOptions).pipe(
			catchError(this.handleError('updatedAusfueller', ausfueller))
		);
	}

	fragebogenRechtZurueckziehen(ausfueller: VerwaltbarerAusfuellerDto,
								 institution: VerwaltbarerAusfuellerInstitutionDto,
								 recherchekomplex: VerwaltbarerAusfuellerRecherchekomplexDto,
								 fragebogen: VerwaltbarerAusfuellerFragebogenDto): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.delete('/view/ausfueller/fragebogen_berechtigung/' + ausfueller.id + '/' + institution.id + '/' + recherchekomplex.id + '/' + fragebogen.id)
			.toPromise()
			.then(() => null)
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	abschnittRechtZurueckziehen(ausfueller: VerwaltbarerAusfuellerDto,
								institution: VerwaltbarerAusfuellerInstitutionDto,
								recherchekomplex: VerwaltbarerAusfuellerRecherchekomplexDto,
								fragebogen: VerwaltbarerAusfuellerFragebogenDto,
								abschnitt: Abschnitt): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.delete('/view/ausfueller/abschnitt_berechtigung/' + ausfueller.id + '/' + institution.id + '/' + recherchekomplex.id + '/' + fragebogen.id + '/' + abschnitt.id)
			.toPromise()
			.then(() => null)
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	recherchekomplexRechtZurueckziehen(ausfueller: VerwaltbarerAusfuellerDto,
									   institutionId: String,
									   recherchekomplexId: String): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get('/view/ausfueller/recherchekomplex_coadmin_berechtigung/' + ausfueller.id + '/' + institutionId + '/' + recherchekomplexId)
			.toPromise()
			.then(() => null)
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	alleRechteEinesAusfuellersLoeschen(ausfueller: VerwaltbarerAusfuellerDto): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.delete('/view/ausfueller/alle_berechtigungen/' + ausfueller.id + "/" + this.userService.getAktuelleInstitution().id).toPromise()
			.then(() => null)
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	isAnyAdmin(ausfueller: VerwaltbarerAusfuellerDto): Promise<boolean> {
		return this.httpClient.get<boolean>('/view/ausfueller/is_any_admin/' + ausfueller.id + "/")
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}

	isAnyAdminForInstitute(ausfuellerId: String, institutionId: String): Promise<boolean> {
		return this.httpClient.get<boolean>('/view/ausfueller/is_any_admin_for_institute/' + ausfuellerId + "/" + institutionId)
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}

	neuenBenutzercodeGenerieren(ausfueller: VerwaltbarerAusfuellerDto): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.httpClient.get('/view/ausfueller/neuen_code_erstellen/' + ausfueller.id)
			.toPromise()
			.then(() => null)
			.catch(this.alertService.handleHttpError)
			.finally(this.blockUI.stop);
	}

	isAnyCoAdmin(ausfueller: VerwaltbarerAusfuellerDto): Promise<boolean> {
		return this.isAnyCoAdminByID(ausfueller.id)
	}

	isAnyCoAdminByID(ausfuellerID: String): Promise<boolean> {
		return this.httpClient.get<boolean>('/view/ausfueller/is_any_coadmin/' + ausfuellerID)
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}

	inviteAsCoAdmin(verwaltbarerAusfuellerId: string, newKomplexId: String,
					institutionenId: String,
					darfFragebogenAbschliessen: boolean): Observable<VerwaltbarerAusfuellerDto> {
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/invite_co_admin/' + verwaltbarerAusfuellerId + '/' + newKomplexId + '/' + institutionenId + '/' + darfFragebogenAbschliessen, this.httpOptions).pipe();
	}

	createUser(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto): Observable<VerwaltbarerAusfuellerDto> {
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/create_user/' + this.userService.getAktuelleInstitution().id, verwaltbarerAusfueller, this.httpOptions).pipe();
	}

	createUserAndAddRights(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto,
						   recherchekomplexMitAllenAbschnitten: RecherchekomplexMitSichtbarenAbschnittenDto,
						   darfFragebogenAbschliessen: boolean): Observable<VerwaltbarerAusfuellerDto> {
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/create_user_add_rights', {
			"neueAusfuellerdaten": verwaltbarerAusfueller,
			"recherchekomplexMitAllenAbschnitten": recherchekomplexMitAllenAbschnitten,
			"darfFragebogenAbschliessen": darfFragebogenAbschliessen
		}).pipe();
	}

	createAndInviteAsCoAdmin(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto,
							 newKomplexId: String,
							 darfFragebogenAbschliessen: boolean): Observable<VerwaltbarerAusfuellerDto> {
		return this.httpClient.put<VerwaltbarerAusfuellerDto>('/view/ausfueller/create_and_invite_co_admin/' + newKomplexId + '/' + this.userService.getAktuelleInstitution().id + '/' + darfFragebogenAbschliessen, verwaltbarerAusfueller, this.httpOptions).pipe();
	}

	sendTempPwd(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto) {
		this.blockUI.start('Daten werden verarbeitet...');
		try {
			return this.httpClient.get('/view/ausfueller/send_tmp_pwd/' + verwaltbarerAusfueller.id)
				.toPromise().then(response => {
					if (!response) {
						this.alertService.showDanger("Passwort konnte nicht versendet werden")
					} else {
						this.alertService.showInfo("Passwort wurde versendet")
					}
				})
				.catch(this.alertService.handleHttpError)
				.finally(this.blockUI.stop);
		} catch (exception) {
			console.log(exception)
		} finally {
		}
	}

	private handleError<T>(operation = `operation`, result?: T) {
		return (error: any): Observable<T> => {
			console.error(error);
			return of(result as T);
		};
	}
}
