import {Injectable} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {Berechtigung} from 'app/form-viewer/model/Berechtigung';
import {UserService} from 'app/form-viewer/service/UserService';
import {CacheUtil} from 'app/form-viewer/util/CacheUtil';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';
import {UUID} from 'app/util/export-types';
import {selectInstitution} from "../../store/selector/institution.selectors";
import {selectRecherchekomplex} from "../../store/selector/recherchekomplex.selectors";

@Injectable()
export class AusfuellerBerechtigungsService {

	private cache = new CacheUtil();

	constructor(
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private userService: UserService,
		private store: Store
	) {
	}

	clearCache() {
		this.cache.clear()
	}

	istUnterausfueller() {
		return !this.istAdministrator() && !this.istCoAdministrator();
	}

	darfCoAdminsVerwalten() {
		return this.istAdministrator();
	}

	darfFragebogenausfuellerVerwalten() {
		return this.istAdministrator();
	}

	darfAbschnittsausfuellerFuerFragebogenVerwalten(fragebogen: Fragebogen) {
		return this.istAdministrator();
	}

	darfAbschnittBearbeiten(abschnitt: Abschnitt) {
		return this.istAdministrator() ||
			this.istCoAdministrator() ||
			this.istFragebbogenAusfuellerFuer(abschnitt.fragebogen_id) ||
			this.istAbschnittsAusfuellerFuer(abschnitt.id);
	}

	darfFrageboegenAufUndAbschliessen() {
		if (this.istAdministrator()) {
			return true;
		}

		return this.findeCoAdministratorBerechtigungen()
			.filter(berechtigung => berechtigung.darfFrageboegenAufUndAbschliessen)
			.length > 0;
	}

	darfAlsGeprueftMarkieren() {
		return this.istAdministrator() ||
			this.istCoAdministrator();
	}

	darfAntwortenUebernehmen() {
		return this.istAdministrator() ||
			this.istCoAdministrator();
	}

	darfFrageFertigMarkerSehen() {
		return this.darfAlsGeprueftMarkieren();
	}

	darfFrageAlsFertigMarkierenFuerAbschnitt(abschnitt: Abschnitt) {
		return !this.istAdministrator() &&
			!this.istCoAdministrator() &&
			(this.istFragebbogenAusfuellerFuer(abschnitt.fragebogen_id) ||
				this.istAbschnittsAusfuellerFuer(abschnitt.id));
	}

	gehoertZuRecherchekomplexUndInstitution(): boolean {
		let institution
		this.store.pipe(select(selectInstitution)).subscribe(value => institution = value)

		let recherchekomplex
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => recherchekomplex = value)

		return this.userService.getAusfueller()
			.berechtigungen
			.filter(b => b.institution == institution && b.recherchekomplex == recherchekomplex).length > 0
	}

	istAdministrator(): boolean {
		return this.userService.getAusfueller().berechtigungen
			.filter(b => b.gehoertZu(
				this.aktuelleBerechtigungService.getInstitutionsId(),
				this.aktuelleBerechtigungService.getRecherchekomplexId()))
			.some(b => b.type === 'RECHERCHEKOMPLEX_ADMIN')
	}

	istCoAdministrator(): boolean {
		return this.findeCoAdministratorBerechtigungen().length > 0;
	}

	private findeCoAdministratorBerechtigungen(): Berechtigung[] {
		return this.userService.getAusfueller().berechtigungen
			.filter(b => b.gehoertZu(
				this.aktuelleBerechtigungService.getInstitutionsId(),
				this.aktuelleBerechtigungService.getRecherchekomplexId()))
			.filter(b => b.type === 'CO_ADMIN')
	}

	istAbschnittsAusfuellerFuer(abschnittId: UUID): boolean {
		return this.userService.getAusfueller().berechtigungen
			.filter(b => b.gehoertZu(
				this.aktuelleBerechtigungService.getInstitutionsId(),
				this.aktuelleBerechtigungService.getRecherchekomplexId()))
			.some(b => b.type === 'ABSCHNITTSAUSFUELLER' && b.fuerAbschnitt.id === abschnittId)
	}

	istFragebbogenAusfuellerFuer(fragebogenId: UUID): boolean {
		return this.userService.getAusfueller().berechtigungen
			.filter(b => b.gehoertZu(
				this.aktuelleBerechtigungService.getInstitutionsId(),
				this.aktuelleBerechtigungService.getRecherchekomplexId()))
			.some(b => b.type === 'FRAGEBOGENAUSFUELLER' && b.fuerFragebogen.id === fragebogenId)
	}
}
