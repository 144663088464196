<div class="btn-group">

	<button
		class="btn btn-default"
		(click)="closeDrawerAndPrev()"
		[disabled]="isAtFirst()"
	>
		<!--		routerLink="#frage-{{ getFrageId(getPrevFrage()) }}"-->
		<i class="icon-chevron-up"></i>
	</button>

	<button
		class="btn btn-default"
		(click)="closeDrawerAndNext()"
		[disabled]="isAtLast()"
	>
		<!--		routerLink="#frage-{{ getFrageId(getNextFrage()) }}"-->
		<i class="icon-chevron-down"></i>
	</button>

</div>
<div class="aktive-frage" [class.opened]="drawerOpen" (click)="toggleDrawer()">
	Fragenverzeichnis
	<div class="icon">
		<i class="icon-chevron-up"></i>
	</div>
</div>

<nav class="frageliste" [class.opened]="drawerOpen">
	<div class="padded">
		<h3>Fragen in {{ abschnitt.ueberschrift }}</h3>
		<div class="row" *ngIf="!isMehrspaltig()">
			<div class="col-md-12">
				<app-viewer-fragen-nav-eintrag
					*ngFor="let frage of abschnitt.fragen"
					[frage]="frage"
					(click)="closeDrawerAndFocusFrage(frage)"
					[isAktiveFrage]="isAktiveFrage(frage)"
				></app-viewer-fragen-nav-eintrag>
			</div>
		</div>

		<div class="row" *ngIf="isMehrspaltig()">
			<div class="col-md-6">
				<app-viewer-fragen-nav-eintrag
					*ngFor="let frage of getFragenSpalten()[0]"
					[frage]="frage"
					(click)="closeDrawer()"
					[isAktiveFrage]="isAktiveFrage(frage)"
				></app-viewer-fragen-nav-eintrag>
			</div>
			<div class="col-md-6">
				<app-viewer-fragen-nav-eintrag
					*ngFor="let frage of getFragenSpalten()[1]"
					[frage]="frage"
					(click)="closeDrawer()"
					[isAktiveFrage]="isAktiveFrage(frage)"
				></app-viewer-fragen-nav-eintrag>
			</div>
		</div>
	</div>
</nav>
