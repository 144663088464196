import {AbschnittSperre} from 'app/shared/model/AbschnittSperre';
import {AbschnittStatus} from 'app/shared/model/AbschnittStatus';

export class AbschnittInfoDto {

	private sperren: AbschnittSperre[] = [];
	private status: AbschnittStatus[] = [];

	constructor(abschnittInfoDto: AbschnittInfoDto = null) {
		if (abschnittInfoDto) {
			this.status = abschnittInfoDto.status.map(status => new AbschnittStatus(status));
			this.sperren = abschnittInfoDto.sperren.map(sperre => new AbschnittSperre(sperre));
		}
	}

	public getSperren(): AbschnittSperre[] {
		return this.sperren;
	}

	public getStatus(): AbschnittStatus[] {
		return this.status;
	}

}
