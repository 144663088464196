<table class="table table-bordered">
	<thead>
	<tr>
		<th></th>
		<th *ngFor="let spalte of frage.spalten">
			<app-editor-abschnitt-frage-edit-tabelle-title
				[spalte]="spalte"
			></app-editor-abschnitt-frage-edit-tabelle-title>
		</th>
		<th class="add-spalte">
			<button class="btn btn-primary" (click)="addSpalte()">Spalte hinzufügen</button>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let zeile of frage.fixeZeilen; let first = first">
		<th>
			<input
				type="text"
				class="form-control"
				placeholder="Zeilentitel"
				[(ngModel)]="zeile.titel"
				name="titel"
			>
		</th>
		<ng-container *ngIf="first">
			<td *ngFor="let spalte of getSpalten()" rowSpan="{{ frage.fixeZeilen.length }}">
				<app-input-type-edit
					displaySuffix="-Spalte"
					[availableTypes]="availableTypes"
					[selectedType]="spalte.type"
					(changed)="onSpalteChanged(spalte, $event)"
				></app-input-type-edit>
			</td>
		</ng-container>
		<th class="remove-zeile">
			<button [disabled]="removeZeilenDisabled()" class="btn btn-danger" (click)="removeZeile(zeile)">Zeile löschen</button>
		</th>
	</tr>
	</tbody>
	<tfoot>
	<tr>
		<th class="add-zeile">
			<button class="btn btn-primary" (click)="addZeile()"><i class="icon-plus"></i>Zeile hinzufügen</button>
		</th>
		<th *ngFor="let spalte of frage.spalten">
			<button [disabled]="removeSpaltenDisabled()" class="btn btn-danger" (click)="removeSpalte(spalte)">Spalte löschen</button>
		</th>
	</tr>
	</tfoot>
</table>
