import {Component, HostListener, Input, OnInit} from '@angular/core';
import {RecherchekomplexUebersichtDto} from "../../../model/RecherchekomplexUebersichtDto";
import {RecherchekomplexUebersichtService} from "../../../services/recherchekomplex-uebersicht.service";
import {VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto} from "../../../model/VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {VerwaltbarerAusfuellerDto} from "../../../model/VerwaltbarerAusfuellerDto";
import {AusfuellerService} from "../../../services/AusfuellerService";
import {ActivatedRoute, Router} from "@angular/router";
import {faPencilAlt, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {BenutzerBearbeitenComponent} from "../../registrierte-benutzer/benutzer-bearbeiten/benutzer-bearbeiten.component";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {EventService} from "../../../services/EventService";

@Component({
	selector: 'app-recherchekomplex-benutzer',
	templateUrl: './recherchekomplex-benutzer.component.html',
	styleUrls: ['./recherchekomplex-benutzer.component.less'],
	providers: [DialogService, ConfirmationService]
})
export class RecherchekomplexBenutzerComponent implements OnInit {

	@BlockUI() blockUI: NgBlockUI;

	@Input() recherchekomplexId = '';
	public recherchekomplexBenutzer: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	public ausfuellerListe: VerwaltbarerAusfuellerDto[];
	public recherchekomplex: RecherchekomplexUebersichtDto;
	public selectedValue: VerwaltbarerAusfuellerDto;
	public cols: any[];
	public rows: number;
	faPen = faPencilAlt;
	faTrash = faTrashCan;
	ref: DynamicDialogRef;
	private screenWidth: number;
	private screenHeight: number;
	private recherchekomplexName: string;
	private initDone: boolean;

	constructor(public recherchekomplexUebersichtService: RecherchekomplexUebersichtService,
				private ausfuellerService: AusfuellerService,
				private route: ActivatedRoute,
				private router: Router,
				public dialogService: DialogService,
				private confirmationService: ConfirmationService,
				private messageService: MessageService,
				private eventService: EventService) {
	}

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.calcTabelRows(event.target.innerWidth, event.target.innerHeight);
	}

	ngOnInit(): void {
		this.route.params.subscribe(params => this.recherchekomplexId = params['recherchekomplexId']);
		// console.log('recherchekomplexId: ' + this.recherchekomplexId);
		this.calcTabelRows(window.innerWidth, window.innerHeight);

		this.cols = [
			{field: 'anrede', header: 'Anrede', filterTyp: 'contains'},
			{field: 'anzeigename', header: 'Name', filterTyp: 'contains'},
			{field: 'email', header: 'E-Mail', filterTyp: 'contains'},
			{field: 'institutionUndKomplex.recherchekomplexLabel', header: 'Rolle', filterTyp: 'contains'},
			{field: 'statusEnabledOderArchiviert', header: 'Status', filterTyp: 'startsWith'},
		];

		this.route.queryParams
			.subscribe(params => {
					this.recherchekomplexName = params.recherchekomplexName;
				}
			);

		this.initDone = false
		this.eventService.userUserRightsEditListener().subscribe(info => {
			// console.log("refreshing ausfueller liste on active toggle" + " inaktiv")
			this.reloadUserList();
		})

		this.eventService.recherchekomplexEingeladeneUserListener().subscribe(info => {
			// console.log("refreshing ausfueller liste ")
			this.reloadUserList();
		})
		this.initDone = true
		this.rufeRecherchekomplexBenutzerlisteAb();
	}

	async rufeRecherchekomplexBenutzerlisteAb() {
		this.reloadUserList();
	}

	zeigeBenutzerrechte(verwaltbarerAusfueller: VerwaltbarerAusfuellerDto) {
		if (verwaltbarerAusfueller == null) {
			this.messageService.clear();
			this.messageService.add({severity: 'warn', life: 5000, summary: 'Es konnte kein Benutzer geladen werden'});
		} else if (verwaltbarerAusfueller != null && verwaltbarerAusfueller.enabled && !verwaltbarerAusfueller.archiviert) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: 'Benutzer bearbeiten',
				width: '100%',
				height: '100%',
				styleClass: 'FullScreenDialog',
				modal: true,
				contentStyle: {
					"height": "100%",
					"width": "100%",
					"max-width": "100%",
					"max-height": "100%",
					"overflow": "auto"
				},
				baseZIndex: 10000,
				style: {"max-width": "100%", "max-height": "100%"},
				data: {
					verwaltbarerAusfueller: verwaltbarerAusfueller,
					einladung: false
				}
			});
		} else if (verwaltbarerAusfueller != null && verwaltbarerAusfueller.enabled && verwaltbarerAusfueller.archiviert) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: 'Inaktiven Benutzer bearbeiten',
				width: '100%',
				height: '100%',
				styleClass: 'FullScreenDialog',
				modal: true,
				contentStyle: {
					"height": "100%",
					"width": "100%",
					"max-width": "100%",
					"max-height": "100%",
					"overflow": "auto"
				},
				baseZIndex: 10000,
				style: {"max-width": "100%", "max-height": "100%"},
				data: {
					verwaltbarerAusfueller: verwaltbarerAusfueller,
					einladung: false
				}
			});
		} else if (verwaltbarerAusfueller != null && !verwaltbarerAusfueller.enabled && !verwaltbarerAusfueller.archiviert) {
			this.ref = this.dialogService.open(BenutzerBearbeitenComponent, {
				header: 'Noch nicht registrierten Benutzer bearbeiten',
				width: '100%',
				height: '100%',
				styleClass: 'FullScreenDialog',
				modal: true,
				contentStyle: {
					"height": "100%",
					"width": "100%",
					"max-width": "100%",
					"max-height": "100%",
					"overflow": "auto"
				},
				baseZIndex: 10000,
				style: {"max-width": "100%", "max-height": "100%"},
				data: {
					verwaltbarerAusfueller: verwaltbarerAusfueller,
					einladung: true
				}
			});
		}
	}

	loescheRecherchekomplexRechte(recherchekomplexBenutzer: RecherchekomplexUebersichtDto) {

	}

	importiereBenutzer(
		// recherchekomplex: RecherchekomplexUebersichtDto
	) {
	}

	neueEinladung(
		// rechechekomplex: RecherchekomplexUebersichtDto
	) {
	}

	trackeVeraenderungenAmBaum(index, verwaltbarerAusfueller) {
		return verwaltbarerAusfueller.id;
	}

	back(): void {
		// this.router.navigateByUrl('/benutzerverwaltung')
		// backToBenutzerverwaltung() {
		// console.log("back")
		this.router.navigate(['/benutzerverwaltung/'], {queryParams: {activeIndex: 2}});
		// }
	}

	getAnzahlRecherchekomplexBenutzer() {
		return (this.ausfuellerListe || []).length;
	}

	private reloadUserList() {

		if (!this.initDone) {
			return
		}

		this.blockUI.start('Daten werden verarbeitet...');
		// console.log("reloadUserList")

		this.ausfuellerService.holeAusfuellerForCurrentInst(false).then(ausfuellerDtoListe => {
				let userIds = new Set();
				this.ausfuellerListe = [];

				ausfuellerDtoListe.filter(ausfueller => {
					ausfueller.institutionenUndRechechekomplexe.forEach(institutionUndRecherchekomplex => {
						if (!userIds.has(ausfueller.id)) {
							if (institutionUndRecherchekomplex.rechekomplexId == this.recherchekomplexId) {
								// console.log('pushing ausfueller');
								ausfueller.institutionUndKomplex = institutionUndRecherchekomplex
								this.ausfuellerListe = [...this.ausfuellerListe, ausfueller];
								userIds.add(ausfueller.id)
								return;
							}
						}
					});
				});
			}
		).finally(this.blockUI.stop);
	}

	private calcTabelRows(width, height) {
		this.screenWidth = width;
		this.screenHeight = height;
		this.rows = Math.floor((this.screenHeight - 65 - 40 - 40 - 210) / 41 - 1)
		if (this.rows < 4) {
			this.rows = 4;
		}
	}

}
