<div class="form-group">
	<div class="checkbox abc-checkbox">
		<input
			class="form-control checkbox"
			type="checkbox"
			name="darfFrageboegenAufUndAbschliessen"
			[(ngModel)]="dto.darfFrageboegenAufUndAbschliessen"
			id="{{ 'darfFrageboegenAufUndAbschliessen' | newID }}"
			[disabled]="disabled"
		/>
		<label for="{{ 'darfFrageboegenAufUndAbschliessen' | lastID }}">Darf Fragebögen an JUVE übermitteln und
			wiedereröffnen</label>
	</div>
</div>

