<p *ngIf="isEntscheidungsfragenAntwort() && getEntscheidungsfragenAntwort().auswahl">
	<strong *ngIf="getEntscheidungsfragenAntwort().auswahl === 'POSITIV'">
		{{ getEntscheidungsfrage().beschriftungPositiv }}
	</strong>
	<strong *ngIf="getEntscheidungsfragenAntwort().auswahl === 'NEGATIV'">
		{{ getEntscheidungsfrage().beschriftungNegativ }}
	</strong>
	{{getEntscheidungsfragenAntwort().begruendung.trim()}}
</p>
<em *ngIf="isEntscheidungsfragenAntwort() && !getEntscheidungsfragenAntwort().auswahl" class="keine_antwort">Keine Antwort</em>

<div *ngIf="isKartenfragenAntwort()">
	<div *ngFor="let karte of getKartenfragenAntwort().getGefuellteKarten()" class="panel panel-default">
		<div class="panel-heading">
			<strong>{{ getKartenFrage().titel }}</strong>
			{{ karte.kartenTitel }}
		</div>
		<div class="panel-body">
			<div class="row">
				<div class="col-xs-6">
					<div *ngFor="let feld of getKartenFrage().felderLinks" class="feld">
						<h4>{{ feld.ueberschrift }}</h4>
						<div [innerHTML]="karte.findForFeld(feld).antwort.getHtml() | sanitizeHtml"></div>
					</div>
				</div>
				<div class="col-xs-6">
					<div *ngFor="let feld of getKartenFrage().felderRechts" class="feld">
						<h4>{{ feld.ueberschrift }}</h4>
						<div [innerHTML]="karte.findForFeld(feld).antwort.getHtml() | sanitizeHtml"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<em *ngIf="isKartenfragenAntwort() && getKartenfragenAntwort().getGefuellteKarten().length === 0" class="keine_antwort">Keine Antwort</em>

<div *ngIf="isMehrfachauswahlfragenAntwort()" class="form-group container-fluid mehrfachauswahlantwort">
	<div class="row">
		<ul class="col-xs-12" style="columns: 2">
			<li *ngFor="let option of getMehrfachauswahlFrage().optionen">
				<div class="checkbox">
					<!--{% set input_id = randomId("mehrfach-antwort") %}TODO: entfernen oder fixen-->
					<input type="checkbox"
						   class="form-check-input"
						   disabled
						   id="{{ option }}"
						   value="{{ option }}"
						   [checked]="getMehrfachauswahlfragenAntwort().antworten.indexOf(option) >= 0"/>
					<label class="form-check-label" for="{{ option }}">{{ option }}</label>
				</div>
			</li>
		</ul>
	</div>
</div>

<table *ngIf="isTabellenfragenAntwort() && !getTabellenfragenAntwort().isEmpty()" class="table table-striped">
	<thead>
	<tr>
		<th *ngIf="getTabellenFrage().hasFixeZeilen()"></th>
		<th *ngFor="let spalte of getTabellenFrage().spalten">{{ spalte.ueberschrift }}</th>
	</tr>
	</thead>
	<tbody>
	<tr *ngFor="let zeile of getTabellenfragenAntwort().getGefuellteZeilen()">
		<th *ngIf="!zeile.isEmpty() && getTabellenFrage().hasFixeZeilen()">{{ getTabellenFrage().fixeZeilen[zeile.index].titel }}</th>

		<td *ngFor="let zelle of zeile.zellen">
			<div [innerHTML]="zelle.antwort.getHtml() | sanitizeHtml"></div>
		</td>
	</tr>
	</tbody>
</table>
<em *ngIf="isTabellenfragenAntwort() && getTabellenfragenAntwort().isEmpty()" class="keine_antwort">Keine Antwort</em>

<p *ngIf="isTextfeldfragenAntwort() && getTextfeldfragenAntwort().textantwort"
   class="antwort-multiline">{{ getTextfeldfragenAntwort().textantwort.trim() }}</p>
<em *ngIf="isTextfeldfragenAntwort() && !getTextfeldfragenAntwort().textantwort" class="keine_antwort">Keine Antwort</em>
