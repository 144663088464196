import {AusfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import {CoAdminVerwaltungFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormComponent';
import {CoAdminAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/CoAdminAnlegenDto';

export class CoAdminVerwaltungFormConfiguration implements AusfuellerVerwaltungFormConfiguration {
	readonly FORM_COMPONENT = CoAdminVerwaltungFormComponent;
	readonly DTO_TYPE = CoAdminAnlegenDto;

	constructor(private recherchekomplexid: string) {
	}

	getWebserviceEndpoint() {
		return 'coadmin';
	}

	getFormTitleHtml() {
		return 'Neuen Recherche-Koordinator einladen';
	}

	getEinladenButtonToolTipHtml() {
		return 'Hier können Sie weitere Bearbeiter für <b>alle Fragebögen</b> einladen.';
	}

	getErklaerungsHtml() {
		return `<p>Hier können Sie weitere <a href="faq?recherchekomplex=${this.recherchekomplexid}#benutzerrolle_recherchekoordinator"
				target="_blank">Recherchekomplex-Koordinatoren</a> für <b>alle Fragebögen</b> einladen.
				Dieser Bearbeiter darf alle Fragebögen ansehen und
				beantworten. Sie können wählen, ob dieser Bearbeiter selbstständig Fragebögen an Juve übermitteln darf.</p>

				<p>Möchten Sie stattdessen Bearbeiter mit geringeren Befugnissen einladen, verwenden Sie die Einladen-Buttons am jeweiligen
				Fragebogen bzw. Abschnitt.</p>`;
	}

	applyToAnlegenDto(anlegenDto: CoAdminAnlegenDto): CoAdminAnlegenDto {
		return anlegenDto;
	}
}
