import {Component, Input, OnInit} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputTypeEmail} from "../../../model/frage/input/InputTypeEmail";

@Component({
	selector: 'app-input-type-email-edit',
	templateUrl: './InputTypeEmailEditComponent.html',
	styleUrls: ['./InputTypeEmailEditComponent.less']
})
export class InputTypeEmailEditComponent implements OnInit, IInputTypeEditComponent<InputTypeEmail> {
	@Input()
	inputType: InputTypeEmail;

	platzhalterText = '';

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
