import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {KartenFeld} from 'app/shared/model/frage/KartenFeld';
import {UUID} from 'app/util/export-types';

export abstract class KartenFrageEditSpalte {

	name: string;

	header: string;

	protected frage: KartenFrage;

	constructor(frage: KartenFrage, name: string, header: string) {
		this.frage = frage;
		this.name = name;
		this.header = header;
	}

	isDraggable(): boolean {
		return this.hasMoreThanOneFeld();
	}

	canRemoveFeld(): boolean {
		return this.hasMoreThanOneFeld();
	}

	private hasMoreThanOneFeld() {
		return this.getFelder().length > 1;
	}

	findFeldById(id: UUID) {
		const gefunden = this.getFelder().filter(feld => feld.id === id);
		if (gefunden.length > 0) {
			return gefunden[0];
		} else {
			return null;
		}
	}

	abstract getFelder(): KartenFeld[];

	abstract addFeld(): void;

	abstract removeFeld(feld: KartenFeld): void;
}
