import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {InputTypeKommazahl} from "../../frage/input/InputTypeKommazahl";

export class InputTypeKommazahlAntwort extends InputTypeAntwort<InputTypeKommazahl> {
	wert = null;

	constructor(antwort?: InputTypeKommazahlAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeKommazahl.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert?.length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert?.trim();
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert?.trim() === (o as InputTypeKommazahlAntwort).wert?.trim();
	}
}
