import {KartenFeldAntwort} from 'app/shared/model/antwort/KartenFeldAntwort';
import {KartenFeld} from 'app/shared/model/frage/KartenFeld';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {ClientIdAware} from 'app/shared/util/ClientIdAware';
import {ClientIdUtil} from 'app/shared/util/ClientIdUtil';
import {UUID} from 'app/util/export-types';

export class KartenAntwort implements ClientIdAware {
	kartenTitel = '';
	feldAntworten: KartenFeldAntwort[] = [];
	id: UUID;
	clientId: UUID;

	static forKartenFrage(frage: KartenFrage): KartenAntwort {
		const kartenAntwort = new KartenAntwort();

		for (const kartenFeld of frage.felderRechts) {
			kartenAntwort.feldAntworten.push(KartenFeldAntwort.forKartenfeld(kartenFeld));
		}
		for (const kartenFeld of frage.felderLinks) {
			kartenAntwort.feldAntworten.push(KartenFeldAntwort.forKartenfeld(kartenFeld));
		}

		return kartenAntwort;
	}

	constructor(antwort?: KartenAntwort) {
		if (antwort) {
			this.id = antwort.id;
			this.kartenTitel = antwort.kartenTitel;
			this.feldAntworten = antwort.feldAntworten.map(feldAntwort => new KartenFeldAntwort(feldAntwort));

			this.clientId = antwort.clientId;
		}

		ClientIdUtil.generateClientIdIfMissing(this);
	}

	hatGefuellteAntworten(): boolean {
		return !this.kartenTitel || (this.feldAntworten.filter(antwort => antwort.antwort && !antwort.antwort.isEmpty()).length > 0);
	}

	findForFeld(feld: KartenFeld): KartenFeldAntwort {
		return this.feldAntworten.filter(feldAntwort => feldAntwort.feld_id === feld.id)[0];
	}

	addFeldAntwort(): KartenFeldAntwort {
		const antwort = new KartenFeldAntwort();
		this.feldAntworten.push(antwort);
		return antwort;
	}

	applyIds(karte: KartenAntwort) {
		this.id = karte.id;

		karte.feldAntworten.forEach((feld, idx) =>
			this.feldAntworten[idx].applyIds(feld));
	}

	istInhaltlichGleich(o: KartenAntwort) {
		if (this.feldAntworten.length !== o.feldAntworten.length) {
			return false;
		}

		for (let i = 0; i < this.feldAntworten.length; i++) {
			const feldThis = this.feldAntworten[i];
			const feldOther = o.feldAntworten[i];
			if (!feldThis.istInhaltlichGleich(feldOther)) {
				return false;
			}
		}

		return true;
	}

	isValid() {
		return this.feldAntworten.every(f => f.isValid());
	}
}
