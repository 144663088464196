<div class="edit-optionen container-fluid">
	<p-orderList class="row option"  [value]="eintraege" [dragdrop]="true" (onReorder)="onReorder()">
		<ng-template let-option pTemplate="item">
			<div class="col-md-1"></div>
			<div class="col-md-10 panel panel-default">
				<div class="panel-body">
					<input
						class="form-control"
						[(ngModel)]="option.value"
						name="eintrag{{ option.index }}"
						required minlength="1" maxlength="255"
					/>
				</div>
			</div>
			<div class="col-md-1">
				<button class="btn btn-danger remove" [disabled]="!canRemove()" (click)="remove(option.index)"><i class="icon-trash"></i></button>
			</div>
		</ng-template>
	</p-orderList>

	<button class="btn btn-primary" (click)="add()">Option hinzufügen</button>
</div>
