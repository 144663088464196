<ng-template #fertigPopover>
	<ul class="list-unstyled">
		<li *ngFor="let marker of frageFertigMarker" class="clearfix">
			<i class="icon-check"></i>
			<span class="name" title="{{ marker.ausfueller.anzeigename }}">{{ marker.ausfueller.anzeigename }}</span>
			<span class="datum" title="{{ marker.created | date:'dd.MM.yy HH:mm' }} Uhr">
				{{ marker.created | date:'dd.MM.yy' }}
			</span>
		</li>
		<li *ngFor="let ausfueller of unfertigeAusfueller" class="unfertig clearfix">
			<i class="icon-check"></i>
			<span class="name" title="{{ ausfueller.anzeigename }}">{{ ausfueller.anzeigename }}</span>
			<span class="datum">noch nicht fertig</span>
		</li>
	</ul>
</ng-template>

<div
	class="marker"
	*ngIf="!isEmpty()"
	[popover]="fertigPopover"
	placement="top"
>
	{{ frageFertigMarker.length }} als fertig markiert
</div>
