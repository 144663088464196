import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {HttpClient} from '@angular/common/http';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {AbschnittSperreService} from 'app/form-viewer/service/AbschnittSperreService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {FragebogenAbschnittsdaten} from 'app/form-viewer/service/AbschnittStorageService/FragebogenAbschnittsdaten';
import {FormViewerAbschnittSperrenHelper} from 'app/sperren-helper/FormViewerAbschnittSperrenHelper';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';

const debug = require('debug')('service:AbschnittReadonlyService');

@Injectable()
export class AbschnittReadonlyService {

	constructor(private http: HttpClient,
		private alertService: AlertService,
		private abschnittSperreService: AbschnittSperreService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private formViewerAbschnittSperrenHelper: FormViewerAbschnittSperrenHelper,
		private previewService: PreviewService) {
	}

	/**
	 * Relevant beim Anzeigen der Antworten eines Abschnitts – gibt an ob eine eigene Sperre vor liegt und tatsächlich editiert werden kann.
	 */
	public sindAbschnittsinhalteInnerhalbDerBearbeitmaskeReadonly(
		fragebogenAbschnittsdaten: FragebogenAbschnittsdaten,
		fragebogen_abgeschlossen: boolean,
		abschnitt: Abschnitt
	): boolean {
		if (this.previewService.isInPreview()) {
			return false;
		}

		return !this.istAbschnittVonAktuellemUserGesperrt(abschnitt)
			|| this.sindAbschnittsinhalteReadonly(fragebogenAbschnittsdaten, fragebogen_abgeschlossen, abschnitt);
	}

	/**
	 * Sind die Inhalte des Abschnitt readonly.
	 *
	 * Lässt die eigene Sperre des Nutzers außen vor. Das heißt, es gilt als editierbar, auch wenn der User noch keine Sperre hat. Dies
	 * ist nützlich um den Status anzuzeigen, bevor der Nutzer den Abschnitt betritt. Danach sollte aber immer
	 * `sindAbschnittsinhalteInnerhalbDerBearbeitmaskeReadonly` betrachtet werden.
	 *
	 * Bezieht sich nicht auf Rechtevergabe und Übermittlung.
	 */
	public sindAbschnittsinhalteReadonly(fragebogenAbschnittsdaten: FragebogenAbschnittsdaten, fragebogen_abgeschlossen: boolean, abschnitt: Abschnitt): boolean {
		if (this.previewService.isInPreview()) {
			return false;
		}

		return this.sindKeinerleiAenderungenErlaubt(fragebogenAbschnittsdaten, fragebogen_abgeschlossen, abschnitt) ||
			this.istAbschnittVonAnderemUserGesperrt(fragebogenAbschnittsdaten, abschnitt);
	}

	/**
	 * Der Abschnitt ist Übermittelt und keinerlei Aktionen sind mehr zugelassen. Editieren / Abschliessen / Einladen.
	 */
	public sindKeinerleiAenderungenErlaubt(fragebogenAbschnittsdaten: FragebogenAbschnittsdaten, fragebogen_abgeschlossen: boolean, abschnitt: Abschnitt): boolean {
		if (this.previewService.isInPreview()) {
			return false;
		} else if (fragebogen_abgeschlossen) {
			return true;
		} else if (!fragebogenAbschnittsdaten.kannNochAusgefuelltWerden()) {
			return true;
		} else if (!this.ichDarfAbschnittBearbeiten(abschnitt)) {
			return true;
		} else {
			/* Aenderungen sind moeglich */
			return false;
		}
	}

	private istAbschnittVonAktuellemUserGesperrt(abschnitt: Abschnitt) {
		return this.abschnittSperreService.istAbschnittFuerAktuellenUserGesperrt(abschnitt);
	}

	private istAbschnittVonAnderemUserGesperrt(fragebogenAbschnittsdaten: FragebogenAbschnittsdaten, abschnitt: Abschnitt) {
		return this.abschnittSperreService.hatAktuellerUserKeinenZugriff(fragebogenAbschnittsdaten.abschnittSperren, abschnitt);
	}

	private ichDarfAbschnittBearbeiten(abschnitt: Abschnitt) {
		return this.ausfuellerBerechtigungsService.darfAbschnittBearbeiten(abschnitt);
	}
}
