import {Component, Input} from '@angular/core';

@Component({
			   selector: 'app-message',
			   templateUrl: './MessageComponent.html',
			   styleUrls: ['./message.less']
		   })
export class MessageComponent {
	@Input() title: string
}
