import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeGanzzahlViewComponent} from "../../../../form-viewer/abschnitt/frage-liste/frage/view/input/input-type-ganzzahl-view/InputTypeGanzzahlViewComponent";
import {InputTypeGanzzahlEditComponent} from "../../../components/input-type/ganzzahl/InputTypeGanzzahlEditComponent";
import {InputTypeGanzzahlAntwort} from "../../antwort/input/InputTypeGanzzahlAntwort";

export class InputTypeGanzzahl extends InputType {
	static readonly ID = 'InputTypeGanzzahl';
	static readonly TYPE_DISPLAY_NAME = 'Ganzzahl';
	static readonly ANTWORT = InputTypeGanzzahlAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static readonly VIEWER = InputTypeGanzzahlViewComponent;
	static readonly EDITOR = InputTypeGanzzahlEditComponent;

	platzhalterText = '';
	vorkommastellen = 0;

	constructor(type?: InputTypeGanzzahl) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
			this.vorkommastellen = type.vorkommastellen;
		}
	}

}
