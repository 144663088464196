import {VerwaltbarerAusfuellerRecherchekomplexDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerRecherchekomplexDto';

export class VerwaltbarerAusfuellerInstitutionDto {
	id: string;
	anzeigename: string;

	recherchekomplexe: VerwaltbarerAusfuellerRecherchekomplexDto[] = [];

	constructor(ausfuellerInstitutionenDto?: VerwaltbarerAusfuellerInstitutionDto) {
		if (ausfuellerInstitutionenDto) {
			this.id = ausfuellerInstitutionenDto.id;
			this.anzeigename = ausfuellerInstitutionenDto.anzeigename;

			this.recherchekomplexe = ausfuellerInstitutionenDto.recherchekomplexe
				.map(recherchekomplex => new VerwaltbarerAusfuellerRecherchekomplexDto(recherchekomplex));

		}
	}

	public toString(): string {
		return ' ' + this.anzeigename;
	}

}
