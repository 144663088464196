import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AlertService} from "../../shared/alert/service/AlertService";

const debug = require('debug')('service:PasswortZuruecksetzenService');

@Injectable()
export class PasswortZuruecksetzenService {

	BASE_PATH = '/passwortzuruecksetzen'

	constructor(private http: HttpClient, private store: Store, private alertService: AlertService) {
	}

	sendePasswortZuruecksetzenMail( username: string): Promise<any> {
		console.log('Service ' + username)
		const params = new HttpParams().set('username', username)
		return this.http.post(`${this.BASE_PATH}`, params).toPromise()
	}
}
