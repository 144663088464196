import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MessageService} from 'primeng/api';
import {UserService} from '../../form-viewer/service/UserService';

@Component({
			   selector: 'app-sicherheit',
			   templateUrl: './SicherheitComponent.html',
			   styleUrls: ['../content-page.less']
		   })
export class SicherheitComponent implements OnInit {

	@BlockUI() blockUI: NgBlockUI;

	constructor(private title: Title,
				private messageService: MessageService,
				private userService: UserService,
	) {
		title.setTitle('Datenschutz und Datensicherheit - Juve Recherche');
	}

	ngOnInit(): void {
		try {
			this.messageService.clear();
			this.userService.isAlive();
		}
		finally {
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal();
				} catch (exception) {
				}
			}, 1000);
		}
	}

}
