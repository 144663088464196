<span [class.disabled]="isReadOnly()" [class.enabled]="!isReadOnly()">
	<span class="fragetyp-icon" title="{{ frage.typeDisplayName() }} ">
		<i class="icon-{{ frage.typeIconName() }}"></i>
	</span>
	<strong>{{ frage.ueberschrift }}</strong>
	<i
		*ngIf="frage.istVerlinkt()"
		class="icon-link vorjahresfrage"
		title="Verknüpft mit {{frage.vorjahresfrage.ueberschrift}}"
	></i>
</span>

<span class="pull-right" ngPreserveWhitespaces>
	<button type="button" [disabled]="isReadOnly()" class="btn btn-xs" (click)="beginEdit()"><i class="icon-edit"></i></button>
	<button type="button" [disabled]="isReadOnly()" class="btn btn-xs" (click)="beginDuplicate()"><i class="icon-copy"></i></button>
	<button type="button" [disabled]="isReadOnly()" class="btn btn-xs" (click)="commitRemove()"><i class="icon-trash"></i></button>
</span>
