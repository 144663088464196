import {AusfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import {UUID} from 'app/util/export-types';
import {AusfuellerType} from 'app/form-viewer/model/AusfuellerType';

export class FragebogenausfuellerAnlegenDto extends AusfuellerAnlegenDto {
	type: AusfuellerType = AusfuellerType.Fragebogenausfueller;

	fuerFragebogen: UUID;
	abgabefrist = '';
}
