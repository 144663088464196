import {Abschnitt} from "../../shared/model/Abschnitt";
import {AntwortVersion} from "../../shared/model/AntwortVersion";
import {Fragebogen} from "../../shared/model/Fragebogen";
import {Institution} from "../../shared/model/Institution";

export class AntwortAnsichtDto {

	version: AntwortVersion
	existierenMehrereVersionen: boolean = false
	fragebogen: Fragebogen
	institution: Institution
	abschnitte: Abschnitt[]

	constructor(dto: AntwortAnsichtDto) {
		this.version = dto.version
		this.existierenMehrereVersionen = dto.existierenMehrereVersionen
		this.fragebogen = new Fragebogen(dto.fragebogen);
		this.institution = new Institution(dto.institution);
		this.abschnitte = dto.abschnitte.map(a => new Abschnitt(a));
	}

}
