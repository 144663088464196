import {Component, Input} from '@angular/core';
import {KartenFeldAntwortTupel} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/KartenFeldAntwortTupel';

@Component({
	selector: 'app-karten-view-spalte',
	templateUrl: './KartenViewSpalteComponent.html',
	styleUrls: ['./KartenViewSpalteComponent.less']
})
export class KartenViewSpalteComponent {
	@Input()
	feldAntworten: KartenFeldAntwortTupel[];

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
