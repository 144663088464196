import {UUID} from "../../util/export-types";


export class AbschnittFreigaben {
	id: UUID = undefined;
	hatRechte: boolean = false;
	beschreibung: String = "";
	ueberschrift: String = "";

	constructor(tmp: AbschnittFreigaben) {
		this.id = tmp.id;
		this.hatRechte = tmp.hatRechte;
		this.beschreibung = tmp.beschreibung;
		this.ueberschrift = tmp.ueberschrift;
	}

	toggleRechte(adminOrCo) {
		if (!adminOrCo)
			this.hatRechte = !this.hatRechte;
	}
}
