import {UUID} from 'app/util/export-types';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';
import {DateFormatterUtil} from 'app/shared/util/DateFormatterUtil';

export class FragebogenStatus {

	id: UUID;
	fragebogen: Fragebogen;
	institutionId: string;
	abschlussdatum: string;
	abschlussGeaendertDurchUser: Ausfueller;

	constructor(fragebogenStatus?: FragebogenStatus) {
		if (fragebogenStatus) {
			this.id = fragebogenStatus.id;
			this.fragebogen = fragebogenStatus.fragebogen;
			this.institutionId = fragebogenStatus.institutionId;
			this.abschlussdatum = DateFormatterUtil.transformToDateOnly(new Date(fragebogenStatus.abschlussdatum));

			this.abschlussGeaendertDurchUser = new Ausfueller(fragebogenStatus.abschlussGeaendertDurchUser);
		}
	}
}
