<ng-template #defaultOptionTemplate let-option>
	{{ option }}
</ng-template>

<select
	class="form-control"
	[(ngModel)]="wert"
	[disabled]="disabled"
	id="{{ id }}"
	appScrollToFocusedElement
>
	<option *ngFor="let option of options" [ngValue]="option">
		<ng-template
			[ngTemplateOutlet]="optionTemplate"
			[ngTemplateOutletContext]="{'$implicit': option}"
		></ng-template>
	</option>
</select>
