import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {AktuelleBerechtigungService} from "../../shared/service/AktuelleBerechtigungService";
import {ContentDto} from "../ContentDto";

@Injectable({
				providedIn: 'root'
			})
export class ContentService {

	constructor(private httpClient: HttpClient,
				private alertService: AlertService,
				private berechtigungService: AktuelleBerechtigungService) {
	}

	holePortalContent(): Promise<ContentDto> {
		return this.holeContent('/content/portal')
	}

	holePortalTeaser(): Promise<ContentDto> {
		return this.holeContent('/content/portal-teaser')
	}

	holeFaqContent(): Promise<ContentDto> {
		return this.holeContent('/content/faq')
	}

	holeTeamContent(): Promise<ContentDto> {
		return this.holeContent('/content/team')
	}

	private holeContent(url: string) {
		return this.httpClient.get<ContentDto>(url, {params: this.berechtigungService.addCommonParams({})})
				   .toPromise()
				   .catch(this.alertService.handleHttpError)
				   .then(json => new ContentDto(json))
	}

}
