import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AbschnittBuilderUtil} from 'app/shared/util/AbschnittBuilderUtil';

// Import BlockUI decorator & optional NgBlockUI type
import {BlockUI, NgBlockUI} from 'ng-block-ui';

const debug = require('debug')('service:ajax:AbschnittEditorService');

@Injectable()
export class AbschnittEditorService {

	@BlockUI() blockUI: NgBlockUI;


	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getAbschnitte(fragebogen: Fragebogen): Promise<Abschnitt[]> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.http.get<Abschnitt[]>('/api/editor/abschnitt/abschnitte',
			{
				params: {
					fragebogen: fragebogen.id,
				}
			})
				   .toPromise()
				   .then(response => {
					   return AbschnittBuilderUtil.buildList(response);
				   })
				   .catch(this.alerts.handleHttpError)
				   .finally(this.blockUI.stop);
	}

	saveAbschnitt(abschnitt: Abschnitt): Promise<Abschnitt> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.http.post<Abschnitt>('/api/editor/abschnitt', abschnitt)
				   .toPromise()
				   .then(response => {
					   return AbschnittBuilderUtil.build(response);
				   })
				   .catch(this.alerts.handleHttpError)
				   .finally(this.blockUI.stop);
	}

	deleteAbschnitt(abschnitt: Abschnitt): Promise<any> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.http.delete('/api/editor/abschnitt/',
			{
				params: {
					abschnitt: abschnitt.id
				}
			})
				   .toPromise()
				   .catch(this.alerts.handleHttpError)
				   .finally(this.blockUI.stop);
	}

	saveMehrereAbschnitte(abschnitte: Abschnitt[]): Promise<Abschnitt[]> {
		this.blockUI.start('Daten werden verarbeitet...');

		return this.http.post<Abschnitt[]>('/api/editor/abschnitt/abschnitte', abschnitte)
				   .toPromise()
				   .then(response => {
					   return AbschnittBuilderUtil.buildList(response);
				   })
				   .catch(this.alerts.handleHttpError)
				   .finally(this.blockUI.stop);
	}

	saveAbschnittReihenfolge(fragebogen: Fragebogen, abschnitte: Abschnitt[]): Promise<void> {
		this.blockUI.start('Daten werden verarbeitet...');

		const abschnittReihenfolgeDto = {
			fragebogen: fragebogen.id,
			abschnitte: abschnitte.map(abschnitt => abschnitt.id)
		};

		return this.http.post('/api/editor/abschnitt/reihenfolge', abschnittReihenfolgeDto)
				   .toPromise()
				   .catch(this.alerts.handleHttpError)
				   .then(() => null)
				   .finally(this.blockUI.stop);
	}
}
