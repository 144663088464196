import {Component, Input} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeCheckbox} from 'app/shared/model/frage/input/InputTypeCheckbox';
import {InputTypeCheckboxAntwort} from 'app/shared/model/antwort/input/InputTypeCheckboxAntwort';

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Einsatz in Tabellenzelle, ohne Margin
 */
@Component({
	selector: 'app-input-type-checkbox-view',
	templateUrl: './InputTypeCheckboxViewComponent.html',
	styleUrls: ['./InputTypeCheckboxViewComponent.less']
})
export class InputTypeCheckboxViewComponent implements IInputTypeViewComponent<InputTypeCheckbox> {

	context = {};

	variant: string;

	@Input()
	antwort: InputTypeCheckboxAntwort;

	@Input()
	type: InputTypeCheckbox;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	title(): string {
		return this.context['hint'];
	}
}
