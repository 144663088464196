import {Component, Input, ViewChild} from '@angular/core';
import {FragebogenStatusService} from 'app/form-viewer/service/FragebogenStatusService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {NavigationService} from 'app/shared/service/NavigationService';
import {ModalDirective} from 'ngx-bootstrap/modal';

const debug = require('debug')('FragebogenAbschliessenComponent');

@Component({
	selector: 'app-fragebogen-abschliessen',
	templateUrl: './FragebogenAbschliessenComponent.html',
	styleUrls: ['./FragebogenAbschliessenComponent.less'],
})
export class FragebogenAbschliessenComponent {

	private static readonly FRAGEBOGEN_ABGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN = 3;

	@Input()
	fragebogen: Fragebogen;

	@ViewChild('abschliessenModal', { static: true })
	abschliessenModal: ModalDirective;

	constructor(private navigationService: NavigationService, private fragebogenStatusService: FragebogenStatusService) {
	}

	openDialog() {
		this.abschliessenModal.show();
	}

	closeDialog() {
		this.abschliessenModal.hide();
	}

	commitAbschliessen() {
		this.closeDialog();

		this.fragebogenStatusService.schliesseFragebogen(this.fragebogen)
			.then(() => {
				this.initializeRedirect();
			})
			.catch((error) => {
				debug('Fehler beim Abschliessen des Fragebogens', error);
			});
	}

	private initializeRedirect() {
		setTimeout(() => {
			this.navigationService.goToFragebogenuebersicht();
		}, FragebogenAbschliessenComponent.FRAGEBOGEN_ABGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN * 1000);
	}
}
