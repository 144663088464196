import {Component} from '@angular/core';

@Component({
	selector: 'app-viewer-hinweis',
	templateUrl: './ViewerHinweisComponent.html',
	styleUrls: ['./ViewerHinweisComponent.less'],
	providers: [],
})
export class ViewerHinweisComponent {

}
