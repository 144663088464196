import {Pipe, PipeTransform, SecurityContext} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
	name: 'sanitizeHtml'
})

export class HtmlSanitizerPipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) {
	}

	transform(content): SafeHtml {
		return this.sanitizer.sanitize(SecurityContext.HTML, content);
	}
}
