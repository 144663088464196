import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {FragebogenService} from 'app/shared/service/FragebogenService';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-editor-fragebogen-metadata-dialog',
	templateUrl: './FragebogenMetadataDialogComponent.html',
	styleUrls: ['./FragebogenMetadataDialogComponent.less'],
})
export class FragebogenMetadataDialogComponent {
	@Input()
	fragebogen: Fragebogen;

	editFragebogen: Fragebogen = null;

	@Output()
	fragebogenChange = new EventEmitter<Fragebogen>();

	@ViewChild('editDialogModal', { static: true })
	editDialogModal: ModalDirective;

	disabled = false;

	constructor(private fragebogenService: FragebogenService) {
	}

	show() {
		this.editFragebogen = new Fragebogen(this.fragebogen);
		this.editDialogModal.show();
	}

	hide() {
		this.editDialogModal.hide();
	}

	commitSave() {
		this.disabled = true;
		this.fragebogenService.saveFragebogen(this.editFragebogen)
			.then(fragebogen => {
				this.fragebogen = fragebogen;
				this.fragebogenChange.emit(fragebogen);

				this.disabled = false;
				this.hide();
			});
	}
}
