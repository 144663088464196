import {Component, Input, OnInit} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';

@Component({
	selector: 'app-viewer-fragen-nav-eintrag',
	templateUrl: './ViewerFragenNavEintragComponent.html',
	styleUrls: ['./ViewerFragenNavEintragComponent.less']
})
export class ViewerFragenNavEintragComponent implements OnInit {
	@Input()
	frage: Frage;

	@Input()
	isAktiveFrage: boolean;

	constructor() {
	}

	ngOnInit() {
	}

}
