import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../form-viewer/service/UserService";

@Injectable()
export class AuthGuardViewer {

	constructor(private userService: UserService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let ausfueller = this.userService.getAusfuellerWithoutMessage();
		if (ausfueller) {
			if (ausfueller.authorityStrings.includes('ROLE_AUSFUELLER')) {
				return true
			} else {
				this.router.navigate(['/403'])
			}
		} //else {
		//	this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}})
		//}
	}

}
