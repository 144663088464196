import {Component, Input} from '@angular/core';
import {IFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {TabellenFrage} from 'app/shared/model/frage/TabellenFrage';
import {TabellenfragenAntwort} from 'app/shared/model/antwort/TabellenfragenAntwort';

@Component({
	selector: 'app-viewer-abschnitt-frage-view-tabelle',
	templateUrl: './TabelleFrageViewComponent.html',
	styleUrls: ['./TabelleFrageViewComponent.less', './tabelle-view.shared.less']
})
export class TabelleFrageViewComponent implements IFrageViewComponent {

	@Input()
	frage: TabellenFrage;

	@Input()
	antwort: TabellenfragenAntwort;

	@Input()
	readonly: boolean;

	printView: boolean;

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}

	isReadonly() {
		return this.readonly;
	}
}
