import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {UserService} from "../form-viewer/service/UserService";

@Injectable()
export class AuthGuardEditor {

	constructor(private userService: UserService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (route.url.toString().includes('/ansprechpartner-login')){
			console.log('AuthGuardEditor returning for url ansprechpartner-login')
			this.router.navigate(['/ansprechpartner-login']);
		}

		if (this.userService.isAutor()) {
			return true
		} else {
			this.router.navigate(['/403'])
		}
	}

}
