<div class="slider">
	<div class="inner score-{{ score }}"></div>
</div>

<div class="text" *ngIf="hasWarning() || hasSuggestions()">
	<div class="warning" *ngIf="hasWarning()">
		<h3>Achtung!</h3>
		<p>{{ warning }}</p>
	</div>
	<div class="suggestions" *ngIf="hasSuggestions()">
		<h3>Tipps</h3>
		<ul>
			<li *ngFor="let suggestion of suggestions">{{ suggestion }}</li>
		</ul>
	</div>
</div>
