import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeKommazahlViewComponent} from "../../../../form-viewer/abschnitt/frage-liste/frage/view/input/input-type-kommazahl-view/InputTypeKommazahlViewComponent";
import {InputTypeKommazahlEditComponent} from "../../../components/input-type/kommazahl/InputTypeKommazahlEditComponent";
import {InputTypeKommazahlAntwort} from "../../antwort/input/InputTypeKommazahlAntwort";

export class InputTypeKommazahl extends InputType {
	static readonly ID = 'InputTypeKommazahl';
	static readonly TYPE_DISPLAY_NAME = 'Kommazahl';
	static readonly ANTWORT = InputTypeKommazahlAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static readonly VIEWER = InputTypeKommazahlViewComponent;
	static readonly EDITOR = InputTypeKommazahlEditComponent;

	platzhalterText = '';
	vorkommastellen = 0;
	nachkommastellen = 2;

	constructor(type?: InputTypeKommazahl) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
			this.vorkommastellen = type.vorkommastellen;
			this.nachkommastellen = type.nachkommastellen;
		}
	}

}
