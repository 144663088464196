const debug = require('debug')('service:TickService');

/**
 * Sollen in einem Livecycle-Hook, z.B. ngOnInit, Dinge geändert werden, die andere Komponenten betreffen – will dort beispielsweise
 * eine Komponente den EditLock mit dem EditLockService erlangen – muss diese Änderung vom aktuellen Event-Loop Cycle
 * entkoppelt werden. Dazu wird laut AngularJS-Doku ein setTimeout(…, 0) empfohlen, welches mit diesem Service gekapselt wird.
 */
export class TickService {
	public static onNextTick(callback: () => void) {
		debug('registering callback on next tick', callback);
		setTimeout(callback, 0);
	}
}
