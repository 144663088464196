<div class="hero noBackground">
	<div class="noKeyvisualBackground">
		<app-header></app-header>
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-md-offset-3">
					<div class="panel form-panel panel-default">
						<div class="panel-body">
							<h2 class="serif">Archiv</h2>
							<app-portal-selector [bereich]="'archiv'">
								<app-spinner></app-spinner>
							</app-portal-selector>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container">
	<div class="row contacts">
		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Steuermarkt?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'mecke'" [bluePanel]="true"></app-contact>
			</div>
		</div>

		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Wirtschaftskanzleien?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'scherer'" [bluePanel]="true"></app-contact>
			</div>
		</div>

		<div class="col-md-6">
			<div class="blue-panel">
				<h3>Frage zur Recherche Top-Arbeitgeber für Juristen?</h3>
				<h3>Ihre Ansprechpartnerin:</h3>
				<app-contact [person]="'ossen'" [bluePanel]="true"></app-contact>
			</div>
		</div>
	</div>
</div>

<div class="container about-juve centered">
	<div class="logo">
		<img src="assets/portal/juve-logomono.png" width="118" alt="">
	</div>
	<div class="about-text">
		Umfangreiche Recherchen bei Kanzleien, Unternehmensverantwortlichen, Behördenvertretern und Mitarbeitern aus Justiz und
		Wissenschaft schaffen die Basis für die Publikationen des JUVE-Verlags. Unsere umfassenden Rankings werden von einem
		rund 30-köpfigen Team von Fachjournalisten recherchiert und erstellt.
	</div>
</div>

<app-footer></app-footer>
