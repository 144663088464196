import {Component, Input, ViewChild} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-edit-karten-titel',
	templateUrl: './KartenFrageEditTitelComponent.html',
	styleUrls: ['./KartenFrageEditTitelComponent.less'],
	viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class KartenFrageEditTitelComponent {
	@Input()
	frage: KartenFrage;

	@ViewChild('optionsModal', { static: true })
	optionsModal: ModalDirective;

	titelPlatzhalterText = '';
	titelBeschreibung = '';

	openDialog() {
		this.titelPlatzhalterText = this.frage.titelPlatzhalterText;
		this.titelBeschreibung = this.frage.titelBeschreibung;
		this.optionsModal.show();
	}

	closeDialog(event?: Event) {
		this.optionsModal.hide();
		if (event) {
			event.stopPropagation();
		}
	}

	commitSave() {
		this.optionsModal.hide();
		this.frage.titelPlatzhalterText = this.titelPlatzhalterText;
		this.frage.titelBeschreibung = this.titelBeschreibung;
	}
}
