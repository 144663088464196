<button
	(click)="openDuplicateModalClicked($event)"
	class="btn btn-xs"
	name="openDuplicateModal"
	ngPreserveWhitespaces
	title="Fragebogen&nbsp;duplizieren"
	type="button"
>
	<i class="icon-copy"></i> Duplizieren
</button>

<div
	#duplicateDialogModal="bs-modal"
	appPreventDefault
	bsModal
	class="modal fade"
	role="dialog"
	tabindex="-1">
	<div class="modal-dialog modal-lg">
		<div *ngIf="isOpen()" class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left">Fragebogen duplizieren: „{{ fragebogen.name }}”</h4>
				<button (click)="duplicateDialogModal.hide()" aria-label="Close" class="close pull-right" type="button">
					<span>&times;</span>
				</button>
				<br>
				<h5>Bitte beachten: Bei "Duplizieren mit Referenz" soll nur der Fragebogen aus dem Vorjahr verwendet werden!</h5>
			</div>
			<div class="modal-body">

				<div class="form-group">
					<label for="{{ 'recherchekomplex' | newID }}" required>
						Recherchekomplex&nbsp;<span class="required-marker">*</span>
					</label>
					<select
						[(ngModel)]="editFragebogen.recherchekomplex"
						[compareWith]='byIdComparator'
						class="form-control"
						id="{{ 'recherchekomplex' | lastID }}"
						name="recherchekomplex">
						>
						<option
							*ngFor="let recherchekomplex of recherchekomplexe"
							[ngValue]="recherchekomplex"
						>
							{{ recherchekomplex.name}}
						</option>
					</select>
				</div>

				<app-editor-fragebogen-metadata-form
					(abbrechen)="duplicateDialogModal.hide()"
					(commitSave)="dupliziereFragebogen($event)"
					[(beschreibungKurz)]="editFragebogen.beschreibungKurz"
					[(beschreibungLang)]="editFragebogen.beschreibungLang"
					[(name)]="editFragebogen.name"
					[disabled]="modalDisabled"
					[submitButtonNames]="['Duplizieren','Duplizieren mit Referenz']"
				></app-editor-fragebogen-metadata-form>
			</div>
		</div>
	</div>
</div>
