import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml, Title} from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MessageService} from 'primeng/api';
import {UserService} from '../../form-viewer/service/UserService';
import {ContentService} from '../services/ContentService';

@Component({
			   selector: 'app-faq',
			   templateUrl: './FaqComponent.html',
			   styleUrls: ['../content-page.less', './FaqComponent.less'],
		   })
export class FaqComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	public navigation: string;
	public content: SafeHtml;
	public loaded: boolean;

	constructor(private sanitizer: DomSanitizer,
				private contentService: ContentService,
				private userService: UserService,
				private title: Title,
				private messageService: MessageService,
				private route: ActivatedRoute,) {
		title.setTitle('Portal - Juve Recherche');
		this.loaded = false;
	}

	ngOnInit(): void {
		this.contentService.holeFaqContent().then(dto => {
			this.navigation = dto.navigation;
			this.content = this.sanitizer.bypassSecurityTrustHtml(dto.content);
			this.loaded = true;
			setTimeout(() => {
				this.route.fragment.subscribe(fragment => {
					document.getElementById(fragment).scrollIntoView()
				})
			}, 100)
		});

		try {
			this.messageService.clear();
			this.userService.isAlive();
		}
		finally {
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal();
				} catch (exception) {
				}
			}, 1000);
		}
	}
}
