<div class="message"></div>

<form action="/view/passwort-zuruecksetzen/form" method="post" class="clearfix" #form (keydown.enter)="submit()">
	<div class="form-group">
		<label for="{{ 'username' | newID }}">E-Mail-Adresse</label>
		<input
			type="email"
			class="form-control"
			id="{{ 'username' | lastID }}"
			name="username"
			value="{{ username }}"
			readonly
		/>
	</div>

	<app-password-eingabe
		[username]="username"
		#passwordEingabeComponent
	></app-password-eingabe>

	<app-xsrf-form-field></app-xsrf-form-field>

	<input type="hidden" name="code" value="{{ code }}"/>

	<nav class="pull-right">
		<button
			type="button"
			class="btn btn-primary"
			[class.disabled]="hasError()"
			(click)="submit()"
			[title]="getErrorMessage()"
		>
			Speichern
		</button>
	</nav>
</form>
