import {Component, Input, OnInit} from '@angular/core';
import {SafeHtml} from "@angular/platform-browser";
import {select, Store} from "@ngrx/store";
import {selectRecherchekomplex} from "../../../store/selector/recherchekomplex.selectors";
import {Recherchekomplex} from "../../model/Recherchekomplex";
import {UserService} from "../../../form-viewer/service/UserService";

@Component({
	selector: 'app-footer',
	templateUrl: './FooterComponent.html',
	styleUrls: ['footer.less']
})
export class FooterComponent implements OnInit {
	@Input() style: string
	@Input() recherchekomplex: Recherchekomplex
	themaLink: SafeHtml
	public currentUser: string;


	constructor(private store: Store, private userService: UserService) {
	}

	ngOnInit(): void {
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => this.recherchekomplex = value)
		if (this.recherchekomplex &&
			(this.recherchekomplex.name.toLowerCase().includes('handbuch-steuern') || this.recherchekomplex.name.toLowerCase().includes('steuermarkt'))) {
			this.themaLink = '<a href="http://www.juve-steuermarkt.de/" target="_blank">Juve Steuermarkt</a>'
		}
	}
}
