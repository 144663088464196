<app-viewer-abschnitt-frage-view
	[frage]="frage"
	[antwort]="antwort"
	[abschnitt]="abschnitt"
	[printView]="_printView"
	[fertig]="fertig"
	[geprueft]="geprueft"
	[readonly]="readonly"
	(entered)="onEntered()"
	(imported)="onImported($event)"
	(fileuploadWorking)="onFileuploadWorking($event)"
></app-viewer-abschnitt-frage-view>
