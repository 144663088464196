<form
	#frageEdit="ngForm"
	(keydown.enter)="commitEdit()"
	(keydown.escape)="cancelEdit()"
	class="clearfix edit">
	<div class="form-group">
		<label for="{{ 'ueberschrift' | newID }}" required>Frage&nbsp;<span class="required-marker">*</span></label>
		<input [(ngModel)]="frage.ueberschrift"
			   class="form-control"
			   id="{{ 'ueberschrift' | lastID }}"
			   maxlength="255"
			   minlength="1"
			   name="ueberschrift"
			   placeholder="Fragentitel" required type="text"
		>
	</div>
	<div class="form-group">
		<label class="required" for="{{ 'beschreibung' | newID }}">Beschreibung</label>
		<textarea [(ngModel)]="frage.beschreibung" appPreventSubmitOnEnter class="form-control" id="{{ 'beschreibung' | lastID }}"
				  name="beschreibung"></textarea>
	</div>

	<div *ngIf="isNew()" class="form-group">
		<div class="btn-group btn-group-justified" data-toggle="buttons">
			<label *ngFor="let type of types" [class.btn-primary]="type.ID == frage.type" class="btn btn-default">
				<input (change)="changeFrageType()" [(ngModel)]="frage.type" autocomplete="off" name="type" type="radio"
					   value="{{ type.ID }}"> {{ type.TYPE_DISPLAY_NAME }}
			</label>
		</div>
	</div>

	<ng-container #frageEditor></ng-container>

	<div *ngIf="zeigeCheckboxInhaltlichGeaendert()" class="row">
		<div class="col-md-12">
			<div class="checkbox">
				<input
					[(ngModel)]="frage.inhaltlichGeaendert"
					id="{{ 'inhaltlichGeaendert' | newID }}"
					name="inhaltlichGeaendert"
					type="checkbox"
				/>
				<label for="{{ 'inhaltlichGeaendert' | lastID }}">
					Frage inhaltlich geändert
				</label>
			</div>
		</div>
	</div>

	<div class="btn-toolbar pull-right" ngPreserveWhitespacess role="toolbar">
		<button (click)="cancelEdit()" class="btn btn-default" type="button">Abbrechen</button>
		<button (click)="commitEdit()"
				[disabled]="!frageEdit.form.valid || isCommitDisabled()"
				class="btn btn-primary"
				type="button"
		>Speichern
		</button>
	</div>
</form>
