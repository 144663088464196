<div class="form-group" [class.has-warning]="!isValid()">
	<label for="{{ 'kartenTitel' | newID }}" class="control-label required">
		{{ frage.titel }}
		<app-view-info-text [beschreibung]="frage.titelBeschreibung"></app-view-info-text>
	</label>

	<input
		type="text"
		class="form-control"
		id="{{ 'kartenTitel' | lastID }}"
		[(ngModel)]="antwort.kartenTitel"
		[readonly]="readonly"
		*ngIf="!printView"
		name="kartenTitel"
		placeholder="{{ frage.titelPlatzhalterText }}"
		appScrollToFocusedElement
	/>

	<div *ngIf="printView" class="kartentitel readonly">
		{{ this.antwort.kartenTitel }}
	</div>
</div>
