import {Component, DoCheck, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionEntry} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/widgets/sortable-options/OptionEntry';

const SNAPSHOT_LIST_ENTRY_SEPARATOR = '__';

@Component({
	selector: 'app-editor-sortierbare-optionen',
	templateUrl: './SortableOptionsComponent.html',
	styleUrls: ['./SortableOptionsComponent.less']
})
export class SortableOptionsComponent implements OnInit, DoCheck {

	@Input()
	optionen: string[];

	@Output()
	optionenChange = new EventEmitter<string[]>();

	eintraege: OptionEntry[] = [];

	private eintraegeSnapshot: string;

	private static canDrag(el, container, handle) {
		return handle.classList.contains('drag-handle') && handle.classList.contains('draggable');
	}

	ngOnInit(): void {
		if (!this.optionen) {
			this.optionen = [];
		} else {
			this.eintraege = this.optionen.map((o, i) => new OptionEntry(i, o));
		}
		this.eintraegeSnapshot = this.makeEintraegeSnapshot();
		if (this.eintraege.length === 0) {
			this.add();
		}
	}

	private makeEintraegeSnapshot(): string {
		if (!this.eintraege) {
			return '';
		}

		return this.eintraege.map(o => o.value).join(SNAPSHOT_LIST_ENTRY_SEPARATOR);
	}

	ngDoCheck() {
		const newSnapshot = this.makeEintraegeSnapshot();
		if (newSnapshot !== this.eintraegeSnapshot) {
			this.eintraegeSnapshot = newSnapshot;
			this.onEintraegeChanged();
		}
	}

	private onEintraegeChanged() {
		// Ändere nicht das Array, sondern dessen Inhalt, leere es dazu zunächst und ersetzt den Inhalt
		this.optionen.splice(0);
		Array.prototype.push.apply(this.optionen, this.eintraege.map(e => e.value));
		this.optionenChange.emit(this.optionen);
	}

	isDraggable(): boolean {
		return this.hasMoreThanOneEntry();
	}

	canRemove(): boolean {
		return this.hasMoreThanOneEntry();
	}

	private hasMoreThanOneEntry() {
		return this.eintraege.length > 1;
	}

	add(): void {
		this.eintraege = [...this.eintraege, new OptionEntry(this.eintraege.length, '')];
	}

	remove(index: number): void {
		this.eintraege.splice(index, 1);
		this.onReorder();
	}

	onReorder() {
		this.eintraege.forEach((o, i) => o.index = i);
		console.log(this.eintraege);
	}
}
