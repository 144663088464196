import {Component, Input, OnInit} from '@angular/core';
import {InputTypeAuswahl} from 'app/shared/model/frage/input/InputTypeAuswahl';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';

@Component({
	selector: 'app-input-type-auswahl-edit',
	templateUrl: './InputTypeAuswahlEditComponent.html',
	styleUrls: ['./InputTypeAuswahlEditComponent.less']
})
export class InputTypeAuswahlEditComponent implements OnInit, IInputTypeEditComponent<InputTypeAuswahl> {
	@Input()
	inputType: InputTypeAuswahl;

	options: string[];
	sonstigesTextfeld: boolean;

	ngOnInit(): void {
		this.options = this.inputType.options.slice(0);
		this.sonstigesTextfeld = this.inputType.sonstigesTextfeld;
	}

	getEditorTitel(): string {
		return 'Auswahloptionen';
	}

	// trackByFn used in the ngFor, because it is iterating over an array of strings, so the default tracking by identity will fail
	// if the strings change content
	trackByFn(index, item) {
		return index;
	}

	addOption() {
		this.options.push('');
	}

	removeOption(index: number) {
		if (index > -1) {
			this.options.splice(index, 1);
		}
	}

	commitSave() {
		this.inputType.options = this.options;
		this.inputType.sonstigesTextfeld = this.sonstigesTextfeld;
	}
}
