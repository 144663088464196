import {UUID} from 'app/util/export-types';
import {DateUtil} from 'app/shared/util/DateUtil';

export class Recherchekomplex {
	id: UUID = undefined;
	name = '';
	startdatum: Date = undefined;
	wiedereroeffnenMoeglichBis: Date = undefined;
	ausfuellenMoeglichBis: Date = undefined;
	hatRecht: boolean;

	constructor(recherchekomplex?: Recherchekomplex) {
		if (recherchekomplex) {
			this.id = recherchekomplex.id;
			this.name = recherchekomplex.name;

			this.startdatum = DateUtil.parseDate(recherchekomplex.startdatum);
			this.wiedereroeffnenMoeglichBis = DateUtil.parseDate(recherchekomplex.wiedereroeffnenMoeglichBis);
			this.ausfuellenMoeglichBis = DateUtil.parseDate(recherchekomplex.ausfuellenMoeglichBis);
		}
	}

	public istGestartet(): boolean {
		return this.startdatum == null || this.startdatum <= new Date();
	}

	public kannWiedereroeffnetWerden(): boolean {
		return this.wiedereroeffnenMoeglichBis == null || this.wiedereroeffnenMoeglichBis > new Date();
	}

	public kannNochAusgefuelltWerden(): boolean {
		return this.ausfuellenMoeglichBis == null || this.ausfuellenMoeglichBis > new Date();
	}
}
