import {AusfuellerType} from 'app/form-viewer/model/AusfuellerType';

export class AusfuellerAnlegenDto {
	type: AusfuellerType;

	anrede = '';
	anzeigename = '';
	email = '';

	toString() {
		return '[' + this.type + '] ' +
			'<' + this.email + '> ' +
			this.anrede + ' ' + this.anzeigename;
	}

	getVollenNamen() {
		return (this.anrede + ' ' + this.anzeigename).trim();
	}

	getType() {
		if (this.type === 'Bearbeiter Abschnitt') {
			return 'Abschnitt-Bearbeiter';
		} else if (this.type === 'Bearbeiter Fragebogen') {
			return 'Fragebogen-Bearbeiter';
		} else {
			return (this.type).trim();
		}
	}
}
