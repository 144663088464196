import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {localStorageSync} from "ngrx-store-localstorage";
import {institutionFeatureKey} from "../store/reducer/institution.reducer";
import {recherchekomplexFeatureKey} from "../store/reducer/recherchekomplex.reducer";


export interface State {

}

export const reducers: ActionReducerMap<State> = {

};


function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
	return localStorageSync({keys: [institutionFeatureKey, recherchekomplexFeatureKey], rehydrate: true})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
