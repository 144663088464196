import {AfterViewChecked, Component, ElementRef, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {AbschnittAntwortService} from 'app/form-viewer/service/AbschnittAntwortService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {AbschnittAntwortDto} from 'app/shared/model/AbschnittAntwortDto';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {FragebogenService} from "../../shared/service/FragebogenService";
import {ViewerAbschnittService} from "../service/ViewerAbschnittService";

const debug = require('debug')('FragebogenDruckenComponent');

@Component({
	selector: 'app-fragebogen-drucken',
	templateUrl: './FragebogenDruckenComponent.html',
	styleUrls: ['./FragebogenDruckenComponent.less'],
})
export class FragebogenDruckenComponent implements OnInit, AfterViewChecked {

	fragebogen: Fragebogen;
	abschnitte: Abschnitt[];
	abschnittAntworten = new Map<Abschnitt, AbschnittAntwortDto>();
	loaded = false;
	druckDialogWurdeBereitsAngezeigt = false;
	private WAIT_AFTER_CHECK_BEFORE_PRINT_DIALOG_MS = 3000;

	constructor(private _elementRef: ElementRef,
				private abschnittAntwortService: AbschnittAntwortService,
				private route: ActivatedRoute,
				private fragebogenService: FragebogenService,
				private abschnittService: ViewerAbschnittService,
				private title: Title) {
	}

	ngOnInit() {

		console.log("FRAGEBOGEN DRUCKEN")

		const fragebogenId = this.route.snapshot.paramMap.get('fragebogenId');
		this.fragebogenService.getFragebogen(fragebogenId)
			.then(response => this.fragebogen = response)
			.then(() => this.abschnittService.getAbschnitte(this.fragebogen))
			.then(response => this.abschnitte = response).then(() => {
			this.title.setTitle(`${this.fragebogen.name} - ${this.fragebogen.recherchekomplex.name}`)
			const self = this;
			Promise.all(
				this.abschnitte.map(function (abschnitt) {
					return self.abschnittAntwortService.getAbschnittAntworten(abschnitt)
						.then(antwort => {
							self.abschnittAntworten.set(abschnitt, antwort);
						});
				}))
				.then(() => {
						self.setLoaded(true)
					}
				)
				.catch(function (grund) {
					debug('Beim Laden der Antworten zu den gegeben Abschnitten ist ein Fehler aufgetreten.', grund);
					self.setLoaded(false);
				});
		});
	}

	isLoaded() {
		return this.loaded;
	}

	getAntwort(abschnitt: Abschnitt): AbschnittAntwortDto {

		return this.abschnittAntworten.get(abschnitt);
	}

	ngAfterViewChecked() {
		if (this.loaded && !this.druckDialogWurdeBereitsAngezeigt) {
			this.druckDialogWurdeBereitsAngezeigt = true;

			// setTimeout(print, this.WAIT_AFTER_CHECK_BEFORE_PRINT_DIALOG_MS);
		}
	}

	private setLoaded(loaded: boolean) {
		this.loaded = loaded;
	}
}
