import {Component, EventEmitter, Input, Output} from '@angular/core';
import {KartenAntwort} from 'app/shared/model/antwort/KartenAntwort';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {KartenFeldAntwortTupel} from 'app/form-viewer/abschnitt/frage-liste/frage/view/karte/karten-view/KartenFeldAntwortTupel';

@Component({
	selector: 'app-karten-view',
	templateUrl: './KartenViewComponent.html',
	styleUrls: ['./KartenViewComponent.less']
})
export class KartenViewComponent {

	private _kartenAntwort: KartenAntwort;
	feldAntwortTupelLinks: KartenFeldAntwortTupel[];
	feldAntwortTupelRechts: KartenFeldAntwortTupel[];

	@Output()
	remove = new EventEmitter();

	@Input()
	frage: KartenFrage;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	@Input()
	set kartenAntwort(kartenAntwort: KartenAntwort) {
		this._kartenAntwort = kartenAntwort;
		this.generateFeldAntwortTupel();
	}

	get kartenAntwort(): KartenAntwort {
		return this._kartenAntwort;
	}

	isReadonly() {
		return this.readonly;
	}

	private generateFeldAntwortTupel() {
		let feldAntwortTupel = [];

		if (this.frage) {
			feldAntwortTupel = this.frage.felder().map(feld =>
				new KartenFeldAntwortTupel(feld, this._kartenAntwort.findForFeld(feld)));
		}

		this.feldAntwortTupelLinks = feldAntwortTupel.filter(tupel =>
			this.frage.isFeldLinks(tupel.feld));

		this.feldAntwortTupelRechts = feldAntwortTupel.filter(tupel =>
			this.frage.isFeldRechts(tupel.feld));
	}

	commitRemove() {
		this.remove.emit();
	}
}
