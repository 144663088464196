<div class="wrapper">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 hero">
				<div class="logo">
					<img src="assets/portal/logo.png" width="160">
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 col-md-offset-3">
				<div class="panel form-panel panel-default">
					<div class="panel-body">

						<app-registrierungs-formular></app-registrierungs-formular>

					</div>

					<div class="panel-footer">
						<h3>Ihre Ansprechpartner:</h3>
						<div class="contacts">
							<app-contact [person]="'scherer'"></app-contact>
							<app-contact [person]="'mecke'"></app-contact>
							<app-contact [person]="'ossen'"></app-contact>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>
</div>
