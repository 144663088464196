import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ViewerAbschnittComponent} from 'app/form-viewer/abschnitt/ViewerAbschnittComponent';
import {RecherchekomplexModalSteuerung} from 'app/form-viewer/modal/RecherchekomplexModalSteuerung';
import {AbschnittReadonlyService} from 'app/form-viewer/service/AbschnittReadonlyService';
import {AbschnittStorageService} from 'app/form-viewer/service/AbschnittStorageService';
import {AusfuellerAbgabezeitpunktService} from 'app/form-viewer/service/AusfuellerAbgabezeitpunktService';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {UserService} from 'app/form-viewer/service/UserService';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AbschnittUrlHashService} from 'app/shared/service/AbschnittUrlHashService';
import {DummyFormViewerAbschnittSperrenHelper} from 'app/sperren-helper/DummyFormViewerAbschnittSperrenHelper';
import {FormViewerAbschnittSperrenHelper} from 'app/sperren-helper/FormViewerAbschnittSperrenHelper';
import {IFormViewerAbschnittSperrenHelper} from 'app/sperren-helper/IFormViewerAbschnittSperrenHelper';
import {UnloadService} from 'app/util/UnloadService';
import {Ausfueller} from './model/Ausfueller';
import {BlockUI, NgBlockUI} from "ng-block-ui";

const debug = require('debug')('FormViewerComponent');

@Component({
	selector: 'app-form-viewer',
	templateUrl: './FormViewerComponent.html',
	styleUrls: ['./FormViewerComponent.less']
})
export class FormViewerComponent implements OnInit, OnDestroy {

	@Input()
	fragebogen: Fragebogen;

	@Input()
	fragebogen_abgeschlossen: boolean;

	@Input()
	istArchivzugriff: boolean;

	@Input()
	kontaktZumAufschliessen: Ausfueller[] = [];

	@Input()
	istVorschau: boolean;

	selectedAbschnitt: Abschnitt;

	loaded = false;

	anzahlAbschnitte = 0;

	private formViewerAbschnittSperrenHelper: IFormViewerAbschnittSperrenHelper;

	private removeUnloadCallback: () => void;

	@ViewChild('abschnittView')
	abschnittComponent: ViewerAbschnittComponent;

	@BlockUI() blockUI: NgBlockUI;

	constructor(private _elementRef: ElementRef,
				formViewerAbschnittSperrenHelper: FormViewerAbschnittSperrenHelper,
				private abschnittStorageService: AbschnittStorageService,
				private abschnittReadonlyService: AbschnittReadonlyService,
				private abschnittUrlHashService: AbschnittUrlHashService,
				private unloadService: UnloadService,
				private userService: UserService,
				private previewService: PreviewService,
				private alerts: AlertService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private ausfuellerAbgabezeitpunktService: AusfuellerAbgabezeitpunktService
	) {
		this.blockUI.start('Daten werden verarbeitet...');
		ausfuellerAbgabezeitpunktService.clearCache();
		this.formViewerAbschnittSperrenHelper = formViewerAbschnittSperrenHelper;
	}

	ngOnInit() {
		debug('ngOnInit');
		// console.log('ngOnInit');

		this.abschnittUrlHashService.abschnittSelected.subscribe(abschnitt => this.onSelectAbschnitt(abschnitt));
		this.abschnittUrlHashService.abschnittDeselected.subscribe(() => {
			this.abschnittComponent.onCommitSaveThenClose();
		});

		this.previewService.inPreview = this.istVorschau;

		if (this.fragebogen_abgeschlossen || this.previewService.isInPreview()) {
			debug('Fragebogen übermittelt oder Preview Modus aktiv, verwende Dummy Sperren-Helper');
			this.formViewerAbschnittSperrenHelper = new DummyFormViewerAbschnittSperrenHelper();
		} else {
			debug('belasse richtigen Sperren-Helper');
		}


		this.removeUnloadCallback = this.unloadService.registerUnloadCallback(() => {
			this.entsperreAbschnittBestEffort();
		});

		debug('Fragebogen in preview-context: ', this.previewService.isInPreview(), ' abgeschlossen: ', this.fragebogen_abgeschlossen);

		this.abschnittStorageService.ladeAbschnitte(this.fragebogen)
			.then(fragebogenAbschnittsdaten => {
				this.anzahlAbschnitte = fragebogenAbschnittsdaten.length;
				return this.initializeAbschnittUrlHashService(fragebogenAbschnittsdaten);
			})
			.then(isAbschnittSelected => {
				if (!isAbschnittSelected) {
					debug('initialisierung abgeschlossen, kein Abschnitt Ausgewaehlt');
					this.loaded = true;
				}
			}).finally(this.blockUI.stop);
	}

	ngOnDestroy(): void {
		debug('ngOnDestroy');
		this.removeUnloadCallback();
		this.entsperreAbschnittBestEffort();
	}

	private initializeAbschnittUrlHashService(fragebogenAbschnittsdaten): Promise<boolean> {
		debug('initializing abschnittUrlHashService');

		this.abschnittUrlHashService.initializeWithAbschnitte(fragebogenAbschnittsdaten.abschnitte);
		return Promise.resolve(this.abschnittUrlHashService.isAbschnittSelected());
	}

	onSelectAbschnitt(abschnitt: Abschnitt) {
		debug('onSelectAbschnitt – sperre Abschnitt, starte regelmäßige Verlängerung.');

		/* Achtung: Sobald man in der UI direkt von einem Abschnitt in den anderen wechseln kann
		 * vorhandene Sperre zunächst entfernen */

		let sperrenPromise = Promise.resolve(null);
		if (!this.abschnittReadonlyService.sindAbschnittsinhalteReadonly(
			this.abschnittStorageService.get(this.fragebogen), this.fragebogen_abgeschlossen, abschnitt)) {
			// Sperrung notwendig, da veraenderbar
			sperrenPromise = this.formViewerAbschnittSperrenHelper.sperreAbschnitt(abschnitt);
		}

		sperrenPromise.then((sperre) => {
			this.selectedAbschnitt = abschnitt;
			this.abschnittUrlHashService.selectAbschnitt(abschnitt);

			if (sperre) {
				this.formViewerAbschnittSperrenHelper.starteRegelmaessigeVerlaengerung(abschnitt);
			}

			this.loaded = true;
		});
	}

	onDeselectAbschnitt(): void {
		debug('onDeselectAbschnitt – stoppe Sperrenverlängerung, gebe Sperre frei.');

		this.formViewerAbschnittSperrenHelper.stoppeRegelmaessigeVerlaengerung();

		if (!this.selectedAbschnitt) {
			return;
		}

		this.formViewerAbschnittSperrenHelper.entsperrenAbschnitt(this.selectedAbschnitt).then(() => {
			this.selectedAbschnitt = null;
			this.abschnittUrlHashService.deselectAbschnitt();
			this.abschnittStorageService.aktualisiereAbschnittsStatus(this.fragebogen);
		}, () => {
			this.abschnittStorageService.aktualisiereAbschnittsStatus(this.fragebogen);
		})
			.catch(this.alerts.handleHttpError);
	}

	private entsperreAbschnittBestEffort() {
		if (this.selectedAbschnitt) {
			debug('entsperreAbschnittBestEffort – gebe Sperre frei, sofern zeitlich noch möglich');

			this.formViewerAbschnittSperrenHelper.entsperrenAbschnitt(this.selectedAbschnitt)
				.then(() => {
					debug('Best-Effort-Entsperren erfolgreich');
				})
				.catch((e) => {
					debug('Best-Effort-Entsperren fehlgeschlagen', e);
				});
		}
	}

	showControls(): boolean {
		return this.loaded && this.previewService.isNotInPreview();
	}

	onCommitSaveThenClose(): void {
		this.abschnittComponent.onCommitSaveThenClose();
	}

	istAbgeschlossenHinweisSichtbar(): boolean {
		return this.previewService.isNotInPreview()
			&& !this.istArchivzugriff
			&& this.ausfuellerBerechtigungsService.istUnterausfueller()
			&& this.fragebogen_abgeschlossen;
	}

	istAbgabefristHinweisSichtbar(): boolean {
		return this.previewService.isNotInPreview()
			&& !this.istArchivzugriff
			&& !this.fragebogen_abgeschlossen
			&& this.ausfuellerBerechtigungsService.istUnterausfueller()
			&& this.getAusfuellerAbgabefrist() != null;
	}

	getAusfuellerAbgabefrist(): string {
		if (this.previewService.isInPreview()) {
			return '';
		} else {
			return this.ausfuellerAbgabezeitpunktService.getAbgabefrist(this.fragebogen, this.selectedAbschnitt).frist;
		}
	}

	getAbgabefristEinheit(): string {
		return this.ausfuellerAbgabezeitpunktService.getAbgabefrist(this.fragebogen, this.selectedAbschnitt).typ;
	}

	zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal(): boolean {
		return new RecherchekomplexModalSteuerung(this.fragebogen.recherchekomplex).zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal();
	}

	zeigeFrageboegenNichtWiederaufschliessbarModal(): boolean {
		return new RecherchekomplexModalSteuerung(this.fragebogen.recherchekomplex).zeigeFrageboegenNichtWiederaufschliessbarModal();
	}
}
