import {Component} from '@angular/core';
import {StyledSelectComponent} from 'app/shared/components/select/styled/StyledSelectComponent';
import * as _ from 'underscore';
import {removeElement} from 'app/util/removeElement';
import {isDefined} from 'app/util/isDefined';

/**
 * Select-Komponente mit anpassbaren Option-Templates und der Möglichkeit, mehrfache Elemente auszuwählen
 *
 * <app-select
 *   [(wert)]="wertVariableOderSetter"
 *   [options]="getOptions()"
 *   id="some-id"
 * ></app-select>
 *
 * um ein eigenes Template für den Inhalt anzugeben muss folgende Syntax verwendet werden:
 *
 * <app-select …>
 * <ng-template let-options #option>
 *   <ng-container *ngFor="let option of options; let last=last">
 *     <ng-container *ngIf="option">
 *       <strong>{{ option?.title }}</strong>
 *       <div class="badge">{{ option?.count }}</div>
 *     </ng-container>
 *
 *     <ng-container *ngIf="!last">&nbsp;–&nbsp;</ng-container>
 *   </ng-container>
 *
 *   <em *ngIf="options.length == 0">nichts gewählt</em>
 * </ng-template>
 * </app-select>
 */
@Component({
	selector: 'app-multi-select',
	templateUrl: './MultiSelectComponent.html',
	styleUrls: ['./MultiSelectComponent.less'],
})
export class MultiSelectComponent extends StyledSelectComponent {

	// initially nothing is highlighted
	highlightedIndex = null;

	constructor() {
		super();
		this._wert = [];
	}

	get wertAsArray(): any[] {
		return this.ensureArray(this._wert);
	}

	onWertClicked(wert: any) {
		this._wert = this.ensureArray(this._wert);

		if (_.contains(this._wert, wert)) {
			removeElement(this._wert, wert);
		} else {
			this._wert.push(wert);
			this._wert = _.uniq(this._wert).sort();
		}

		if (this.highlightedIndex !== null) {
			this.highlightedIndex = this.indexOfWert(wert);
		}

		this.wertChanged();
	}

	isWertSelected(wert: any) {
		return _.contains(this._wert, wert);
	}

	close() {
		super.close();
		this.highlightedIndex = null;
	}

	private ensureArray(wert: any): any[] {
		if (_.isArray(wert)) {
			return wert;
		} else if (isDefined(wert)) {
			return [wert];
		} else {
			return [];
		}
	}

	isHighlighted(idx: number): boolean {
		return this.highlightedIndex === idx;
	}

	onKeyUp(event: KeyboardEvent) {
		switch (this.getKey(event)) {
			case 'Space':
				if (this.isOpen) {
					this.selectHighlightedValue();
					break;
				}
				this.toggle();
				event.stopPropagation();
				break;
			case 'Enter':
			case 'NumpadEnter':
				this.toggle();
				event.stopPropagation();
				break;
			case 'ArrowDown':
				if (!this.isOpen) {
					this.open();
				} else {
					this.highlightNextValue();
				}
				event.stopPropagation();
				break;
			case 'ArrowUp':
				if (!this.isOpen) {
					this.open();
				} else {
					this.highlightPreviousValue();
				}
				event.stopPropagation();
				break;
		}
	}

	onKeyDown(event: KeyboardEvent) {
		switch (this.getKey(event)) {
			case 'Space':
			case 'ArrowUp':
			case 'ArrowDown':
				event.preventDefault();
				event.stopPropagation();
				break;
		}
	}

	private highlightNextValue() {
		if (this.highlightedIndex == null) {
			this.highlightedIndex = -1;
		}
		this.highlightedIndex = this.limitOptionIndex(this.highlightedIndex + 1);
	}

	private highlightPreviousValue() {
		if (this.highlightedIndex == null) {
			this.highlightedIndex = this.numberOfSelectOptions() + 1;
		}
		this.highlightedIndex = this.limitOptionIndex(this.highlightedIndex - 1);
	}

	private selectHighlightedValue(): void {
		this.selectWertViaKeyboard(this.getSelectedWertForIndex(this.highlightedIndex));
	}

	protected selectWertViaKeyboard(wert: string): void {
		this.onWertClicked(wert);
	}
}
