<textarea
	*ngIf="!printView"
	class="form-control text {{ variant }}"
	[readonly]="readonly"
	[ngClass]="{ 'empty': isEmpty() }"
	placeholder="{{ getPlatzhalterText() }}"
	[(ngModel)]="antwort.wert"
	id="{{ id }}"
	appPreventSubmitOnEnter
	appScrollToFocusedElement
></textarea>

<div *ngIf="printView" class="textfeld readonly">
	{{ this.antwort.wert ? this.antwort.wert : "-" }}
</div>
