<div
	class="modal fade"
	bsModal
	(onHide)="onModalHide()"
	#modal="bs-modal"
	tabindex="-1"
	role="dialog"
>
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title" [innerHtml]="getFormTitle()"></h4>

				<button type="button" class="close" aria-label="Close" (click)="hide()">
					<span>&times;</span>
				</button>
			</div>
			<div class="modal-body">

				<app-ausfueller-verwaltung-form
					(close)="hide()"
					[configuration]="configuration"
					#form
				>
				</app-ausfueller-verwaltung-form>

			</div>
		</div>
	</div>
</div>
