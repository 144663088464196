<div class="row tabellen-spalte-titel">
	<div class="col-xs-10">
		<input
			[(ngModel)]="spalte.ueberschrift"
			class="form-control"
			name="ueberschrift"
			placeholder="Spaltentitel"
			type="text"
		>
		<i
			*ngIf="spalte.hatVorjahresSpalte()"
			class="icon-link vorjahresfeld"
			title="Verknüpft mit {{spalte.vorjahresTabellenFrageSpalte.ueberschrift}}"
		></i>
	</div>
	<app-editor-info-text
		(infoTextChanged)="infoTextChanged($event)"
		[beschreibung]="spalte.beschreibung"
		[ueberschrift]="spalte.ueberschrift"
	></app-editor-info-text>
</div>
