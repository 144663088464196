<div class="form-group entscheidung" *ngIf="!printView">
	<div class="form-check checkbox">
		<input
			[(ngModel)]="antwort.auswahl"
			class="form-check-input"
			type="radio"
			name="{{ 'entscheidung' | newID }}"
			id="{{ 'entscheidung-positiv' | newID }}"
			[disabled]="readonly"
			value="POSITIV"
			appScrollToFocusedElement
		>
		<label class="form-check-label" for="{{ 'entscheidung-positiv' | lastID }}">
			{{ frage.beschriftungPositiv }}
		</label>
	</div>

	<div class="form-check checkbox">
		<input
			[(ngModel)]="antwort.auswahl"
			class="form-check-input"
			type="radio"
			name="{{ 'entscheidung' | newID }}"
			id="{{ 'entscheidung-negativ' | newID }}"
			[disabled]="readonly"
			value="NEGATIV"
			appScrollToFocusedElement
		>
		<label class="form-check-label" for="{{ 'entscheidung-negativ' | lastID }}">
			{{ frage.beschriftungNegativ }}
		</label>
	</div>
</div>

<div *ngIf="printView" class="form-group">
	<ng-container [ngSwitch]="antwort.auswahl">
		<ng-container *ngSwitchCase="'POSITIV'">{{ frage.beschriftungPositiv }}</ng-container>
		<ng-container *ngSwitchCase="'NEGATIV'">{{ frage.beschriftungNegativ }}</ng-container>
		<ng-container *ngSwitchDefault>-</ng-container>
	</ng-container>
</div>

<div class="form-group textfeld" *ngIf="begruendungsTextfeldAktiviert()">
	<label for="{{ 'begruendung' | newID }}" class="required">{{ frage.beschriftungBegruendung }}</label>
	<textarea
		class="form-control"
		id="{{ 'begruendung' | lastID }}"
		[(ngModel)]="antwort.begruendung"
		name="{{ 'begruendung' | newID }}"
		[readonly]="readonly"
		*ngIf="!printView"
		appPreventSubmitOnEnter
		appScrollToFocusedElement
	></textarea>

	<div *ngIf="printView" class="textfeld readonly">
		{{ this.antwort.begruendung ? this.antwort.begruendung : "-" }}
	</div>
</div>
