import {Component, Input} from '@angular/core';
import {AusfuellerVerwaltungInnerFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
import {ControlContainer, NgForm} from '@angular/forms';
/* eslint-disable-next-line max-len */
import {FragebogenausfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/fragebogenausfueller/FragebogenausfuellerVerwaltungFormConfiguration';
import {FragebogenausfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/FragebogenausfuellerAnlegenDto';

@Component({
	selector: 'app-fragebogenausfueller-verwaltung-form',
	templateUrl: './FragebogenausfuellerVerwaltungFormComponent.html',
	styleUrls: ['./FragebogenausfuellerVerwaltungFormComponent.less'],
	viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class FragebogenausfuellerVerwaltungFormComponent implements AusfuellerVerwaltungInnerFormComponent {
	@Input()
	dto: FragebogenausfuellerAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: FragebogenausfuellerVerwaltungFormConfiguration;
}
