<table class="table table-bordered">
	<thead>
	<tr>
		<th *ngFor="let spalte of frage.spalten">
			<app-editor-abschnitt-frage-edit-tabelle-title
				[spalte]="spalte"
			></app-editor-abschnitt-frage-edit-tabelle-title>
		</th>
		<th class="add-spalte">
			<button class="btn btn-primary" (click)="addSpalte()">Spalte hinzufügen</button>
		</th>
	</tr>
	</thead>
	<tbody>
	<tr>
		<td *ngFor="let spalte of getSpalten()">
			<app-input-type-edit
				displaySuffix="-Spalte"
				[availableTypes]="availableTypes"
				[selectedType]="spalte.type"
				(changed)="onSpalteChanged(spalte, $event)"
			></app-input-type-edit>
		</td>
	</tr>
	</tbody>
	<tfoot>
	<tr>
		<th *ngFor="let spalte of frage.spalten">
			<button [disabled]="removeSpaltenDisabled()" class="btn btn-danger" (click)="removeSpalte(spalte)">Spalte löschen</button>
		</th>
	</tr>
	</tfoot>
</table>
