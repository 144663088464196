<div class="hero">
	<div class="container">
		<div *ngIf="fragebogen" class="fragebogen-drucken">
			<h1>Fragebogen:  {{ fragebogen.name }}</h1>

			<div class="printButtons" style="position:sticky; top: 20px; z-index: 10;">
				<button id="closeButton"
						style="float: right; margin-right: 10px;"
						onclick="window.close();"
						class="btn btn-primary"
						type="button">
					Vorschau schließen
				</button>
				<button id="printButton"
						style="float: right; margin-right: 10px;"
						onclick="window.print();"
						class="btn btn-primary"
						type="button">
					Drucken
				</button>
			</div>

			<h2>Inhalt</h2>
			<ul class="inhalt">
				<li *ngFor="let abschnitt of abschnitte" class="abschnitt">{{ abschnitt.ueberschrift }}
					<ul>
						<li *ngFor="let frage of abschnitt.fragen" class="frage">{{ frage.ueberschrift }}</li>
					</ul>
				</li>
			</ul>
			<hr>
			<app-alert></app-alert>
			<ng-container *ngIf="isLoaded()">
				<div *ngFor="let abschnitt of abschnitte" class="abschnitt">
					<div class="abschnitt-text">
						<h2>{{ abschnitt.ueberschrift }}</h2>
						<p>{{ abschnitt.beschreibung }}</p>
					</div>
					<app-viewer-abschnitt-frage-liste
						[abschnitt]="abschnitt"
						[antwort]="getAntwort(abschnitt)"
						[frageFertigMarker]="[]"
						[frageGeprueftMarker]="[]"
						[ausfueller]="[]"
						[meineFragenFertigMarker]="[]"
						[readonly]="true"
						[printView]="true"
					></app-viewer-abschnitt-frage-liste>
				</div>
			</ng-container>
			<div *ngIf="!isLoaded()">
				<app-spinner></app-spinner>
			</div>
		</div>
	</div>
</div>




