import {Directive, HostListener} from '@angular/core';

@Directive({
	selector: '[appPreventSubmitOnEnter]',
})
export class PreventSubmitOnEnterDirective {
	@HostListener('keydown.enter', ['$event'])
	onEnter(e) {
		e.stopPropagation();
	}
}
