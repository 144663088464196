export class AnredeUtil {
	static dekliniere(anrede: String, nominativ = false): String {
		if (nominativ) {
			return anrede;
		} else {
			if (anrede === 'Herr') {
				return 'Herrn';
			} else if (anrede === 'Frau') {
				return 'Frau';
			} else {
				return anrede;
			}
		}
	}
}
