import {DatePipe} from '@angular/common';

export class DateFormatterUtil {

	private static datePipe = new DatePipe('de-DE');

	static transformToDateOnly(value: Date): string {
		return DateFormatterUtil.datePipe.transform(value, 'dd.MM.yyyy');
	}

	static transformToTimeOnly(value: Date): string {
		return DateFormatterUtil.datePipe.transform(value, 'HH:mm') + ' Uhr';
	}

	static transformToDateAndTime(value: Date): string {
		return DateFormatterUtil.datePipe.transform(value, 'dd.MM.yy HH:mm') + ' Uhr';
	}
}
