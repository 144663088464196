<ng-container *ngIf="institution.length == 0">
	<div class="offset-top">
		<p>
			Sie haben noch keine Ihnen zugewiesenen Fragebögen.
		</p>
	</div>
</ng-container>

<ng-container *ngIf="institution.length == 1">
	<h3 class="institution">{{ aktuelleInstitution.institution.anzeigename }}</h3>
</ng-container>

<ng-container *ngIf="institution.length > 1">
	<div class="select">
		<app-styled-select
			[wert]="aktuelleInstitution"
			(wertChange)="aktuelleInstitutionChanged($event)"
			[options]="institution"
		>
			<ng-template let-option #option>

				<ng-container *ngIf="option">
					{{ option.institution.anzeigename }}
				</ng-container>

			</ng-template>
		</app-styled-select>
	</div>
</ng-container>
