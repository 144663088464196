import {Component} from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
			   templateUrl: './UnbekannterCodeComponent.html'
		   })
export class UnbekannterCodeComponent {

	constructor(private title: Title) {
		title.setTitle('Registrieren - Unbekannter Code')
	}

}
