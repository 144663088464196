<app-editor-abschnitt-frage-view
	[frage]="frage"
	(edit)="beginEdit()"
	(duplicate)="beginDuplicate()"
	(remove)="commitRemove()"
	*ngIf="!isEditing() || isAbschnittNew()"
></app-editor-abschnitt-frage-view>

<app-editor-abschnitt-frage-edit
	[frage]="editFrage"
	(cancel)="cancelEdit()"
	(commit)="commitEdit($event)"
	*ngIf="isEditing() && !isAbschnittNew()"
></app-editor-abschnitt-frage-edit>
