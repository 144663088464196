import {HttpClient} from "@angular/common/http";
import {Component, Input, OnInit} from '@angular/core';
import {SichtbareInstitutionDto} from 'app/portal-selector/model/SichtbareInstitutionDto';
import {AlertService} from "../shared/alert/service/AlertService";
import {UserService} from "../form-viewer/service/UserService";
import {Ausfueller} from "../form-viewer/model/Ausfueller";
import {UUID} from "../util/export-types";
import {MessageService} from "primeng/api";
import {select, Store} from "@ngrx/store";
import {selectInstitution} from "../store/selector/institution.selectors";
import {Institution} from "../shared/model/Institution";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: 'app-portal-selector',
	templateUrl: './PortalSelectorComponent.html'
})
export class PortalSelectorComponent implements OnInit {
	@Input() bereich: string
	sichtbareInstitution: SichtbareInstitutionDto[] = [];
	public loaded: boolean;
	@BlockUI() blockUI: NgBlockUI;
	private ausfueller: Ausfueller;

	constructor(private httpClient: HttpClient,
				private alertService: AlertService,
				private userService: UserService,
				private store: Store,
				private messageService: MessageService) {
		this.loaded = false;
	}

	private _aktuelleInstitution: SichtbareInstitutionDto;

	get aktuelleInstitution(): SichtbareInstitutionDto {
		return this._aktuelleInstitution;
	}

	set aktuelleInstitution(value: SichtbareInstitutionDto) {
		this._aktuelleInstitution = value;
		this.userService.setAktuelleInstitution(value.institution)
	}

	ngOnInit(): void {
		this.initData();
	}

	isLoaded() {
		return this.loaded;
	}

	private initData() {
		// this.blockUI.start('Daten werden verarbeitet...PortalSelectorComponent');
		try {
			this.ausfueller = this.userService.getAusfueller()
			this.httpClient.get<SichtbareInstitutionDto[]>(`/content/${this.bereich}`
			)
				.toPromise()
				.catch(this.alertService.handleHttpError)
				.then(json => SichtbareInstitutionDto.buildList(json))
				.then(dtos => this.sichtbareInstitution = dtos)
				.then(() => {
					this.extractCurrentInstitute(true);
				}).finally();
		} finally {

			setTimeout(() => {
				this.blockUI.stop()
			}, 2000);
		}
	}

	private extractCurrentInstitute(setUserServiceInstituteList) {
		this.loaded = true;
		let tmpInst: Institution;

		let tmpSeriveInst = this.userService.getAktuelleInstitution()

		let found;
		if (this.sichtbareInstitution.length > 0) {
			this.sichtbareInstitution.sort((a, b) => a.institution.anzeigename.toUpperCase().localeCompare(b.institution.anzeigename.toUpperCase()));

			if (setUserServiceInstituteList)
				this.userService.setSichtbareInstitutionen(this.sichtbareInstitution)

			// this.messageService.clear();
			this.store.pipe(select(selectInstitution)).subscribe(value => tmpInst = new Institution(value));

			if (!tmpSeriveInst)
				this._aktuelleInstitution = this.sichtbareInstitution[0];
			else {
				found = false
				for (const sichtbareInstitutionDto of this.sichtbareInstitution) {
					if (sichtbareInstitutionDto.institution.id === tmpSeriveInst.id) {
						this._aktuelleInstitution = sichtbareInstitutionDto
						found = true
					}
				}
				if (!found)
					this._aktuelleInstitution = this.sichtbareInstitution[0];
			}

			if (this.userService.getAktuelleInstitution()) {
				for (const aktuelleInstitutionElement of this.sichtbareInstitution) {
					if (aktuelleInstitutionElement.institution.id === this.userService.getAktuelleInstitution().id) {
						this._aktuelleInstitution = aktuelleInstitutionElement
						break
					}
				}
			} else {
				this._aktuelleInstitution = this.sichtbareInstitution[0];
			}

			this.userService.setAktuelleInstitution(this._aktuelleInstitution.institution)

			for (const s of this.sichtbareInstitution) {
				if (s.institution.id === tmpInst.id) {
					this._aktuelleInstitution = s;
				}
			}
		} else {
			this.messageService.clear();
			this.messageService.add({
				severity: 'warn',
				life: 5000,
				summary: 'Es konnten keine Daten geladen werden.'
			});
		}
	}

	private getCommonParams() {
		const additionalParams = {};
		this.addIfNotNull(additionalParams, 'ausfuellerId', this.userService.getAusfueller().id);
		return additionalParams;
	}

	private addIfNotNull(additionalParams: {}, key: string, value: UUID) {
		const institutionId = value;
		if (institutionId != null) {
			additionalParams[key] = institutionId;
		}
	}
}
