import {Abschnitt} from 'app/shared/model/Abschnitt';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Injectable} from '@angular/core';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';
import {HttpClient} from '@angular/common/http';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

const debug = require('debug')('AbschnittAusfuellerService');

@Injectable()
export class AbschnittAusfuellerService {

	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	getAusfuellerFuerAbschnitt(abschnitt: Abschnitt): Promise<Ausfueller[]> {
		debug('Lade Ausfüller für Abschnitt', abschnitt);
		return this.http.get<Abschnitt[]>('/view/abschnitt/unterausfueller', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => Ausfueller.buildList(response))
			.catch(this.alertService.handleHttpError);
	}
}
