import {Abschnitt} from 'app/shared/model/Abschnitt';
import {FrageFertigMarker} from 'app/form-viewer/model/FrageFertigMarker';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

const debug = require('debug')('FrageFertigMarkerService');

@Injectable()
export class FrageFertigMarkerService {

	constructor(
		private http: HttpClient,
		private alertService: AlertService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService
	) {
	}

	getFertigMarkerFuerAbschnitt(abschnitt: Abschnitt): Promise<FrageFertigMarker[]> {
		debug('Lade Fertig-Marker für Abschnitt', abschnitt);
		return this.http.get<FrageFertigMarker[]>('/view/frage/fertig-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => FrageFertigMarker.buildList(response))
			.catch(this.alertService.handleHttpError);
	}

	getMeineFertigMarkerFuerAbschnitt(abschnitt: Abschnitt) {
		debug('Lade meine Fertig-Marker für Abschnitt', abschnitt);
		return this.http.get<FrageFertigMarker[]>('/view/frage/fertig-marker/meine', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				abschnitt: abschnitt.id,
			})
		})
			.toPromise()
			.then(response => FrageFertigMarker.buildList(response))
			.catch(this.alertService.handleHttpError);
	}

	alsFertigMarkieren(frage: Frage): Promise<void> {
		return this.http.post('/view/frage/fertig-marker', null, {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}

	alsUnfertigMarkieren(frage: Frage): Promise<void> {
		return this.http.delete('/view/frage/fertig-marker', {
			params: this.aktuelleBerechtigungService.addCommonParams({
				frage: frage.id,
			})
		})
			.toPromise()
			.then(() => {
			})
			.catch(this.alertService.handleHttpError);
	}
}
