import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {ViewerAbschnittService} from 'app/form-viewer/service/ViewerAbschnittService';
import {AbschnittInfoService} from 'app/form-viewer/service/AbschnittInfoService';
import {AnzahlGepruefterFragenProAbschnittDto} from 'app/shared/model/AnzahlGepruefterFragenProAbschnittDto';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {ArrayUtil} from 'app/shared/util/ArrayUtil';
import {FragebogenAbschnittsdaten} from 'app/form-viewer/service/AbschnittStorageService/FragebogenAbschnittsdaten';
import {BlockUI, NgBlockUI} from "ng-block-ui";

const debug = require('debug')('service:AbschnittStorageService');

@Injectable()
export class AbschnittStorageService {

	abschnittsdaten = new Map<Fragebogen, FragebogenAbschnittsdaten>();

	constructor(private http: HttpClient,
		private abschnittService: ViewerAbschnittService,
		private abschnittInfoService: AbschnittInfoService,
		private previewService: PreviewService) {
	}

	@BlockUI() blockUI: NgBlockUI;

	ladeAbschnitte(fragebogen: Fragebogen): Promise<FragebogenAbschnittsdaten> {
		debug('ladeAbschnitte fuer:', fragebogen.name);

		return Promise.all([
			this.abschnittService.getAbschnitte(fragebogen),
			this.abschnittInfoService.getFragebogenAbschnitteInfo(fragebogen),
			this.getAnzahlGepruefterFragenProAbschnitt(fragebogen),
		]).then((results: any[]) => {
			debug('Abschnitte geladen fuer:', fragebogen.name);

			const fragebogenAbschnittsdaten = new FragebogenAbschnittsdaten(
				fragebogen,
				results[0],
				results[1].getSperren(),
				results[1].getStatus(),
				results[2]
			);

			this.abschnittsdaten.set(fragebogen, fragebogenAbschnittsdaten);
			return Promise.resolve(fragebogenAbschnittsdaten);
		});

	}

	aktualisiereAbschnittsStatus(fragebogen: Fragebogen): Promise<FragebogenAbschnittsdaten> {
		debug('aktualisiereAbschnittsStatus fuer:', fragebogen.name);

		return Promise.all([
			this.abschnittInfoService.getFragebogenAbschnitteInfo(fragebogen),
			this.getAnzahlGepruefterFragenProAbschnitt(fragebogen),
		]).then(results => {
			debug('aktualisiereAbschnittsStatus erledigt fuer:', fragebogen.name);

			ArrayUtil.replaceContent(this.get(fragebogen).abschnittSperren, results[0].getSperren());
			ArrayUtil.replaceContent(this.get(fragebogen).abschnittStatus, results[0].getStatus());
			ArrayUtil.replaceContent(this.get(fragebogen).anzahlGepruefterFragenProAbschnittDtos, results[1]);
		}).catch(error =>
			debug('Beim Aktualisieren des Abschnittstatus fuer ', fragebogen, ' ist ein Fehler aufgetreten: ', error));
	}

	get(fragebogen: Fragebogen): FragebogenAbschnittsdaten {
		return this.abschnittsdaten.get(fragebogen);
	}

	private getAnzahlGepruefterFragenProAbschnitt(fragebogen: Fragebogen): Promise<AnzahlGepruefterFragenProAbschnittDto[]> {
		if (this.previewService.isInPreview()) {
			return Promise.resolve([]);
		} else {
			return this.abschnittService.getAnzahlGepruefterFragenProAbschnitt(fragebogen);
		}
	}
}
