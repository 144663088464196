<div class="marker clearfix" *ngIf="!istVersteckt()">
	<button
		type="button"
		(click)="toggleFertig()"
		class="btn btn-toggle pull-left"
		[class.btn-checked]="istAlsFertigMarkiert()"
		[title]="istAlsFertigMarkiert() && !readonly ? 'Erneut klicken um als Unfertig zu markieren.' : ''"
		[disabled]="readonly"
	>
		<i class="icon-check"></i>
		{{ istAlsFertigMarkiert() ? 'Fertig' : 'Als fertig markieren' }}
	</button>

	<div *ngIf="istAlsFertigMarkiert()" class="infotext pull-left">
		Als fertig markiert am {{ frageFertigMarker.created | date:'dd.MM.yy HH:mm' }} Uhr
	</div>
</div>
