<button (click)="openDialog()" class="btn btn-primary"
		title="Übermittelte Fragebögen können nicht mehr bearbeitet werden.">Fragebogen an JUVE übermitteln
</button>

<div class="modal fade" bsModal #abschliessenModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Fragebogen an JUVE übermitteln</h4>

				<button type="button" class="close pull-right" aria-label="Close" (click)="closeDialog()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="height: 400px">
				Wollen Sie diesen Fragebogen wirklich an JUVE übermitteln? Übermittelte Fragebögen können nicht mehr bearbeitet werden.

				<div class="button-bar" ngPreserveWhitespaces>
					<button class="btn btn-primary" (click)="commitAbschliessen()">An JUVE übermitteln</button>
					<button class="btn btn-default" (click)="closeDialog()">Nicht übermitteln</button>
				</div>
			</div>
		</div>
	</div>
</div>
