import {Component, Input} from '@angular/core';
import {IFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {TextfeldFrage} from 'app/shared/model/frage/TextfeldFrage';
import {TextfeldfragenAntwort} from 'app/shared/model/antwort/TextfeldfragenAntwort';

@Component({
	selector: 'app-viewer-abschnitt-frage-view-textfeld',
	templateUrl: './TextfeldFrageViewComponent.html',
	styleUrls: ['./TextfeldFrageViewComponent.less']
})
export class TextfeldFrageViewComponent implements IFrageViewComponent {

	@Input()
	frage: TextfeldFrage;

	@Input()
	antwort: TextfeldfragenAntwort;

	@Input()
	readonly: boolean;

	printView = false;

	isReadonly() {
		return this.readonly;
	}

	showTextarea(): boolean {
		return this.frage.grossesTextfeldAktiviert;
	}

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}
}
