export class AusfuellerBenutzerdatenDto {
	bekannt: boolean;

	anrede: string;
	anzeigename: string;
	nichtVerfuegbar: boolean;

	constructor(ausfuellerBenutzerdaten?: AusfuellerBenutzerdatenDto) {
		if (ausfuellerBenutzerdaten) {
			this.bekannt = ausfuellerBenutzerdaten.bekannt;
			this.nichtVerfuegbar = ausfuellerBenutzerdaten.nichtVerfuegbar;

			this.anrede = ausfuellerBenutzerdaten.anrede;
			this.anzeigename = ausfuellerBenutzerdaten.anzeigename;
		}
	}
}
