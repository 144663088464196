import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {FrageBuilderUtil} from 'app/shared/util/FrageBuilderUtil';
import {EditLockService} from 'app/shared/service/EditLockService';
import {TickService} from 'app/shared/service/TickService';
import {FrageEditorService} from 'app/form-editor/service/FrageEditorService';

const debug = require('debug')('FrageComponent');

@Component({
	selector: 'app-editor-abschnitt-frage',
	templateUrl: './FrageComponent.html',
	styleUrls: ['./FrageComponent.less'],
})
export class FrageComponent implements OnInit, OnDestroy {

	@Input()
	frage: Frage;

	@Input()
	abschnitt: Abschnitt;

	editFrage: Frage = null;

	@Input()
	index: number;

	@Output()
	remove = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	@Output()
	update = new EventEmitter<Frage>();

	constructor(private frageService: FrageEditorService, private lock: EditLockService) {
	}

	ngOnInit() {
		if (this.isNew()) {
			TickService.onNextTick(() => this.beginEdit());
		}
	}

	ngOnDestroy(): void {
		this.lock.unlockIfLockedFor(this);
	}

	isEditing() {
		return this.editFrage != null;
	}

	isNew() {
		return !this.frage.id;
	}

	isAbschnittNew() {
		return !this.abschnitt.id;
	}

	beginEdit() {
		if (this.lock.lockFor(this)) {
			this.editFrage = FrageBuilderUtil.build(this.frage);
			if (!this.editFrage.abschnitt_id) {
				this.editFrage.abschnitt_id = this.abschnitt.id;
			}
		}
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	cancelEdit() {
		this.editFrage = null;
		this.lock.unlockIfLockedFor(this);
		if (this.isNew()) {
			this.commitRemove();
		}
	}

	commitRemove() {
		if (!this.isNew()) {
			this.frageService.deleteFrage(this.frage)
				.then(() => this.remove.emit());
		} else {
			this.remove.emit();
		}
	}

	commitEdit(frage: Frage) {
		this.frageService.saveFrage(frage)
			.then(persistedFrage => {
				this.frage = persistedFrage;
				this.editFrage = null;
				this.update.emit(persistedFrage);
				this.lock.unlockIfLockedFor(this);
			})
			.catch((error) => debug('Fehler beim Speichern der Frage', error));
	}
}
