import {Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnInit, Output, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypes} from 'app/shared/model/InputTypes';
import {ModalDirective} from 'ngx-bootstrap/modal';

const debug = require('debug')('InputTypeEditComponent');

@Component({
	selector: 'app-input-type-edit',
	templateUrl: './InputTypeEditComponent.html',
	styleUrls: ['./InputTypeEditComponent.less']
})
export class InputTypeEditComponent implements OnInit {
	@Input()
	selectedType: InputType;

	@Input()
	displaySuffix: String = '';

	@Input()
	availableTypes: typeof InputType[];

	@ViewChild('optionsModal', { static: true })
	optionsModal: ModalDirective;

	@ViewChild('editor', { read: ViewContainerRef, static: true })
	editor: ViewContainerRef;

	editorTitel = '';

	private component: ComponentRef<IInputTypeEditComponent<any>>;

	@Output()
	changed = new EventEmitter<InputType>();

	selectedTypeId: string;

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {
	}

	ngOnInit(): void {
		this.selectedTypeId = this.selectedType.getClass().ID;
	}

	hasEditor() {
		return this.getEditor() !== null;
	}

	private getEditor(): Type<IInputTypeEditComponent<any>> {
		return this.selectedType.getClass().EDITOR;
	}

	private updateEditor() {
		this.editor.clear();

		const factory = this.componentFactoryResolver.resolveComponentFactory(this.getEditor());
		this.component = this.editor.createComponent(factory);
		debug('Created Instance of editor UI Component', this.component.instance, 'for', this.selectedType);
		this.component.instance.inputType = this.selectedType;
		this.editorTitel = this.component.instance.getEditorTitel();
	}

	commitSave() {
		this.component.instance.commitSave();
		this.optionsModal.hide();
		this.changed.emit(this.selectedType);
	}

	getTypes(): typeof InputType[] {
		return this.availableTypes ? this.availableTypes : InputTypes.TYPES;
	}

	getTypeDisplayName(type: typeof InputType): string {
		return type.TYPE_DISPLAY_NAME + this.displaySuffix;
	}

	onTypeSelected() {
		const selectedTypeClass = InputTypes.typeForID(this.selectedTypeId);
		this.selectedType = new selectedTypeClass();
		this.changed.emit(this.selectedType);
	}

	openEditor() {
		this.updateEditor();
		this.optionsModal.show();
	}

	closeEditor(event?: Event) {
		this.optionsModal.hide();
		if (event) {
			event.stopPropagation();
		}
	}
}
