import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ContentService} from "../services/ContentService";

@Component({
			   selector: 'app-teaser',
			   templateUrl: './TeaserComponent.html',
			   styleUrls: ['./TeaserComponent.less']
		   })
export class TeaserComponent implements OnInit {

	content: SafeHtml

	constructor(private contentService: ContentService, private sanitizer: DomSanitizer) {
	}

	ngOnInit(): void {
		this.contentService.holePortalTeaser().then(dto => this.content = this.sanitizer.bypassSecurityTrustHtml(dto.content))
	}

}
