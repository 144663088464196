import {InputTypeCheckboxViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-checkbox-view/InputTypeCheckboxViewComponent';
import {InputTypeCheckboxAntwort} from 'app/shared/model/antwort/input/InputTypeCheckboxAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';

export class InputTypeCheckbox extends InputType {
	static readonly ID = 'InputTypeCheckbox';
	static readonly ANTWORT = InputTypeCheckboxAntwort;

	static readonly TYPE_DISPLAY_NAME = 'Checkbox';

	static readonly VIEWER = InputTypeCheckboxViewComponent;
	static readonly EDITOR = null;
}
