<div class="form-group">
	<label for="{{ 'platzhalterText' | newID }}" class="required">Platzhalter-Text</label>
	<input type="text" class="form-control" id="{{ 'platzhalterText' | lastID }}" [(ngModel)]="frage.platzhalterText" name="platzhalterText" />
</div>
<div class="checkbox">
	<input type="checkbox" id="{{ 'grossesTextfeldAktiviert' | newID }}" [(ngModel)]="frage.grossesTextfeldAktiviert" name="grossesTextfeldAktiviert" />
	<label for="{{ 'grossesTextfeldAktiviert' | lastID }}">
		Großes Textfeld
	</label>
</div>
