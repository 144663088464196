import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FragebogenDuplizierenService} from 'app/form-editor/service/FragebogenDuplizierenService';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {Recherchekomplex} from 'app/shared/model/Recherchekomplex';
import {RecherchekomplexService} from 'app/shared/service/RecherchekomplexService';
import {KopieSubstringBuilder} from 'app/shared/util/KopieSubstringBuilder';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-editor-fragebogen-list-duplicate-modal',
	templateUrl: './EditorFragebogenListeDuplicateModalComponent.html',
	styleUrls: ['./EditorFragebogenListeDuplicateModalComponent.less'],
	providers: []
})
export class EditorFragebogenListeDuplicateModalComponent implements OnInit {
	@Input()
	fragebogen: Fragebogen;

	@Output()
	duplicateCreated = new EventEmitter<Fragebogen>();

	editFragebogen: Fragebogen = null;

	@ViewChild('duplicateDialogModal', { static: true })
	duplicateDialogModal: ModalDirective;

	modalDisabled = false;

	recherchekomplexe: Recherchekomplex[] = [];

	constructor(
		private duplizierenService: FragebogenDuplizierenService,
		private recherchekomplexService: RecherchekomplexService,
		private alertService: AlertService
	) {
	}

	ngOnInit(): void {
		this.duplicateDialogModal.onShow.subscribe(() => this.initialisiereDialog());
	}

	isOpen(): boolean {
		return this.editFragebogen !== null;
	}

	initialisiereDialog(): void {
		this.editFragebogen = new Fragebogen(this.fragebogen);
		this.editFragebogen.name = this.erzeugeKopieName(this.editFragebogen.name);
		this.ladeRecherchekomplexe();
	}

	erzeugeKopieName(name: string) {
		return name + ' ' + KopieSubstringBuilder.buildKopieSubstringFor(new Date());
	}

	dupliziereFragebogen(number: number): void {
		this.setModalReadonly();
		this.duplizierenService.dupliziereFragebogen(this.editFragebogen, !!number)
			.then(response => new Fragebogen(response))
			.then(fragebogen => {
				this.zeigeErfolgsmeldung();
				this.duplicateCreated.emit(fragebogen);
				this.duplicateDialogModal.hide();
			})
			.finally(() => this.setModalEditable());
	}

	openDuplicateModalClicked(event: any) {
		this.duplicateDialogModal.show();
		event.stopPropagation();
	}

	byIdComparator(a: Recherchekomplex, b: Recherchekomplex) {
		return (a == null && b == null) || (a && b && a.id === b.id);
	}

	private ladeRecherchekomplexe() {
		this.recherchekomplexService.getRecherchekomplexe()
			.then(recherchekomplexe => this.recherchekomplexe = recherchekomplexe);
	}

	private setModalEditable() {
		return this.modalDisabled = false;
	}

	private setModalReadonly() {
		this.modalDisabled = true;
	}

	private zeigeErfolgsmeldung() {
		this.alertService.showSuccess('Fragebogen ' + this.editFragebogen.name + ' erfolgreich angelegt. Lade Seite neu.');
	}

}
