import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeBuilderUtil} from 'app/shared/util/InputTypeBuilderUtil';
import {InputTypeText} from 'app/shared/model/frage/input/InputTypeText';

const debug = require('debug')('KartenFeld');

export class KartenFeld {
	id: string;
	ueberschrift = '';
	beschreibung = '';
	type: InputType;
	vorjahresfeld: KartenFeld;

	constructor(feld?: KartenFeld) {
		if (feld) {
			this.constructFromFeld(feld);
		} else {
			this.constructEmpty();
		}
	}

	onInfoTextChanged(beschreibung: string) {
		this.beschreibung = beschreibung;
	}

	clearIds() {
		this.id = null;
		this.type.clearIds();
	}

	hatVorjahresfeld() {
		return !!this.vorjahresfeld;
	}

	private constructFromFeld(feld: KartenFeld) {
		this.id = feld.id;
		this.ueberschrift = feld.ueberschrift;
		this.beschreibung = feld.beschreibung;
		this.type = InputTypeBuilderUtil.build(feld.type);
		this.vorjahresfeld = feld.vorjahresfeld;
	}

	private constructEmpty() {
		this.type = new InputTypeText();
	}
}
