import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-view-info-text',
	templateUrl: './InfoTextViewComponent.html',
	styleUrls: ['./InfoTextViewComponent.less']
})
export class InfoTextViewComponent {

	@Input()
	beschreibung: string;

	hasBeschreibung() {
		return !!this.beschreibung;
	}

	getBeschreibung() {
		return this.beschreibung;
	}
}
