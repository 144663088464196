<form class="form clearfix" #verwaltungsForm="ngForm">
	<div class="erklaerung" *ngIf="configuration" [innerHtml]="configuration.getErklaerungsHtml()"></div>

	<ng-container *ngIf="dto">
		<div class="row">
			<div class="col-sm-12">
				<div
					class="form-group"
					[class.has-success]="benutzerdatenService.emailFeedback === true"
					[class.has-error]="benutzerdatenService.emailFeedback === false"
					[class.has-feedback]="benutzerdatenService.emailFeedback !== null"
				>
					<label for="{{ 'email' | newID }}">E-Mail-Adresse&nbsp;<span class="required-marker">*</span></label>
					<input
						type="email"
						class="form-control"
						name="email"
						id="{{ 'email' | lastID }}"
						placeholder="m.mueller@example.de"
						maxlength="255"
						pattern="^[^@]+@[^@]+\.[^.]+$"
						required
						[(ngModel)]="dto.email"
						[disabled]="disabled"
						(keydown)="benutzerdatenService.emailChanged(dto.email)"
						(keyup)="benutzerdatenService.emailChanged(dto.email)"
						(change)="benutzerdatenService.emailChanged(dto.email)"
					>
					<span class="form-control-feedback" aria-hidden="true" *ngIf="benutzerdatenService.emailFeedback !== null">
						<i [class.icon-check]="benutzerdatenService.emailFeedback === true"
						   [class.icon-cross]="benutzerdatenService.emailFeedback === false"></i>
					</span>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-5">
				<div class="form-group">
					<label for="{{ 'anrede' | newID }}" required>Anrede&nbsp;<span class="required-marker">*</span></label>
					<app-styled-select
						[(wert)]="dto.anrede"
						[options]="['Herr', 'Frau']"
						id="{{ 'anrede' | newID }}"
						[disabled]="disabled || !benutzerdatenService.benutzerdatenBearbeitbar"
						placeholder="Anrede"
					></app-styled-select>
				</div>
			</div>
			<div class="col-md-7">
				<div class="form-group">
					<label for="{{ 'anzeigename' | newID }}">Name&nbsp;<span class="required-marker">*</span></label>
					<input
						type="text"
						class="form-control"
						name="anzeigename"
						id="{{ 'anzeigename' | lastID }}"
						placeholder="Müller"
						required minlength="1" maxlength="255"
						[(ngModel)]="dto.anzeigename"
						[disabled]="disabled || !benutzerdatenService.benutzerdatenBearbeitbar"
					>
				</div>
			</div>
		</div>
	</ng-container>

	<div class="optionForm">
		<ng-container #optionForm></ng-container>
	</div>

	<div>
		<button
			type="button"
			class="btn btn-primary center-block"
			[disabled]="!isValid() || benutzerdatenService.emailAdresseNichtVerfuegbar"
			(click)="onInvite()"
		>
			Einladen
		</button>
	</div>
</form>
