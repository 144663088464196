<div [ngClass]="variant" class="checkbox" *ngIf="!printView">
	<input
		class="form-control checkbox"
		type="checkbox"
		title="{{ title() }}"
		[(ngModel)]="antwort.wert"
		id="{{ id }}"
		[disabled]="readonly"
		appScrollToFocusedElement
	/>
	<label for="{{ id }}"></label>
</div>
<div *ngIf="printView" class="readonly" [ngClass]="variant">
	{{ this.antwort.wert ? 'Ja' : "Nein" }}
</div>
