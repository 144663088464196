import {Institution} from 'app/shared/model/Institution';
export class InstitutionFragebogenStatus {
	institution: Institution;
	abgeschlossen: boolean;
	abschlussdatum: Date;

	constructor(institution: Institution, abgeschlossen: boolean, abschlussdatum: Date) {
		this.institution = institution;
		this.abgeschlossen = abgeschlossen;
		this.abschlussdatum = abschlussdatum;
	}
}
