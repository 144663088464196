import {Injectable} from '@angular/core';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {HttpClient} from '@angular/common/http';
import {FragebogenStatus} from 'app/shared/model/FragebogenStatus';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

@Injectable()
export class FragebogenStatusService {

	constructor(private http: HttpClient, private alerts: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	schliesseFragebogen(fragebogen: Fragebogen): Promise<any> {
		return this.http.post('/viewer/fragebogenstatus/schliesse', null, {
			params: this.aktuelleBerechtigungService.addCommonParams({
				fragebogen: fragebogen.id,
			})
		})
			.toPromise()
			.then(() => {
				this.alerts.showSuccess('Fragebogen ' + fragebogen.name + ' erfolgreich an JUVE übermittelt.');
			})
			.catch(this.alerts.handleHttpError);
	}

	getAbgeschlosseneFrageboegen(): Promise<FragebogenStatus[]> {
		return this.http.get<FragebogenStatus[]>('/viewer/fragebogenstatus/listAbgeschlossen', {params: this.aktuelleBerechtigungService.addCommonParams({})})
			.toPromise()
			.then(response => {
				return response.map(fragebogen => new FragebogenStatus(fragebogen));
			})
			.catch(this.alerts.handleHttpError);
	}

	schliesseFragebogenAuf(fragebogen: Fragebogen) {
		return this.http.post('/viewer/fragebogenstatus/schliesse-auf', null, {
			params: this.aktuelleBerechtigungService.addCommonParams({
				fragebogen: fragebogen.id,
			})
		})
			.toPromise()
			.then(() => {
				this.alerts.showSuccess('Fragebogen ' + fragebogen.name + ' erfolgreich wieder geöffnet');
			})
			.catch(this.alerts.handleHttpError);
	}
}
