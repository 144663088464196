import {Component, OnInit} from '@angular/core';
import {HttpXsrfTokenExtractor} from '@angular/common/http';

@Component({
	selector: 'app-xsrf-form-field',
	templateUrl: './XsrfFormFieldComponent.html'
})
export class XsrfFormFieldComponent implements OnInit {
	private tokenExtractor: HttpXsrfTokenExtractor;
	token: string;

	constructor(tokenExtractor: HttpXsrfTokenExtractor) {
		this.tokenExtractor = tokenExtractor;
	}

	ngOnInit() {
		this.token = this.tokenExtractor.getToken();
	}
}
