import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromRecherchekomplex from "../reducer/recherchekomplex.reducer";

export const selectRecherchekomplexState = createFeatureSelector<fromRecherchekomplex.RecherchekomplexState>(
	fromRecherchekomplex.recherchekomplexFeatureKey,
)

export const selectRecherchekomplex = createSelector(
	selectRecherchekomplexState,
	(state: fromRecherchekomplex.RecherchekomplexState) => state.recherchekomplex
)
