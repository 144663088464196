import {VerwaltbarerAusfuellerInstitutionDto} from 'app/benutzerverwaltung/model/VerwaltbarerAusfuellerInstitutionDto';
import {VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto} from "./VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto";
import {RecherchekomplexMitSichtbarenAbschnittenDto} from "./RecherchekomplexMitSichtbarenAbschnittenDto";

export class VerwaltbarerAusfuellerDto {
	id: string;
	anrede: string;
	anzeigename: string;
	benutzername: string;
	email: string;
	enabled: boolean;
	registriert: Date;
	eingeladen: Date;
	lastLogin: Date;
	archiviertAm: Date;

	eingeladenString: String;
	lastLoginString: String;
	archiviertAmString: String;

	archiviert: boolean;
	institutionen: VerwaltbarerAusfuellerInstitutionDto[] = [];
	currentInstitution: VerwaltbarerAusfuellerInstitutionDto;
	institutionenUndRechechekomplexe: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto[] = [];
	institutionUndKomplex: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto;
	recherchekomplexMitAllenAbschnittenListetransfer: RecherchekomplexMitSichtbarenAbschnittenDto[] = [];
	statusEnabledOderArchiviert: string;
	datumLetzteStatusAenderung: String;
	datumLetzteStausAenderungString: String;
	institionListAsString: String;
	private statusEnabledOderArchiviertTitle: String;

	constructor(verwaltbarerAusfuellerDto?: VerwaltbarerAusfuellerDto) {
		if (verwaltbarerAusfuellerDto) {
			this.id = verwaltbarerAusfuellerDto.id;
			this.anrede = verwaltbarerAusfuellerDto.anrede;
			this.anzeigename = verwaltbarerAusfuellerDto.anzeigename;
			this.benutzername = verwaltbarerAusfuellerDto.benutzername;
			this.email = verwaltbarerAusfuellerDto.email;
			this.enabled = verwaltbarerAusfuellerDto.enabled;
			this.registriert = verwaltbarerAusfuellerDto.registriert ? new Date(verwaltbarerAusfuellerDto.registriert) : null;
			this.eingeladen = verwaltbarerAusfuellerDto.eingeladen ? new Date(verwaltbarerAusfuellerDto.eingeladen) : null;
			this.lastLogin = verwaltbarerAusfuellerDto.lastLogin ? new Date(verwaltbarerAusfuellerDto.lastLogin) : null;
			this.archiviert = verwaltbarerAusfuellerDto.archiviert;
			this.archiviertAm = verwaltbarerAusfuellerDto.archiviertAm ? new Date(verwaltbarerAusfuellerDto.archiviertAm) : null;
			this.statusEnabledOderArchiviert = this.benutzerEnabledOderArchiviertStatusAnzeigen(verwaltbarerAusfuellerDto);
			this.statusEnabledOderArchiviertTitle = this.statusEnabledOderArchiviert == "inaktiv" ? 'Dieser Benutzer kann nur durch JUVE aktiviert werden!' : ""

			const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};

			if (this.eingeladen)
				this.eingeladenString = this.eingeladen.toLocaleDateString('de-DE', options);
			if (this.lastLogin)
				this.lastLoginString = this.lastLogin.toLocaleDateString('de-DE', options);
			if (this.archiviertAm)
				this.archiviertAmString = this.archiviertAm.toLocaleDateString('de-DE', options);

			this.datumLetzteStatusAenderung = this.datumLetzteStatusAenderungAnzeigen();

			// this.datumLetzteStausAenderungString = this.datumLetzteStatusAenderung.toLocaleDateString('de-DE', options);

			this.institutionen = verwaltbarerAusfuellerDto.institutionen
				.map(institution => new VerwaltbarerAusfuellerInstitutionDto(institution));

			this.institutionen.forEach(institution => {
				institution.recherchekomplexe.forEach(recherchekomplex => {
					var institutionenUndRecherchekomplexe: VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto =
						new VerwaltbarerAusfuellerInstitutionUndRecherchekomplexDto();
					institutionenUndRecherchekomplexe.ausfuellerName = this.anzeigename;
					institutionenUndRecherchekomplexe.ausfuellerId = this.id;
					institutionenUndRecherchekomplexe.ausfuellerAnrede = this.anrede;
					institutionenUndRecherchekomplexe.ausfuellerBenutzername = this.benutzername;
					institutionenUndRecherchekomplexe.ausfuellerEmail = this.email;
					institutionenUndRecherchekomplexe.ausfuellerEnabled = this.enabled;
					institutionenUndRecherchekomplexe.ausfuellerRegistriert = this.registriert;
					institutionenUndRecherchekomplexe.ausfuellerEingeladen = this.eingeladen;
					institutionenUndRecherchekomplexe.ausfuellerLastLogin = this.lastLogin;
					institutionenUndRecherchekomplexe.ausfuellerArchiviert = this.archiviert;
					institutionenUndRecherchekomplexe.ausfuellerArchiviertAm = this.archiviertAm;
					institutionenUndRecherchekomplexe.institutionName = institution.anzeigename;
					institutionenUndRecherchekomplexe.institutionId = institution.id;
					institutionenUndRecherchekomplexe.recherchekomplexName = recherchekomplex.name;
					institutionenUndRecherchekomplexe.rechekomplexId = recherchekomplex.id;
					institutionenUndRecherchekomplexe.recherchekomplexLabel = recherchekomplex.label;
					institutionenUndRecherchekomplexe.recherchekomplexTitel = recherchekomplex.title;
					institutionenUndRecherchekomplexe.recherchekomplexFrageboegenGesamt = recherchekomplex.frageboegenGesamt;
					institutionenUndRecherchekomplexe.recherchekomplexFrageboegenBerechtigt = recherchekomplex.frageboegenBerechtigt;

					recherchekomplex.frageboegen.forEach(frage => {
						institutionenUndRecherchekomplexe.fragebogenName = frage.name;
						institutionenUndRecherchekomplexe.frageboegen.push(frage);
						frage.abschnitte.forEach(abschnitt => {
							institutionenUndRecherchekomplexe.abschnittUeberschrift = abschnitt.ueberschrift;
							institutionenUndRecherchekomplexe.abschnitte.push(abschnitt);
							//console.log(abschnitt);
						})
					})
					if (institutionenUndRecherchekomplexe.recherchekomplexLabel) {
						this.institutionenUndRechechekomplexe.push(institutionenUndRecherchekomplexe);
					}
				});
			});
		}

		// this.institionListAsString = this.institutionen.map(s => " " + s.anzeigename).toString();
	}

	static fromList(list: VerwaltbarerAusfuellerDto[]) {
		return list.map(verwaltbarerAusfuellerDto => new VerwaltbarerAusfuellerDto(verwaltbarerAusfuellerDto));
	}

	getInstitionListAsString() {
		return this.institutionen.map(s => " " + s.anzeigename).toString();
	}

	istAusfuellerEinRecherchekomplexAdministrator() {
		for (const institution of this.institutionen) {
			for (const recherchekomplex of institution.recherchekomplexe) {
				if (recherchekomplex.istRecherchekomplexAdministrator) {
					return true;
				}
			}
		}
		return false;
	}

	getBerechtigungForRecherchekomplex(komplexId: String) {
		for (const institutionUndRechechekomplex of this.institutionenUndRechechekomplexe) {
			if (institutionUndRechechekomplex.rechekomplexId == komplexId) {
				return institutionUndRechechekomplex.recherchekomplexLabel
			}
		}
		return ""
	}

	isValid() {
		console.log(this.anrede)
		console.log(this.anzeigename)
		console.log(this.email)
		if (!this.anrede ||
			!this.anzeigename ||
			!this.email ||
			this.anrede === "" ||
			this.anzeigename === "" ||
			this.email === "") {
			return false;
		} else {
			var res = this.email.match("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,65}");
			if (res && res.length > 0)
				if (res[0] === this.email) {
					return true
				} else {
					console.log(res[0])
					console.log(this.email)
				}

			return false
		}
	}

	benutzerEnabledOderArchiviertStatusAnzeigen(ausfuellerServiceDto: VerwaltbarerAusfuellerDto) {
		if (ausfuellerServiceDto.enabled && !ausfuellerServiceDto.archiviert) {
			return "aktiv"
		} else if (!ausfuellerServiceDto.enabled && !ausfuellerServiceDto.archiviert) {
			return "noch nicht registriert"
		} else if (ausfuellerServiceDto.archiviert) {
			return "inaktiv"
		} else {
			return " "
		}
	}

	getEingeladenString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.eingeladen.toLocaleDateString('de-DE', options);
	}

	getLastLoginString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.lastLogin.toLocaleDateString('de-DE', options);
	}

	getArchiviertAmString() {
		const options: Intl.DateTimeFormatOptions  = {year: 'numeric', month: '2-digit', day: '2-digit'};
		return this.archiviertAm.toLocaleDateString('de-DE', options);
	}

	datumLetzteStatusAenderungAnzeigen() {
		if (this.enabled && !this.archiviert) {
			return this.lastLoginString
		} else if (!this.enabled && !this.archiviert) {
			return this.eingeladenString
		} else if (this.archiviert) {
			return this.archiviertAmString
		} else {
			return this.lastLoginString
		}
	}
}
