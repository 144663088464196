import {UUID} from 'app/util/export-types';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';
import {Abschnitt} from 'app/shared/model/Abschnitt';

export class AbschnittSperre {
	id: UUID;
	abschnitt_id: string;
	institution_id: string;
	angelegt: Date;
	letzte_erneuerung: Date;
	bearbeiter: Ausfueller;

	constructor(sperre?: AbschnittSperre) {
		if (sperre) {
			this.id = sperre.id;
			this.abschnitt_id = sperre.abschnitt_id;
			this.institution_id = sperre.institution_id;
			this.angelegt = sperre.angelegt;
			this.letzte_erneuerung = sperre.letzte_erneuerung;

			if (sperre.bearbeiter) {
				this.bearbeiter = new Ausfueller(sperre.bearbeiter);
			}
		}
	}

	getAnzeigename(): string {
		return this.bearbeiter.anzeigename;
	}

	getAnrede(): string {
		return this.bearbeiter.anrede;
	}

	gehoertZu(ausfueller: Ausfueller): boolean {
		return ausfueller != null && this.bearbeiter.id === ausfueller.id;
	}

	gehoertZuAbschnitt(abschnitt: Abschnitt): boolean {
		return this.abschnitt_id === abschnitt.id;
	}
}
