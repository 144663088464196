<div class="form-group">
	<label for="{{ 'platzhalterText' | newID }}" class="required">Platzhalter-Text</label>
	<input type="text" class="form-control" id="{{ 'platzhalterText' | lastID }}" [(ngModel)]="platzhalterText"
		   name="platzhalterText"/>
	<p></p>
	<label for="{{ 'vorkommastellen' | newID }}" class="required">Anzahl Stellen (0 für unbegrenzt)</label>
	<p-inputNumber [inputStyleClass]="'form-control text'" id="{{ 'vorkommastellen' | lastID }}" [(ngModel)]="vorkommastellen"
		   name="vorkommastellen" [min]="0"></p-inputNumber>
	<p></p>
	<label for="{{ 'nachkommastellen' | newID }}" class="required">Anzahl Nachkommastellen</label>
	<p-inputNumber [inputStyleClass]="'form-control text'" id="{{ 'nachkommastellen' | lastID }}" [(ngModel)]="nachkommastellen"
		   name="nachkommastellen" [min]="1"></p-inputNumber>
</div>
