export class RolleTitleUtil {
	private static rechercheAnsprechpartner: string;
	private static rechercheKoordinator: string;
	private static bearbeiter: string;

	static dekliniere(rolle: String): String {

		this.rechercheAnsprechpartner = "Erhält von JUVE den Zugangslink und den Registrierungscode. Steuert den Bearbeitungsablauf innerhalb seiner Organisation und kann Fragebögen an JUVE übermitteln. Der Recherche-Ansprechpartner darf Bearbeiter einladen, vergibt und koordiniert deren Registrierungscodes und kann vergebene Zugänge wieder entziehen. Bei Verlust von Codes oder Zugangsproblemen wenden sich alle anderen Bearbeiter an diese Person.";
		this.rechercheKoordinator = "Ist vom Recherche-Ansprechpartner eingeladen. Kann alle im jeweiligen Recherchekomplex aktiven Fragebögen sehen und bearbeiten. Darf Fragebögen übermitteln, wenn der Recherche-AP dieses Recht eingeräumt hat.";
		this.bearbeiter = "Ist vom Recherche-Ansprechpartner eingeladen, um einen Fragenbogen oder Abschnitte eines Fragebogens auszufüllen. Kann keine weiteren Bearbeiter einladen. Bei Verlust des Registrierungscodes oder Problemen mit der Anmeldung wenden sich alle Bearbeiter an den Recherche-Ansprechpartner.";
		let switchRolle = "";

		if (rolle) {
			switchRolle = ("" + rolle).toUpperCase();
		}

		switch (switchRolle) {
			case "RECHERCHE-ANSPRECHPARTNER": {
				return this.rechercheAnsprechpartner;
			}
			case "JUVE RECHERCHE-ANSPRECHPARTNER": {
				return this.rechercheAnsprechpartner;
			}
			case "RECHERCHE-KOORDINATOR": {
				return this.rechercheKoordinator;
			}
			case "BEARBEITER": {
				return this.bearbeiter;
			}
			case "FRAGEBOGEN-BEARBEITER": {
				return this.bearbeiter;
			}
			case "ABSCHNITT-BEARBEITER": {
				return this.bearbeiter;
			}
			default: {
				return rolle;
			}
		}
	}
}
