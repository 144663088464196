import {Component, ElementRef, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {MessageService} from 'primeng/api';
import {Ausfueller} from "../../form-viewer/model/Ausfueller";
import {UserService} from "../../form-viewer/service/UserService";
import {AusfuellerBerechtigungsService} from "../../form-viewer/service/AusfuellerBerechtigungsService";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: 'app-portal-allgemein',
	templateUrl: './PortalAllgemeinComponent.html',
	styleUrls: ['../content-page.less', '../../../website-styles/content-page/_portal-meta.less']
})
export class PortalAllgemeinComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	ausfueller: Ausfueller
	public initDone: boolean = false;

	constructor(private elementRef: ElementRef,
				private userService: UserService,
				private messageService: MessageService,
				private title: Title,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService) {
		title.setTitle('Portal - Juve Recherche')
		this.initDone = false
	}

	ngOnInit(): void {
		try {
			this.messageService.clear()
			this.userService.isAlive()
			this.userService.getAusfueller()
			this.ausfueller = this.userService.getAusfueller()
			this.ausfuellerBerechtigungsService.clearCache();
			this.userService.getSichtbareInstitutionen();
		} finally {
			this.initDone = true
			setTimeout(() => {
				try {
					this.blockUI.resetGlobal()
				} catch (exception) {
				}
			}, 1000);
		}
	}
}
