import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {AusfuellerBenutzerdatenDto} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerBenutzerdatenDto';

const debug = require('debug')('service:ajax:AusfuellerBenutzerdatenApiService');

@Injectable()
export class AusfuellerBenutzerdatenApiService {
	constructor(private http: HttpClient, private alertService: AlertService) {
	}

	ladeBenutzerdaten(email: string): Promise<AusfuellerBenutzerdatenDto> {
		debug('Lade Benutzerdaten für E-Mail', email);
		return this.http.get<AusfuellerBenutzerdatenDto>('/view/ausfueller/existiert-ausfueller-bereits', {
			params: {
				email: email
			}
		})
			.toPromise()
			.then(response => new AusfuellerBenutzerdatenDto(response))
			.catch(this.alertService.handleHttpError);
	}
}
