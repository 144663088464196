import {Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';

const debug = require('debug')('InputViewComponent');

@Component({
	selector: 'app-input-view',
	templateUrl: './InputViewComponent.html',
	styleUrls: ['./InputViewComponent.less']
})
export class InputViewComponent implements OnInit {

	@Input()
	_antwort: InputTypeAntwort<any>;

	@Input()
	type: InputType;

	@ViewChild('input', { read: ViewContainerRef, static: true })
	input: ViewContainerRef;

	@Input()
	id = '';

	@Input()
	context = {};

	@Input()
	printView: boolean;

	private _readonly: boolean;

	@Input()
	variant = 'default';

	private component: ComponentRef<IInputTypeViewComponent<any>>;

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {
	}

	ngOnInit() {
		this.updateView();
	}

	private updateView() {
		this.input.clear();
		if (this._antwort) {
			const factory = this.componentFactoryResolver.resolveComponentFactory(this.type.getClass().VIEWER);
			this.component = this.input.createComponent(factory);
			this.component.instance.antwort = this._antwort;
			this.component.instance.type = this.type;
			this.component.instance.context = this.context;
			this.component.instance.variant = this.variant;
			this.component.instance.id = this.id;
			this.component.instance.readonly = this._readonly;
			this.component.instance.printView = this.printView;
		}
	}

	@Input()
	set antwort(antwort: InputTypeAntwort<any>) {
		this._antwort = antwort;
		if (this.component) {
			this.component.instance.antwort = antwort;
		}
	}

	@Input()
	set readonly(readonly: boolean) {
		this._readonly = readonly;
		if (this.component) {
			this.component.instance.readonly = this._readonly;
		}
	}
}
