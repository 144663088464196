<div class="content">
	<div [class.has-items]="true" class="fluidcontainer">
		<app-karten-add-button (click)="addKarte()" *ngIf="!readonly"></app-karten-add-button>

		<div *ngIf="showEmptyMessage()" class="message">Keine Einträge vorhanden</div>

		<div class="panel panel-default karte" *ngFor="let viewKarte of viewKarten">
			<!--			[class.dimmed]="isCardDisabled(viewKarte)"-->
			<div *ngIf="!printView" class="panel-heading" role="tab" tabindex="0"
				 appScrollToFocusedElement>
				<div class="panel-title">
					<div role="button" (click)="toggleOpen(viewKarte)" ngPreserveWhitespaces>

						<ng-container *ngIf="!viewKarte.isOpen">
							<strong *ngIf="frage.titel">
								{{ frage.titel }}
							</strong>
							<span *ngIf="viewKarte.antwort.kartenTitel">
								{{ viewKarte.antwort.kartenTitel }}
							</span>

							<i class="pull-right icon icon-chevron-small-down"></i>
						</ng-container>

						<ng-container *ngIf="viewKarte.isOpen">
							<strong>
								{{ frage.titel }} bearbeiten
							</strong>

							<i class="pull-right icon icon-chevron-small-up"></i>
						</ng-container>

					</div>
				</div>
			</div>
			<div class="panel-collapse collapse in" role="tabpanel" *ngIf="viewKarte.isOpen">
				<div class="panel-body">

					<app-karten-view [frage]="frage"
									 [printView]="printView"
									 [kartenAntwort]="viewKarte.antwort"
									 (remove)="onRemoveKarte(viewKarte)"
					></app-karten-view>
				</div>
			</div>
		</div>
	</div>
</div>
