<ng-template #optionInput let-option=" option">
	<div class="form-check checkbox abc-checkbox">
		<input
			type="checkbox"
			value="{{ option }}"
			class="form-check-input"
			name="{{ 'option-antwort-name' | newID }}"
			id="{{ 'option-antwort' | newID }}"
			(change)="checkboxChange($event.target.value, $event.target.checked)"
			[checked]=antwort.istGewaehlt(option)
			[disabled]=isReadonly()
		><label for="{{ 'option-antwort' | lastID }}" class="form-check-label">{{ option }}</label>
	</div>
</ng-template>

<div class="form-group container-fluid">
	<div class="row" *ngIf="!printView">
		<ul class="col-xs-6">
			<ng-container *ngFor="let option of frage.optionen; let idx=index; trackBy: trackByIndex">
				<li *ngIf="istLinkeSpalte(idx)">
					<ng-container *ngTemplateOutlet="optionInput; context: {option: option}"></ng-container>
				</li>
			</ng-container>
		</ul>
		<ul class="col-xs-6">
			<ng-container *ngFor="let option of frage.optionen; let idx=index; trackBy: trackByIndex">
				<li *ngIf="istRechteSpalte(idx)">
					<ng-container *ngTemplateOutlet="optionInput; context: {option: option}"></ng-container>
				</li>
			</ng-container>
		</ul>
	</div>
	<div *ngIf="printView">
		{{ antwort.antworten ? antwort.antworten.join(', ') : "-" }}
	</div>
</div>
