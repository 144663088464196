import {UUID} from 'app/util/export-types';
export class Institution {
	id: UUID;
	juveosId: string;
	anzeigename: string;

	constructor(institution?: Institution) {
		if (institution) {
			this.id = institution.id;
			this.juveosId = institution.juveosId;
			this.anzeigename = institution.anzeigename;
		}
	}
}
