import {Component, Input, OnInit} from '@angular/core';
import {IFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/IFrageViewComponent';
import {MehrfachauswahlFrage} from 'app/shared/model/frage/MehrfachauswahlFrage';
import {MehrfachauswahlAntwort} from 'app/shared/model/antwort/MehrfachauswahlAntwort';

@Component({
	selector: 'app-viewer-abschnitt-frage-view-mehrfachauswahl',
	templateUrl: './MehrfachauswahlFrageViewComponent.html',
	styleUrls: ['./MehrfachauswahlFrageViewComponent.less']
})
export class MehrfachauswahlFrageViewComponent implements IFrageViewComponent, OnInit {
	@Input()
	frage: MehrfachauswahlFrage;

	@Input()
	antwort: MehrfachauswahlAntwort;

	@Input()
	readonly: boolean;

	printView = false;

	ngOnInit(): void {
	}

	isReadonly() {
		return this.readonly;
	}

	setPrintView(printView: boolean): void {
		this.printView = printView;
	}

	checkboxChange(option, ausgewaehlt): void {
		if (ausgewaehlt) {
			this.antwort.push(option);
		} else {
			this.antwort.remove(option);
		}
	}

	istLinkeSpalte(index: number): boolean {
		return index < Math.ceil(this.getAnzahlOptionen() / 2);
	}

	istRechteSpalte(index: number): boolean {
		return !this.istLinkeSpalte(index);
	}

	getAnzahlOptionen(): number {
		return this.frage.getAnzahlOptionen();
	}

	trackByIndex(index: number) {
		return index;
	}
}
