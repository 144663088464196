<div class="container-fluid">

	<div class="row" *ngFor="let initialien of getInitialienInGroups()">
		<div class="col-md-4" *ngFor="let initiale of initialien">
			<h2>{{ initiale }}</h2>

			<ul class="list-unstyled">
				<li *ngFor="let eintrag of getInstitutionenFuerInitiale(initiale)">
					<!--TODO: ordentlich fixen-->
					<a href=""
					   (mouseup)="onSelect(eintrag)"
					   class="institution"
					   [ngClass]="{'offen': !eintrag.abgeschlossen}"
					   title="{{ institutionLinkTitle(eintrag) }}"
					   appPreventDefault>{{ eintrag.institution.anzeigename }}</a>
				</li>
			</ul>
		</div>
	</div>
</div>
