<app-ausfueller-verwaltung-dialog
	[configuration]="configuration"
	#dialog
></app-ausfueller-verwaltung-dialog>

<span class="controls">
	<ng-template #tooltipContent>
		<span [innerHtml]="getTooltipHtml()"></span>
	</ng-template>

	<button
		class="btn btn-default btn-xs"
		type="button"
		(click)="onClick()"
		[title]="tooltipContent"
		container="body"
	>
		<span *ngIf="type != 'CoAdmin'"><i class="glyphicon glyphicon-user"></i> +</span>
		<span *ngIf="type == 'CoAdmin'">Recherche-Koordinator einladen</span>
	</button>
</span>
