<app-alert></app-alert>

<a href="#" class="dropdown-toggle" (click)="institutionModal.show()" appPreventDefault>
	{{ fragebogenName || 'Institution auswählen' }}
	<span class="caret"></span>
</a>

<div class="modal fade institutes" bsModal #institutionModal="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Institution auswählen</h4>

				<button type="button" class="close pull-right" aria-label="Close" (click)="institutionModal.hide()" *ngIf="canClose">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">

				<app-institutions-uebersicht
					[institutionenUndStatus]="institutionenUndStatus"
					*ngIf="isLoaded()"
					(select)="onSelect($event)"
				></app-institutions-uebersicht>

				<app-spinner *ngIf="!isLoaded()">></app-spinner>

			</div>
		</div>
	</div>
</div>
