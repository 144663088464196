<app-ausfueller-verwaltung-dialog #dialog></app-ausfueller-verwaltung-dialog>

<div class="container">
	<div class="row">
		<div class="col-xs-12 text-center">
			<h1>{{ fragebogen.name }}</h1>
			<button (click)="openDialog($event)"
					*ngIf="darfAntwortenUebernehmen() && hatFragebogenAntworten"
					class="btn btn-primary"
					title="Beim Klick auf ‚Antworten übernehmen‘ importiert das System automatisch die Angaben des Fragebogens aus dem Vorjahr in den aktuellen Fragebogen. Nicht mehr gültige Informationen löschen Sie anschließend innerhalb der JUVE Recherche. Vorsicht: Bereits in die JUVE Recherche eingegebene Informationen werden beim Import der archivierten Daten überschrieben."
					type="button">Antworten aus dem Vorjahr übernehmen
			</button>
			<div [innerHTML]="fragebogen.beschreibungLang | newlinesToParagraphs" class="beschreibung"></div>
		</div>
	</div>

	<div class="row abschnitt-liste">
		<div class="col-md-6 col-md-push-3">

			<div class="list-group">
				<li (click)="onSelectAbschnitt(abschnitt)" *ngFor="let abschnitt of abschnitte" class="abschnitt">

					<div class="text">
						<div class="title">
							<span class="link">{{ abschnitt.ueberschrift }}</span>
							<div *ngIf="istAbschnittVonAnderemUserGesperrt(abschnitt)" class="badge locked">
								<i class="icon-lock"></i>
								<span>In Bearbeitung</span>
							</div>
							<div *ngIf="istVonAktuellemUserInAndererInstanzGesperrt(abschnitt)"
								 class="badge locked-self">
								<i class="icon-lock"></i>
								<span>Selbst in Bearbeitung</span>
							</div>
							<div *ngIf="sindAlleFragenGeprueft(abschnitt)" class="badge alle-geprueft">
								<i class="icon-lock-success"></i>
								<span>Geprüft</span>
							</div>
						</div>
						<div [ngSwitch]="abschnittNochNichtBearbeitet(abschnitt)" class="modified-description">
							<span *ngSwitchCase="true">Noch nicht bearbeitet.</span>
							<ng-container *ngSwitchCase="false">
								<ng-container [ngSwitch]="isInBearbeitung(abschnitt)">
									<span
										*ngSwitchCase="true">In Bearbeitung durch {{ zeigeAktuellenBearbeiterAn(abschnitt) }}
										.</span>
									<span
										*ngSwitchCase="false">Zuletzt bearbeitet am {{ zeigeLetztenBearbeiterAn(abschnitt) }}
										.</span>
								</ng-container>
							</ng-container>
							<br/>
							<span class="subtitle">
								{{ getAnzahlFragen(abschnitt) | plural:'eine Frage':'Fragen' | capitalizeFirst }}<span
								*ngIf="getAnzahlFragen(abschnitt) > 0">,&nbsp;davon&nbsp;{{ getAnzahlGepruefterFragen(abschnitt) | plural:'eine geprüfte Frage':'geprüfte Fragen' }}
								.</span>
							</span>

						</div>
						<div class="modified-description" style="color: #333333; font-weight: normal">
							<span class="subtitle">
								<span>
									{{getRechteLabel(abschnitt)}}
								</span>
							</span>
						</div>
						<div class="description">
							<span *ngIf="abschnitt.beschreibung">{{ abschnitt.beschreibung }}</span>
						</div>
					</div>

					<div class="buttons-right" ngPreserveWhitespaces>
						<button (click)="abschnittsausfuellerVerwaltenDialogOeffnen(abschnitt)"
								*ngIf="istAbschnittsausfuellerVerwaltenSichtbar()"
								appPreventDefault
								class="btn btn-default"
								container="body"
								title="Hier können Sie weitere Bearbeiter für diesen Abschnitt einladen"
								type="button"
						>
							<i class="glyphicon glyphicon-user"></i> +
						</button>
						<button class="btn btn-primary" type="button">
							<i [ngClass]="iconClassForAbschnitt(abschnitt)"></i>
						</button>
					</div>
				</li>
			</div>
		</div>
	</div>

	<div *ngIf="showLowerBackLink()" class="row">
		<div class="col-md-6 col-md-push-3 zurueck bottom">
			<a (click)="onZurueckZurUebersichtClicked()" appPreventDefault href>« Zurück zur Übersicht</a>
		</div>
	</div>

	<div *ngIf="showControls" class="row controls col-md-12 text-center">
		<app-fragebogen-abschliessen *ngIf="zeigeFragebogenAbschliessen()"
									 [fragebogen]="fragebogen"></app-fragebogen-abschliessen>
		<app-fragebogen-aufschliessen *ngIf="zeigeFragebogenAufschliessen()"
									  [fragebogen]="fragebogen"></app-fragebogen-aufschliessen>
		<button *ngIf="zeigeDeaktiviertenAufschliessenButton()"
				class="btn btn-primary"
				disabled="disabled"
				title="Nach Ablauf der Abgabefrist können Fragebögen nicht wieder aufgeschlossen werden."
				type="button"
		>Fragebogen erneut bearbeiten
		</button>
		<button *ngIf="zeigeDeaktiviertenAbschliessenButton()"
				class="btn btn-primary"
				disabled="disabled"
				title="Nach Ablauf der Abgabefrist können Fragebögen nicht mehr übermittelt werden."
				type="button"
		>Fragebogen an JUVE übermitteln
		</button>

		<p-button (click)="print()"
				  icon="pi pi-print"
				  iconPos="left"
				  label="Druckvorschau"
				  class="btn btn-default bigButton noBackgroundFix"
				  type="button">
		</p-button>

<!--				<a class="btn btn-default"-->
<!--				   (click)="print()"-->
<!--				   href="fragebogen/{{ fragebogen.id }}/drucken" target="_blank">-->
<!--					<span aria-hidden class="glyphicon glyphicon-print"></span>&nbsp;Fragebogen drucken</a>-->
	</div>

	<div
		#modal="bs-modal"
		*ngIf="darfAntwortenUebernehmen() && hatFragebogenAntworten"
		appPreventDefault
		bsModal
		class="modal fade"
		role="dialog"
		tabindex="-1">
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<button (click)="closeDialog()" aria-label="Close" class="close" type="button">
						<span>&times;</span>
					</button>
					<h4 class="modal-title"><i class="glyphicon glyphicon-warning-sign" style="color: orange"></i>
						Daten importieren und aktuelle Daten überschreiben</h4>
				</div>
				<div class="modal-body clearfix">
					<p>Wenn Sie auf ‘Daten importieren’ klicken, werden alle bereits eingetragenen Informationen mit den
						Inhalten des im letzten Jahr eingereichten Fragebogens <b>überschrieben</b>.
					</p>
					<p>
						<b>Bitte beachten Sie</b>: Der Button kann beliebig oft benutzt werden, überschreibt dann aber
						immer wieder den
						Informationsstand im aktuellen Fragebogen.
					</p>
					<div class="btn-toolbar pull-right" ngPreserveWhitespaces role="toolbar">
						<button (click)="uebernehmeAntworten(fragebogen.id, institutionId)"
								class="btn btn-primary">Daten importieren und überschreiben
						</button>
						<button (click)="closeDialog()" class="btn btn-default">Abbrechen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-new-tab-container #vorschau *ngIf="fragebogen"></app-new-tab-container>

