import {Component, Input} from '@angular/core';
import {RecherchekomplexModalSteuerung} from 'app/form-viewer/modal/RecherchekomplexModalSteuerung';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {FragebogenStatusService} from 'app/form-viewer/service/FragebogenStatusService';
import {UserService} from 'app/form-viewer/service/UserService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {FragebogenStatus} from 'app/shared/model/FragebogenStatus';
import {Recherchekomplex} from 'app/shared/model/Recherchekomplex';
import {FragebogenService} from 'app/shared/service/FragebogenService';
import {NavigationService} from 'app/shared/service/NavigationService';

@Component({
	selector: 'app-viewer-portal-recherchekomplex',
	templateUrl: './ViewerPortalRecherchekomplexComponent.html',
	styleUrls: ['./ViewerPortalRecherchekomplexComponent.less']
})
export class ViewerPortalRecherchekomplexComponent {

	@Input() recherchekomplex: Recherchekomplex;

	@Input() recherchekomplexFrist: string;

	@Input() showSpeichernInfo: boolean;

	ausfueller: Ausfueller;

	frageboegen: Fragebogen[];

	abgeschlosseneFrageboegen: FragebogenStatus[];

	constructor(
		userService: UserService,
		fragebogenService: FragebogenService,
		fragebogenStatusService: FragebogenStatusService,
		private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
		private navigationService: NavigationService
	) {
		this.abgeschlosseneFrageboegen = [];
		this.frageboegen = [];

		this.ausfueller = userService.getAusfueller();
		fragebogenService.getOffeneFrageboegen().then(list => {
			this.frageboegen = list;
		});
		fragebogenStatusService.getAbgeschlosseneFrageboegen().then(list => {
			this.abgeschlosseneFrageboegen = list;
		});
	}

	darfCoAdminsVerwalten() {
		return this.ausfuellerBerechtigungsService.darfCoAdminsVerwalten();
	}

	goToFragebogen(fragebogen: Fragebogen) {
		this.navigationService.goToViewerFragebogen(fragebogen);
	}

	zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal(): boolean {
		return new RecherchekomplexModalSteuerung(this.recherchekomplex).zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal();
	}

	zeigeFrageboegenNichtWiederaufschliessbarModal(): boolean {
		return new RecherchekomplexModalSteuerung(this.recherchekomplex).zeigeFrageboegenNichtWiederaufschliessbarModal();
	}
}
