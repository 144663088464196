<div [ngClass]="spalte.name" class="zeilen col-lg-6">
	<div class="spalten-heading">{{ spalte.header }}</div>
	<div pDroppable="kartenFeld">
		<!-- [dragulaModel]="spalte.getFelder()" [dragula]="'KartenFelder'" -->
		<div *ngFor="let feld of spalte.getFelder()" [attr.data-feld-id]="feld.id" class="row zeile">
			<div pDraggable="kartenFeld" class="col-lg-1 karten-feld-drag-handle"></div>
			<div class="col-lg-10 panel panel-default">
				<div class="panel-heading">
					<input
						[(ngModel)]="feld.ueberschrift"
						class="form-control"
						name="ueberschrift"
						placeholder="Feldtitel"
					>
					<i
						*ngIf="feld.hatVorjahresfeld()"
						class="icon-link vorjahresfeld"
						title="Verknüpft mit {{feld.vorjahresfeld.ueberschrift}}"
					></i>
					<app-editor-info-text
						(infoTextChanged)="feld.onInfoTextChanged($event)"
						[beschreibung]="feld.beschreibung"
						[ueberschrift]="feld.ueberschrift"
					></app-editor-info-text>
				</div>
				<div class="panel-body">
					<app-input-type-edit
						(changed)="onTypeChanged(feld, $event)"
						[selectedType]="feld.type"
						displaySuffix="-Feld"
					></app-input-type-edit>
				</div>
			</div>
			<div class="col-lg-1">
				<button (click)="spalte.removeFeld(feld)" [disabled]="!spalte.canRemoveFeld()" class="btn btn-danger remove"><i
					class="icon-trash"></i></button>
			</div>
		</div>
	</div>
	<button (click)="spalte.addFeld()" class="btn btn-primary">Feld hinzufügen</button>
</div>
