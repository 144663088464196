<div class="abschnitt" *ngFor="let abschnitt of abschnitte; let index = index">
	<app-editor-abschnitt
		[abschnitt]="abschnitt"
		(abschnittChange)="abschnitte[index] = $event"
		(remove)="removeAbschnitt(abschnitt)"
		(duplicate)="duplicateAbschnitt(abschnitt)"
		(fragenUmsortiert)="saveAbschnittOnReorder($event)"
	></app-editor-abschnitt>
</div>

<button class="btn" (click)="addAbschnitt()" [disabled]="isReadOnly()"><i class="icon-plus"></i>Abschnitt hinzufügen</button>
