<button
	class="btn btn-xs"
	type="button"
	(click)="openModalClicked($event)"
	title="Fragebogen&nbsp;veröffentlichen"
	ngPreserveWhitespaces
>
	<i class="icon-lock-success"></i> Veröffentlichen
</button>

<div
	class="modal fade"
	bsModal
	#modal="bs-modal"
	tabindex="-1"
	role="dialog"
	appPreventDefault>
	<div class="modal-dialog modal-md">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" aria-label="Close" (click)="closeDialog()">
					<span>&times;</span>
				</button>
				<h4 class="modal-title">Fragebogen veröffentlichen: „{{ fragebogen.name }}”</h4>
			</div>
			<div class="modal-body clearfix">
				<p>Wollen Sie diesen Fragebogen wirklich veröffentlichen? Veröffentlichte Fragebögen können nicht mehr bearbeitet werden und
					sind für Ausfüller sichtbar.</p>

				<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
					<button class="btn btn-primary" (click)="commitVeroeffentlichen()">Fragebogen veröffentlichen</button>
					<button class="btn btn-default" (click)="closeDialog()">Nicht veröffentlichen</button>
				</div>
			</div>
		</div>
	</div>
</div>
