import {MehrfachauswahlFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/mehrfachauswahl/MehrfachauswahlFrageEditComponent';
import {MehrfachauswahlFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/mehrfachauswahl/MehrfachauswahlFrageViewComponent';
import {Frage} from 'app/shared/model/Frage';
import {MehrfachauswahlAntwort} from "../antwort/MehrfachauswahlAntwort";

export class MehrfachauswahlFrage extends Frage {
	static readonly TYPE_DISPLAY_NAME = 'Mehrfachauswahl';
	static readonly TYPE_ICON_NAME = 'multiselect';
	static readonly ID = 'MehrfachauswahlFrage';

	static readonly EDITOR = MehrfachauswahlFrageEditComponent;
	static readonly VIEWER = MehrfachauswahlFrageViewComponent;

	optionen: string[] = [];

	constructor(frage?: MehrfachauswahlFrage) {
		super(frage);

		if (frage) {
			this.constructFromMehrfachauswahlFrage(frage);
		}
	}

	private constructFromMehrfachauswahlFrage(frage: MehrfachauswahlFrage) {
		if (frage.optionen) {
			this.optionen = frage.optionen.slice();
		} else {
			this.optionen = [];
		}

		this.aeltesteAntwort = frage.aeltesteAntwort ? new MehrfachauswahlAntwort(frage.aeltesteAntwort as MehrfachauswahlAntwort) : null;
		this.neuesteAntwort = frage.neuesteAntwort ? new MehrfachauswahlAntwort(frage.neuesteAntwort as MehrfachauswahlAntwort) : null;
	}

	typeIconName() {
		return MehrfachauswahlFrage.TYPE_ICON_NAME;
	}

	typeDisplayName() {
		return MehrfachauswahlFrage.TYPE_DISPLAY_NAME;
	}

	isExportableAsCsv() {
		return false;
	}

	getAnzahlOptionen() {
		return this.optionen.length;
	}
}
