<div class="wrapper">
	<div class="container">
		<p-toast></p-toast>

		<div class="row">
			<div class="col-xs-12 hero">
				<div class="logo">
					<img src="/assets/portal/logo.png" width="160">
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md-6 col-md-offset-3">
				<div class="panel form-panel panel-default">

					<div class="panel-body">
									<span *ngIf="!loaded">
				<app-spinner></app-spinner>
			</span>
						<span *ngIf="loaded">

							<div class="row">
					<h2>Passwort zurücksetzen</h2>
					<form (submit)="passwortZuruecksetzen()" method="post" class="clearfix">

						<p>Bitte geben Sie Ihre E-Mail-Adresse in das folgende Feld ein. Informationen zum weiteren Vorgehen senden wir
							Ihnen anschließend per E-Mail an diese Adresse.</p>

						<!--{% if unbekannteEMailAdresse %}
							<div class="alert alert-danger offset-top" role="alert">
								Die eingegebene E-Mail-Adresse ist nicht bei JUVE Recherche registriert.
							</div>
						{% endif %}-->

						<div class="form-group">
							<label for="username">E-Mail-Adresse</label>
							<input type="email" class="form-control" id="username" name="username" [(ngModel)]="credentials.username"/>
						</div>

						<!--<input type="hidden"
							   name="{{ _csrf.parameterName }}"
							   value="{{ _csrf.token }}"/>-->
						<nav class="pull-left">
							<a [routerLink]="['/login']">
							   Zurück zum Login
							</a>
						</nav>
						<nav class="pull-right">
							<button class="btn btn-primary">Passwort zurücksetzen</button>
						</nav>

					</form>


							</div>
						</span>
					</div>

					<div class="panel-footer">
						<h3>Ihre Ansprechpartner:</h3>
						<div class="contacts">
							<app-contact [person]="'scherer'"></app-contact>
							<app-contact [person]="'mecke'"></app-contact>
							<app-contact [person]="'ossen'"></app-contact>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>
</div>
