import {Component, Input, OnInit} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputTypeGanzzahl} from "../../../model/frage/input/InputTypeGanzzahl";

@Component({
	selector: 'app-input-type-ganzzahl-edit',
	templateUrl: './InputTypeGanzzahlEditComponent.html',
	styleUrls: ['./InputTypeGanzzahlEditComponent.less']
})
export class InputTypeGanzzahlEditComponent implements OnInit, IInputTypeEditComponent<InputTypeGanzzahl> {
	@Input()
	inputType: InputTypeGanzzahl;

	platzhalterText = '';
	vorkommastellen = 0;

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
		this.vorkommastellen = this.inputType.vorkommastellen;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
		this.inputType.vorkommastellen = this.vorkommastellen;
	}
}
