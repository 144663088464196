import {SafeHtml} from "@angular/platform-browser";
import {InputType} from 'app/shared/model/frage/input/InputType';
import {UUID} from 'app/util/export-types';

const debug = require('debug')('InputTypeAntwort');

export abstract class InputTypeAntwort<T extends InputType> {
	id: UUID;
	type: string;
	input_id: UUID;

	constructor(antwort?: InputTypeAntwort<T>) {
		this.type = this.getType();

		if (antwort) {
			this.constructFromAntwort(antwort);
		}
	}

	getClass(): typeof InputTypeAntwort {
		return this.constructor as typeof InputTypeAntwort;
	}

	private constructFromAntwort(antwort: InputTypeAntwort<T>) {
		this.id = antwort.id;
		this.input_id = antwort.input_id;
	}

	abstract getType(): string;

	abstract isEmpty(): boolean;

	applyIds(antwort: InputTypeAntwort<any>) {
		this.id = antwort.id;
	}

	abstract getHtml(): SafeHtml;

	abstract istInhaltlichGleich(o: InputTypeAntwort<any>): boolean;

	isValid() {
		return true;
	}
}
