import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
	selector: '[appScrollToFocusedElement]',
})
export class ScrollToFocusedElementDirective {
	private FIXED_FOOTER_HEIGHT_PX = 75;
	private ADDITIONAL_PADDING_BOTTOM_PX = 10;

	constructor(private el: ElementRef) {
	}

	@HostListener('focus', ['$event'])
	scrollToFocusedElement() {
		const scrollDistance = this.calculateScrollDistance();

		if (scrollDistance > 0) {
			window.scrollBy(0, scrollDistance);
		}
	}

	getScreenHeight() {
		return window.innerHeight;
	}

	getVisibleScreenHeight() {
		return this.getScreenHeight() - this.FIXED_FOOTER_HEIGHT_PX;
	}

	calculateScrollDistance() {
		const distanceToBottom = this.getVisibleScreenHeight() - this.getElementBottom();
		return -distanceToBottom + this.ADDITIONAL_PADDING_BOTTOM_PX;
	}

	getElementBottom() {
		const relativePosition = this.el.nativeElement.getBoundingClientRect();
		return (relativePosition.height + relativePosition.top);
	}
}
