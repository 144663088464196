import {Injectable} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {AbschnittEditorService} from 'app/form-editor/service/AbschnittEditorService';

@Injectable()
export class FragenSortService {

	constructor(private abschnittService: AbschnittEditorService) {

	}

	speichereAbschnittListe(abschnitt_ids: string[], abschnitte: Abschnitt[]): Promise<any> {
		const abschnitteToSave = [];
		for (const abschnitt of abschnitte) {
			if (abschnitt_ids[0] === abschnitt.id || abschnitt_ids[1] === abschnitt.id) {
				abschnitteToSave.push(abschnitt);
			}
		}
		return this.saveAbschnittListe(abschnitteToSave).then(
			abschnitte_persistiert => {
				abschnitte_persistiert.forEach((abschnittPersistiert) => {
					this.replaceOldAbschnittWithPersistedOne(abschnittPersistiert, abschnitte);
				});
			}
		);
	}

	private replaceOldAbschnittWithPersistedOne(abschnittPersistiert: Abschnitt, abschnitteAlt: Abschnitt[]) {
		abschnitteAlt.forEach((abschnittAlt, index, abschnitteAltTmp) => {
			if (abschnittPersistiert.id === abschnittAlt.id) {
				abschnitteAltTmp[index] = abschnittPersistiert;
			}
		});
	}

	private saveAbschnittListe(abschnitte: Abschnitt[]): Promise<Abschnitt[]> {
		return this.abschnittService.saveMehrereAbschnitte(abschnitte);
	}
}
