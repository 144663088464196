import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromInstitution from "../reducer/institution.reducer";

export const selectInstitutionState = createFeatureSelector<fromInstitution.InstitutionState>(
	fromInstitution.institutionFeatureKey,
)

export const selectInstitution = createSelector(
	selectInstitutionState,
	(state: fromInstitution.InstitutionState) => state.institution
)
