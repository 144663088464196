<form #fragebogenMetadata="ngForm">
	<fieldset [attr.disabled]="(disabled) ? 'disabled' : null">
		<div class="form-group">
			<label for="{{ 'name' | newID }}" required>Fragebogen-Name&nbsp;<span class="required-marker">*</span></label>
			<input
				[(ngModel)]="name"
				class="form-control"
				id="{{ 'name' | lastID }}"
				maxlength="255"
				minlength="1"
				name="name" placeholder="z.B. Konzernsteuern 2017" required
			>
		</div>

		<div class="form-group">
			<label for="{{ 'beschreibungKurz' | newID }}" required>Kurzbeschreibung</label>
			<input
				[(ngModel)]="beschreibungKurz"
				class="form-control"
				id="{{ 'beschreibungKurz' | lastID }}"
				name="beschreibungKurz"
				placeholder="Beschreibende Stichpunkte welche auf der Portalseite unter dem Fragebbogen-Namen angezeigt werden."
			>
		</div>

		<div class="form-group">
			<label for="{{ 'beschreibungLang' | newID }}" required>Langbeschreibung</label>
			<textarea
				[(ngModel)]="beschreibungLang"
				class="form-control"
				id="{{ 'beschreibungLang' | lastID }}"
				name="beschreibungLang"
				placeholder="Ausführlicher, Merhzeiliger Beschreibungstext welcher in der Abschnittsansicht unter dem Abschnittstitel."
				rows="7"
			></textarea>
		</div>

		<div class="clearfix">
			<div class="btn-toolbar pull-right" ngPreserveWhitespaces role="toolbar">
				<button (click)="abbrechen.emit()"
						class="btn btn-default"
						type="button">Abbrechen
				</button>
				<button
					*ngFor="let actionName of submitButtonNames; let index = index"
					(click)="commitSave.emit(index)"
					[attr.disabled]="(fragebogenMetadata.form.valid) ? null : 'disabled'"
					class="btn btn-primary"
					type="button"
				>
					{{ actionName }}
				</button>
			</div>
		</div>
	</fieldset>
</form>
