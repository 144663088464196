import {Component} from '@angular/core';
import {TabelleViewComponentBase} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleViewComponentBase';

@Component({
	selector: 'app-tabelle-fix-view',
	templateUrl: './TabelleFixViewComponent.html',
	styleUrls: ['./TabelleFixViewComponent.less', '../tabelle-view.shared.less']
})
export class TabelleFixViewComponent extends TabelleViewComponentBase {

}
