import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BenutzerverwaltungComponent} from "./benutzerverwaltung/component/BenutzerverwaltungComponent";
import {
	RecherchekomplexBenutzerComponent
} from "./benutzerverwaltung/component/recherchekomplexe/recherchekomplex-benutzer/recherchekomplex-benutzer.component";
import {RecherchekomplexeComponent} from "./benutzerverwaltung/component/recherchekomplexe/recherchekomplexe.component";
import {EditorAntwortenComponent} from "./form-editor/antworten/EditorAntwortenComponent";
import {FormEditorComponent} from "./form-editor/FormEditorComponent";
import {EditorFragebogenUebersichtComponent} from "./form-editor/fragebogen-uebersicht/EditorFragebogenUebersichtComponent";
import {FormEditorVorschauComponent} from "./form-editor/vorschau/FormEditorVorschauComponent";
import {FormViewerWrapperComponent} from "./form-viewer/form-viewer-wrapper/FormViewerWrapperComponent";
import {FragebogenDruckenComponent} from "./form-viewer/fragebogen-drucken/FragebogenDruckenComponent";
import {ArchivComponent} from "./pages/archiv/ArchivComponent";
import {FaqComponent} from "./pages/faq/FaqComponent";
import {InitPageComponent} from "./pages/init/InitPageComponent";
import {PortalAllgemeinComponent} from "./pages/portal-allgemein/PortalAllgemeinComponent";
import {PortalComponent} from "./pages/portal/PortalComponent";
import {SicherheitComponent} from "./pages/sicherheit/SicherheitComponent";
import {TeamComponent} from "./pages/team/TeamComponent";
import {RegistrierungsWrapperComponent} from "./passwort-eingabe/RegistrierungsWrapperComponent";
import {UnbekannterCodeComponent} from "./passwort-eingabe/UnbekannterCodeComponent";
import {ForbiddenComponent} from "./security/403/ForbiddenComponent";
import {AuthGuardEditor} from "./security/AuthGuardEditor";
import {AuthGuardViewer} from "./security/AuthGuardViewer";
import {AuthGuardViewerRK} from "./security/AuthGuardViewerRK";

const appRoutes: Routes = [
	{
		path: '403',
		component: ForbiddenComponent
	},
	{
		path: '',
		redirectTo: '/init-page',
		pathMatch: 'full'
	},
	{
		path: 'init-page',
		component: InitPageComponent,
		pathMatch: 'full'
	},
	{
		path: 'portal-allgemein',
		component: PortalAllgemeinComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'archiv',
		component: ArchivComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'sicherheit',
		component: SicherheitComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'faq',
		component: FaqComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'benutzerverwaltung',
		component: BenutzerverwaltungComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'team',
		component: TeamComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'portal',
		component: PortalComponent,
		canActivate: [AuthGuardViewerRK]
	},
	{
		path: 'fragebogen/:fragebogenId',
		component: FormViewerWrapperComponent,
		canActivate: [AuthGuardViewerRK]
	},
	{
		path: 'fragebogen/:fragebogenId/drucken',
		component: FragebogenDruckenComponent,
		canActivate: [AuthGuardViewerRK]
	},
	{
		path: 'editor',
		redirectTo: '/editor/fragebogen',
		pathMatch: 'full'
	},
	{
		path: 'editor/fragebogen',
		component: EditorFragebogenUebersichtComponent,
		canActivate: [AuthGuardEditor]
	},
	{
		path: 'editor/fragebogen/:fragebogenId',
		component: FormEditorComponent,
		canActivate: [AuthGuardEditor]
	},
	{
		path: 'editor/fragebogen/:fragebogenId/antworten',
		component: EditorAntwortenComponent,
		canActivate: [AuthGuardEditor]
	},
	{
		path: 'editor/fragebogen/:fragebogenId/antworten/:institutionId',
		component: EditorAntwortenComponent,
		canActivate: [AuthGuardEditor]
	},
	{
		path: 'editor/fragebogen/:fragebogenId/vorschau',
		component: FormEditorVorschauComponent,
		canActivate: [AuthGuardEditor]
	},
	{
		path: 'benutzerverwaltung/recherchekomplexe',
		component: RecherchekomplexeComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'benutzerverwaltung/recherchekomplexe/:recherchekomplexId',
		component: RecherchekomplexBenutzerComponent,
		canActivate: [AuthGuardViewer]
	},
	{
		path: 'view/registrieren',
		component: RegistrierungsWrapperComponent,
		pathMatch: 'full'
	},
	{
		path: 'unbekannter-code',
		component: UnbekannterCodeComponent,
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: '/init-page',
		pathMatch: 'full',
	}
]

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, {anchorScrolling: 'enabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
