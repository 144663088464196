import {KartenFeld} from 'app/shared/model/frage/KartenFeld';
import {KartenFrageEditSpalte} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';

export class KartenFrageEditSpalteLinks extends KartenFrageEditSpalte {

	getFelder(): KartenFeld[] {
		return this.frage.felderLinks;
	}

	addFeld(): void {
		this.frage.addFeldLinks();
	}

	removeFeld(feld: KartenFeld): void {
		this.frage.removeFeldLinks(feld);
	}
}
