import {Component, Input} from '@angular/core';
import {EntscheidungsFrage} from 'app/shared/model/frage/EntscheidungsFrage';
import {IFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';

@Component({
	selector: 'app-editor-abschnitt-frage-edit-entscheidung',
	templateUrl: './EntscheidungsFrageEditComponent.html',
	styleUrls: ['./EntscheidungsFrageEditComponent.less']
})
export class EntscheidungsFrageEditComponent implements IFrageEditComponent<EntscheidungsFrage> {
	@Input()
	frage: EntscheidungsFrage;
}
