import {Component, Input, OnInit} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputTypeTelefon} from "app/shared/model/frage/input/InputTypeTelefon";

@Component({
	selector: 'app-input-type-telefon-edit',
	templateUrl: './InputTypeTelefonEditComponent.html',
	styleUrls: ['./InputTypeTelefonEditComponent.less']
})
export class InputTypeTelefonEditComponent implements OnInit, IInputTypeEditComponent<InputTypeTelefon> {
	@Input()
	inputType: InputTypeTelefon;

	platzhalterText = '';

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
