import {Component, Input, OnInit} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputTypeKommazahl} from "../../../model/frage/input/InputTypeKommazahl";

@Component({
	selector: 'app-input-type-kommazahl-edit',
	templateUrl: './InputTypeKommazahlEditComponent.html',
	styleUrls: ['./InputTypeKommazahlEditComponent.less']
})
export class InputTypeKommazahlEditComponent implements OnInit, IInputTypeEditComponent<InputTypeKommazahl> {
	@Input()
	inputType: InputTypeKommazahl;

	platzhalterText = '';
	vorkommastellen = 0;
	nachkommastellen = 2;

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
		this.vorkommastellen = this.inputType.vorkommastellen;
		this.nachkommastellen = this.inputType.nachkommastellen;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
		this.inputType.vorkommastellen = this.vorkommastellen;
		this.inputType.nachkommastellen = this.nachkommastellen;
	}
}
