import {Antwort} from 'app/shared/model/Antwort';
import {AntwortZeile} from 'app/shared/model/antwort/tabellenfrage/AntwortZeile';
import {ClientIdUtil} from 'app/shared/util/ClientIdUtil';

export class TabellenfragenAntwort extends Antwort {
	static readonly FRAGEN_TYPE_ID = 'TabellenFrage';

	zeilen: AntwortZeile[] = [];

	constructor(antwort?: TabellenfragenAntwort) {
		super(antwort);
		if (antwort) {
			this.constructFromAntwort(antwort);
		}
	}

	private constructFromAntwort(antwort: TabellenfragenAntwort) {
		this.zeilen = [];
		if (antwort.zeilen) {
			this.zeilen = [];
			for (let i = 0; i < antwort.zeilen.length; i++) {
				const zeile = new AntwortZeile(antwort.zeilen[i]);
				zeile.index = i;
				this.zeilen.push(zeile);
			}
		}
	}

	getGefuellteZeilen(): AntwortZeile[] {
		return this.zeilen.filter(z => !z.isEmpty());
	}

	deleteType() {
		super.deleteType();

		this.zeilen.forEach(zeile =>
			zeile.zellen.forEach(zelle =>
				delete zelle.antwort.type
			)
		);
	}

	applyIds(antwort: TabellenfragenAntwort) {
		super.applyIds(antwort);

		ClientIdUtil.applyIdsBasedOnClientId(antwort.zeilen, this.zeilen);
	}

	anzahlFehlendeZeilen(fixeZeilenAnzahl: number) {
		return fixeZeilenAnzahl - this.getZeilenAnzahl();
	}

	getZeilenAnzahl() {
		return this.zeilen.length;
	}

	addZeile(): AntwortZeile {
		const antwortZeile = new AntwortZeile();
		this.zeilen.push(antwortZeile);
		return antwortZeile;
	}

	isEmpty() {
		const nonEmptyZeilen = this.zeilen.filter(zeile => {
			const nonEmptyZellen = zeile.zellen.filter(zelle =>
				!zelle.antwort.isEmpty());

			return nonEmptyZellen.length > 0;
		});

		return nonEmptyZeilen.length === 0;
	}

	istInhaltlichGleich(o: Antwort): boolean {
		const trimmedThis = this.zeilen; // this.getGefuellteZeilen();
		const trimmedOther = (o as TabellenfragenAntwort).zeilen; // .getGefuellteZeilen();

		if (trimmedThis.length === trimmedOther.length) {
			return false;
		}

		for (let i = 0; i < trimmedThis.length; i++) {
			if (!trimmedThis[i].istInhaltlichGleich(trimmedOther[i])) {
				return false;
			}
		}

		return true;
	}

	isValid(): boolean {
		return this.zeilen.every(z => z.isValid());
	}
}
