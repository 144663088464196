import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';
import {UUID} from 'app/util/export-types';
import {tap} from 'rxjs/operators';
import {Ausfueller} from "../../form-viewer/model/Ausfueller";
import {FragebogenBuilderUtil} from "../util/FragebogenBuilderUtil";

@Injectable()
export class FragebogenService {

	constructor(
		private http: HttpClient,
		private alerts: AlertService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService
	) {
	}

	getFrageboegen(): Promise<Fragebogen[]> {
		return this.http.get<string>(`/api/editor/fragebogen`)
				   .toPromise()
				   .then(response => FragebogenBuilderUtil.buildList(response))
				   .catch(this.alerts.handleHttpError);
	}

	getFragebogenToEdit(id: string): Promise<Fragebogen> {
		return this.http.get<Fragebogen>(`/api/editor/fragebogen/${id}`)
				   .toPromise()
				   .then(response => new Fragebogen(response as Fragebogen))
				   .catch(this.alerts.handleHttpError);
	}

	getFragebogenVorschau(id: string): Promise<Fragebogen> {
		return this.http.get<Fragebogen>(`/api/editor/fragebogen/${id}/vorschau`)
				   .toPromise()
				   .then(response => new Fragebogen(response as Fragebogen))
				   .catch(this.alerts.handleHttpError);
	}

	getFragebogen(id: string): Promise<Fragebogen> {
		return this.http.get<Fragebogen>(`/view/fragebogen/${id}`, {params: this.aktuelleBerechtigungService.addCommonParams({})})
			.toPromise()
			.then(response => new Fragebogen(response as Fragebogen))
		   	.catch(this.alerts.handleHttpError);
	}

	getRecherchekomplexAdmins(id: string): Promise<Ausfueller[]> {
		return this.http.get<Ausfueller[]>(`/view/fragebogen/recherchekomplex_admins/${id}`, {params: this.aktuelleBerechtigungService.addCommonParams({})})
				   .toPromise()
				   .then(response => Ausfueller.buildList(response as Ausfueller[]))
				   .catch(this.alerts.handleHttpError);
	}

	saveFragebogen(fragebogen: Fragebogen): Promise<Fragebogen> {
		return this.http.post('/api/editor/fragebogen/edit', fragebogen)
			.toPromise()
			.then(response => {
				return new Fragebogen(response as Fragebogen);
			})
			.catch(this.alerts.handleHttpError);
	}

	getOffeneFrageboegen(): Promise<Fragebogen[]> {
		return this.http.get<Fragebogen[]>('/view/fragebogen/listOffen', {params: this.aktuelleBerechtigungService.addCommonParams({})})
			.toPromise()
			.then(response => {
				return response.map(fragebogen => new Fragebogen(fragebogen));
			})
			.catch(this.alerts.handleHttpError);
	}

	uebernehmeAntworten(fragebogenId: UUID, institutionId: UUID): Promise<void> {
		return this.http.post<void>('/view/fragebogen/antworten-uebernehmen', null, {
			params: {
				institution: institutionId,
				fragebogen: fragebogenId
			}
		})
			.pipe(tap(() => this.alerts.showSuccess('Antworten aus dem Vorjahr wurden erfolgreich übernommen.')))
			.toPromise()
			.catch(this.alerts.handleHttpError);
	}

	hatAbgeschlosseneFragebogenVersion(fragebogenId: UUID, institutionId: UUID): Promise<boolean> {
		return this.http.get<boolean>('/viewer/fragebogenstatus/hat-abgeschlossene-fragebogen-version', {
			params: {
				institution: institutionId,
				fragebogen: fragebogenId
			}
		})
			.toPromise()
			.catch(this.alerts.handleHttpError);
	}
}
