import {Component, ElementRef, ViewChild} from '@angular/core';
import {PasswordEingabeComponent} from 'app/passwort-eingabe/PasswordEingabeComponent';

@Component({
	selector: 'app-passwort-zuruecksetzen-formular',
	templateUrl: 'PasswordZuruecksetzenFormularComponent.html'
})
export class PasswordZuruecksetzenFormularComponent {
	username: string;
	code: string;

	@ViewChild('passwordEingabeComponent', { static: true })
	passwordEingabeComponent: PasswordEingabeComponent;

	@ViewChild('form', { static: true })
	form: ElementRef<HTMLFormElement>;

	constructor(private _elementRef: ElementRef) {
		const native = this._elementRef.nativeElement;

		this.username = native.getAttribute('username');
		this.code = native.getAttribute('code');
	}

	hasError(): boolean {
		return this.passwordEingabeComponent && this.passwordEingabeComponent.hasError();
	}

	getErrorMessage(): string {
		return this.hasError() ? this.passwordEingabeComponent.getErrorMessage() : '';
	}

	submit() {
		this.passwordEingabeComponent.validiere();

		if (!this.hasError()) {
			this.form.nativeElement.submit();
		}
	}
}
