import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AbschnittEditorService} from 'app/form-editor/service/AbschnittEditorService';

/* eslint-disable-next-line max-len */
import {AusfuellerVerwaltungDialogComponent} from 'app/form-viewer/ausfueller-verwaltung/dialog/AusfuellerVerwaltungDialogComponent';
/* eslint-disable-next-line max-len */
import {AbschnittsausfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/abschnittsausfueller/AbschnittsausfuellerVerwaltungFormConfiguration';
import {AusfuellerBerechtigungsService} from 'app/form-viewer/service/AusfuellerBerechtigungsService';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AbschnittSaveEventServiceService} from "../service/AbschnittSaveEventServiceService";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: 'app-viewer-abschnitt-navbar',
	templateUrl: './ViewerAbschnittNavbarComponent.html',
	styleUrls: ['./ViewerAbschnittNavbarComponent.less'],
	providers: [AbschnittEditorService],
})
export class ViewerAbschnittNavbarComponent {

	@BlockUI()
	blockUI: NgBlockUI;

	@Input()
	abschnitt: Abschnitt;

	@Input()
	fragebogen: Fragebogen;

	@Input()
	abgeschlossen: boolean;

	@Output()
	back = new EventEmitter();

	@ViewChild('dialog', {static: true})
	dialog: AusfuellerVerwaltungDialogComponent;

	private saving: boolean;

	constructor(private previewService: PreviewService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private abschnSaveSvc: AbschnittSaveEventServiceService) {
	}

	ngOnInit() {
		this.abschnSaveSvc.savingEventListner().subscribe(info => {
			// console.log(info); // here you get the message from Child component
			this.saving = JSON.parse(String(info));
		})
	}

	onZurueckZurUebersichtClicked() {
		try {
			this.blockUI.start('Daten werden verarbeitet...');
			this.back.emit();
		} finally {
			setTimeout(() => {
				this.blockUI.stop();
			}, 500);
		}
	}

	istAbschnittsausfuellerVerwaltenAktiv() {
		return this.previewService.isNotInPreview() &&
			!this.abgeschlossen &&
			this.ausfuellerBerechtigungsService.darfAbschnittsausfuellerFuerFragebogenVerwalten(this.fragebogen);
	}

	abschnittsausfuellerVerwaltenDialogOeffnen() {
		this.dialog.show(
			new AbschnittsausfuellerVerwaltungFormConfiguration(this.abschnitt, this.fragebogen)
		);
	}

	isSaving() {
		return this.saving;
	}
}
