<ng-template #defaultOptionTemplate let-option>
	{{ option }}
</ng-template>

<div class="combo form-control"
	 [class.open]="isOpen"
	 [class.notouch]="!isTouch"
	 [class.touch]="isTouch"
	 [class.disabled]="disabled"
	 tabindex="0"
	 appScrollToFocusedElement
	 (click)="toggle(); $event.stopPropagation()"
	 (blur)="close()"
	 (keydown)="onKeyDown($event)"
	 (keyup)="onKeyUp($event)"
>
	<select
		[(ngModel)]="wert"
		[disabled]="disabled"
		id="{{ id }}"
		appScrollToFocusedElement
	>
		<option *ngFor="let option of options" [ngValue]="option">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': option}"
			></ng-template>
		</option>
	</select>

	<div class="label">
		<div class="content" [class.placeholder]="showPlaceholder()">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': showPlaceholder() ? placeholder : wert}"
			></ng-template>
		</div>

		<i class="icon-chevron-down"></i>

		<ul class="flyout list-unstyled" *ngIf="isOpen">
			<li
				*ngFor="let option of options"
				(click)="onWertSelected(option); $event.stopPropagation()"
				[class.selected]="isWertSelected(option)"
				[class.highlighted]="isWertSelected(option)"
			>
				<ng-template
					[ngTemplateOutlet]="optionTemplate"
					[ngTemplateOutletContext]="{'$implicit': option}"
				></ng-template>
			</li>
		</ul>
	</div>
</div>
