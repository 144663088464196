import {Component} from '@angular/core';

@Component({
	selector: 'app-registrierungs-wrapper',
	templateUrl: './RegistrierungsWrapperComponent.html',
    styleUrls: ['RegistrierungsWrapperComponent.less']
})
export class RegistrierungsWrapperComponent {

}
