<div class="fragebogen-editor">
	<app-abschnitt-sortieren-dialog
		#abschnittSortierenDialog
		[abschnitte]="abschnitteWithId()"
		(abschnitteChange)="commitMove($event)"
	></app-abschnitt-sortieren-dialog>

	<app-editor-fragebogen-metadata-dialog
		#fragebogenMetadataDialog
		[(fragebogen)]="fragebogen">

	</app-editor-fragebogen-metadata-dialog>

	<div class="container">
		<app-form-editor-header
			[fragebogen]="fragebogen"
			[enableMove]="canMove()"
			(beginMove)="beginMove()"
			(beginEditMetadata)="beginEditMetadata()">

		</app-form-editor-header>

		<div class="col-xs-12">
			<app-alert></app-alert>

			<span *ngIf="!isLoaded()" style="width: 100%; height: 100%">
				<app-spinner></app-spinner>
			</span>

			<app-editor-abschnitt-liste
				[fragebogen]="fragebogen"
				[abschnitte]="abschnitte"
				*ngIf="isLoaded()">

			</app-editor-abschnitt-liste>
		</div>
	</div>
</div>
