import {Injectable} from '@angular/core';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';
import {UserService} from 'app/form-viewer/service/UserService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Berechtigung} from 'app/form-viewer/model/Berechtigung';
import {UUID} from 'app/util/export-types';
import {CacheUtil} from 'app/form-viewer/util/CacheUtil';

@Injectable()
export class AusfuellerAbgabezeitpunktService {
	private cache = new CacheUtil();

	private static getFirstOrNull<T>(list: T[]): T {
		return list.length > 0 ? list[0] : null;
	}

	constructor(
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private userService: UserService
	) {
	}

	clearCache() {
		this.cache.clear();
	}

	private findeBerechtigungFuerAbschnitt(abschnittId: UUID): Berechtigung {

		return AusfuellerAbgabezeitpunktService.getFirstOrNull(
			this.userService.getAusfueller().berechtigungen
				.filter(b => b.gehoertZu(
					this.aktuelleBerechtigungService.getInstitutionsId(),
					this.aktuelleBerechtigungService.getRecherchekomplexId()))
				.filter(b => b.type === 'ABSCHNITTSAUSFUELLER' && b.fuerAbschnitt.id === abschnittId)
		)


		// return this.cache.getOrCalculateAndSave('findeBerechtigungFuerAbschnitt-' + abschnittId, () =>
		// 	AusfuellerAbgabezeitpunktService.getFirstOrNull(
		// 		this.userService.getAusfueller().berechtigungen
		// 			.filter(b => b.gehoertZu(
		// 				this.aktuelleBerechtigungService.getInstitutionsId(),
		// 				this.aktuelleBerechtigungService.getRecherchekomplexId()))
		// 			.filter(b => b.type === 'ABSCHNITTSAUSFUELLER' && b.fuerAbschnitt.id === abschnittId)
		// 	)
		// );
	}

	private findeBerechtigungFuerFragebogen(fragebogenId: UUID) {

		return AusfuellerAbgabezeitpunktService.getFirstOrNull(
			this.userService.getAusfueller().berechtigungen
				.filter(b => b.gehoertZu(
					this.aktuelleBerechtigungService.getInstitutionsId(),
					this.aktuelleBerechtigungService.getRecherchekomplexId()))
				.filter(b => b.type === 'FRAGEBOGENAUSFUELLER' && b.fuerFragebogen.id === fragebogenId)
		)

		// return this.cache.getOrCalculateAndSave('findeBerechtigungFuerFragebogen-' + fragebogenId, () =>
		// 	AusfuellerAbgabezeitpunktService.getFirstOrNull(
		// 		this.userService.getAusfueller().berechtigungen
		// 			.filter(b => b.gehoertZu(
		// 				this.aktuelleBerechtigungService.getInstitutionsId(),
		// 				this.aktuelleBerechtigungService.getRecherchekomplexId()))
		// 			.filter(b => b.type === 'FRAGEBOGENAUSFUELLER' && b.fuerFragebogen.id === fragebogenId)
		// 	)
		// );
	}

	getAbgabefrist(fragebogen: Fragebogen, abschnitt?: Abschnitt): { frist: string, typ: string } {
		if (abschnitt) {
			const abschnittBerechtigung = this.findeBerechtigungFuerAbschnitt(abschnitt.id);

			if (abschnittBerechtigung) {
				return {frist: abschnittBerechtigung.abgabefrist, typ: 'Abschnitt'};
			}
		}

		const fragebogenBerechtigung = this.findeBerechtigungFuerFragebogen(fragebogen.id);
		if (fragebogenBerechtigung) {
			return {frist: fragebogenBerechtigung.abgabefrist, typ: 'Fragebogen'};
		}

		return {frist: null, typ: null};
	}
}
