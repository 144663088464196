import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {FragebogenService} from "../../shared/service/FragebogenService";

@Component({
			   selector: 'app-form-editor-vorschau',
			   templateUrl: './FormEditorVorschauComponent.html',
		   })
export class FormEditorVorschauComponent implements OnInit {

	fragebogen: Fragebogen;

	constructor(private route: ActivatedRoute, private fragebogenService: FragebogenService) {
	}

	ngOnInit(): void {
		const fragebogenId = this.route.snapshot.paramMap.get('fragebogenId');
		this.fragebogenService.getFragebogenVorschau(fragebogenId).then(response => this.fragebogen = response);
	}

}
