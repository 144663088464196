import {ComponentPortal, ComponentType, DomPortalOutlet} from '@angular/cdk/portal';
import {ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, Injector} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {OAuthEvent} from "angular-oauth2-oidc/events";

@Component({
			   selector: 'app-new-tab-container',
			   template: ''
		   })
export class NewTabComponent {

	static portal: DomPortalOutlet;
	static attached: ComponentRef<unknown>;
	static externalWindow: Window;

	private static cleanup() {
		NewTabComponent.externalWindow?.close();
		NewTabComponent.portal?.dispose();
		NewTabComponent.attached?.destroy();
	}

	constructor(private r: ComponentFactoryResolver, private applicationRef: ApplicationRef, private injector: Injector,private oauthService:OAuthService) {
		this.oauthService.events.subscribe((s:OAuthEvent)=>{
			switch (s.type){
				case "logout":
					NewTabComponent.cleanup();
			}
		})
	}

	openContent(component: ComponentType<unknown>, title: string): void {
		setTimeout(() => {
			NewTabComponent.cleanup();

			NewTabComponent.externalWindow = window.open('', '_blank');

			this.fillHeader(title);

			new MutationObserver(list => {
				for (const mutationRecord of list) {
					mutationRecord.addedNodes.forEach(addedNode => {
						if (addedNode.nodeName.toLowerCase() === 'style') {
							NewTabComponent.externalWindow.document.head.appendChild(addedNode.cloneNode(true));
						}
					});
				}
			}).observe(document.head, {attributes: true, childList: true, subtree: true});

			const containerPortal = new ComponentPortal(component, null, this.injector);
			NewTabComponent.portal = new DomPortalOutlet(NewTabComponent.externalWindow.document.body, this.r, this.applicationRef, this.injector);
			NewTabComponent.attached = NewTabComponent.portal.attach(containerPortal);

			NewTabComponent.externalWindow.addEventListener('beforeunload', (event) => {
				console.log('disposing...');
				NewTabComponent.portal.dispose();

				console.log('destroying...');
				NewTabComponent.attached.destroy();
			});
		});
	}

	private fillHeader(title: string) {
		const iconElement = document.createElement('link');
		const styleSheetElement = document.createElement('link');

		document.querySelectorAll('link').forEach(htmlElement => {
			if (htmlElement.rel === 'icon') {
				const absoluteUrl = new URL(htmlElement.href).href;
				iconElement.rel = 'icon';
				iconElement.type = 'image/x-icon';
				iconElement.href = absoluteUrl;
			}
			if (htmlElement.rel === 'stylesheet') {
				const absoluteUrl = new URL(htmlElement.href).href;
				styleSheetElement.rel = 'stylesheet';
				styleSheetElement.href = absoluteUrl;
			}
		});

		NewTabComponent.externalWindow.document.head.appendChild(iconElement);
		NewTabComponent.externalWindow.document.head.appendChild(styleSheetElement);

		const tags = [];
		document.querySelectorAll('style').forEach(htmlElement => {
			const styleTag = document.createElement('style');
			styleTag.innerHTML = htmlElement.innerHTML;
			tags.push(styleTag);
		});
		tags.forEach(t => {
			NewTabComponent.externalWindow.document.head.appendChild(t);
		});

		const titleElement = document.createElement('title');
		titleElement.innerText = title;
		NewTabComponent.externalWindow.document.head.appendChild(titleElement);
	}

}
