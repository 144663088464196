<nav class="navbar navbar-default">
	<div class="container-fluid">
		<div class="navbar-header">
			<span class="navbar-brand" [class.disabled]="isReadOnly()">{{ abschnitt.ueberschrift }}</span>
		</div>

		<div class="collapse navbar-collapse">
			<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
				<button (click)="beginEdit()" type="button" [disabled]="isReadOnly()" class="btn btn-primary btn-xs navbar-btn"><i
					class="icon-edit"></i></button>
				<button (click)="beginDuplicate()" type="button" [disabled]="isReadOnly()" class="btn btn-primary btn-xs navbar-btn"><i
					class="icon-copy"></i></button>
			</div>
		</div>
	</div>
</nav>
