import {Component, Input, ViewChild} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeGanzzahlAntwort} from "../../../../../../../shared/model/antwort/input/InputTypeGanzzahlAntwort";
import {InputTypeGanzzahl} from "../../../../../../../shared/model/frage/input/InputTypeGanzzahl";

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: 'app-input-type-ganzzahl-view',
	templateUrl: './InputTypeGanzzahlViewComponent.html',
	styleUrls: ['./InputTypeGanzzahlViewComponent.less']
})
export class InputTypeGanzzahlViewComponent implements IInputTypeViewComponent<InputTypeGanzzahl> {

	context = {};

	variant: string;

	@ViewChild('tooltip') tooltip;

	@Input()
	antwort: InputTypeGanzzahlAntwort;

	@Input()
	type: InputTypeGanzzahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getMaxLength(): number {
		return this.type.vorkommastellen ? this.type.vorkommastellen : null;
	}

	showTooltipIfMaxlength(value) {
		if (this.getMaxLength() && Math.floor(Math.log10(Number(value))) + 1 > this.getMaxLength()) {
			this.tooltip.show();
		} else {
			this.tooltip.hide();
		}
	}

	variantClass(): string {
		return this.variant;
	}
}
