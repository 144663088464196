export class ArrayUtil {
	static isNonEmptyArray(obj: any): boolean {
		return Array.isArray(obj) && obj.length > 0;
	}

	static contains<T>(array: Array<T>, obj: T): boolean {
		// array.includes could work but has no IE support
		return array.indexOf(obj) !== -1;
	}

	static replaceContent<T>(targetArray: T[], newContent: T[]) {
		targetArray.splice(0, targetArray.length, ...newContent);
	}

}
