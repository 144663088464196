import {createAction} from '@ngrx/store';
import {Recherchekomplex} from "../../shared/model/Recherchekomplex";

export const loadRecherchekomplexe = createAction(
  '[Recherchekomplex] Load Recherchekomplexs'
);

export const setRecherchekomplex = createAction(
	'[Recherchekomplex] Add Recherchekomplex',
	(recherchekomplex: Recherchekomplex) => ({recherchekomplex})
)
