import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {FrageTypes} from 'app/shared/model/FrageTypes';
import {FrageBuilderUtil} from 'app/shared/util/FrageBuilderUtil';

@Component({
	selector: 'app-editor-abschnitt-frage-edit',
	templateUrl: './FrageEditComponent.html',
	styleUrls: ['./FrageEditComponent.less']
})
export class FrageEditComponent implements OnInit {
	@Input()
	index: number;

	@Input()
	frage: Frage;

	@Output()
	commit = new EventEmitter<Frage>();

	@Output()
	cancel = new EventEmitter();

	@ViewChild('frageEditor', { read: ViewContainerRef, static: true })
	frageEditor: ViewContainerRef;

	types: typeof Frage[];

	constructor(private componentFactoryResolver: ComponentFactoryResolver) {
	}

	zeigeCheckboxInhaltlichGeaendert(): boolean {
		return !!this.frage && !!this.frage.vorjahresfrage;
	}

	ngOnInit(): void {
		this.types = FrageTypes.TYPES;
		this.updateEditor();
	}

	isNew() {
		return !this.frage.id;
	}

	isCommitDisabled() {
		return !this.hasType();
	}

	hasType() {
		return !!this.frage.type;
	}

	changeFrageType() {
		this.frage = FrageBuilderUtil.build(this.frage);
		this.updateEditor();
	}

	commitEdit() {
		this.commit.emit(this.frage);
	}

	cancelEdit() {
		this.cancel.emit();
	}

	private updateEditor() {
		this.frageEditor.clear();

		if (this.frage.type) {
			const frageType: typeof Frage = FrageTypes.typeForID(this.frage.type);

			const factory = this.componentFactoryResolver.resolveComponentFactory(frageType.EDITOR);
			const component = this.frageEditor.createComponent(factory);
			component.instance.frage = this.frage;
		}
	}
}
