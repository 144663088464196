import {createAction} from '@ngrx/store';
import {Institution} from "../../shared/model/Institution";

export const loadInstitutions = createAction(
  '[Institution] Load Institutions'
);

export const setInstitution = createAction(
	'[Institution] Add Institution',
	(institution: Institution) => ({institution})
)


