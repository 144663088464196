<ng-template #beschreibungPopover>
	<div class="beschreibung-popover">
		<div class="form-group">
			<div class="tooltip-hint">Tooltip-Text</div>
			<textarea (keydown.escape)="resetDescription($event)" rows="5" [(ngModel)]="beschreibung"
					  appPreventSubmitOnEnter></textarea>
		</div>
		<div class="clearfix">
			<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
				<button class="btn btn-danger" (click)="resetDescription($event)">Abbrechen</button>
				<button class="btn btn-primary" (click)="saveDescription()">Übernehmen</button>
			</div>
		</div>
	</div>
</ng-template>

<span
	class="info-icon glyphicon glyphicon-info-sign"
	[ngClass]="{'blau' : hasBeschreibung()}"
	[popover]="beschreibungPopover"
	popoverTitle="Hilfestellung für '{{ueberschrift}}' bearbeiten"
	placement="bottom"
	#pop="bs-popover"
></span>
