import {Component, Input} from '@angular/core';
import {TabellenSpalte} from 'app/shared/model/frage/TabellenSpalte';

@Component({
	selector: 'app-editor-abschnitt-frage-edit-tabelle-title',
	templateUrl: './TabelleFrageTitleEditComponent.html',
	styleUrls: ['./TabelleFrageTitleEditComponent.less']
})
export class TabelleFrageTitleEditComponent {

	@Input()
	spalte: TabellenSpalte;

	constructor() {
	}

	infoTextChanged(beschreibung: string) {
		this.spalte.beschreibung = beschreibung;
	}
}
