<div [class.input-group]="hasEditor()">
	<select
		class="form-control"
		[(ngModel)]="selectedTypeId"
		(change)="onTypeSelected()"
		name="type"
	>
		<option *ngFor="let type of getTypes()" value="{{ type.ID }}">{{ getTypeDisplayName(type) }}</option>
	</select>
	<span class="input-group-btn" *ngIf="hasEditor()">
		<button class="btn btn-default" type="button" (click)="openEditor()"><i class="icon-cog"></i></button>
	</span>
</div>


<div class="modal fade"
	 bsModal
	 #optionsModal="bs-modal"
	 tabindex="-1"
	 role="dialog"
	 aria-hidden="true"
	 (keydown.escape)="closeEditor($event)"
>
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">{{ editorTitel }}</h4>
				<button type="button" class="close pull-right" aria-label="Close" (click)="closeEditor()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<ng-container #editor></ng-container>

				<div class="clearfix">
					<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
						<button class="commit" class="pull-right btn btn-primary" (click)="commitSave()" type="button">Übernehmen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
