import {Component, Input} from '@angular/core';
import {CoAdminAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/CoAdminAnlegenDto';
import {AusfuellerVerwaltungInnerFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungInnerFormComponent';
import {ControlContainer, NgForm} from '@angular/forms';
import {CoAdminVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/co-admin/CoAdminVerwaltungFormConfiguration';

@Component({
	selector: 'app-coadmin-verwaltung-form',
	templateUrl: './CoAdminVerwaltungFormComponent.html',
	styleUrls: ['./CoAdminVerwaltungFormComponent.less'],
	viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class CoAdminVerwaltungFormComponent implements AusfuellerVerwaltungInnerFormComponent {
	@Input()
	dto: CoAdminAnlegenDto;

	@Input()
	disabled = false;

	@Input()
	configuration: CoAdminVerwaltungFormConfiguration;

}
