import {KartenFeld} from 'app/shared/model/frage/KartenFeld';
import {KartenFeldAntwort} from 'app/shared/model/antwort/KartenFeldAntwort';

export class KartenFeldAntwortTupel {
	feld: KartenFeld;
	antwort: KartenFeldAntwort;

	constructor(feld: KartenFeld, antwort: KartenFeldAntwort) {
		this.feld = feld;
		this.antwort = antwort;
	}
}
