import {InputTypeAuswahlViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-auswahl-view/InputTypeAuswahlViewComponent';
import {InputTypeAuswahlEditComponent} from 'app/shared/components/input-type/auswahl/InputTypeAuswahlEditComponent';
import {InputTypeAuswahlAntwort} from 'app/shared/model/antwort/input/InputTypeAuswahlAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';

export class InputTypeAuswahl extends InputType {
	static readonly ID = 'InputTypeAuswahl';

	static readonly TYPE_DISPLAY_NAME = 'Auswahl';
	static readonly ANTWORT = InputTypeAuswahlAntwort;

	static readonly VIEWER = InputTypeAuswahlViewComponent;
	static readonly EDITOR = InputTypeAuswahlEditComponent;

	options: string[] = [];
	sonstigesTextfeld = false;

	constructor(type?: InputTypeAuswahl) {
		super(type);
		if (type) {
			this.options = type.options;
			this.sonstigesTextfeld = type.sonstigesTextfeld;
		}
	}
}
