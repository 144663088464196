import {Component, Input, OnInit} from '@angular/core';
import {IFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {KartenFrageEditSpalteRechts} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalteRechts';
import {KartenFrageEditSpalte} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalte';
import {KartenFrageEditSpalteLinks} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/karten/spalte/KartenFrageEditSpalteLinks';
import {KartenFeld} from 'app/shared/model/frage/KartenFeld';
import {UUID} from 'app/util/export-types';
import {ControlContainer, NgForm} from '@angular/forms';

const debug = require('debug')('KartenFrageEditComponent');

@Component({
	selector: 'app-editor-abschnitt-frage-edit-karten',
	templateUrl: './KartenFrageEditComponent.html',
	styleUrls: ['./KartenFrageEditComponent.less'],
	viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})
export class KartenFrageEditComponent implements IFrageEditComponent<KartenFrage>, OnInit {

	@Input()
	frage: KartenFrage;

	spalteLinks: KartenFrageEditSpalte;
	spalteRechts: KartenFrageEditSpalte;

	constructor() {
	}

	ngOnInit() {
		this.spalteLinks = new KartenFrageEditSpalteLinks(this.frage, 'links', 'Linke Spalte');
		this.spalteRechts = new KartenFrageEditSpalteRechts(this.frage, 'rechts', 'Rechte Spalte');

		if (!this.frage.hasFelder()) {
			this.spalteLinks.addFeld();
			this.spalteRechts.addFeld();
		}
	}

	private findFeld(id: UUID): KartenFeld {
		const matchedLinks = this.spalteLinks.findFeldById(id);
		const matchedRechts = this.spalteRechts.findFeldById(id);

		if (matchedLinks) {
			return matchedLinks;
		} else if (matchedRechts) {
			return matchedRechts;
		} else {
			return null;
		}
	}
}
