import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {AusfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import {AusfuellerAnlegenDto} from 'app/form-viewer/ausfueller-verwaltung/model/AusfuellerAnlegenDto';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

const debug = require('debug')('service:ajax:AusfuellerAnlegenService');

@Injectable()
export class AusfuellerAnlegenService {
	constructor(private http: HttpClient, private alertService: AlertService, private aktuelleBerechtigungService: AktuelleBerechtigungService) {
	}

	anlegen(configuration: AusfuellerVerwaltungFormConfiguration, dto: AusfuellerAnlegenDto, params?: any): Promise<void> {
		debug('Speichere Benutzer/Recht', dto.toString());
		return this.http.post<void>('/view/ausfueller/' + configuration.getWebserviceEndpoint(), dto, {
			params: params ? params : this.aktuelleBerechtigungService.addCommonParams({})
		})
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
