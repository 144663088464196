<ng-template #defaultOptionTemplate let-options>
	{{ options.join(', ') }}
</ng-template>

<div class="combo form-control"
	 [class.open]="isOpen"
	 [class.notouch]="!isTouch"
	 [class.touch]="isTouch"
	 [class.disabled]="disabled"
	 tabindex="0"
	 appScrollToFocusedElement
	 (click)="toggle(); $event.stopPropagation()"
	 (blur)="close()"
	 (keydown)="onKeyDown($event)"
	 (keyup)="onKeyUp($event)"
>
	<select
		[(ngModel)]="wert"
		[disabled]="disabled"
		id="{{ id }}"
		multiple
		appScrollToFocusedElement
	>
		<option *ngFor="let option of options" [ngValue]="option">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': [option]}"
			></ng-template>
		</option>
	</select>

	<div class="label">
		<div class="content">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': wertAsArray}"
			></ng-template>
		</div>

		<i class="icon-chevron-down"></i>

		<ul class="flyout list-unstyled">
			<li
				*ngFor="let option of options; let idx = index"
				(click)="onWertClicked(option); $event.stopPropagation()"
				[class.selected]="isWertSelected(option)"
				[class.highlighted]="isHighlighted(idx)"
			>
				<div class="checkbox">
					<input type="checkbox" id="{{ 'multicheck' | newID }}" [checked]="isWertSelected(option)"
						   (click)="$event.preventDefault()" [title]="idx">
					<label for="{{ 'multicheck' | lastID }}" (click)="$event.preventDefault()">
						<ng-template
							[ngTemplateOutlet]="optionTemplate"
							[ngTemplateOutletContext]="{'$implicit': [option]}"
						></ng-template>
					</label>
				</div>
			</li>
		</ul>
	</div>
</div>
