import {InputType} from 'app/shared/model/frage/input/InputType';
import {UUID} from 'app/util/export-types';
import {InputTypeBuilderUtil} from 'app/shared/util/InputTypeBuilderUtil';
import {InputTypeText} from 'app/shared/model/frage/input/InputTypeText';

const debug = require('debug')('TabellenSpalte');

export class TabellenSpalte {
	id: string;
	ueberschrift = '';
	beschreibung = '';
	type: InputType;
	frage_id: UUID;
	vorjahresTabellenFrageSpalte: TabellenSpalte;

	constructor(spalte?: TabellenSpalte) {
		if (spalte) {
			this.constructFromSpalte(spalte);
		} else {
			this.constructEmpty();
		}
	}

	public getId(): string {
		return this.id;
	}

	clearIds() {
		this.id = null;
		this.frage_id = null;
		this.type.clearIds();
	}

	hatVorjahresSpalte(): boolean {
		return !!this.vorjahresTabellenFrageSpalte;
	}

	private constructFromSpalte(spalte: TabellenSpalte) {
		this.id = spalte.id;
		this.ueberschrift = spalte.ueberschrift;
		this.beschreibung = spalte.beschreibung;
		this.type = InputTypeBuilderUtil.build(spalte.type);
		this.frage_id = spalte.frage_id;
		this.vorjahresTabellenFrageSpalte = spalte.vorjahresTabellenFrageSpalte;
	}

	private constructEmpty() {
		this.type = new InputTypeText();
		debug('constructEmpty with type=', this.type);
	}
}
