<app-header></app-header>

<p-card header="Zum Recherchekomplex eingeladene Benutzer">

	<p-table #dt2
			 [value]="ausfuellerListe"
			 [paginator]="true"
			 [rows]="rows"
			 [showCurrentPageReport]="true"
			 [(selection)]="selectedValue"
			 selectionMode="single"
			 sortField="anzeigename"
			 sortOrder="1"
			 dataKey="anzeigename"
			 styleClass="p-datatable-striped">

		<ng-template pTemplate="caption" class="pull-right" style="margin-right: 10px;">
			{{getAnzahlRecherchekomplexBenutzer()}} Benutzer für den Recherchekomplex " {{recherchekomplexName}} "
			gefunden
		</ng-template>

		<ng-template pTemplate="header">
			<tr>
				<th *ngFor="let col of cols" pSortableColumn="{{col.field}}">{{col.header}}
					<p-sortIcon field="{{col.field}}"></p-sortIcon>
				</th>
				<th style="width: 5rem"></th>
			</tr>
			<tr>
				<th *ngFor="let col of cols">
				<span class="p-input-icon-left p-column-filter-row">
				<i class="pi pi-filter"></i>
				<input (input)="dt2.filter($any($event.target).value, col.field, col.filterMatchMode=col.filterTyp)"
					   pInputText
					   type="text"
					   class="p-column-filter-row">
				</span>
				<th></th>
			</tr>
		</ng-template>

		<ng-template let-recherchekomplexBenutzer pTemplate="body">
			<tr style="cursor: pointer;" (dblclick)="zeigeBenutzerrechte(recherchekomplexBenutzer)">
				<td>{{recherchekomplexBenutzer.anrede}}</td>
				<td>{{recherchekomplexBenutzer.anzeigename}}</td>
				<td>{{recherchekomplexBenutzer.email}}</td>
				<td>{{recherchekomplexBenutzer.institutionUndKomplex.recherchekomplexLabel}}</td>
				<td title="{{recherchekomplexBenutzer.statusEnabledOderArchiviertTitle}}">{{recherchekomplexBenutzer.statusEnabledOderArchiviert}}</td>
				<!--				<td>{{recherchekomplexBenutzer.datumLetzteStatusAenderung}}</td>-->
				<td>
					<button pButton
							data-bs-toggle="tooltip" data-bs-placement="right"
							title="Rechte für diesen Benutzer anzeigen"
							class="p-button-rounded p-button-outlined p-button-raised table-button"
							(click)="zeigeBenutzerrechte(recherchekomplexBenutzer)">
						<fa-icon style="margin-right: 1px" [icon]="faPen"></fa-icon>
					</button>
				</td>
			</tr>
		</ng-template>
	</p-table>

	<br>
	<div>
		<button pButton
				pRipple
				icon="pi pi-times"
				iconPos="left"
				style="float: right;"
				type="button"
				class="p-d-flex p-button-text"
				label="Abbrechen"
				(click)="back()"></button>
	</div>
	<br>
</p-card>

<app-footer style="margin-top: 20px;"></app-footer>
