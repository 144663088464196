import {Component, OnInit} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Alert} from 'app/shared/alert/service/Alert';

@Component({
	selector: 'app-alert',
	templateUrl: './AlertComponent.html',
	styleUrls: ['./AlertComponent.less'],
})
export class AlertComponent implements OnInit {
	alerts: Alert[];

	constructor(private alertService: AlertService) {
	}

	closeAlert(alert: Alert) {
		this.alertService.dismissAlert(alert);
	}

	ngOnInit() {
		this.alertService.newAlert.subscribe(() => this.updateAlertList());
		this.alertService.removeAlert.subscribe(() => this.updateAlertList());
	}

	private updateAlertList() {
		this.alerts = this.alertService.getAlerts();
	}
}
