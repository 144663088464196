<app-viewer-hinweis *ngIf="gesperrt">
	<div class="hinweis">
		<h3><span class="icon-lock">&nbsp;</span>Abschnitt ist in Bearbeitung</h3>
		<p>Dieser Abschnitt wird im Moment von&nbsp;<span class="highlight abschnitt-sperre-bearbeiter">{{ bearbeiterName }}</span>&nbsp;bearbeitet.<br/>
			Sie können die Antworten einsehen, jedoch nicht bearbeiten. Erst wenn der Abschnitt durch die andere Person verlassen wurde,
			ist das Bearbeiten wieder möglich. Verwenden Sie den blauen Pfeil oben links um zurück zur Abschnittsübersicht zu gelangen und
			den Status zu aktualisieren.</p>
	</div>
</app-viewer-hinweis>
<app-viewer-hinweis *ngIf="abgeloest">
	<h3><span class="icon-lock">&nbsp;</span>Abschnitt ist Gesperrt</h3>
	<p>Dieser Abschnitt wird im Moment mit <span class="highlight">Ihrem Zugang</span> in einem anderen Browser, <span style="white-space: nowrap;">Browser-Tab</span>
		oder auf einem anderen Computer bearbeitet.</p>
	<p>Sie können die Antworten weiterhin einsehen, jedoch nicht bearbeiten. Erst wenn der Abschnitt in der anderen Instanz verlassen wurde,
		ist das Bearbeiten wieder möglich. Sichern Sie eventuell <span class="highlight">noch&nbsp;nicht gespeicherte Eingaben</span> manuell
		durch Kopieren, um sie später wieder eingeben zu können.</p>
	<p>Verwenden Sie den blauen Pfeil oben links um zurück zur Abschnittsübersicht zu gelangen und den Status zu aktualisieren.</p>
</app-viewer-hinweis>
