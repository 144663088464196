import {TabellenZeileView} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabellenZeileView';
import {TabellenFrage} from 'app/shared/model/frage/TabellenFrage';
import {TabellenSpalte} from 'app/shared/model/frage/TabellenSpalte';
import {TabellenfragenAntwort} from 'app/shared/model/antwort/TabellenfragenAntwort';
import {AntwortZeile} from 'app/shared/model/antwort/tabellenfrage/AntwortZeile';

export class TabellenView {
	private tabellenFrage: TabellenFrage;

	private tabellenZeilenViews: TabellenZeileView[];
	private tabellenAntwort: TabellenfragenAntwort;

	constructor(tabellenFrage: TabellenFrage, tabellenAntwort: TabellenfragenAntwort) {
		this.tabellenZeilenViews = [];
		this.tabellenFrage = tabellenFrage;
		this.tabellenAntwort = tabellenAntwort;
		this.erzeugeFixeAntwortZeilen();
		this.erzeugeTabellenZeilenViews();
	}

	getSpalten(): TabellenSpalte[] {
		return this.tabellenFrage.spalten;
	}

	getZeilenViews(): TabellenZeileView[] {
		return this.tabellenZeilenViews;
	}

	getAnzahlZeilen(): number {
		return this.tabellenAntwort.getZeilenAnzahl();
	}

	addZeile() {
		const neueZeile = new AntwortZeile();
		neueZeile.zellen = AntwortZeile.erzeugeLeereAntwortZellen(this.tabellenFrage.spalten);
		this.tabellenAntwort.zeilen.push(neueZeile);
		this.tabellenZeilenViews.push(new TabellenZeileView(this.tabellenFrage.spalten, neueZeile.zellen));
	}

	private erzeugeFixeAntwortZeilen() {
		const anzahlFehlenderZeilen = this.tabellenAntwort.anzahlFehlendeZeilen(this.tabellenFrage.getFixeZeilenAnzahl());
		for (let i = 0; i < anzahlFehlenderZeilen; i++) {
			this.addZeile();
		}
	}

	private clearZeilen(): void {
		this.tabellenAntwort.zeilen = [];
	}

	removeZeile(zeilenView: TabellenZeileView): void {
		const indexOfZeile = this.tabellenZeilenViews.indexOf(zeilenView);
		if (indexOfZeile > -1) {
			this.tabellenAntwort.zeilen.splice(indexOfZeile, 1);
			this.tabellenZeilenViews.splice(indexOfZeile, 1);
		}
	}

	private erzeugeTabellenZeilenViews(): void {
		this.tabellenZeilenViews = [];
		let zeilenIndex = 0;
		for (const antwortZeile of this.tabellenAntwort.zeilen) {
			const tabellenZeileView = new TabellenZeileView(this.tabellenFrage.spalten, antwortZeile.zellen);
			this.setzeTitelFallsVorhanden(tabellenZeileView, zeilenIndex);
			this.tabellenZeilenViews.push(tabellenZeileView);
			zeilenIndex++;
		}
	}

	private setzeTitelFallsVorhanden(tabellenZeileView: TabellenZeileView, zeilenIndex: number) {
		if (this.tabellenFrage.anzahlFixeZeilen() > zeilenIndex) {
			tabellenZeileView.titel = this.tabellenFrage.getZeilentitel(zeilenIndex);
		}
	}

	isEmpty() {
		return this.getAnzahlZeilen() === 0;
	}

	setTabellenAntwort(tabellenAntwort: TabellenfragenAntwort) {
		this.tabellenAntwort = tabellenAntwort;
		this.erzeugeTabellenZeilenViews();
	}
}
