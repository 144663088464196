import {Injectable} from "@angular/core";
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {AbschnittSperre} from 'app/shared/model/AbschnittSperre';
import {IFormViewerAbschnittSperrenHelper} from 'app/sperren-helper/IFormViewerAbschnittSperrenHelper';

@Injectable()
export class DummyFormViewerAbschnittSperrenHelper implements IFormViewerAbschnittSperrenHelper {

	istAbschnittFuerAktuellenUserGesperrt(abschnitt: Abschnitt): boolean {
		return false;
	}

	sperreAbschnitt(abschnitt: Abschnitt): Promise<AbschnittSperre> {
		return Promise.resolve(null);
	}

	entsperrenAbschnitt(abschnitt: Abschnitt): Promise<void> {
		return Promise.resolve();
	}

	starteRegelmaessigeVerlaengerung(abschnitt: Abschnitt) {
		// nop
	}

	stoppeRegelmaessigeVerlaengerung() {
		// nop
	}
}
