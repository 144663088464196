import {Component, Input, ViewChild} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeTelefonAntwort} from "app/shared/model/antwort/input/InputTypeTelefonAntwort";
import {InputTypeTelefon} from "app/shared/model/frage/input/InputTypeTelefon";

/**
 * Variants:
 *
 * - default: bs style Eingabefeld
 * - table-cell: Vollflächiges Eingabefeld ohne Rand zum Einbinden in Tabellenzellen
 */
@Component({
	selector: 'app-input-type-telefon-view',
	templateUrl: './InputTypeTelefonViewComponent.html',
	styleUrls: ['./InputTypeTelefonViewComponent.less']
})
export class InputTypeTelefonViewComponent implements IInputTypeViewComponent<InputTypeTelefon> {

	context = {};

	variant: string;

	@ViewChild('tooltip') tooltip;

	@Input()
	antwort: InputTypeTelefonAntwort;

	@Input()
	type: InputTypeTelefon;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}

	isValid() {
		return this.antwort.isValid();
	}

	getPlatzhalterText(): string {
		return this.type.platzhalterText;
	}

	getRegex(): string {
		return "^(\\(?([\\d \\-\\)\\–\\+\\/\\(]+)\\)?([ .\\-–\\/]?)([\\d]+))$";
	}

	variantClass(): string {
		return this.variant;
	}
}
