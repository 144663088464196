import { Injectable } from '@angular/core';
import {environment} from "../environments/environment";
import {AuthConfig, NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {OAuthEvent} from "angular-oauth2-oidc/events";
import {Router} from "@angular/router";


export  const authConfig: AuthConfig = {
  issuer: `${environment.keycloak.url}realms/${environment.keycloak.realm}`,
  clientId: environment.keycloak.clientId,
  redirectUri: window.location.origin ,
  responseType: 'code',
  requireHttps: false,
  strictDiscoveryDocumentValidation: false,
  skipIssuerCheck: true,
  preserveRequestedRoute : true
}

function getCodeParam(){
	if (window.location.href.includes("code=")) {
		let number = window.location.href.indexOf("code=");
		return window.location.href.slice(number)
	}
	return codeParam;
}
export const codeParam:string = getCodeParam()
// @ts-ignore
@Injectable()
export class AuthService {

  constructor(
    private readonly oauthService: OAuthService,
	private router: Router,
    private readonly auth: AuthConfig,) { }

  private getRedirectUri(){
	  if (codeParam){
		  return window.location.pathname + "?" + codeParam
	  }
	  return window.location.pathname;
  }

  async initAuth(): Promise<any> {
	  this.oauthService.events.subscribe((s:OAuthEvent)=>{
		  switch (s.type){
			  case "token_received":
				  let redirectUri = decodeURIComponent(this.oauthService.state);
				  if (redirectUri.length>1){
					  this.router.navigateByUrl(redirectUri)
				  }
		  }
	  })

	  return new Promise((resolveFn, rejectFn) => {
      // setup oauthService
      this.oauthService.configure(this.auth);
      this.oauthService.setStorage(localStorage)
	  this.oauthService.tokenValidationHandler = new NullValidationHandler();

      this.oauthService.loadDiscoveryDocumentAndLogin().then(isLoggedIn => {
        if (isLoggedIn) {
          this.oauthService.setupAutomaticSilentRefresh();
          resolveFn(isLoggedIn);
        } else {
          this.oauthService.initCodeFlow(this.getRedirectUri());
          rejectFn();
        }
      });

    });
  }

}
