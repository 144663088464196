<input
	*ngIf="!printView"
	class="form-control text {{isValid() ? '' : 'invalid'}}"
	[readonly]="readonly"
	[ngClass]="variant"
	type="text"
	placeholder="{{ getPlatzhalterText() }}"
	[(ngModel)]="antwort.wert"
	[pattern]="getRegex()"
	id="{{ id }}"
	appScrollToFocusedElement
/>

<div *ngIf="printView" class="text readonly" [ngClass]="variant">
	{{ this.antwort.wert ? this.antwort.wert : "-" }}
</div>
