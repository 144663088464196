<div class="form-group">
	<label for="{{ 'titel' | newID }}" class="required">Titel des Datensatzes (Einzahl)&nbsp;<span class="required-marker">*</span></label>

	<div class="input-group">
		<input
			type="text"
			class="form-control"
			id="{{ 'titel' | lastID }}"
			placeholder="z.B. Mandant"
			[(ngModel)]="frage.titel"
			name="titel"
			required minlength="1" maxlength="255"
		>

		<span class="input-group-btn">
			<button class="btn btn-default" type="button" (click)="openDialog()"><i class="icon-cog"></i></button>
		</span>
	</div>
</div>


<div class="modal fade"
	 bsModal
	 #optionsModal="bs-modal"
	 tabindex="-1"
	 role="dialog"
	 aria-hidden="true"
	 (keydown.escape)="closeDialog($event)"
>
	<div class="modal-dialog modal-sm">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Optionen</h4>
				<button type="button" class="close pull-right" aria-label="Close" (click)="closeDialog()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">


				<div class="form-group">
					<label for="{{ 'placeholder' | newID }}" class="required">Platzhalter-Text</label>

					<input type="text" class="form-control" id="{{ 'platzhalterText' | lastID }}"
						   [(ngModel)]="titelPlatzhalterText"
						   name="platzhalterText">
				</div>
				<div class="form-group">
					<label for="{{ 'beschreibung' | newID }}" class="required">Info-Text</label>

					<input type="text" class="form-control" id="{{ 'beschreibung' | lastID }}"
						   [(ngModel)]="titelBeschreibung"
						   name="beschreibung">
				</div>

				<div class="clearfix">
					<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
						<button class="commit" class="pull-right btn btn-primary" (click)="commitSave()" type="button">Übernehmen</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
