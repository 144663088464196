import {Institution} from "../../shared/model/Institution";
import {FragebogenFreigaben} from "../../shared/model/FragebogenFreigaben";
import {RecherchekomplexFreigabenDTO} from "../../shared/model/RecherchekomplexFreigabenDTO";

export class RecherchekomplexMitSichtbarenAbschnittenDto {

	institution: Institution;
	recherchekomplex: RecherchekomplexFreigabenDTO;
	frageboegen: FragebogenFreigaben[] = [];
	adminRights: boolean = false;
	coAdminRights: boolean = false;
	darfFragebogenAbschliessen: boolean= false;
	frageboegenGesamtAnzahl: number;

	static toObj(obj: RecherchekomplexMitSichtbarenAbschnittenDto) {
		return new RecherchekomplexMitSichtbarenAbschnittenDto(obj)
	}

	static fromList(list: RecherchekomplexMitSichtbarenAbschnittenDto[]) {
		// console.log(list)
		return list.map(recherchekomplexMitSichtbarenAbschnittenDto => new RecherchekomplexMitSichtbarenAbschnittenDto(recherchekomplexMitSichtbarenAbschnittenDto));
	}

	constructor(recherchekomplexMitSichtbarenAbschnittenDto: RecherchekomplexMitSichtbarenAbschnittenDto) {
		this.institution = recherchekomplexMitSichtbarenAbschnittenDto.institution;
		this.recherchekomplex = new RecherchekomplexFreigabenDTO(recherchekomplexMitSichtbarenAbschnittenDto.recherchekomplex);
		this.adminRights = recherchekomplexMitSichtbarenAbschnittenDto.adminRights;
		this.coAdminRights = recherchekomplexMitSichtbarenAbschnittenDto.coAdminRights;
		// this.frageboegen = this.recherchekomplex.frageboegenFreigabenList;

		this.frageboegen = this.recherchekomplex.frageboegenFreigabenList.sort((obj1, obj2) => {
			if (obj1.name > obj2.name) {
				return 1;
			}

			if (obj1.name < obj2.name) {
				return -1;
			}

			return 0;
		});

		this.frageboegenGesamtAnzahl = this.frageboegen.length;
	}
}
