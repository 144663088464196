<div
	class="modal fade"
	bsModal
	#sortDialogModal="bs-modal"
	tabindex="-1"
	role="dialog"
	appPreventDefault
>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title pull-left">Abschnitte sortieren</h4>

				<button type="button" class="close pull-right" aria-label="Close" (click)="hide()">
					<span>&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<app-abschnitt-sortieren
					[abschnitte]="editAbschnitte"
					[disabled]="disabled"
				></app-abschnitt-sortieren>

				<div class="clearfix">
					<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
						<button
							type="button"
							[disabled]="disabled"
							(click)="onCancelSave()"
							class="btn btn-default"
						>
							Abbrechen
						</button>

						<button
							type="button"
							[disabled]="disabled"
							(click)="onCommitSave()"
							class="btn btn-primary"
						>
							Speichern
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
