import {InputTypeTextareaViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-textarea-view/InputTypeTextareaViewComponent';
import {InputTypeTextareaEditComponent} from 'app/shared/components/input-type/textarea/InputTypeTextareaEditComponent';
import {InputTypeTextareaAntwort} from 'app/shared/model/antwort/input/InputTypeTextareaAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';

export class InputTypeTextarea extends InputType {
	static readonly ID = 'InputTypeTextarea';
	static readonly TYPE_DISPLAY_NAME = 'Großes Text'; // …-Feld
	static readonly ANTWORT = InputTypeTextareaAntwort;

	static readonly VIEWER = InputTypeTextareaViewComponent;
	static readonly EDITOR = InputTypeTextareaEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeTextarea) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}
}
