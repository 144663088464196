import {UUID} from "../../util/export-types";

export class RecherchekomplexUebersichtDto {

	id: UUID;
	name: string;
	eingeladeneAusfueller: number;
	anzahlFrageboegen: number;
	status: string;
	enddatum: Date;

	static fromList(list: RecherchekomplexUebersichtDto[]) {
		return list.map(recherchekomplexUebersichtDto => new RecherchekomplexUebersichtDto(recherchekomplexUebersichtDto));
	}

	constructor(recherchekomplexUebersichtDto?: RecherchekomplexUebersichtDto) {
		if (recherchekomplexUebersichtDto) {
			this.id = recherchekomplexUebersichtDto.id;
			this.name = recherchekomplexUebersichtDto.name;
			this.eingeladeneAusfueller = recherchekomplexUebersichtDto.eingeladeneAusfueller;
			this.anzahlFrageboegen = recherchekomplexUebersichtDto.anzahlFrageboegen;
			this.status = recherchekomplexUebersichtDto.status;
			this.enddatum = recherchekomplexUebersichtDto.enddatum ? new Date(recherchekomplexUebersichtDto.enddatum) : null;
		}
	}
}
