<div class="modal fade" bsModal #modalDirective="bs-modal" tabindex="-1" role="dialog"
	 aria-hidden="true">
	<div class="modal-dialog modal-lg" data-backdrop="static">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Wichtige Information zur Fragebogenübermittlung</h4>
			</div>
			<div class="modal-body">
				<p>Sehr geehrte Teilnehmer,</p>

				<p>die Recherche für „{{ recherchekomplex.name }}“ ist beendet. Sollten Sie Fragen
					haben, wenden Sie sich bitte direkt an uns.</p>

				<p>Ihre&nbsp;JUVE&nbsp;Redaktion</p>

				<div class="button-bar">
					<button class="btn btn-default" (click)="closeDialog()">Zur Kenntnis genommen</button>
				</div>

				<app-juve-ansprechpartnerbox></app-juve-ansprechpartnerbox>
			</div>
		</div>
	</div>
</div>
