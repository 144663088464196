<div class="wrapper keyvisual noDialog">
	<div class="container">
		<div class="logo">
			<img src="/assets/portal/logo.png" width="160">
		</div>

		<div class="row">
			<div class="col-md-6 col-md-offset-3">
				<div class="panel form-panel panel-default">
					<div class="panel-body message">
						<h2 class="centered">{{title}}</h2>
						<ng-content></ng-content>
					</div>

					<div class="panel-footer">
						<h3>Ihre Ansprechpartner:</h3>
						<div class="contacts">
							<app-contact [person]="'scherer'"></app-contact>
							<app-contact [person]="'mecke'"></app-contact>
							<app-contact [person]="'ossen'"></app-contact>
						</div>
					</div>

				</div>

			</div>
		</div>

	</div>

	<app-footer [style]="'message'"></app-footer>
</div>
