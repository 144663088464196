<div class="well">
	<app-editor-abschnitt-view
		[abschnitt]="abschnitt"
		(edit)="beginEdit()"
		(duplicate)="beginDuplicate()"
		*ngIf="!isEditing()"
	></app-editor-abschnitt-view>

	<div [class.has-questions]="hasQuestions()" *ngIf="isEditing()">
		<app-editor-abschnitt-edit
			[abschnitt]="editAbschnitt"
			(cancel)="cancelEdit()"
			(commit)="commitEdit()"
			(remove)="commitRemove()"
		></app-editor-abschnitt-edit>
	</div>

	<app-editor-abschnitt-frage-liste
		[abschnitt]="abschnitt"
		(fragenUmsortiert)="onFragenUmsortiert($event)"
	></app-editor-abschnitt-frage-liste>
</div>
