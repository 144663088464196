import {Component, Input, OnInit} from '@angular/core';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {InputTypeTextarea} from 'app/shared/model/frage/input/InputTypeTextarea';

@Component({
	selector: 'app-input-type-textarea-edit',
	templateUrl: './InputTypeTextareaEditComponent.html',
	styleUrls: ['./InputTypeTextareaEditComponent.less']
})
export class InputTypeTextareaEditComponent implements OnInit, IInputTypeEditComponent<InputTypeTextarea> {
	@Input()
	inputType: InputTypeTextarea;

	platzhalterText = '';

	getEditorTitel(): string {
		return 'Optionen';
	}

	ngOnInit(): void {
		this.platzhalterText = this.inputType.platzhalterText;
	}

	commitSave() {
		this.inputType.platzhalterText = this.platzhalterText;
	}
}
