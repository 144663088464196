import {Component, Input} from '@angular/core';
import {IFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';
import {MehrfachauswahlFrage} from 'app/shared/model/frage/MehrfachauswahlFrage';

@Component({
	selector: 'app-editor-abschnitt-frage-edit-mehrfachauswahl',
	templateUrl: './MehrfachauswahlFrageEditComponent.html',
	styleUrls: ['./MehrfachauswahlFrageEditComponent.less']
})
export class MehrfachauswahlFrageEditComponent implements IFrageEditComponent<MehrfachauswahlFrage> {
	@Input()
	frage: MehrfachauswahlFrage;
}
