<div [ngClass]="variant">
	<ng-container *ngIf="!printView">
		<app-select-mit-sonstiges-option
			*ngIf="type.sonstigesTextfeld"
			[(wert)]="antwort.wert"
			[disabled]="readonly"
			[title]="antwort.wert"
			[options]="type.options"
			id="{{ id }}"
		></app-select-mit-sonstiges-option>
		<app-styled-select
			*ngIf="!type.sonstigesTextfeld"
			[(wert)]="antwort.wert"
			[disabled]="readonly"
			[title]="antwort.wert"
			[options]="type.options"
			id="{{ id }}"
		></app-styled-select>
	</ng-container>

	<div *ngIf="printView" class="textfeld readonly">
		{{ this.antwort.wert ? this.antwort.wert : "-" }}
	</div>
</div>
