import {Component, Input} from '@angular/core';
import {KartenAntwort} from 'app/shared/model/antwort/KartenAntwort';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';

@Component({
	selector: 'app-karten-view-title-field',
	templateUrl: './KartenViewTitleFieldComponent.html',
	styleUrls: ['./KartenViewTitleFieldComponent.less']
})
export class KartenViewTitleFieldComponent {
	@Input()
	frage: KartenFrage;

	@Input()
	antwort: KartenAntwort;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isValid() {
		return this.antwort.kartenTitel.trim().length > 0;
	}

	isReadonly() {
		return this.readonly;
	}
}
