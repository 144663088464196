<header [style.background-color]="headerColor ? headerColor : ''">
	<div class="container">
		<svg width="100px" height="50px" viewBox="0 0 100 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<title>jurec-logo-wht</title>
			<desc>Created with Sketch.</desc>
			<defs/>
			<g id="Assets" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
				<g id="jurec-logo" [attr.fill]="logoColor">
					<path
						d="M7.78929537,7.37421082 L7.78929537,0 L15.1635062,0 L15.1635062,7.37421082 L7.78929537,7.37421082 Z M7.86230431,10.3448134 L15.374945,10.3448134 L15.374945,39.4921469 C15.374945,39.4921469 14.9191518,45.8245447 12.0131498,47.7123181 C9.11637646,49.5929136 7.06453806,49.616498 6.66616764,49.7072464 C5.61615138,49.9682124 0,49.3401509 0,49.3401509 L0,42.8862424 C0,42.8862424 2.8408887,43.7004152 4.63124844,43.3087099 C6.69846794,42.8703486 7.86230431,41.1850931 7.86230431,39.7367064 L7.86230431,10.3448134 Z M19.1597717,10.3448134 L26.8923621,10.3448134 L26.8923621,37.1480677 C26.8923621,37.1480677 27.3235455,42.9354619 33.0453137,42.9185427 C38.8321953,42.9021362 39.6786684,39.068654 39.6786684,37.1480677 L39.6786684,10.3448134 L47.1995123,10.3448134 L47.1513182,37.1480677 C47.1513182,37.1480677 47.143115,50 33.0540297,50 C18.9644317,50 19.281795,39.6228863 19.2166817,37.1480677 L19.1597717,10.3448134 Z M50.9927473,10.3448134 L58.3259419,10.3448134 L58.3259419,40.4037333 L71.1378833,10.3448134 L79.846148,10.3448134 L62.6147045,49.3401509 L50.9927473,49.3401509 L50.9927473,10.3448134 Z M76.5330596,25.9074591 L91.6398608,25.9074591 L91.6398608,32.2485729 L73.7654875,32.2485729 L76.5330596,25.9074591 Z M68.9388991,42.9510481 L91.6398608,42.9510481 L91.6398608,49.340356 L66.1077518,49.340356 L68.9388991,42.9510481 Z M83.4842903,10.3781392 L91.6393481,10.3781392 L91.6393481,16.6940792 L80.6598081,16.6940792 L83.4842903,10.3781392 Z"
						id="Combined-Shape"></path>
				</g>
			</g>
		</svg>

		<div class="logo-text" [style.color]="logoColor ? logoColor : ''">
			<div class="logo-text-line top">
				{{ logoFirstLine ? logoFirstLine : "Recherche" }}
			</div>
			<div class="logo-text-line bottom">
				{{ logoSecondLine ? logoSecondLine : fragebogen.recherchekomplex.name }}
			</div>
		</div>
	</div>
</header>

<div *ngIf="loaded" class="fragebogen-viewer">
	<app-form-viewer
		[fragebogen]="fragebogen"
		[fragebogen_abgeschlossen]="fragebogen.abgeschlossen"
		[istArchivzugriff]="istArchivzugriff"
		[kontaktZumAufschliessen]="kontaktZumAufschliessen">
		<app-spinner></app-spinner>
	</app-form-viewer>
</div>
