<app-header></app-header>
<!--<p-toast></p-toast>-->

<div *ngIf="sichtbareInstitution.length > 1" class="aktuelleInstitutionWrapper" id="aktuelleInstitutionWrapper"
	 style="position: absolute; top: 110px; right: 10px;">

	<p-dropdown [(ngModel)]="aktuelleInstitution"
				[options]="sichtbareInstitution"
				[showClear]="false"
				class="col-12"
				name="newKomplex"
				optionLabel="institution.anzeigename"
				placeholder="Aktuelles Unternehmen"
				title="Ihr aktuelles Unternehmen">
	</p-dropdown>
</div>

<p-tabView [(activeIndex)]="activeIndex" (onChange)="handleChange($event)">
	<p-tabPanel header="Registrierte Benutzer">
		<div style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 240px);">
			<app-registrierte-benutzer></app-registrierte-benutzer>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Offene Einladungen">
		<div style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 240px);">
			<app-offene-einladungen></app-offene-einladungen>
		</div>
	</p-tabPanel>
	<p-tabPanel header="Meine Recherchekomplexe">
		<div style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 240px);">
			<app-recherchekomplexe></app-recherchekomplexe>
		</div>
	</p-tabPanel>
</p-tabView>

<app-footer></app-footer>
