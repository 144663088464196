import {Component, Input, ViewChild} from '@angular/core';
import {FragebogenStatusService} from 'app/form-viewer/service/FragebogenStatusService';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {NavigationService} from 'app/shared/service/NavigationService';
import {ModalDirective} from 'ngx-bootstrap/modal';

const debug = require('debug')('FragebogenAbschliessenComponent');

@Component({
	selector: 'app-fragebogen-aufschliessen',
	templateUrl: './FragebogenAufschliessenComponent.html',
	styleUrls: ['./FragebogenAufschliessenComponent.less'],
})
export class FragebogenAufschliessenComponent {

	private static readonly FRAGEBOGEN_AUFGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN = 3;

	@Input()
	fragebogen: Fragebogen;

	@ViewChild('aufschliessenModal', { static: true })
	aufschliessenModal: ModalDirective;

	constructor(private navigationService: NavigationService, private fragebogenStatusService: FragebogenStatusService) {
	}

	openDialog() {
		this.aufschliessenModal.show();
	}

	closeDialog() {
		this.aufschliessenModal.hide();
	}

	commitAufschliessen() {
		this.closeDialog();

		this.fragebogenStatusService.schliesseFragebogenAuf(this.fragebogen)
			.then(() => {
				this.initializeRedirect();
			}).catch((error) => {
			debug('Fehler beim Aufschliessen des Fragebogens', error);
		});
	}

	private initializeRedirect() {
		setTimeout(() => {
			this.navigationService.goToFragebogenuebersicht();
		}, FragebogenAufschliessenComponent.FRAGEBOGEN_AUFGESCHLOSSEN_MELDUNG_ANZEIGE_DAUER_SEKUNDEN * 1000);
	}
}
