<div class="scroll">
	<div class="fluidcontainer">
		<div class="table-container">
			<app-tabelle-erweiterbar-view
				[tabellenFrage]="frage"
				[tabellenAntwort]="antwort"
				[readonly]="readonly"
				[printView]="printView"
				*ngIf="frage.isErweiterbar()"
			></app-tabelle-erweiterbar-view>
			<app-tabelle-fix-view
				[tabellenFrage]="frage"
				[tabellenAntwort]="antwort"
				[readonly]="readonly"
				[printView]="printView"
				*ngIf="!frage.isErweiterbar()"
			></app-tabelle-fix-view>
		</div>
	</div>
</div>
