import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypes} from 'app/shared/model/InputTypes';

export class InputTypeBuilderUtil {
	static build(input: any): InputType {
		const type = InputTypes.typeForID(input.type);
		if (type) {
			return new type(input);
		}

		return new InputType(input as InputType);
	}
}
