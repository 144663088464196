<div class="form-group">
	<label for="{{ 'abgabefrist' | newID }}">Abgabefrist (optional)</label>
	<input
		type="text"
		class="form-control"
		name="text"
		id="{{ 'abgabefrist' | lastID }}"
		placeholder="z.B. 02.12.2018"
		maxlength="255"
		[(ngModel)]="dto.abgabefrist"
		[disabled]="disabled"
	>
</div>
