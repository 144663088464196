import {UUID} from "../../util/export-types";
import {AbschnittFreigaben} from "./AbschnittFreigaben";


export class FragebogenFreigaben {
	id: UUID = undefined;
	name: String = "";
	beschreibungKurz: String = "";
	beschreibungLang: String = "";
	anzahlabschnitte: number = 0;
	hatRechte: boolean = false;
	abschnitteFreigabenList: AbschnittFreigaben[] = [];

	constructor(tmp: FragebogenFreigaben) {
		this.id = tmp.id;
		this.name = tmp.name;
		this.beschreibungKurz = tmp.beschreibungKurz;
		this.beschreibungLang = tmp.beschreibungLang;
		this.hatRechte = tmp.hatRechte;
		this.anzahlabschnitte = tmp.anzahlabschnitte;
		this.abschnitteFreigabenList = tmp.abschnitteFreigabenList.map(abschnitt => new AbschnittFreigaben(abschnitt))
	}

	setAlleRechte() {
		for (const abschnittFreigaben of this.abschnitteFreigabenList) {
			abschnittFreigaben.hatRechte = true;
		}
		this.hatRechte = true;
	}

	removeAlleRechte() {
		for (const abschnittFreigaben of this.abschnitteFreigabenList) {
			abschnittFreigaben.hatRechte = false;
		}
		this.hatRechte = false;
	}

	hatAlleRechte() {

		if (this.abschnitteFreigabenList.length == 0) {
			// this.hatRechte = false;
			return false;
		}

		for (const abschnittFreigaben of this.abschnitteFreigabenList) {
			if (!abschnittFreigaben.hatRechte) {
				// this.hatRechte = false;
				return false;
			}
		}

		// this.hatRechte = true;
		return true;
	}

	hatKeineRechte() {
		if (this.abschnitteFreigabenList.length == 0)
			return true;

		for (const abschnittFreigaben of this.abschnitteFreigabenList) {
			if (abschnittFreigaben.hatRechte) {
				return false;
			}
		}
		return true;
	}

	hatTeilweiseRechte() {

		if (this.abschnitteFreigabenList.length == 0)
			return false;

		var rechtFound = false;
		var keinRechtFound = false;

		for (const abschnittFreigaben of this.abschnitteFreigabenList) {
			if (abschnittFreigaben.hatRechte) {
				rechtFound = true;
			}
			if (!abschnittFreigaben.hatRechte) {
				keinRechtFound = true;
			}
		}

		if (rechtFound && keinRechtFound) {
			return true;
		} else {
			return false;
		}
	}

	toggleRechte() {
		if (this.hatAlleRechte()) {
			this.removeAlleRechte();
		} else {
			this.setAlleRechte()
		}
	}
}
