import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NavigationService} from "../shared/service/NavigationService";
import {RegistrierungService} from "./service/RegistrierungService";

@Component({
	selector: 'app-registrierungs-formular',
	templateUrl: './RegistrierungsFormularComponent.html'
})
export class RegistrierungsFormularComponent implements OnInit{

	code: string;
	username: string = '';
	zweiFaktorCode: string = ''
	zweiFaktorCodeFalsch: boolean
	buttonDisabled: boolean = true

	@ViewChild('form', { static: true })
	form: ElementRef<HTMLFormElement>;

	constructor(private route: ActivatedRoute,
				private navigationService: NavigationService,
				private registrierungService: RegistrierungService) {
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.code = params.code;
			this.registrierungService.getAusfuellerZuCode(this.code)
				.then(ausfueller => {
					this.username = ausfueller.username;
					this.buttonDisabled = false;
				})
				.catch(() => this.navigationService.goToUnbekannterCode());
		});
	}

	submit(): void {
		this.zweiFaktorCodeFalsch = false
		this.registrierungService.registrieren(this.code, this.zweiFaktorCode)
			.then(antwort => {
				if (antwort.codeUnbekannt) {
					this.navigationService.goToUnbekannterCode()
				} else if (antwort.zweiFaktorCodeInkorrekt) {
					this.zweiFaktorCodeFalsch = true
				} else {
					this.navigationService.goToUebersicht()
				}
			})
	}
}
