import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {isDefined} from 'app/util/isDefined';
import {Recherchekomplex} from '../../shared/model/Recherchekomplex';
import {FragebogenService} from '../../shared/service/FragebogenService';
import {RecherchekomplexService} from '../../shared/service/RecherchekomplexService';
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
	selector: 'app-editor-fragebogen-uebersicht',
	templateUrl: './EditorFragebogenUebersichtComponent.html',
	styleUrls: ['./EditorFragebogenUebersichtComponent.less']
})
export class EditorFragebogenUebersichtComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	recherchekomplexe: Recherchekomplex[];
	bearbeitbareFrageboegen: { [key: string]: Fragebogen[] } = {};
	veroeffentlichteFrageboegen: { [key: string]: Fragebogen[] } = {};
	index: number;

	constructor(private recherchekomplexService: RecherchekomplexService,
				private fragebogenService: FragebogenService,
				private title: Title) {
		title.setTitle('Fragebogen-Liste - Juve Recherche');
	}

	ngOnInit(): void {
		this.blockUI.start("Daten werden verarbeitet...")
		this.recherchekomplexService.getRecherchekomplexe()
			.then(recherchekomplexe => this.recherchekomplexe = recherchekomplexe)
			.then(() => this.fragebogenService.getFrageboegen().then(frageboegen => this.groupFrageboegen(frageboegen)));

		const hash = window.location.hash.replace('#', '');
		this.index = isNaN(+hash) ? 0 : +hash;
		this.blockUI.stop();
	}

	private groupFrageboegen(frageboegen: Fragebogen[]) {
		if (!isDefined(frageboegen)) {
			this.bearbeitbareFrageboegen = {};
			this.veroeffentlichteFrageboegen = {};
			return;
		}

		const bearbeitbareFrageboegen = frageboegen.filter(fragebogen => !fragebogen.veroeffentlicht);
		const veroeffentlichteFrageboegen = frageboegen.filter(fragebogen => fragebogen.veroeffentlicht);

		this.recherchekomplexe.forEach(rk => {
			this.bearbeitbareFrageboegen[rk.id] = bearbeitbareFrageboegen.filter(fb => (fb.recherchekomplex.id === rk.id));
			this.veroeffentlichteFrageboegen[rk.id] = veroeffentlichteFrageboegen.filter(fb => (fb.recherchekomplex.id === rk.id));
		});
	}

	onDuplicateCreated($event: Fragebogen) {
		this.bearbeitbareFrageboegen[$event.recherchekomplex.id].push($event);
	}

	onFragebogenVeroeffentlicht($event: Fragebogen) {
		const rkId = $event.recherchekomplex.id;
		const index = this.bearbeitbareFrageboegen[rkId].indexOf($event);
		this.bearbeitbareFrageboegen[rkId].splice(index, 1);
		this.veroeffentlichteFrageboegen[rkId].push($event);
	}
}
