import {Component, Input} from '@angular/core';
import {TextfeldFrage} from 'app/shared/model/frage/TextfeldFrage';
import {IFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/IFrageEditComponent';

@Component({
	selector: 'app-editor-abschnitt-frage-edit-textfeld',
	templateUrl: './TextfeldFrageEditComponent.html',
	styleUrls: ['./TextfeldFrageEditComponent.less']
})
export class TextfeldFrageEditComponent implements IFrageEditComponent<TextfeldFrage> {
	@Input()
	frage: TextfeldFrage;
}
