import {Type} from '@angular/core';
import {UUID} from 'app/util/export-types';
import {IInputTypeEditComponent} from 'app/shared/components/input-type/IInputTypeEditComponent';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';

export class InputType {
	static readonly ID: string;
	static readonly TYPE_DISPLAY_NAME: string;
	static readonly VIEWER: Type<IInputTypeViewComponent<any>>;
	static readonly EDITOR: Type<IInputTypeEditComponent<any>>;
	static readonly ANTWORT: Type<InputTypeAntwort<any>>;

	public type: string;
	public id: UUID;

	constructor(type?: InputType) {
		this.type = this.getClass().ID;

		if (type) {
			this.id = type.id;
		}
	}

	getClass(): typeof InputType {
		return this.constructor as typeof InputType;
	}

	clearIds() {
		this.id = null;
	}
}
