import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Institution} from 'app/shared/model/Institution';
import {InstitutionFragebogenStatus} from 'app/shared/model/InstitutionFragebogenStatus';
import {UUID} from 'app/util/export-types';

@Injectable()
export class InstitutionAntwortService {

	constructor(private http: HttpClient, private alerts: AlertService) {
	}

	getInstitutionenUndStatusFuerFragebogen(fragebogenId: UUID): Promise<InstitutionFragebogenStatus[]> {
		return this.http.get<InstitutionFragebogenStatus[]>('/api/editor/fragebogen/' + fragebogenId + '/institutionen')
			.toPromise()
			.then(response => response.map(
				institutionStatus => new InstitutionFragebogenStatus(
					new Institution(institutionStatus.institution as Institution),
					institutionStatus.abgeschlossen,
					new Date(institutionStatus.abschlussdatum))))
			.catch(reason => {
				this.alerts.handleHttpError(reason);
				return [];
			});
	}
}
