import {Component, Input, OnInit} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Frage} from 'app/shared/model/Frage';
import {ViewportScroller} from "@angular/common";

@Component({
	selector: 'app-viewer-fragen-nav',
	templateUrl: './ViewerFragenNavComponent.html',
	styleUrls: ['./ViewerFragenNavComponent.less']
})
export class ViewerFragenNavComponent implements OnInit {

	static readonly ZWEI_SPALTENS_CHWELLWERT = 10;

	@Input()
	abschnitt: Abschnitt;

	@Input()
	aktiveFrage: Frage;

	drawerOpen = false;

	constructor(private viewportScroller: ViewportScroller) {
	}

	get inNavigationAktiveFrage(): Frage {
		return this.getCurrentFrageByScrollPos()
	}

	get scrollDistanceToBottomInPx() {
		const scrollPosition = window.pageYOffset;
		const windowHeight = window.innerHeight;
		const bodyHeight = document.body.offsetHeight;
		return bodyHeight - (scrollPosition + windowHeight);
	}

	toggleDrawer() {
		this.drawerOpen = !this.drawerOpen;
	}

	closeDrawer() {
		this.drawerOpen = false;
	}

	closeDrawerAndFocusFrage(frage: Frage) {
		this.drawerOpen = false;
		this.aktiveFrage = frage;
		if (frage)
			this.viewportScroller.scrollToAnchor("frage-" + frage.id);
	}

	closeDrawerAndPrev() {
		this.drawerOpen = false;
		this.aktiveFrage = this.getCurrentFrageByScrollPos()
		let prevFrage = this.getPrevFrage();
		this.aktiveFrage = prevFrage;
		if (prevFrage)
			this.viewportScroller.scrollToAnchor("frage-" + prevFrage.id);
	}

	getFragenIndex() {
		return this.abschnitt.fragen.indexOf(this.inNavigationAktiveFrage);
	}

	getNextFrage() {
		return this.abschnitt.fragen[this.getFragenIndex() + 1];
	}

	getPrevFrage() {
		return this.abschnitt.fragen[this.getFragenIndex() - 1];
	}

	getLastFrage() {
		return this.abschnitt.fragen[this.abschnitt.fragen.length - 1];
	}

	closeDrawerAndNext() {
		this.drawerOpen = false;
		this.aktiveFrage = this.getCurrentFrageByScrollPos()
		let nextFrage = this.getNextFrage();
		this.aktiveFrage = nextFrage;
		if (nextFrage)
			this.viewportScroller.scrollToAnchor("frage-" + nextFrage.id);
	}

	getFrageId(frage: Frage) {
		if (frage) {
			return frage.id;
		}

		return null;
	}

	getFirstFrage() {
		return this.abschnitt.fragen[0];
	}

	getCoordsForFrage(frage: Frage) {
		if (frage)
		return this.getCoords(document.getElementById("frage-" + frage.id))
	}

	getCoords(elem) {
		let box = elem.getBoundingClientRect();

		return {
			top: box.top + pageYOffset,
			left: box.left + pageXOffset
		};
	}

	getCurrentFrageByScrollPos() {
		const scrollPosition = window.pageYOffset
		let activeQuestionTmp: Frage;
		let lastDiff: number;
		activeQuestionTmp = this.abschnitt.fragen[0];
		for (const f of this.abschnitt.fragen) {
			let top = this.getCoordsForFrage(f).top;
			let diff = scrollPosition - top;
			if (diff < 0) {
				diff = diff * -1;
			}

			if (lastDiff == null) {
				if (diff < 0) {
					lastDiff = 0
				} else {
					lastDiff = diff
				}
				activeQuestionTmp = f;
			} else if (diff < lastDiff) {
				lastDiff = diff
				activeQuestionTmp = f;
			}
		}
		return activeQuestionTmp;
	}

	isAtFirst() {
		return this.getFragenIndex() === 0;
	}

	ngOnInit() {
	}

	isMehrspaltig() {
		return this.abschnitt.fragen.length > ViewerFragenNavComponent.ZWEI_SPALTENS_CHWELLWERT;
	}

	getSliceIndex(laenge: number): number {
		return Math.ceil(laenge / 2);
	}

	getFragenSpalten(): Frage[][] {
		const sliceIndex = this.getSliceIndex(this.abschnitt.fragen.length);
		return [
			this.abschnitt.fragen.slice(0, sliceIndex),
			this.abschnitt.fragen.slice(sliceIndex)
		];
	}

	isAtLast() {
		if (this.inNavigationAktiveFrage.id === this.getLastFrage().id) {
			return true
		} else {
			return false;
		}
	}

	isAktiveFrage(frage: Frage): boolean {
		return frage === this.inNavigationAktiveFrage;
	}

	private isAtEndOfPage(): boolean {
		return this.scrollDistanceToBottomInPx < 25;
	}

	private isAtStartOfPage(): boolean {

		const scrollPosition = window.pageYOffset
		if (scrollPosition < 60) {
			return true
		}
		return false
	}

}
