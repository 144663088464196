import {Component, Input, ViewChild} from '@angular/core';
import {AusfuellerVerwaltungFormComponent} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormComponent';
import {AusfuellerVerwaltungFormConfiguration} from 'app/form-viewer/ausfueller-verwaltung/form/AusfuellerVerwaltungFormConfiguration';
import {ModalDirective} from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-ausfueller-verwaltung-dialog',
	templateUrl: './AusfuellerVerwaltungDialogComponent.html',
	styleUrls: ['./AusfuellerVerwaltungDialogComponent.less'],
})
export class AusfuellerVerwaltungDialogComponent {

	@ViewChild('modal', { static: true })
	modal: ModalDirective;

	@ViewChild('form', { static: true })
	form: AusfuellerVerwaltungFormComponent;

	@Input()
	configuration: AusfuellerVerwaltungFormConfiguration;

	show(configuration?: AusfuellerVerwaltungFormConfiguration): void {
		if (configuration) {
			this.configuration = configuration;
		}

		this.modal.show();
	}

	hide(): void {
		this.modal.hide();
	}

	onModalHide(): void {
		this.form.reset();
	}

	getFormTitle(): string {
		return this.configuration ? this.configuration.getFormTitleHtml() : '';
	}
}
