import {SafeHtml} from "@angular/platform-browser";
import {InputTypeAntwort} from 'app/shared/model/antwort/input/InputTypeAntwort';
import {InputTypeTelefon} from "app/shared/model/frage/input/InputTypeTelefon";

export class InputTypeTelefonAntwort extends InputTypeAntwort<InputTypeTelefon> {
	wert = null;

	constructor(antwort?: InputTypeTelefonAntwort) {
		super(antwort);

		if (antwort) {
			this.wert = antwort.wert;
		}
	}

	getType() {
		return InputTypeTelefon.ID;
	}

	isEmpty(): boolean {
		return !this.wert || this.wert.trim().length === 0;
	}

    getHtml(): SafeHtml {
		return this.wert?.trim();
	}

	istInhaltlichGleich(o: InputTypeAntwort<any>): boolean {
		return this.wert?.trim() === (o as InputTypeTelefonAntwort).wert?.trim();
	}

	isValid(): boolean {
		return this.isEmpty() || new RegExp('^\\+?[\\(\\)\\d \\-]*$').test(this.wert.trim())
	}
}
