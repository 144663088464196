<div class="modal fade" bsModal #modalDirective="bs-modal" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-lg" data-backdrop="static">
		<div class="modal-content">

			<div class="modal-header">
				<h4 class="modal-title pull-left">Wichtige Information zur Fragebogenübermittlung</h4>
			</div>
			<div class="modal-body longertext">
				<p>Sehr geehrte Teilnehmer,</p>

				<p>Sie können bereits an uns übermittelte Fragebögen ab jetzt nicht mehr selbst verändern. Sollten noch Änderungen nötig
					sein, wenden Sie sich bitte direkt an uns. Wir sind an aktuellen Informationen weiterhin interessiert, können aber eine
					Einbeziehung in die laufende Recherche nicht mehr garantieren.</p>

				<p>Fragebögen, die Sie noch nicht an uns übermittelt haben, stehen Ihnen weiter vollständig zum Bearbeiten offen. Wenn Sie
					diese übermittelt haben und Sie dann noch Updates haben, kontaktieren Sie uns bitte.</p>

				<p>Unabhängig vom Übermittlungsstatus können Sie alle Fragebögen weiter lesen, drucken oder als PDF speichern.</p>

				<p>Ihre&nbsp;JUVE&nbsp;Redaktion</p>

				<div class="button-bar">
					<button class="btn btn-default" (click)="closeDialog()">Zur Kenntnis genommen</button>
				</div>

				<app-juve-ansprechpartnerbox></app-juve-ansprechpartnerbox>
			</div>
		</div>
	</div>
</div>
