<div class="row">
	<div class="col-md-6">
		<div class="form-group">
			<label for="{{ 'beschriftungPositiv' | newID }}" class="required">Beschriftung Positiv</label>
			<input type="text" class="form-control" id="{{ 'beschriftungPositiv' | lastID }}" [(ngModel)]="frage.beschriftungPositiv" name="beschriftungPositiv" />
		</div>
	</div>
	<div class="col-md-6">
		<div class="form-group">
			<label for="{{ 'beschriftungNegativ' | newID }}" class="required">Beschriftung Negativ</label>
			<input type="text" class="form-control" id="{{ 'beschriftungNegativ' | lastID }}" [(ngModel)]="frage.beschriftungNegativ" name="beschriftungNegativ" />
		</div>
	</div>
</div>
<div class="row">
	<div class="col-md-12">
		<div class="checkbox">
			<input
				type="checkbox"
				id="{{ 'begruendungsTextfeldAktiviert' | newID }}"
				[(ngModel)]="frage.begruendungsTextfeldAktiviert"
				name="begruendungsTextfeldAktiviert"
			/>
			<label for="{{ 'begruendungsTextfeldAktiviert' | lastID }}">
				Begründungs-Textfeld
			</label>
		</div>
	</div>
</div>
<div class="row" *ngIf="frage.begruendungsTextfeldAktiviert">
	<div class="col-md-6">
		<div class="form-group">
			<label for="{{ 'beschriftungBegruendung' | newID }}" class="required">Beschriftung Begründung</label>
			<input type="text" class="form-control" id="{{ 'beschriftungBegruendung' | lastID }}" [(ngModel)]="frage.beschriftungBegruendung" name="beschriftungBegruendung" />
		</div>
	</div>
	<div class="col-md-6">
		<div class="form-group">
			<label for="{{ 'begruendungBei' | newID }}" class="required">Anzeigen bei</label>
			<select class="form-control" id="{{ 'begruendungBei' | lastID }}" [(ngModel)]="frage.begruendungBei" name="beschriftungNegativ">
				<option value="POSITIV">Positiv</option>
				<option value="NEGATIV">Negativ</option>
				<option value="BEIDES">Positiv &amp; Negativ</option>
			</select>
		</div>
	</div>
</div>
