<div class="contact-container {{bluePanel ? 'blue-panel' : ''}}">
	<div class="contact-image">
		<img src="assets/portal/{{ imgName }}" class="avatar">
	</div>
	<div class="contact-details">
		<h4>{{ fullName }}</h4>
		<div class="jobtitle">{{ jobtitle }}
			<br *ngIf="zustaendigkeit"><span *ngIf="zustaendigkeit">{{ zustaendigkeit }}</span>
		</div>
		<p class="tel">Telefon: {{ phoneNumber }}</p>
	</div>
	<div class="contact-mail">
		<a href="mailto:{{ emailAddress }}" class="btn btn-primary">E-Mail</a>
	</div>
</div>
