import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Antwort} from 'app/shared/model/Antwort';

@Component({
	selector: 'app-viewer-abschnitt-frage',
	templateUrl: './ViewerFrageComponent.html',
	styleUrls: ['./ViewerFrageComponent.less'],
})
export class ViewerFrageComponent implements OnInit {

	@Input()
	frage: Frage;

	@Input()
	antwort: Antwort;

	@Input()
	abschnitt: Abschnitt;

	@Input()
	index: number;

	@Input()
	readonly: boolean;

	@Input()
	fertig: boolean;

	@Input()
	geprueft: boolean;

	@Output()
	entered = new EventEmitter<any>();

	@Output()
	imported = new EventEmitter<any>();

	@Output()
	fileuploadWorking = new EventEmitter<boolean>();

	_printView = false;

	constructor() {
	}

	@Input()
	set printView(value: boolean) {
		this._printView = value;
	}

	onEntered() {
		this.entered.emit();
	}

	onImported(antwort: Antwort) {
		this.antwort = antwort;
		this.imported.emit();
	}

	isReadonly() {
		return this.readonly;
	}

	isPrintView() {
		return this._printView;
	}

	ngOnInit() {

	}

	onFileuploadWorking(working: boolean) {
		this.fileuploadWorking.emit(working);
	}
}
