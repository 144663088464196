	import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {AbschnittInfoDto} from 'app/shared/model/AbschnittInfoDto';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

/*
 * Zugriff auf die Daten werden ueber AbschnittStorageService gewaehrt
 */
@Injectable()
export class AbschnittInfoService {

	constructor(private http: HttpClient,
		private alertService: AlertService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService,
		private previewService: PreviewService) {
	}

	getFragebogenAbschnitteInfo(fragebogen: Fragebogen): Promise<AbschnittInfoDto> {
		if (this.previewService.isInPreview()) {
			return Promise.resolve(new AbschnittInfoDto());
		} else {
			return this.http.get<AbschnittInfoDto>('/api/fragebogen/abschnitt-status',
				{
					params: this.aktuelleBerechtigungService.addCommonParams({
						fragebogen: fragebogen.id,
					})
				})
				.toPromise()
				.then(response => {
					return new AbschnittInfoDto(response);
				})
				.catch(this.alertService.handleHttpError);
		}
	}
}
