<div class="metalist-item-container">
	<a
		[class.inaktiv-ohne-verlinkung]="!hatFrageboegen()"
		class="metalist-item recherchekomplex"
		(click)="setCurrentRKundInstitution()"
		routerLink="/portal"
	>
		<i (click)="toggleOpen($event)"
		   [ngClass]="{'glyphicon-folder-open': open, 'glyphicon-folder-close': !open}"
		   class="glyphicon"
		></i>
		<div class="title">{{ name }}</div>

		<div *ngIf="hatFrageboegen()" class="btn btn-sm btn-default btn-go">»</div>

		<div *ngIf="zeigeRecherchekomplexAdminHinweis()" class="description">
			Sie sind Recherche-Ansprechpartner.
		</div>
		<div *ngIf="zeigeCoAdminHinweis()" class="description">
			Sie sind Recherche-Koordinator.
		</div>
		<div *ngIf="zeigeFragebogenAusfuellerHinweis()" class="description">
			Sie sind Fragenbogenausfüller.
		</div>
		<div *ngIf="zeigeAbschnittAusfuellerHinweis()" class="description">
			Sie sind Abschnittsausfüller.
		</div>
		<div *ngIf="zeigeWiedereroeffnenNichtMehrMoeglichHinweis()" class="description">
			Wiedereröffnen nach dem Übermitteln nun nicht mehr möglich.
		</div>
		<div *ngIf="zeigeNichtMehrAusfuellbarHinweis()" class="description">
			Recherchezeitraum beendet.
		</div>
		<div *ngIf="!hatFrageboegen()" class="description">
			Es sind für diesen Recherchekomplex momentan keine Fragebögen bearbeitbar!
		</div>
	</a>

	<ng-container *ngIf="open">
		<a
			*ngFor="let fragebogenDto of frageboegen"

			class="metalist-item fragebogen"
			(click)="setCurrentRKundInstitution()"
			routerLink="/fragebogen/{{ fragebogenDto.fragebogen.id }}"
		>
			<i class="glyphicon glyphicon-list-alt"></i>
			<div class="title">{{ fragebogenDto.fragebogen.name }}</div>

			<div class="description">
				{{ fragebogenDto.zustandsbeschreibung }}
			</div>

		</a>
	</ng-container>
</div>
