import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {Antwort} from 'app/shared/model/Antwort';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {FrageAntwortBuilderUtil} from 'app/shared/util/FrageAntwortBuilderUtil';
import {PreviewService} from 'app/form-viewer/service/PreviewService';
import {Alert} from 'app/shared/alert/service/Alert';
import {AktuelleBerechtigungService} from 'app/shared/service/AktuelleBerechtigungService';

@Component({
	selector: 'app-viewer-import-trigger-view',
	templateUrl: './ViewerImportTriggerComponent.html',
	styleUrls: ['./ViewerImportTriggerComponent.less']
})
export class ViewerImportTriggerComponent {
	@Input()
	frage: Frage;

	@Input()
	antwort: Antwort;

	@Input()
	isReadonly: boolean;

	@Output()
	imported: EventEmitter<Antwort> = new EventEmitter<Antwort>();

	@Output()
	fileuploadWorking: EventEmitter<boolean> = new EventEmitter<boolean>();

	hint: Alert;

	constructor(
		private http: HttpClient,
		private alertService: AlertService,
		private previewService: PreviewService,
		private aktuelleBerechtigungService: AktuelleBerechtigungService
	) {
	}

	private isImportable() {
		return this.frage.isExportableAsCsv();
	}

	isImportEnabled() {
		return this.previewService.isNotInPreview() && !this.isReadonly && this.isImportable();
	}

	generateWebserviceUrl() {
		return '/view/fragebogen/csv/import/?frage=' + this.frage.id + '&institution=' + this.aktuelleBerechtigungService.getInstitutionsId();
	}

	generateFileUploadName() {
		return 'fileupload_' + this.frage.id;
	}

	readFile(fileReader: HTMLInputElement) {
		const file: File = fileReader.files[0];
		const myReader: FileReader = new FileReader();

		myReader.onloadend = () => {
			this.http.post(
				this.generateWebserviceUrl(),
				myReader.result,
				{
					headers: new HttpHeaders().set('Content-Type', 'application/octet-stream')
				}
			)
				.toPromise()
				.then(response => {
					const neueAntwort: Antwort = FrageAntwortBuilderUtil.build(response);
					this.imported.emit(neueAntwort);
					this.alertService.dismissAlert(this.hint);
					this.alertService.showSuccess('Antworten wurden erfolgreich importiert.');
					this.fileUploadWorking(false);
				})
				.catch(this.alertService.handleHttpError)
				.catch(r => {
					this.fileUploadWorking(false);
					this.alertService.dismissAlert(this.hint);
					this.alertService.showWarning('Der Import ist fehlgeschlagen.');
					Promise.resolve(r);
				});
			fileReader.value = '';
		};

		myReader.readAsArrayBuffer(file);
	}

	onChange($event: Event) {
		if (!this.isImportEnabled()) {
			$event.preventDefault();
		} else {
			this.hint = this.alertService.showInfo('Datei wird importiert.');
			this.fileUploadWorking(true);
			this.readFile($event.target as HTMLInputElement);
		}
	}

	fileUploadWorking(working: boolean) {
		this.fileuploadWorking.emit(working);
	}
}
