import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Frage} from 'app/shared/model/Frage';
import {EditLockService} from 'app/shared/service/EditLockService';

@Component({
	selector: 'app-editor-abschnitt-frage-view',
	templateUrl: './FrageViewComponent.html',
	styleUrls: ['./FrageViewComponent.less']
})
export class FrageViewComponent implements OnInit {

	@Input()
	frage: Frage;

	@Output()
	edit = new EventEmitter();

	@Output()
	duplicate = new EventEmitter();

	@Output()
	remove = new EventEmitter();

	constructor(private lock: EditLockService) {
	}

	ngOnInit() {
	}

	beginEdit() {
		this.edit.emit();
	}

	beginDuplicate() {
		this.duplicate.emit();
	}

	commitRemove() {
		this.remove.emit();
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}
}
