import {Component, Input, OnInit} from '@angular/core';
import {TabellenFrage} from 'app/shared/model/frage/TabellenFrage';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {TabellenSpalte} from 'app/shared/model/frage/TabellenSpalte';
import {FixeZeile} from 'app/shared/model/frage/FixeZeile';

const debug = require('debug')('TabelleFixFrageEditComponent');

@Component({
	selector: 'app-editor-abschnitt-frage-edit-tabelle-fix',
	templateUrl: './TabelleFixFrageEditComponent.html',
	styleUrls: ['./TabelleFixFrageEditComponent.less']
})
export class TabelleFixFrageEditComponent implements OnInit {
	@Input()
	frage: TabellenFrage;

	@Input()
	availableTypes: typeof InputType[];

	ngOnInit() {
		if (!this.frage.hasSpalten()) {
			this.frage.addSpalte();
		}

		if (!this.frage.hasFixeZeilen()) {
			this.frage.addFixeZeile();
		}
	}

	getSpalten(): TabellenSpalte[] {
		return this.frage.spalten;
	}

	addSpalte() {
		this.frage.addSpalte();
	}

	removeSpalte(spalte: TabellenSpalte) {
		this.frage.removeSpalte(spalte);
	}

	hasMultipleSpalten() {
		return this.frage.spalten.length > 1;
	}

	removeSpaltenDisabled(): boolean {
		return !this.hasMultipleSpalten();
	}

	addZeile() {
		this.frage.addFixeZeile();
	}

	removeZeile(zeile: FixeZeile) {
		this.frage.removeFixeZeile(zeile);
	}

	hasMultipleZeilen() {
		return this.frage.fixeZeilen.length > 1;
	}

	removeZeilenDisabled(): boolean {
		return !this.hasMultipleZeilen();
	}

	onSpalteChanged(spalte: TabellenSpalte, type: InputType) {
		if (spalte.type !== type) {
			debug('Löschen der Vorjahres-Referenz aufgrund von Änderung des Feld-Typs');
			spalte.vorjahresTabellenFrageSpalte = null;
		}

		spalte.type = type;
	}
}
