<ng-template #defaultOptionTemplate let-option>
	{{ option }}
</ng-template>

<div class="combo form-control"
	 [class.open]="isOpen"
	 [class.notouch]="!isTouch"
	 [class.touch]="isTouch"
	 [class.disabled]="disabled"
	 tabindex="0"
	 appScrollToFocusedElement
	 (click)="toggle(); $event.stopPropagation()"
	 (blur)="close()"
	 (keydown)="onKeyDown($event)"
	 (keyup)="onKeyUp($event)"
>
	<select
		[(ngModel)]="selectedWert"
		[disabled]="disabled"
		id="{{ id }}"
		appScrollToFocusedElement
	>
		<option *ngFor="let option of options" [ngValue]="option">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': option}"
			></ng-template>
		</option>

		<option [value]="SONSTIGES_MARKER">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': sonstigesOption}"
			></ng-template>
		</option>
	</select>

	<div class="label">
		<div class="content">
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': displayOption}"
			></ng-template>
		</div>

		<i class="icon-chevron-down"></i>

	</div>
	<ul class="flyout list-unstyled">
		<li
			*ngFor="let option of options"
			(click)="onWertSelected(option); $event.stopPropagation()"
			[class.selected]="isWertSelected(option)"
			[class.highlighted]="isWertSelected(option)"
		>
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': option}"
			></ng-template>
		</li>

		<li
			(click)="onWertSelected(SONSTIGES_MARKER); $event.stopPropagation()"
			[class.selected]="isWertSelected(SONSTIGES_MARKER)"
			[class.highlighted]="isWertSelected(SONSTIGES_MARKER)"
		>
			<ng-template
				[ngTemplateOutlet]="optionTemplate"
				[ngTemplateOutletContext]="{'$implicit': sonstigesOption}"
			></ng-template>
		</li>
	</ul>
	<div class="sonstigesText" [class.hidden]="!sonstigesTextfeldSichtbar">
		<input
			#textInput
			class="form-control"
			type="text"
			[(ngModel)]="sonstigesWert"
			[disabled]="disabled"
			appScrollToFocusedElement
			appPreventDefault
		/>
	</div>
</div>
