<div class="form-group">

	<label for="password">
		Passwort
		<span [tooltip]="'Verwenden Sie mehrere Wörter, vermeiden Sie häufige Phrasen. Es ist nicht notwendig Symbole, Ziffern oder Großbuchstaben zu verwenden.'" class="info-icon blau glyphicon glyphicon-info-sign"></span>

	</label>
	<div [class.has-feedback]="!isPristine()" [class.has-error]="hasPasswordError()" [class.has-success]="hasAcceptablePassword()">
		<input
			type="password"
			class="form-control"
			id="password"
			name="password"
			autocomplete="new-password"
			#passwordFeld
			[(ngModel)]="password"
			(keyup)="onPasswordChanged()"
			(blur)="onPasswordChanged()"
			(change)="onPasswordChanged()"
			[tooltip]="getPasswordErrorMessage()"
		/>
		<span class="form-control-feedback" aria-hidden="true" *ngIf="hasPasswordError() && !isPristine()"><i class="icon-cross"></i></span>
		<span class="form-control-feedback" aria-hidden="true" *ngIf="hasAcceptablePassword() && !isPristine()"><i class="icon-check"></i></span>
	</div>

	<app-password-strength
		[score]="passwordScore"
		[warning]="passwordWarning"
	></app-password-strength>
</div>
<div class="form-group">
	<label for="matchingPassword">Passwort erneut eingeben</label>
	<div [class.has-feedback]="!isPristine()" [class.has-error]="hasMatchingPasswordError()" [class.has-success]="hasMatchingPassword()">
		<input
			type="password"
			class="form-control"
			id="matchingPassword"
			autocomplete="new-password"
			name="matchingPassword"
			[(ngModel)]="matchingPassword"
			[tooltip]="getMatchingPasswordErrorMessage()"
		/>
		<span class="form-control-feedback" aria-hidden="true" *ngIf="hasMatchingPasswordError() && !isPristine()"><i class="icon-cross"></i></span>
		<span class="form-control-feedback" aria-hidden="true" *ngIf="!hasMatchingPasswordError() && !isPristine()"><i class="icon-check"></i></span>
	</div>
</div>
