import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class AbschnittSaveEventServiceService {

	constructor() {
	}

	private savingEvent = new BehaviorSubject<Boolean>(false);

	emitSavingEvent(flag: Boolean) {
		this.savingEvent.next(flag)
	}

	savingEventListner() {
		return this.savingEvent.asObservable();
	}
}
