<nav class="navbar navbar-default">
	<div class="container-fluid">
		<div class="navbar-header">
			<span class="navbar-brand" *ngIf="!isNew()">„{{ abschnitt.ueberschrift }}“ bearbeiten</span>
			<span class="navbar-brand" *ngIf="isNew()">Neuer Abschnitt</span>
		</div>

		<div class="collapse navbar-collapse">
			<div class="navbar-right">
				<button (click)="cancelEdit()" type="button" class="btn btn-primary btn-xs navbar-btn"><i class="icon-cross"></i></button>
			</div>
		</div>
	</div>
</nav>

<form class="clearfix" (keydown.escape)="cancelEdit()" (keydown.enter)="commitEdit()">
	<div class="form-group">
		<label for="{{ 'ueberschrift' | newID }}" required>Titel</label>
		<input type="text" class="form-control" id="{{ 'ueberschrift' | lastID }}" placeholder="Titel" [(ngModel)]="abschnitt.ueberschrift"
			   name="ueberschrift">
	</div>
	<div class="form-group">
		<label for="{{ 'beschreibung' | newID }}" class="required">Beschreibung</label>
		<textarea class="form-control" id="{{ 'beschreibung' | lastID }}" [(ngModel)]="abschnitt.beschreibung"
				  name="beschreibung" appPreventSubmitOnEnter></textarea>
	</div>

	<div class="btn-toolbar pull-right" role="toolbar" ngPreserveWhitespaces>
		<button type="button" (click)="cancelEdit()" class="btn btn-default">Abbrechen</button>
		<button type="button" (click)="commitEdit()" class="btn btn-primary">Speichern</button>
	</div>
	<a *ngIf="!isNew()" (click)="commitRemove()" href appPreventDefault><i class="icon-trash"></i>Abschnitt &amp; Inhalt löschen</a>
</form>
