import {Component, Input} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeAuswahl} from 'app/shared/model/frage/input/InputTypeAuswahl';
import {InputTypeAuswahlAntwort} from 'app/shared/model/antwort/input/InputTypeAuswahlAntwort';

@Component({
	selector: 'app-input-type-auswahl-view',
	templateUrl: './InputTypeAuswahlViewComponent.html',
	styleUrls: ['./InputTypeAuswahlViewComponent.less']
})
export class InputTypeAuswahlViewComponent implements IInputTypeViewComponent<InputTypeAuswahl> {

	context = {};

	variant: string;

	@Input()
	antwort: InputTypeAuswahlAntwort;

	@Input()
	type: InputTypeAuswahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
