import {Antwort} from 'app/shared/model/Antwort';
import {KartenAntwort} from 'app/shared/model/antwort/KartenAntwort';
import {KartenFrage} from 'app/shared/model/frage/KartenFrage';
import {ClientIdUtil} from 'app/shared/util/ClientIdUtil';

export class KartenfragenAntwort extends Antwort {
	static readonly FRAGEN_TYPE_ID = 'KartenFrage';

	karten: KartenAntwort[] = [];

	constructor(antwort?: KartenfragenAntwort) {
		super(antwort);
		this.karten = [];

		if (antwort && antwort.karten) {
			this.karten = antwort.karten.map(karte => new KartenAntwort(karte));
		}
	}

	getGefuellteKarten(): KartenAntwort[] {
		return this.karten.filter(k => k.hatGefuellteAntworten());
	}

	deleteType() {
		super.deleteType();

		this.karten.forEach(karte =>
			karte.feldAntworten.forEach(feldAntwort =>
				delete feldAntwort.antwort.type
			)
		);
	}

	isEmpty() {
		const nonEmptyKartenAntworten = this.karten.filter(karte => {
			if (karte.kartenTitel.length > 0) {
				return true;
			}

			const nonEmptyAntworten = karte.feldAntworten.filter(
				feldAntwort => !feldAntwort.antwort.isEmpty());

			return nonEmptyAntworten.length > 0;
		});

		return nonEmptyKartenAntworten.length === 0;
	}

	addKarte(): KartenAntwort {
		const antwortKarte = new KartenAntwort();
		this.karten.push(antwortKarte);
		return antwortKarte;
	}

	applyIds(antwort: KartenfragenAntwort) {
		super.applyIds(antwort);

		ClientIdUtil.applyIdsBasedOnClientId(antwort.karten, this.karten);
	}

	istInhaltlichGleich(o: Antwort): boolean {
		if (!o || !(o instanceof KartenfragenAntwort)) {
			return false;
		}

		const trimmedKarten = this.karten.filter(k => k.hatGefuellteAntworten());
		const trimmedKartenOther = o.karten.filter(k => k.hatGefuellteAntworten());

		if (trimmedKarten.length !== trimmedKartenOther.length) {
			return false;
		}

		for (let i = 0; i < trimmedKarten.length; i++) {
			if (!trimmedKarten[i].istInhaltlichGleich(trimmedKartenOther[i])) {
				return false;
			}
		}

		return true;
	}

	isValid(): boolean {
		return this.karten.every(k => k.isValid());
	}
}
