import {InputTypeMehrfachauswahlViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-mehrfachauswahl-view/InputTypeMehrfachauswahlViewComponent';
import {InputTypeMehrfachauswahlEditComponent} from 'app/shared/components/input-type/mehrfachauswahl/InputTypeMehrfachauswahlEditComponent';
import {InputTypeMehrfachauswahlAntwort} from 'app/shared/model/antwort/input/InputTypeMehrfachauswahlAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';
import {InputTypeAuswahl} from 'app/shared/model/frage/input/InputTypeAuswahl';

export class InputTypeMehrfachauswahl extends InputType {
	static readonly ID = 'InputTypeMehrfachauswahl';

	static readonly TYPE_DISPLAY_NAME = 'Mehrfachauswahl';
	static readonly ANTWORT = InputTypeMehrfachauswahlAntwort;

	static readonly VIEWER = InputTypeMehrfachauswahlViewComponent;
	static readonly EDITOR = InputTypeMehrfachauswahlEditComponent;

	options: string[] = [];

	constructor(type?: InputTypeAuswahl) {
		super(type);
		if (type) {
			this.options = type.options;
		}
	}
}
