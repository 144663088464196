<input
	*ngIf="!printView"
	class="form-control text"
	[readonly]="readonly"
	[ngClass]="variant"
	type="text"
	placeholder="{{ getPlatzhalterText() }}"
	[(ngModel)]="antwort.wert"
	id="{{ id }}"
	appScrollToFocusedElement
/>

<div *ngIf="printView" class="text readonly" [ngClass]="variant">
	{{ this.antwort.wert ? this.antwort.wert : "-" }}
</div>
