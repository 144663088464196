<app-header></app-header>
<div class="container"
	 style="background-color: white; padding: 30px 0 20px 0 ; margin-bottom: 15px; border-radius: 5px;">
	<div class="row">
		<div class="col-md-8 col-md-offset-2">
			<h1>Datenschutz und Datensicherheit</h1>

			<h2>Wie wird JUVE&nbsp;Recherche entwickelt?</h2>
			<p>JUVE Rechereche wird in der europäischen Cloud von Microsoft Azure betrieben.
			   Die Rechenzentren von Microsoft befinden sich in Irland und den Niederlanden.
			   Genauere Informationen zu den Sicherheitsmaßnahmen, mit denen Microsoft die Rechenzentren schützt finden
			   Sie hier:<br/>
				<a target="_blank"
				   href="https://www.microsoft.com/de-de/cloud/sicheres-rechenzentrum">https://www.microsoft.com/de-de/cloud/sicheres-rechenzentrum</a>
			</p>
			<p>Informationen zu den Standorten der Rechenzentren finden Sie hier:<br/>
				<a target="_blank"
				   href="https://azure.microsoft.com/de-de/global-infrastructure/geographies/">https://azure.microsoft.com/de-de/global-infrastructure/geographies/</a>
			</p>

			<h2>Wo werden unsere Daten gespeichert und wie sind sie vor unbefugtem Zugriff geschützt?</h2>
			<p>Die eingegebenen Daten werden auf Servern gespeichert, die ausschließlich von unserem IT-Dienstleister betrieben werden.</p>

			<h2>Welche Maßnahmen ergreift JUVE, um den Zugriff auf die Daten zu kontrollieren:</h2>
			<ul>
				<li>Ausschließlich JUVE Mitarbeiter haben Zugriff auf die Daten, die wir ausschließlich für unsere Verlagszwecke
					verwenden.
					Die Daten werden nicht an Dritte weitergegeben.
				</li>
				<li>Die Einhaltung der Passwortrichtlinien wird zentral von der JUVE IT gesteuert und durchgesetzt.
					Die Passwörter bestehen aus einer Kombination aus Klein-, Großbuchstaben, Zahlen und gebräuchlichen Sonderzeichen
					mit
					einer Mindestlänge von 8 Zeichen. Nach 6 Monaten werden die Passwörter invalidiert,
					so dass zwingend ein neues Passwort generiert werden muss.
				</li>
				<li>Richtlinie zur sicheren Wahl und dem ordnungsgemäßen Umgang mit anderen Passwörtern</li>
				<li>externen Backup-Datenträgern</li>
				<li>automatisches Sperren des Bildschirms mit Passwortschutz nach maximal 10 Minuten</li>
				<li>zentrale softwaregestützte Kennwortverwaltung mit Zugriffshistorie</li>
				<li>dokumentierter Ablauf für das Anlegen und Sperren von neuen/scheidenden Mitarbeitern</li>
				<li>eindeutige Zuordnung von Benutzerkonten zu Benutzern</li>
				<li>Externer Zugriff auf das JUVE Netzwerk ist nur durch JUVE Mitarbeiter und mit Hilfe spezieller Software möglich.
					Die Benutzung der VPN Software ist nur möglich mit VPN-SSL Zertifikaten, die von der JUVE IT ausgegeben und
					verwaltet werden.
				</li>
			</ul>

			<h2>Sind meine Daten bei der Eingabe sicher?</h2>
			<ul>
				<li>JUVE&nbsp;Recherche ist durch ein SSL Zertifikat mit erweiterter Sicherheit von Thawte geschützt.
					Dieser Schutz ist an dem grünen Schlosssymbol in der Adressleiste des Browsers erkennbar.
					Das Zertifikat ermöglicht zum Einen die eindeutige Identifizierung des Webseitenbetreibers,
					zum Anderen garantiert es die wirkungsvolle Verschlüsselung aller eingegebenen Daten, so dass diese bei der
					Übertragung von Ihnen
					zu uns nicht ausgelesen werden können. Es handelt sich dabei um dieselbe Technik wie sie z.B. beim Online-Banking
					eingesetzt wird.
				</li>
				<li>Die sichere Verschlüsselung der Daten bei der Übertragung ist ein entscheidender Vorteil dieses Systems gegenüber
					anderen
					Übertragungsarten wie z.B. dem Verschicken per E-Mail.
				</li>
			</ul>
		</div>
	</div>
</div>

<app-footer></app-footer>
