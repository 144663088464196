<h2>Registrierung</h2>
<div *ngIf="zweiFaktorCodeFalsch" class="alert alert-danger offset-top" role="alert">Der eingegebene Code war falsch, bitte versuchen Sie es erneut.</div>

<div class="message"></div>

<form action="/view/registrieren" method="post" class="clearfix" #form (keydown.enter)="submit()">

	<div class="form-group">
		<label for="username">E-Mail-Adresse</label>
		<input
			type="text"
			class="form-control"
			id="username"
			name="user"
			autocomplete="username"
			[value]="username"
			style="pointer-events: none; background-color: lightgrey; color: #777777;"
			tabindex="-1"
		/>
	</div>

	<div class="form-group" style="padding:20px 0">
		<label for="{{ 'zweiFaktorCode' | newID }}">Code</label>
		<input
			type="text"
			class="form-control"
			id="{{ 'zweiFaktorCode' | newID }}"
			name="zweiFaktorCode"
			placeholder="z.B. F6E785"
			autocomplete="off"
			[(ngModel)]="zweiFaktorCode"
		/>
	</div>

	<app-xsrf-form-field></app-xsrf-form-field>

	<nav class="pull-right">
		<button
			type="button"
			class="btn btn-primary"
			[class.disabled]="buttonDisabled"
			(click)="submit()">
			Registrieren
		</button>
	</nav>
</form>
