import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';

@Component({
	selector: 'app-editor-abschnitt-edit',
	templateUrl: './AbschnittEditComponent.html',
	styleUrls: ['./AbschnittEditComponent.less']
})
export class AbschnittEditComponent implements OnInit {
	@Input()
	abschnitt: Abschnitt;

	@Output()
	cancel = new EventEmitter();

	@Output()
	commit = new EventEmitter();

	@Output()
	remove = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	isNew() {
		return !this.abschnitt.id;
	}

	commitEdit() {
		this.commit.emit();
	}

	cancelEdit() {
		this.cancel.emit();
	}

	commitRemove() {
		this.remove.emit();
	}
}
