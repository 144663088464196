import {InputTypeTelefonViewComponent} from "app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-telefon-view/InputTypeTelefonViewComponent";
import {InputTypeTelefonEditComponent} from "app/shared/components/input-type/telefon/InputTypeTelefonEditComponent";
import {InputTypeTelefonAntwort} from "app/shared/model/antwort/input/InputTypeTelefonAntwort";
import {InputType} from './InputType';

export class InputTypeTelefon extends InputType {
	static readonly ID = 'InputTypeTelefon';
	static readonly TYPE_DISPLAY_NAME = 'Telefon';
	static readonly ANTWORT = InputTypeTelefonAntwort;

	// add to @NgModule.entryComponents in app.module.ts
	static readonly VIEWER = InputTypeTelefonViewComponent;
	static readonly EDITOR = InputTypeTelefonEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeTelefon) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}

}
