import {UUID} from 'app/util/export-types';
import {Ausfueller} from 'app/form-viewer/model/Ausfueller';

export class FrageFertigMarker {
	id: UUID;
	frage_id: UUID;
	ausfueller: Ausfueller;
	created: Date;

	static build(marker: FrageFertigMarker): FrageFertigMarker {
		return new FrageFertigMarker(marker);
	}

	static buildList(list: FrageFertigMarker[]): FrageFertigMarker[] {
		return list.map(marker => FrageFertigMarker.build(marker));
	}

	constructor(frageFertigMarker?: FrageFertigMarker) {
		if (frageFertigMarker) {
			this.id = frageFertigMarker.id;
			this.frage_id = frageFertigMarker.frage_id;
			this.ausfueller = new Ausfueller(frageFertigMarker.ausfueller);
			this.created = new Date(frageFertigMarker.created);
		}
	}
}
