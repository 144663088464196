import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Frage} from 'app/shared/model/Frage';
import {EditLockService} from 'app/shared/service/EditLockService';
import {FrageBuilderUtil} from 'app/shared/util/FrageBuilderUtil';
import {KopieSubstringBuilder} from 'app/shared/util/KopieSubstringBuilder';

@Component({
	selector: 'app-editor-abschnitt-frage-liste',
	templateUrl: './FrageListeComponent.html',
	styleUrls: ['./FrageListeComponent.less']
})
export class FrageListeComponent implements OnInit {

	@Input()
	abschnitt: Abschnitt;

	@Output()
	fragenUmsortiert = new EventEmitter<Abschnitt>()

	constructor(private lock: EditLockService) {
	}

	ngOnInit() {
	}

	isNew() {
		return !this.abschnitt.id;
	}

	addFrage() {
		this.abschnitt.addFrage();
	}

	removeFrage(frage: Frage) {
		this.abschnitt.removeFrage(frage);
	}

	duplicateFrage(frage: Frage) {
		const newFrage = FrageBuilderUtil.build(frage);
		newFrage.clearIds();
		this.addKopieSubstringToUeberschrift(newFrage);
		this.abschnitt.addFrage(newFrage);
	}

	updateFrage(oldFrage: Frage, newFrage: Frage) {
		this.abschnitt.updateFrage(oldFrage, newFrage);
	}

	isReadOnly() {
		return this.lock.isLockedButNotFor(this);
	}

	canDrag() {
		return !this.lock.isLocked();
	}

	onReorder() {
		this.fragenUmsortiert.emit(this.abschnitt)
	}

	private addKopieSubstringToUeberschrift(frage: Frage) {
		frage.ueberschrift = frage.ueberschrift + ' ' + KopieSubstringBuilder.buildKopieSubstringFor(new Date());
	}
}
