import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {MessageService} from 'primeng/api';
import {Fragebogen} from '../../shared/model/Fragebogen';
import {Recherchekomplex} from '../../shared/model/Recherchekomplex';
import {FragebogenService} from '../../shared/service/FragebogenService';
import {selectRecherchekomplex} from '../../store/selector/recherchekomplex.selectors';
import {Ausfueller} from '../model/Ausfueller';
import {AusfuellerBerechtigungsService} from '../service/AusfuellerBerechtigungsService';
import {UserService} from '../service/UserService';

@Component({
			   templateUrl: './FormViewerWrapperComponent.html',
			   styleUrls: ['./FormViewerWrapperComponent.less']
		   })
export class FormViewerWrapperComponent implements OnInit {
	@BlockUI() blockUI: NgBlockUI;

	headerColor: string;
	logoColor: string;
	logoFirstLine: string;
	logoSecondLine: string;

	fragebogen: Fragebogen;
	istArchivzugriff: boolean;
	kontaktZumAufschliessen: Ausfueller[];

	loaded = false;

	constructor(private store: Store,
				private route: ActivatedRoute,
				private fragebogenService: FragebogenService,
				private ausfuellerBerechtigungsService: AusfuellerBerechtigungsService,
				private title: Title,
				private userService: UserService,
				private messageService: MessageService) {
		title.setTitle('Fragebogen ausfüllen - Juve Recherche');
	}

	ngOnInit(): void {
		this.blockUI.start('Daten werden verarbeitet...');
		try {
			try {
				this.messageService.clear();
			}
			finally {

			}

			this.userService.isAlive();

			const fragebogenId = this.route.snapshot.paramMap.get('fragebogenId');
			console.log('SHow Fragebogen ' + fragebogenId);
			Promise.all([
							this.fragebogenService.getFragebogen(fragebogenId).then(response => this.fragebogen = response),
							this.fragebogenService.getRecherchekomplexAdmins(fragebogenId).then(response => this.kontaktZumAufschliessen = response),
							this.istArchivzugriff = !(this.ausfuellerBerechtigungsService.gehoertZuRecherchekomplexUndInstitution())
						]).then(() => {
				this.loaded = true;
				this.title.setTitle(`Fragebogen ausfüllen: ${this.fragebogen.name} - Juve Recherche`);
			});

			this.setzeStyleNachRecherchekomplex();
		}
		finally {
			setTimeout(() => {
				try {
					this.blockUI.stop();
				} catch (exception) {
				}
			}, 1000);
		}
	}

	// TODO: in DB auslagern
	setzeStyleNachRecherchekomplex() {
		this.headerColor = '';
		this.logoColor = 'white';

		let recherchekomplex: Recherchekomplex;
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => recherchekomplex = value);

		if (recherchekomplex) {
			const name = recherchekomplex.name.toLowerCase();
			if (name.startsWith('top arbeitgeber')) {
				this.headerColor = '#CB2F40';
				if (name.startsWith('top arbeitgeber im steuermarkt')) {
					this.logoColor = 'black';
				}
			}
			if (name.startsWith('juve handbuch steuern') || name.startsWith('steuermarkt')) {
				this.headerColor = '#fae332';
				this.logoColor = 'black';
			}

			this.logoFirstLine = '';
			this.logoSecondLine = name;
		}
	}

}
