<div [ngClass]="variant" *ngIf="!printView">
	<app-multi-select
		[(wert)]="antwort.wert"
		[options]="type.options"
		[disabled]="readonly"
		id="{{ id }}"
	></app-multi-select>
</div>
<div *ngIf="printView" class="textfeld readonly">
	{{ this.antwort.wert ? this.antwort.wert.join(', ') : "-" }}
</div>
