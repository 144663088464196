import {Component, Input} from '@angular/core';
import {IInputTypeViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-view/IInputTypeView';
import {InputTypeMehrfachauswahlAntwort} from 'app/shared/model/antwort/input/InputTypeMehrfachauswahlAntwort';
import {InputTypeMehrfachauswahl} from 'app/shared/model/frage/input/InputTypeMehrfachauswahl';

@Component({
	selector: 'app-input-type-mehrfachauswahl-view',
	templateUrl: './InputTypeMehrfachauswahlViewComponent.html',
	styleUrls: ['./InputTypeMehrfachauswahlViewComponent.less']
})
export class InputTypeMehrfachauswahlViewComponent implements IInputTypeViewComponent<InputTypeMehrfachauswahl> {

	context = {};

	variant: string;

	@Input()
	antwort: InputTypeMehrfachauswahlAntwort;

	@Input()
	type: InputTypeMehrfachauswahl;

	@Input()
	id: string;

	@Input()
	readonly: boolean;

	@Input()
	printView: boolean;

	isReadonly() {
		return this.readonly;
	}
}
