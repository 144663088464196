import {Action, createReducer, on} from '@ngrx/store';
import {Recherchekomplex} from "../../shared/model/Recherchekomplex";
import {setRecherchekomplex} from "../action/recherchekomplex.actions";

export const recherchekomplexFeatureKey = 'recherchekomplex';

export interface RecherchekomplexState {
	recherchekomplex: Recherchekomplex
}

export const initialState: RecherchekomplexState = {
	recherchekomplex: null
};

export const recherchekomplexReducer = createReducer(
  initialState,
  on(setRecherchekomplex, (state: RecherchekomplexState, {recherchekomplex}) => ({...state, recherchekomplex: recherchekomplex}))
);

export function reducer(state: RecherchekomplexState | undefined, action: Action): any {
	return recherchekomplexReducer(state, action)
}
