import {TabelleFrageEditComponent} from 'app/form-editor/abschnitt-liste/abschnitt/frage-liste/frage/edit/tabelle/TabelleFrageEditComponent';
import {TabelleFrageViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/tabelle/TabelleFrageViewComponent';
import {Frage} from 'app/shared/model/Frage';
import {FixeZeile} from 'app/shared/model/frage/FixeZeile';
import {TabellenSpalte} from 'app/shared/model/frage/TabellenSpalte';
import {removeElement} from 'app/util/removeElement';
import {TabellenfragenAntwort} from "../antwort/TabellenfragenAntwort";

const debug = require('debug')('TabellenFrage');

export class TabellenFrage extends Frage {
	static readonly TYPE_DISPLAY_NAME = 'Tabelle';
	static readonly TYPE_ICON_NAME = 'table';
	static readonly ID = 'TabellenFrage';

	static readonly EDITOR = TabelleFrageEditComponent;
	static readonly VIEWER = TabelleFrageViewComponent;

	spalten: TabellenSpalte[];
	fixeZeilen: FixeZeile[];

	constructor(frage?: TabellenFrage) {
		super(frage);

		if (frage) {
			this.constructFromTabellenFrage(frage);
		} else {
			this.constructEmpty();
		}
	}

	private constructFromTabellenFrage(frage: TabellenFrage) {
		this.spalten = [];
		if (frage.spalten) {
			this.spalten = frage.spalten.map(spalte => new TabellenSpalte(spalte));
		}

		this.fixeZeilen = [];
		if (frage.fixeZeilen) {
			this.fixeZeilen = frage.fixeZeilen.map(zeile => new FixeZeile(zeile));
		}

		this.aeltesteAntwort = frage.aeltesteAntwort ? new TabellenfragenAntwort(frage.aeltesteAntwort as TabellenfragenAntwort) : null;
		this.neuesteAntwort = frage.neuesteAntwort ? new TabellenfragenAntwort(frage.neuesteAntwort as TabellenfragenAntwort) : null;
	}

	private constructEmpty() {
		this.spalten = [];
		this.fixeZeilen = [];
	}

	typeDisplayName() {
		return TabellenFrage.TYPE_DISPLAY_NAME;
	}

	typeIconName() {
		return TabellenFrage.TYPE_ICON_NAME;
	}

	isErweiterbar() {
		return this.fixeZeilen.length === 0;
	}

	clearFixeZeilen() {
		this.fixeZeilen = [];
	}

	addFixeZeile() {
		this.fixeZeilen.push(new FixeZeile());
	}

	removeFixeZeile(zeile: FixeZeile) {
		removeElement(this.fixeZeilen, zeile);
	}

	hasFixeZeilen() {
		return this.fixeZeilen.length > 0;
	}

	addSpalte() {
		this.spalten.push(new TabellenSpalte());
	}

	removeSpalte(spalte: TabellenSpalte) {
		removeElement(this.spalten, spalte);
	}

	hasSpalten() {
		return this.spalten.length > 0;
	}

	anzahlSpalten() {
		return this.spalten.length;
	}

	clearIds() {
		super.clearIds();
		this.spalten.forEach(spalte => spalte.clearIds());
		this.fixeZeilen.forEach(zeile => zeile.clearIds());
	}

	anzahlFixeZeilen() {
		return this.fixeZeilen.length;
	}

	getZeilentitel(zeilenIndex: number) {
		return this.fixeZeilen[zeilenIndex].titel;
	}

	getFixeZeilenAnzahl() {
		return this.fixeZeilen.length;
	}

	isExportableAsCsv() {
		return true;
	}
}
