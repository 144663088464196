<div class="container-fluid">
	<app-alert></app-alert>
	<app-form-editor-header></app-form-editor-header>
	<p-tabView [(activeIndex)]="index" class="col-xs-12">
		<p-tabPanel header="Bearbeitbare Fragebögen">
			<app-editor-fragebogen-list
				ueberschrift="Bearbeitbare Fragebögen"
				[recherchekomplexe]="recherchekomplexe"
				[frageboegenByRecherchekomplex]="bearbeitbareFrageboegen"
				(duplicateCreated)="onDuplicateCreated($event)"
				(fragebogenVeroeffentlicht)="onFragebogenVeroeffentlicht($event)"
			></app-editor-fragebogen-list>
		</p-tabPanel>
		<p-tabPanel header="Veröffentlichte Fragebögen">
			<app-editor-fragebogen-list
				ueberschrift="Veröffentlichte Fragebögen"
				[recherchekomplexe]="recherchekomplexe"
				[frageboegenByRecherchekomplex]="veroeffentlichteFrageboegen"
				*ngIf="veroeffentlichteFrageboegen"
				(duplicateCreated)="onDuplicateCreated($event)"
			></app-editor-fragebogen-list>
		</p-tabPanel>
	</p-tabView>
</div>
