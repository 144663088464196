import {InputTypeTextViewComponent} from 'app/form-viewer/abschnitt/frage-liste/frage/view/input/input-type-text-view/InputTypeTextViewComponent';
import {InputTypeTextEditComponent} from 'app/shared/components/input-type/text/InputTypeTextEditComponent';
import {InputTypeTextAntwort} from 'app/shared/model/antwort/input/InputTypeTextAntwort';
import {InputType} from 'app/shared/model/frage/input/InputType';

export class InputTypeText extends InputType {
	static readonly ID = 'InputTypeText';
	static readonly TYPE_DISPLAY_NAME = 'Text';
	static readonly ANTWORT = InputTypeTextAntwort;

	static readonly VIEWER = InputTypeTextViewComponent;
	static readonly EDITOR = InputTypeTextEditComponent;

	platzhalterText = '';

	constructor(type?: InputTypeText) {
		super(type);
		if (type) {
			this.platzhalterText = type.platzhalterText;
		}
	}
}
