<p-inputNumber
	[min]="0"
	[maxlength]="getMaxLength()"
	(onInput)="showTooltipIfMaxlength($event.value)"
	title="Maximale Länge erreicht"
	triggers=""
	(onBlur)="tooltip.hide()"
	*ngIf="!printView"
	[inputStyleClass]="'form-control text'"
	[useGrouping]="false"
	[disabled]="readonly"
	[ngClass]="variant"
	placeholder="{{ getPlatzhalterText() }}"
	[(ngModel)]="antwort.wert"
	id="{{ id }}"
	appScrollToFocusedElement></p-inputNumber>

<div *ngIf="printView" class="text readonly" [ngClass]="variant">
	{{ this.antwort.wert ? this.antwort.wert : "-" }}
</div>
