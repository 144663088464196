import {Injectable} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {QueryParamsService} from 'app/shared/service/QueryParamsService';
import {UUID} from 'app/util/export-types';
import {selectInstitution} from "../../store/selector/institution.selectors";
import {selectRecherchekomplex} from "../../store/selector/recherchekomplex.selectors";

@Injectable()
export class AktuelleBerechtigungService {
	constructor(private queryParamsService: QueryParamsService, private store: Store) {
	}

	getInstitutionsId(): UUID {
		let institution;
		this.store.pipe(select(selectInstitution)).subscribe(value => institution = value)
		return institution ? institution.id : null
	}

	getRecherchekomplexId(): UUID {
		let recherchekomplex;
		this.store.pipe(select(selectRecherchekomplex)).subscribe(value => recherchekomplex = value)
		return recherchekomplex ? recherchekomplex.id : null
	}

	addCommonParams(params: any) {
		const additionalParams = this.getCommonParams();
		return Object.assign({}, additionalParams, params);
	}

	addCommonParamsToQueryString(queryParams: { [key: string]: string } = {}): string {
		return this.encodeQueryData(Object.assign({}, this.getCommonParams(), queryParams));
	}

	private getCommonParams() {
		const additionalParams = {};
		this.addIfNotNull(additionalParams, 'institution', this.getInstitutionsId());
		this.addIfNotNull(additionalParams, 'recherchekomplex', this.getRecherchekomplexId());
		return additionalParams;
	}

	private addIfNotNull(additionalParams: {}, key: string, value: UUID) {
		const institutionId = value;
		if (institutionId != null) {
			additionalParams[key] = institutionId;
		}
	}

	private encodeQueryData(data: { [key: string]: string }): string {
		return Object.keys(data).map(function(key) {
			return [key, data[key]].map(encodeURIComponent).join('=');
		}).join('&');
	}
}
