import {EventEmitter, Injectable} from '@angular/core';
import {Abschnitt} from 'app/shared/model/Abschnitt';

const debug = require('debug')('service:browser:AbschnittUrlHashService');

@Injectable()
export class AbschnittUrlHashService {
	private abschnitte: Abschnitt[];
	private selectedAbschnitt: Abschnitt = null;

	abschnittSelected = new EventEmitter<Abschnitt>();
	abschnittDeselected = new EventEmitter<any>();

	static getHashValue() {
		const hash = window.location.hash;

		if (hash[0] === '#') {
			return hash.substr(1);
		}

		return hash;
	}

	static setHashValue(hashvalue: string) {
		if (AbschnittUrlHashService.getHashValue() === hashvalue) {
			return;
		}

		AbschnittUrlHashService.setHash(hashvalue);
	}

	static setHash(hash: string) {
		debug('updated Hash to', hash);
		window.location.hash = hash;
	}

	constructor() {
		this.registerEventListener();
	}

	registerEventListener() {
		window.addEventListener(
			'hashchange',
			() => this.onHashChanged(),
			false
		);
	}

	selectAbschnitt(abschnitt: Abschnitt) {
		this.selectedAbschnitt = abschnitt;
		this.updateUrlHash();
	}

	deselectAbschnitt() {
		this.selectedAbschnitt = null;
		this.updateUrlHash();
	}

	isAbschnittSelected() {
		return this.selectedAbschnitt !== null;
	}

	onHashChanged() {
		this.reinterpretHash();
	}

	initializeWithAbschnitte(abschnitte: Abschnitt[]) {
		this.abschnitte = abschnitte;
		this.reinterpretHash();
	}

	private reinterpretHash() {
		const hash = AbschnittUrlHashService.getHashValue();
		const abschnittPointedToByHash = this.abschnitte.find(abschnitt => abschnitt.id === hash);

		const isAbschnittSelected = !!this.selectedAbschnitt;
		const shouldDeselectCurrentAbschnitt = !abschnittPointedToByHash && isAbschnittSelected;
		const shouldSelectNewAbschnitt = abschnittPointedToByHash && (this.selectedAbschnitt !== abschnittPointedToByHash);

		if (
			shouldDeselectCurrentAbschnitt ||
			(isAbschnittSelected && shouldSelectNewAbschnitt)
		) {
			debug('Deselected Abschnitt', this.selectedAbschnitt, 'from Hash');
			this.abschnittDeselected.emit();
			this.selectedAbschnitt = null;
		}

		if (shouldSelectNewAbschnitt) {
			this.selectedAbschnitt = abschnittPointedToByHash;

			debug('Selected Abschnitt', this.selectedAbschnitt, 'from Hash', hash);
			this.abschnittSelected.emit(this.selectedAbschnitt);
		}
	}

	private updateUrlHash() {
		if (this.selectedAbschnitt === null) {
			AbschnittUrlHashService.setHashValue('');
		} else {
			AbschnittUrlHashService.setHashValue(this.selectedAbschnitt.id);
		}
	}
}
