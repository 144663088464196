<p-toast></p-toast>

<div class="fragebogenliste" *ngIf="recherchekomplexDtos.length > 0">
	<ng-container *ngFor="let recherchekomplexDto of recherchekomplexDtos">
		<app-portal-recherchekomplex-eintrag [institution]="institution"
											 [sichtbarerRecherchekomplex]="recherchekomplexDto"></app-portal-recherchekomplex-eintrag>
	</ng-container>
</div>

<div *ngIf="recherchekomplexDtos.length == 0" style="text-align: center;">
	<h4 style="color: brown; margin-top: 50px; margin-bottom: 50px; text-decoration: underline;">Aktuell sind keine Fragebögen verfügbar</h4>
</div>
