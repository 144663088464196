import {ViewportScroller} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Abschnitt} from 'app/shared/model/Abschnitt';
import {Fragebogen} from 'app/shared/model/Fragebogen';
import {AntwortVersion} from '../../shared/model/AntwortVersion';
import {Institution} from '../../shared/model/Institution';
import {AntwortAnsichtService} from '../service/AntwortAnsichtService';
import {BlockUI, NgBlockUI} from "ng-block-ui";

const debug = require('debug')('FormEditorComponent');

@Component({
	selector: 'app-form-editor',
	templateUrl: './EditorAntwortenComponent.html',
	styleUrls: ['./EditorAntwortenComponent.less'],
})
export class EditorAntwortenComponent implements OnInit {

	@BlockUI() blockUI: NgBlockUI;

	fragebogenId: string;
	fragebogen: Fragebogen;
	institutionId: string;
	institution: Institution;
	abschnitte: Abschnitt[];
	version: AntwortVersion;
	existierenMehrereVersionen: boolean;

	diffEinblenden: boolean;

	constructor(private route: ActivatedRoute,
				private viewportScroller: ViewportScroller,
				private antwortAnsichtService: AntwortAnsichtService,
				private title: Title) {
		title.setTitle('Fragebogen-Editor - Juve Recherche');

		this.fragebogenId = this.route.snapshot.paramMap.get('fragebogenId');
		this.institutionId = this.route.snapshot.paramMap.get('institutionId');
	}

	ngOnInit(): void {
		if (this.institutionId) {
			this.antwortAnsichtService.getAntwortAnsichtInstitution(this.fragebogenId, this.institutionId).then(dto => {
				this.institutionId = dto.institution.id;
				this.institution = dto.institution;
				this.fragebogen = dto.fragebogen;
				this.abschnitte = dto.abschnitte;
				this.version = dto.version;
				this.existierenMehrereVersionen = dto.existierenMehrereVersionen;
			});
		} else {
			this.antwortAnsichtService.getAntwortAnsicht(this.fragebogenId).then(dto => {
				this.institution = dto.institution;
				this.fragebogen = dto.fragebogen;
				this.abschnitte = dto.abschnitte;
				this.version = dto.version;
				this.existierenMehrereVersionen = dto.existierenMehrereVersionen;
			});
		}
	}

	toggleDiffEinblenden() {
		this.diffEinblenden = !this.diffEinblenden;
	}

	selectInstitution(institution: Institution) {
		this.blockUI.start("Daten werden verarbeitet...")
		this.antwortAnsichtService.getAntwortAnsichtInstitution(this.fragebogenId, institution.id)
			.then(dto => {
				this.institution = dto.institution;
				this.fragebogen = dto.fragebogen;
				this.abschnitte = dto.abschnitte;
				this.version = dto.version;
				this.existierenMehrereVersionen = dto.existierenMehrereVersionen;
			})
			.finally(this.blockUI.stop);
	}

	goToAbschnitt(id: string) {
		this.viewportScroller.scrollToAnchor(`a-${id}`);
	}

	goToFrage(id: string) {
		this.viewportScroller.scrollToAnchor(`f-${id}`);
	}
}
