import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AlertService} from 'app/shared/alert/service/AlertService';
import {Fragebogen} from 'app/shared/model/Fragebogen';

@Injectable()
export class FragebogenLoeschenService {

	constructor(private http: HttpClient, private alertService: AlertService) {
	}

	loeschen(fragebogen: Fragebogen): Promise<any> {
		return this.http
			.delete('/api/editor/fragebogen/' + fragebogen.id)
			.toPromise()
			.catch(this.alertService.handleHttpError);
	}
}
