<div class="fragebogen-editor">
	<div class="container-fluid">
		<div class="row">
			<app-form-editor-header [fragebogen]="fragebogen">
				<li class="dropdown">
					<app-institutions-auswahl [institutionId]="institutionId"
											  [fragebogenName]="institution ? institution.anzeigename : ''"
											  [fragebogenId]="fragebogenId"
											  (institutionSelected)="selectInstitution($event)">
						<a href="#">
							{{ institution ? institution.anzeigename : 'Institution auswählen' }}
							<span class="caret"></span>
						</a>
					</app-institutions-auswahl>
				</li>
			</app-form-editor-header>
		</div>
		<div class="row">
			<div class="col-lg-3 visible-lg">
				<div class="sidebar" style="top: 0; background-color: white;">
					<span *ngIf="version" class="status-info">
						Abgeschlossen am {{ version.created | date }}<br/>
						Durch {{ version.createdByUser.anrede }}
						<span class="nowrap">{{ version.createdByUser.anzeigename }}</span>
					</span>

					<ul class="list-unstyled abschnitte">
						<li *ngFor="let abschnitt of abschnitte" class="{{abschnitt.beantwortet ? '' : 'disabled'}}">
							<h2>
								<a (click)="goToAbschnitt(abschnitt.id)" href="" appPreventDefault>{{abschnitt.ueberschrift}}</a>
							</h2>
							<ul class="list-unstyled fragen">
								<li *ngFor="let frage of abschnitt.fragen" class="{{!!frage.neuesteAntwort ? '' : 'disabled'}}">
									<a (click)="goToFrage(frage.id)" href="" appPreventDefault>{{ frage.ueberschrift }}</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-9">
				<div *ngIf="institution" class="content" style="background-color: white; padding: 10px 20px;">
					<ul class="list-unstyled abschnitte" id="abschnitteUl">
						<p *ngIf="existierenMehrereVersionen">
							<a (click)="toggleDiffEinblenden()" href="" appPreventDefault>
								{{diffEinblenden ? 'Vergleich ausschalten' : 'Vergleich mit ursprünglicher Version'}}
							</a>
						</p>
						<div *ngIf="existierenMehrereVersionen" class="antwort-div">
							<div class="aktuelleVersion"><h2>Aktuelle Version</h2></div>
							<div *ngIf="diffEinblenden" class="urspruenglicheVersion"><h2>Ursprüngliche Version</h2></div>
						</div>

						<li *ngFor="let abschnitt of abschnitte" class="{{abschnitt.beantwortet ? '' : 'disabled'}}">
							<a id="a-{{ abschnitt.id }}"></a>
							<h2>{{ abschnitt.ueberschrift }}</h2>

							<ul class="list-unstyled fragen">
								<li *ngFor="let frage of abschnitt.fragen" class="antwort">
									<a id="f-{{ frage.id }}"></a>
									<h3 class="{{!!frage.neuesteAntwort ? '' : 'keine_antwort'}}">{{frage.ueberschrift}}</h3>
									<div class="antwort-div">
										<div *ngIf="existierenMehrereVersionen && diffEinblenden"
											 class="halbeBreite urspruenglicheVersion {{frage.getDiffClass()}}">
											<app-antwort [antwort]="frage.aeltesteAntwort" [frage]="frage"></app-antwort>
										</div>
										<div class="{{existierenMehrereVersionen && diffEinblenden ? 'halbeBreite' : 'ganzeBreite'}} aktuelleVersion {{frage.getDiffClass()}}">
											<app-antwort [antwort]="frage.neuesteAntwort" [frage]="frage"></app-antwort>
										</div>
									</div>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>
