import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'plural'})
export class SimplePluralPipe implements PipeTransform {
	private static WORDS: { [key: number]: string } = {
		2: 'zwei',
		3: 'drei',
		4: 'vier',
		5: 'fünf',
		6: 'sechs',
		7: 'sieben',
		8: 'acht',
		9: 'neun',
		10: 'zehn',
		11: 'elf',
		12: 'zwölf',
	};

	/**
	 * Beispiele:
	 *
	 *   transform(this.anzahlDerAbschnitte, 'ein Abschnitt', 'Abschnitte')
	 *   - ein Abschnitt
	 *   - zwei Abschnitte
	 *   …
	 *   - 20 Abschnitte
	 *
	 *   transform(this.anzahlMäuse, 'eine Maus', 'Mäuse')
	 *   - eine Maus
	 *   - zwei Mäuse
	 *   …
	 *   - 20 Mäuse
	 */
	static transform(number: number, singular: string, plural: string): string {
		if (number === 0) {
			return 'keine ' + plural;
		} else if (number === 1) {
			return singular;
		} else if (number <= 12) {
			return SimplePluralPipe.WORDS[number] + ' ' + plural;
		} else {
			return number + ' ' + plural;
		}
	}

	// Existiert um das PipeTransform Interface zu bedienen!
	transform(number: number, singular: string, plural: string): string {
		return SimplePluralPipe.transform(number, singular, plural);
	}
}
