<app-viewer-abschnitt-sperre
	*ngIf="showAbschnittSperrenhinweis()"
	[abschnitt]="abschnitt"
></app-viewer-abschnitt-sperre>

<div *ngIf="isImporting">
	<div class="fixed spinner">
		<app-spinner></app-spinner>
	</div>
	<div class="fixed overlay"></div>
</div>

<h2>{{ fragebogen.name }}</h2>
<h1>{{ abschnitt.ueberschrift }}</h1>

<div>
	<app-viewer-abschnitt-frage-liste
		#frageListe
		(alsFertigMarkiert)="onAlsFertigMarkiert($event)"
		(alsGeprueftMarkiert)="onAlsGeprueftMarkiert($event)"
		(alsUnfertigMarkiert)="onAlsUnfertigMarkiert($event)"
		(alsUngeprueftMarkiert)="onAlsUngeprueftMarkiert($event)"
		(fileuploadWorking)="onFileuploadWorking($event)"
		(frageEntered)="frageEntered($event)"
		(imported)="onImported()"
		*ngIf="isLoaded()"
		[abschnitt]="abschnitt"
		[antwort]="antwort"
		[ausfueller]="ausfueller"
		[frageFertigMarker]="frageFertigMarker"
		[frageGeprueftMarker]="frageGeprueftMarker"
		[meineFragenFertigMarker]="meineFragenFertigMarker"
		[readonly]="readonly"
	></app-viewer-abschnitt-frage-liste>

	<!--	<span *ngIf="!isLoaded()">-->
	<!--		<app-spinner></app-spinner>-->
	<!--	</span>-->
</div>
<div class=" btn-toolbar save-footer" ngPreserveWhitespaces role="toolbar">
	<div id="saveContainer" class="container" style="padding-bottom: 5px; margin-bottom: 5px; border-bottom: 1px solid #CECECE; display: none">
		<div class="btn-group pull-right">
			<button (click)="saveAndBlock()" class="btn btn-primary" style="width: 201px" type="button">Speichern</button>
		</div>

		<div class="save-indicator pull-right">
			<app-viewer-frage-autosave-indicator
				#autosaveIndicator
			></app-viewer-frage-autosave-indicator>
		</div>

		<span style="display: inline-block; padding: 8px 12px; color: white; background-color: #c9302c; border-radius: 3px;">
			Es gibt ungespeicherte Änderungen
		</span>
	</div>
	<div class="container">
		<div class="btn-group pull-right">
			<button (click)="onCommitSaveThenClose()" class="btn btn-primary" type="button">Zurück zur Abschnittsliste</button>
		</div>

		<app-viewer-fragen-nav
			*ngIf="isLoaded()"
			[abschnitt]="abschnitt"
			[aktiveFrage]="aktiveFrage"
		></app-viewer-fragen-nav>
	</div>
</div>
